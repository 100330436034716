import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  display: none;

  ${mediaQuery.sm} {
    height: 3.5rem;
    background-color: var(--primary-background-cta);
    color: var(--primary-text-contrast);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    z-index: 5;
  }
`;
