/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';

import { Button } from 'components/atoms/_common';
import TabContent, { TabContentProps } from './TabContent';

import { Container, HeaderContainer, ContentContainer } from './styles';
import { TabSectionContext } from './context';

export type TabKey = string;

export interface Tab {
  key: TabKey;
  label: string;
  icon: string;
}

export interface Tabs {
  [key: TabKey]: Tab;
}
interface TabSectionProps {
  tabs: Tabs;
  children: React.ReactNode;
  spaced?: boolean;
  activeTab: string;
  setActiveTab: (tabKey: TabKey) => void;
}

const TabSection: React.FC<TabSectionProps> & {
  Content: React.FC<TabContentProps>;
} = ({ tabs, children, spaced, activeTab, setActiveTab }: TabSectionProps) => {
  return (
    <Container className="tab-section">
      <HeaderContainer spaced={spaced}>
        {Object.values(tabs).map(tab => (
          <Button
            showLoading={false}
            variant={tab.key === activeTab ? 'header-active' : 'header'}
            key={`tab-header-${tab.key}`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </Button>
        ))}
      </HeaderContainer>
      <TabSectionContext.Provider value={{ activeTab }}>
        <ContentContainer>{children}</ContentContainer>
      </TabSectionContext.Provider>
    </Container>
  );
};

TabSection.defaultProps = {
  spaced: false,
};

TabSection.Content = TabContent;

export default TabSection;
