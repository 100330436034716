import { useState } from 'react';
import { Table } from 'components/atoms';
import { Button, Form, Input, Select } from 'components/atoms/_common';
import { Modal } from 'components/atoms/_util';
import { useAppDispatch } from 'hooks';
import { Member } from 'shared/types/Member';
import { updateCompanyCaptableRequest } from 'store/slices/company';
import { Container } from './styles';

const CreateModal = ({
  members,
  onClose,
  companyId,
  fetchCaptable,
}: {
  members: Member[];
  onClose: () => void;
  fetchCaptable: () => void;
  companyId: string;
}) => {
  const dispatch = useAppDispatch();
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);

  const [ownersList, setOwnersList] = useState<
    {
      member: {
        username: string;
        name: string;
        role: string;
      };
      percentage: number;
    }[]
  >([]);

  const updateList = (values: any) => {
    const percentage = Number(values.percentage);
    const foundMember = members.find(m => m.username === values.member);

    if (!foundMember) {
      alert('Member not found.');
      return;
    }

    if (percentage <= 0) {
      alert('Percentage should be greater than 0.');
      return;
    }

    const member = {
      username: foundMember?.username,
      name: foundMember?.full_name,
      role: foundMember?.role === 'admin' ? 'founder' : 'shareholder',
    };

    const totalOfShares = ownersList.reduce(
      (acc, item) => acc + item.percentage,
      0
    );

    if (totalOfShares + percentage > 100) {
      alert('Total of shares cannot be more than 100%.');
      return;
    }

    const isMemberAlreadySelected = selectedMembers.find(
      username => username === member.username
    );

    if (isMemberAlreadySelected) {
      return;
    }

    setSelectedMembers(s => [...s, member.username]);
    setOwnersList(s => [...s, { member, percentage }]);
  };

  const createCaptable = () => {
    if (ownersList.length === 0) {
      alert('Please add at least one owner.');
      return;
    }

    const totalOfShares = ownersList.reduce(
      (acc, item) => acc + item.percentage,
      0
    );

    if (totalOfShares !== 100 || totalOfShares > 100) {
      alert('Total of shares should be 100%.');
      return;
    }

    const callback = () => {
      fetchCaptable();
      onClose();
    };

    dispatch(
      updateCompanyCaptableRequest({
        companyId,
        data: ownersList,
        callback,
      })
    );
  };

  return (
    <Modal open onClose={onClose}>
      <Container>
        <Form onSubmit={updateList} schema={{}}>
          <div className="row-form">
            <Select
              // only show members that are not already selected
              options={members
                .filter(m => !selectedMembers.includes(m.username))
                .map(m => ({
                  id: m.username,
                  label: m.full_name,
                }))}
              name="member"
              className="member"
              label="Select a member:"
            />
            <Input
              type="number"
              step={0.01}
              className="percentage"
              name="percentage"
              label="Percentage of shares (%):"
              max={100}
            />
            <Button type="submit" variant="outlined">
              Add owner
            </Button>
          </div>
        </Form>
        <Table>
          <thead>
            <tr>
              <th>Owner</th>
              <th>Percentage of share (%)</th>
            </tr>
          </thead>
          <tbody>
            {ownersList.map(owner => (
              <tr key={owner.member.username}>
                <td>{owner.member.name}</td>
                <td>{owner.percentage}%</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button full onClick={createCaptable}>
          Create Captable with this structure
        </Button>
      </Container>
    </Modal>
  );
};

export default CreateModal;
