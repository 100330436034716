/* eslint-disable react/no-danger */
import { useState } from 'react';
import { Button, Icon } from 'components/atoms/_common';
import CatawbaLogo from 'assets/images/catawba-logo-2.svg';
import { useNavigate } from 'react-router-dom';

import { useRouteParams } from 'hooks';
import INFORMATION_BY_KIND from './information.data';
import { Container } from './styles';

interface Props {
  kind: string;
  type?: 'success' | 'failure';
  children?: React.ReactNode;
}

const Status: React.FC<Props> = ({ kind, type: typeProp, children }: Props) => {
  const navigate = useNavigate();
  const [type, setType] = useState(typeProp || null);

  useRouteParams(
    typeQuery => {
      if ((typeQuery && typeQuery === 'success') || typeQuery === 'failure') {
        setType(typeQuery);
      }
    },
    ['type']
  );

  const content = INFORMATION_BY_KIND[kind][type];

  const redirect = () => navigate(content.redirect.path || '/');

  return type ? (
    <Container type={type}>
      <img src={CatawbaLogo} alt="Catawba logo" />
      <h2>{content.title}</h2>
      <h4>{content.subtitle}</h4>
      {content.hint && <p dangerouslySetInnerHTML={{ __html: content.hint }} />}
      {content.actionHint && <content.actionHint />}

      <Button variant="outlined" onClick={redirect}>
        {content.redirect.label}
        <Icon name={content.redirect.icon} />
      </Button>
      {children}
    </Container>
  ) : null;
};

Status.defaultProps = {
  type: null,
  children: null,
};

export * from './status.d';
export default Status;
