import styled, { css } from 'styled-components';

export const Container = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  width: 44px;
  height: 44px;
  padding: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid var(--neutrals-border);

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      background-color: var(--neutrals-border);
      color: var(--neutrals-text-weak-passive);
    `}
`;
