import React from 'react';

export const TabSectionContext = React.createContext<{
  activeTab?: string;
}>({});

export const useTabSectionContext = () => {
  const context = React.useContext(TabSectionContext);
  if (context === undefined) {
    throw new Error('useTabSection must be used within a TabSection');
  }
  return context;
};
