import React from 'react';
import { Section } from 'components/molecules';
import { Container } from './styles';

interface Props {
  children: React.ReactNode;
  title: string;
  subtitle: any;
  iconName: string;
  fullHeight?: boolean;
}

const Base: React.FC<Props> = ({
  children,
  title,
  subtitle,
  iconName,
  fullHeight,
}: Props) => {
  return (
    <Section>
      <Section.Header icon={iconName} title={title} subtitle={subtitle} />
      <Section.Content>
        <Container fullHeight={fullHeight}>{children}</Container>
      </Section.Content>
    </Section>
  );
};

Base.defaultProps = {
  fullHeight: false,
};

export default Base;
