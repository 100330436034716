import { useEffect, useLayoutEffect, useState } from 'react';
import { breakpoints } from 'styles/mediaQuery';
import { debounce } from 'util/funcs';

const useIsMobileScreen = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(
    window.innerWidth < breakpoints.sm
  );

  useEffect(() => {
    const updateSize = () => {
      setIsMobileScreen(window.innerWidth < breakpoints.sm);
    };
    window.addEventListener('resize', debounce(updateSize));

    return () => window.removeEventListener('resize', debounce(updateSize));
  }, []);

  return isMobileScreen;
};

export default useIsMobileScreen;
