import React from 'react';

import { Container } from './styles';

interface Props {
  children: React.ReactNode;
  variant?: string;
}
const Box: React.FC<Props> = ({ children, variant }: Props) => {
  return <Container variant={variant}>{children}</Container>;
};

Box.defaultProps = {
  variant: 'default',
};

export default Box;
