import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;

  & > h2 {
    font-size: 31px;
    line-height: 1.2em;
    font-weight: 300;
  }

  & > * {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .imageBackground {
    width: 280px;
    height: 280px;
    background-color: var(--select-background-light);
    padding: 16px;
    border-radius: 8px;

    img {
      width: 100%;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: var(--neutrals-border);
  }

  .valuesContainer {
    display: flex;
    width: 100%;
  }

  ${mediaQuery.sm} {
    .hint-not-scan {
      text-align: center;
    }
    .formContainer {
      width: 100%;
      padding: 1rem;
    }
  }
`;
