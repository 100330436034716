import * as Yup from 'yup';
import i18n from '../i18n';

// TODO: The properties we are creating ('Username', 'Password') also need to be
// dynamized in the i18n file. Probably we are going to do that when listing the input
// data into the i18n as well, since basically these are the field names.

const testAllowEmptyStringAndMinLength = (minLength: number) => 
  Yup.string().test(
    'len', 
    i18n.t('SCHEMA.LENGTH', { field: 'Middle name', length: minLength + 1 }), 
    val => val === '' || val.length > minLength
  );

export const OTP_CODE_SCHEMA = {
  code: Yup
    .string()
    .length(6, i18n.t('SCHEMA.LENGTH', { field: 'Code', length: 6 }))
    .required(i18n.t('SCHEMA.REQUIRED', { field: 'Code' })),
}

export const UPDATE_OTP_PASSWORD_SCHEMA = {
  password: Yup
    .string()
    .min(8, i18n.t('SCHEMA.MIN_LENGTH', { field: 'Password', min: 8 }))
    .matches(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, i18n.t('SCHEMA.PASSWORD_REQUIREMENTS', { field: 'Password' }))
    .required(i18n.t('SCHEMA.REQUIRED', { field: 'Password' })),
  oldOtp: OTP_CODE_SCHEMA.code,
}

const usernameSchema = Yup
  .string()
  .required('Username is required')
  .test('is-not-email', 'Username cannot be an email', (value) => {
    if (value && typeof value === 'string') {
      return !Yup.string().email().isValidSync(value);
    }
    return true;
  })
  .matches(/^[a-zA-Z0-9_]{3,32}$/, i18n.t('SCHEMA.USERNAME_REGEX'));

export const LOGIN_SCHEMA = {
  otp: Yup
    .string()
    .length(6, i18n.t('SCHEMA.LENGTH', { field: 'Code', length: 6 }))
    .optional(),
  username: usernameSchema,
  password: Yup
    .string()
    .min(8, i18n.t('SCHEMA.MIN_LENGTH', { field: 'Password', min: 8 }))
    .matches(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, i18n.t('SCHEMA.PASSWORD_REQUIREMENTS', { field: 'Password' }))
    .required(i18n.t('SCHEMA.REQUIRED', { field: 'Password' })),
};

export const REGISTER_SCHEMA = {
  nameAddress: (country: string) => ({
    firstName: Yup.string().matches(/^[\p{L},.'-][ \p{L},.'-]*[\p{L},.'-]$/u, "First name is not valid").required("First name is required"),
    middleName: testAllowEmptyStringAndMinLength(1),
    lastName: Yup.string().matches(/^[\p{L},.'-][ \p{L},.'-]*[\p{L},.'-]$/u, "Last name is not valid").required("Last name is required"),
    ...(country === 'US' ? {
      streetName: Yup
        .string()
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'Street name' })),
      streetType: Yup
        .string()
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'Street type' })),
      houseNumber: Yup
        .string()
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'House number' })),
      unitApNumber: Yup
        .string(),
      city: Yup
        .string()
        .matches(/^[a-zA-Z]+(?:[a-zA-Z\s-]+)*$/, i18n.t('SCHEMA.LETTERS_SPACE', { field: 'City' }))
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'City' })),
      state: Yup
        .string().required(i18n.t('SCHEMA.REQUIRED', { field: 'State' })),
      zipCode: Yup
        .string()
        .matches(/^\d+$/, i18n.t('SCHEMA.NUMBER', { field: 'ZIP'}))
        .length(5, i18n.t('SCHEMA.LENGTH', { field: 'ZIP', length: 5 }))
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'ZIP' })),
    } : { 
      addressLine1: Yup.string().required(i18n.t('SCHEMA.REQUIRED', { field: 'Address line 1' })),
      addressLine2: Yup.string(),
      city: Yup.string().required(i18n.t('SCHEMA.REQUIRED', { field: 'City' })),
      state: Yup.string().required(i18n.t('SCHEMA.REQUIRED', { field: 'State' })),
      zipCode: Yup.string().required(i18n.t('SCHEMA.REQUIRED', { field: 'ZIP' })),
    }),
  }),
  usernamePassword: {
    username: usernameSchema,
    email: Yup
      .string()
      .email(i18n.t('SCHEMA.EMAIL'))
      .required(i18n.t('SCHEMA.REQUIRED', { field: 'Email' })),
    password: LOGIN_SCHEMA.password,
    confirmPassword: LOGIN_SCHEMA.password
  },
  twoFactorAuth: OTP_CODE_SCHEMA,
  reviewAgreement: {
    agreement: Yup
      .boolean()
      .oneOf([true], i18n.t('SCHEMA.AGREEMENT_REQUIRED')),
  }
}

const { ...nameAddress } = REGISTER_SCHEMA.nameAddress('US');

export const KYC_PERSONAL_INFO_SCHEMA = {
  ...nameAddress,
  governmentId: Yup
    .string()
    .matches(/^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/, i18n.t('SCHEMA.GOVERNMENT_ID'))
    .required(i18n.t('SCHEMA.REQUIRED', { field: 'Government ID' })),
  dateOfBirth: Yup
    .string()
    .required(i18n.t('SCHEMA.REQUIRED', { field: 'Date of Birth' })),
};

export const FORGOT_PASSWORD_SCHEMA = {
  usernameOrEmail: Yup
    .string()
    .required(i18n.t('SCHEMA.REQUIRED', { field: 'This field' })),
};

export const RESET_PASSWORD_SCHEMA = {
  password: LOGIN_SCHEMA.password,
  passwordConfirmation: LOGIN_SCHEMA.password,
};

export const CHANGE_PASSWORD_SCHEMA = {
  oldPassword: LOGIN_SCHEMA.password,
  newPassword: LOGIN_SCHEMA.password,
  otp: Yup
    .string()
    .required(i18n.t('SCHEMA.REQUIRED', { field: 'OTP Code' })),
};

export const CREATE_COMPANY_SCHEMA = {
  nonCatawbaCitizenship: {
    company_name: Yup
      .string()
      .required(i18n.t('SCHEMA.REQUIRED', { field: 'Company name' })),
  },
  catawbaCitizenship: {
    company_name: Yup
      .string()
      .required(i18n.t('SCHEMA.REQUIRED', { field: 'Company name' })),
    tribal_id: Yup
      .string()
      .required(i18n.t('SCHEMA.REQUIRED', { field: 'Catawba Enrollment Number' })),
  },
  information: {
    ownershipDetails: {
      name: Yup
        .string()
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'Name' })),
      sharedAmount: Yup
        .number()
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'Shared amount' }))
        .min(0, i18n.t('SCHEMA.MIN_VALUE', { field: 'Shared amount', min: 0 }))
        .max(100, i18n.t('SCHEMA.MAX_VALUE', { field: 'Shared amount', max: 100 })),
    },
    companyDetails: {
      date_formed: Yup
        .string()
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'Date formed' })),
      fiscal_year_end_date: Yup
        .string()
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'Fiscal year end' })),
      phone: Yup
        .string()
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'Phone number' })),
      primary_email: Yup
        .string()
        .email()
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'Contact Email' })),
    },
    address: (type: 'Mailing' | 'Registration') => ({
      street_name: Yup
        .string()
        .max(255, i18n.t('SCHEMA.MAX_VALUE', { field: 'Street name', max: 255 }))
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'Street name' })),
      street_type: Yup
        .string()
        .max(255, i18n.t('SCHEMA.MAX_VALUE', { field: 'Street type', max: 255 }))
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'Street type' })),
      house_number: Yup
        .number()
        // .max(255, i18n.t('SCHEMA.MAX_VALUE', { field: 'House/Building number', max: 255 }))
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'House/Building number' })),
      unit: Yup
        .string()
        .max(255, i18n.t('SCHEMA.MAX_VALUE', { field: 'Unit/Apartment number', max: 255 })),
      city: Yup
        .string()
        .matches(/^[a-zA-Z]+(?:[a-zA-Z\s-]+)*$/, i18n.t('SCHEMA.LETTERS_SPACE', { field: 'City' }))
        .max(255, i18n.t('SCHEMA.MAX_VALUE', { field: 'City', max: 255 }))
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'City' })),
      [`is${type}InPuertoRico`]: Yup.boolean(),
      state: Yup
        .string()
        .max(255, i18n.t('SCHEMA.MAX_VALUE', { field: 'State', max: 255 }))
        .when(`is${type}InPuertoRico`, {
          is: false,
          then: Yup.string().max(255, i18n.t('SCHEMA.MAX_VALUE', { field: 'State', max: 255 })).required(i18n.t('SCHEMA.REQUIRED', { field: 'State' })),
        }),
      zip: Yup
        .string()
        .matches(/^\d+$/, i18n.t('SCHEMA.NUMBER', { field: 'ZIP'}))
        .length(5, i18n.t('SCHEMA.LENGTH', { field: 'ZIP', length: 5 }))
        .required(i18n.t('SCHEMA.REQUIRED', { field: 'ZIP' })),
    }),
  },
  review: {
    agree: Yup
      .boolean()
      .oneOf([true], i18n.t('SCHEMA.AGREEMENT_REQUIRED')),
  }
}

export const DISSOLUTION_WINDUP_SCHEMA = {
  firstWindup: Yup
    .boolean()
    .oneOf([true], i18n.t('SCHEMA.REQUIRED', { field: 'First windup' })),
  secondWindup: Yup
    .boolean()
    .oneOf([true], i18n.t('SCHEMA.REQUIRED', { field: 'Second windup' })),
  thirdWindup: Yup
    .boolean()
    .oneOf([true], i18n.t('SCHEMA.REQUIRED', { field: 'Third windup' })),
}

export const DOCUMENT_UPLOAD_SCHEMA = {
  file: Yup.mixed().required(i18n.t('SCHEMA.REQUIRED', { field: 'File' })),
}

export const INVITATION_SCHEMA = {
  email: Yup
    .string()
    .email()
    .required(i18n.t('SCHEMA.REQUIRED', { field: 'Email' })),
  role: Yup
    .string()
    .required(i18n.t('SCHEMA.REQUIRED', { field: 'Role' })),
}

export const COMPANY_TYPE_SCHEMA = {
  company_type: Yup
    .string()
    .oneOf(['LLC', 'DAO', 'C Corp'], 'Please select a valid company type')
    .nullable()
    .required(i18n.t('SCHEMA.REQUIRED', { field: 'Company type' })),
  data: Yup
    .object()
    .when('company_type', {
      is: 'DAO',
      then: Yup.object().shape({
        public_identifier: Yup
          .string()
          .required(i18n.t('SCHEMA.REQUIRED', { field: 'Public Identifier' })),
      }),
    }),
}