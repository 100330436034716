import styled, { css } from 'styled-components';

interface ContainerProps {
  type: 'select' | 'success' | 'warning' | 'danger';
  bold: boolean;
  fullWidth: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid;
  padding: 8px 16px;
  border-radius: 4px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};

  background: ${({ type }) => `var(--${type}-background-light)`};
  color: ${({ type }) => `var(--${type}-text)`};
  border-color: ${({ type }) => `var(--${type}-border)`};

  .icon {
    margin-right: 5px;
  }

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
      border-width: 2px;
    `}
`;
