import React from 'react';
import { Container } from './styles';

interface Props {
  name: string;
  fill?: number;
  weight?: number;
  grade?: number;
  opticalSize?: number;
  [rest: string]: any;
}

const Icon: React.FC<Props> = ({ name, ...rest }: Props) => {
  return (
    <Container className="material-symbols-outlined icon" {...rest}>
      {name}
    </Container>
  );
};

Icon.defaultProps = {
  fill: 0,
  weight: 400,
  grade: 0,
  opticalSize: 48,
};

export default Icon;
