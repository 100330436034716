import styled from 'styled-components';

export const Container = styled.div`
  & > .section {
    margin-bottom: 40px;

    h1 {
      color: var(--neutrals-text);
      font-size: 20px;
      line-height: 1.2em;
      margin-bottom: 12px;
      text-align: left;
    }

    p.hint {
      color: var(--neutrals-text-weak-active);
      font-size: 13px;
      line-height: 1.3em;
      margin-bottom: 12px;
    }

    .actions {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 12px;
      }

      & > *:last-child {
        margin-right: 0;
      }
    }

    .actions-theme {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 40px;
      }

      & > *:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const CatawbaBlue = styled.div`
  background-color: var(--primary-background-cta);
  width: 50px;
  height: 50px;
`;

export const VioletPunk = styled.div`
  // TODO: add new theme definition for pink: Pink/Primary/background-cta
  background-color: #ec9cff;
  width: 50px;
  height: 50px;
`;
