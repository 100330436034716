import styled, { css } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  .formContainerCreate {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .selectBoxes {
    display: flex;
    justify-content: center;
    width: 100%;

    .radio:first-of-type {
      margin-right: 80px;
    }
  }

  .companyName {
    max-width: 720px;
    margin: 0 auto;
  }

  .enrollmentNumber {
    margin-top: 20px;
  }

  .messageInline {
    margin-top: 8px;
  }

  .input {
    margin-top: 20px;
  }

  ${mediaQuery.sm} {
    .selectBoxes {
      .radio:first-of-type {
        margin-right: 20px;
      }

      & > * {
        flex: 1;
      }
    }
  }
`;

export const TypesDescription = styled.ul`
  li {
    strong {
      font-weight: 600;
      font-size: 18px;
    }

    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  margin-bottom: 36px;
`;

export const Types = styled.div``;
export const TypeContainer = styled.div``;
export const TypeCard = styled.div`
  display: flex;
  align-items: center;

  .radio {
    margin-right: 16px;
  }
`;
export const TypeDescription = styled.div``;
export const TypeDataFields = styled.div`
  margin-top: 20px;

  .hint {
    margin-top: 4px;
    font-size: 14px;
    color: var(--neutrals-text-weak-active);
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: var(--neutrals-border);
  margin: 28px auto;
  width: 100%;
`;
