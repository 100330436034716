/* eslint-disable react/button-has-type */
/* prettier-ignore */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Label } from 'components/atoms';
import { Checkbox, Form, Input } from 'components/atoms/_common';
import { Row } from 'components/atoms/_util';
import { useCreateCompanyStepper } from 'context/CreateCompanyStepper';
import { CompanyDetails } from 'context/CreateCompanyStepper/types';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { CREATE_COMPANY_SCHEMA } from 'util/schemas';
import SpecializedShares from './SpecializedShares';

interface Props {
  defaultValues: CompanyDetails;
  setCompanyDetails: (companyDetails: CompanyDetails) => void;
}

const CompanyDetailsForm = React.forwardRef(
  (
    { defaultValues, setCompanyDetails }: Props,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const { companyType } = useCreateCompanyStepper();
    const [hasSpecializedShares, setHasSpecializedShares] = useState(false);

    const handleSubmitDetails = ({
      date_formed,
      fiscal_year_end_date,
      primary_email,
      phone,
      total_common,
      total_preferred,
      preferred_name,
      preferred_description,
    }) => {
      setCompanyDetails({
        date_formed,
        fiscal_year_end_date,
        primary_email,
        phone,
        total_common,
        total_preferred,
        preferred_name,
        preferred_description,
      });
    };

    return (
      <Form
        ref={ref}
        schema={CREATE_COMPANY_SCHEMA.information.companyDetails}
        onSubmit={handleSubmitDetails}
        defaultValues={defaultValues}
      >
        <Label text="Company details" iconName="corporate_fare" />
        <Row breakRowOnMobile>
          <Input
            max={format(new Date(), 'yyyy-MM-dd')}
            type="date"
            name="date_formed"
            label="Date formed"
          />
          <Input
            type="date"
            name="fiscal_year_end_date"
            label="Fiscal Year End"
          />
        </Row>
        <Row breakRowOnMobile>
          <Input name="phone" label="Phone number" />
          <Input name="primary_email" label="Contact email" />
        </Row>

        {companyType.type === 'C Corp' && (
          <>
            <Label text="Company shares" iconName="corporate_fare" />

            <div style={{ marginTop: '20px' }}>
              <Input
                name="total_common"
                label="Total common shares"
                type="number"
              />
            </div>

            <Row breakRowOnMobile>
              <Checkbox
                name="hasSpecializedShares"
                checked={hasSpecializedShares}
                onChange={e => setHasSpecializedShares(e.target.checked)}
                label="Does this company have specialized shares?"
              />
            </Row>
            <SpecializedShares visible={hasSpecializedShares} />
          </>
        )}
      </Form>
    );
  }
);

export default CompanyDetailsForm;
