import styled, { css } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div<{ breakRowOnMobile: boolean }>`
  display: flex;
  width: 100%;
  margin: 20px 0;

  &:last-of-type {
    margin-bottom: 0;
  }

  & > div {
    flex: 1;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${({ breakRowOnMobile }) =>
    breakRowOnMobile &&
    css`
      ${mediaQuery.sm} {
        flex-direction: column;

        & > div {
          margin-right: 0;
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    `}
`;
