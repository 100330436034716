import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Form, Input, Button, Icon } from 'components/atoms/_common';
import { FORGOT_PASSWORD_SCHEMA, RESET_PASSWORD_SCHEMA } from 'util/schemas';
import { validateEmail } from 'util/validate';
import { useAppDispatch, useRouteParams } from 'hooks';
import { forgotPasswordRequest, resetPasswordRequest } from 'store/slices/auth';
import { notifyError } from 'services/notify';
import { Container, LinkBack } from './styles';

const ForgotPassword: React.FC = () => {
  const [authInfo, setAuthInfo] = useState(null);
  const dispatch = useAppDispatch();

  const handleForgotSubmit = ({ usernameOrEmail }) => {
    const isEmail = validateEmail(usernameOrEmail);
    if (isEmail) {
      return dispatch(forgotPasswordRequest({ email: usernameOrEmail }));
    }
    return dispatch(forgotPasswordRequest({ username: usernameOrEmail }));
  };

  const handleResetSubmit = ({ password, passwordConfirmation }) => {
    const { invitation, auth } = authInfo;

    if (password !== passwordConfirmation) {
      return notifyError('Passwords do not match');
    }

    return dispatch(resetPasswordRequest({ password, invitation, auth }));
  };

  // prettier-ignore
  useRouteParams((auth, invitation) => {
    if (auth && invitation) {
      setAuthInfo({ invitation, auth });
    }
  }, ['auth', 'invitation']);

  return (
    <Container>
      <section className="header">
        <div className="iconCircle">
          <Icon name="key" fill={1} />
        </div>
        <h2>Password recovery</h2>
        <p>
          Please enter your username or registered email to receive a password
          recovery email.
        </p>
      </section>
      <section className="main">
        {!authInfo ? (
          <Form schema={FORGOT_PASSWORD_SCHEMA} onSubmit={handleForgotSubmit}>
            <Input
              name="usernameOrEmail"
              label="Username or E-mail"
              placeholder="Username or E-mail"
            />
            <Button type="submit" full>
              Recover password
            </Button>
            <LinkBack to="/login">Go back to login</LinkBack>
          </Form>
        ) : (
          <Form schema={RESET_PASSWORD_SCHEMA} onSubmit={handleResetSubmit}>
            <Input
              name="password"
              label="Password"
              type="password"
              placeholder="New password"
            />
            <Input
              name="passwordConfirmation"
              label="Password confirmation"
              type="password"
              placeholder="New password confirmation"
            />
            <Button type="submit" full>
              Reset password
            </Button>
            <LinkBack to="/login">Go back to login</LinkBack>
          </Form>
        )}
      </section>
      <section className="linkBottom">
        <span>
          Don&apos;t have an account?
          <Link className="internal bold" to="/register">
            Sign up
          </Link>
        </span>
      </section>
    </Container>
  );
};

export default ForgotPassword;
