import React from 'react';

import { useFormContext } from '../Form';
import Icon from '../Icon';

import { Container, ErrorSpan, Label } from './styles';

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (e: any) => void;
  defaultChecked?: boolean;
}

const Checkbox: React.FC<Props> = ({
  name,
  label,
  disabled,
  onChange,
  defaultChecked,
  checked,
}: Props) => {
  const { register, errors } = useFormContext();

  const hasErrors = !!errors[name];

  return (
    <Container
      disabled={disabled}
      className={hasErrors ? 'errored checkbox' : 'checkbox'}
    >
      <Label htmlFor={name} className={hasErrors && 'errored'}>
        <input
          type="checkbox"
          id={name}
          disabled={disabled}
          defaultChecked={defaultChecked}
          {...register(name)}
          checked={checked}
          onChange={e => {
            if (onChange) {
              onChange(e);
            }
          }}
        />
        <span className="checkmark">
          <Icon name="check" />
        </span>
        <span className="labelText">{label}</span>
      </Label>

      <ErrorSpan visible={hasErrors}>{errors[name]?.message}</ErrorSpan>
    </Container>
  );
};

Checkbox.defaultProps = {
  label: '',
  disabled: false,
  onChange: undefined,
  defaultChecked: undefined,
  checked: undefined,
};

export default Checkbox;
