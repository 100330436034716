import React from 'react';
import { Button, Icon } from 'components/atoms/_common';
import { useNavigate } from 'react-router-dom';
import { CloseSidebarOnClick } from 'components/atoms/_util';
import { useAppDispatch, useAppSelector } from 'hooks';
import DefaultUserImage from 'assets/images/default-user.png';

import { logoutRequest } from 'store/slices/auth';
import { Container, Menu, DetectOutterClick } from './styles';

interface Props {
  isOpen: boolean;
}

const UserPill: React.FC<Props> = ({ isOpen }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [show, setShow] = React.useState(false);
  const user = useAppSelector(state => state.user.user);

  const handleLogout = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(logoutRequest());
  };

  return (
    <>
      <Container
        isOpen={isOpen}
        className="pill"
        onClick={() => setShow(_show => !_show)}
      >
        {show && (
          <Menu onClick={e => e.stopPropagation()} isOpen={isOpen}>
            <Button onClick={handleLogout} full>
              Logout
            </Button>
          </Menu>
        )}
        <p className="role" title={user.full_name}>
          {user.full_name}
        </p>

        <div className="user">
          <Icon
            name="menu"
            fill={1}
            weight={300}
            grade={200}
            opticalSize={40}
          />
          <img src={DefaultUserImage} alt="User" />
        </div>
      </Container>
      {show && <DetectOutterClick onClick={() => setShow(false)} />}
    </>
  );
};

export default UserPill;
