/* eslint-disable */

import axios from 'axios';
import { store } from 'store';
import { notifyError } from 'services/notify';
import { showLoading, disposeLoading } from 'store/slices/loading';
import endpoints from './endpoints';
import { getErrorMessage, DEFAULT_ERROR } from './errors';
import { signOut } from 'store/slices/auth';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

const anonymousInstance = axios.create();

axiosInstance.interceptors.request.use(
  config => {
    if (config.headers.silent) {
      const { silent, ...headers } = config.headers;
      return { ...config, headers: headers };
    }
    store.dispatch(showLoading());
    return config;
  },
  error => error
);

axiosInstance.interceptors.response.use(
  response => {
    // TODO: Validate something like: response.config.headers.silent ? store.dispatch(disposeLoading()) : null;
    store.dispatch(disposeLoading());
    return {
      success: true,
      data: response.data.data,
      headers: response.headers,
    };
  },
  error => {
    const { response } = error;

    store.dispatch(disposeLoading());
    if (response && response.data) {
      const { status } = response.data;

      // TODO: validate once we have an invalid token what is the message we are looking for
      if (status === 'No Authorization Cookie Present') {
        notifyError('Your session has expired. Please sign in again.');
        store.dispatch(signOut());
        return { success: false, data: { status: 'auth/unauthorized' } };
      }

      notifyError(getErrorMessage(status));

      return {
        success: false,
        data: response.data,
      };
    }
    notifyError(DEFAULT_ERROR);
    return {
      ...error,
      data: { error: { message: 'unknown error' } },
    };
  }
);

export { endpoints, anonymousInstance };

export default axiosInstance;
