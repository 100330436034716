import React from 'react';
import { useTabSectionContext } from '../context';

import { Container } from './styles';

export interface TabContentProps {
  tab: string;
  children: React.ReactNode;
}

const TabContent: React.FC<TabContentProps> = ({
  tab,
  children,
}: TabContentProps) => {
  const { activeTab } = useTabSectionContext();

  if (activeTab !== tab) return null;

  return <Container className="tab-content">{children}</Container>;
};

TabContent.defaultProps = {};

export default TabContent;
