import styled from 'styled-components';

export const Container = styled.div`
  h3 {
    font-size: 18px;
    margin-bottom: 14px;
  }

  .divider {
    margin: 20px 0;
    height: 1px;
    background: #e5e5e5;
  }

  .row-form {
    display: flex;

    .email {
      flex: 0.5;
    }

    .role,
    button {
      flex: 0.25;
    }

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    button {
      height: 61.2px;
      margin-top: 27px;
      margin-bottom: auto;
    }
  }
`;
