import { useState } from 'react';
import { GoChevronDown } from 'react-icons/go';
import * as S from './styles';

interface Props {
  children: React.ReactNode;
  title: string;
}

const Accordion = ({ children, title }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.Container>
      <S.Header isOpen={isOpen} onClick={() => setIsOpen(s => !s)}>
        <h2>{title}</h2>
        <GoChevronDown />
      </S.Header>
      <S.Content isOpen={isOpen}>{children}</S.Content>
    </S.Container>
  );
};

export default Accordion;
