/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Label } from 'components/atoms';
import { REGISTER_SCHEMA } from 'util/schemas';
import { Checkbox, Form, Input } from 'components/atoms/_common';
import { useRegisterStepper } from 'context/RegisterStepper';
import { Row } from 'components/atoms/_util';

import Base from '../_base';
import Address from './Address';

const NameAddress: React.FC = () => {
  const { nextStep, setName, name, address, setAddress, country } =
    useRegisterStepper();

  const handleSubmit = values => {
    const { firstName, middleName, lastName, ...rest } = values;
    setName({ firstName, middleName, lastName });
    setAddress(rest);
    nextStep();
  };

  return (
    <Base
      iconName="person"
      title="Name &#38; Address"
      subtitle="Please enter your full legal name and mailing address."
    >
      <Form
        onSubmit={handleSubmit}
        defaultValues={{
          ...name,
          ...address,
          country,
        }}
        schema={REGISTER_SCHEMA.nameAddress(country)}
      >
        <Label text="Personal details" iconName="person" />
        <Row breakRowOnMobile>
          <Input name="firstName" label="First name" placeholder="First name" />
          <Input
            optional
            name="middleName"
            label="Middle name"
            placeholder="Middle name"
          />
          <Input name="lastName" label="Last name" placeholder="Last name" />
        </Row>

        <Address />

        <button type="submit" className="submit-register" />
      </Form>
    </Base>
  );
};

export default NameAddress;
