import styled, { css } from 'styled-components';

export const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--primary-background);
  box-shadow: 0 0 0 1px var(--primary-border);
  padding: 12px;

  color: var(--primary-text);
  line-height: 1.2em;

  &:hover {
    box-shadow: 0 0 0 2px var(--primary-border);
  }

  .icon {
    margin-right: 12px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      background-color: var(--neutrals-border);
      color: var(--neutrals-text-weak-passive);
      box-shadow: 0 0 0 1px var(--neutrals-border);

      &:hover {
        box-shadow: none;
      }
    `}
`;
