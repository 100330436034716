import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Icon } from 'components/atoms/_common';
import { useAppDispatch, useAppSelector } from 'hooks';
import { signInRequest } from 'store/slices/auth';

import { LOGIN_SCHEMA } from 'util/schemas';
import { Container, FieldsDisplayer } from './styles';

const FormLogin = ({ require2fa }: { require2fa: boolean }) => {
  return (
    <>
      {require2fa && (
        <Input
          name="otp"
          label="Enter your 6 digit 2fa code"
          maxLength={6}
          submitOnEnter
        />
      )}

      <FieldsDisplayer visible={!require2fa}>
        <Input name="username" label="Username" placeholder="Your username" />
        <Input
          name="password"
          label="Password"
          type="password"
          placeholder="Your password"
          submitOnEnter
        />

        <div className="actions">
          <Checkbox name="remember" label="Remember me" />
          <Link className="internal" to="/forgot">
            Forgot password
          </Link>
        </div>
      </FieldsDisplayer>
      <Button type="submit" full>
        Submit
      </Button>
    </>
  );
};

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { require2fa } = useAppSelector(state => state.auth);
  const { shouldRemember, user } = useAppSelector(state => state.user);

  const handleSubmit = ({ username, password, remember, otp }) => {
    if (window.location.search.includes('?redirectTo=')) {
      const redirectTo = window.location.search.split('?redirectTo=')[1];
      const callback = () => navigate(decodeURIComponent(redirectTo));

      dispatch(signInRequest({ username, password, remember, otp, callback }));
      return;
    }
    dispatch(signInRequest({ username, password, remember, otp }));
  };

  const defaultValues = shouldRemember
    ? { username: user.username, remember: true }
    : {};

  return (
    <Container>
      <section className="header">
        <div className="iconCircle">
          <Icon name="front_hand" fill={1} />
        </div>
        <h2>Hello there!</h2>
        <p>
          Welcome to the Catawba Digital Economic Zone. Please login or register
          to access eRegistry services offered by the Zone Authority.
        </p>
      </section>
      <section className="main">
        <Form
          schema={LOGIN_SCHEMA}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
        >
          <FormLogin require2fa={require2fa} />
        </Form>
      </section>

      <section className="linkBottom">
        <span>
          Don&apos;t have an account?
          <Link className="internal bold" to="/register">
            Sign up
          </Link>
        </span>
      </section>
    </Container>
  );
};

export default Login;
