/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import { Label } from 'components/atoms';
import { Checkbox, DropdownInput, Form, Input } from 'components/atoms/_common';
import { Row } from 'components/atoms/_util';
import { CompanyRegistrationAddress } from 'context/CreateCompanyStepper/types';
import { CREATE_COMPANY_SCHEMA } from 'util/schemas';
import USStates from 'components/atoms/_common/DropdownInput/renderers/USStatesItem/us-states-data.mock';
import { useCreateCompanyStepper } from 'context/CreateCompanyStepper';

import USStatesItem from 'components/atoms/_common/DropdownInput/renderers/USStatesItem/styles';
import { Divider } from '../styles';

interface Props {
  defaultValues: CompanyRegistrationAddress;
  setRegistrationAddress: (address: CompanyRegistrationAddress) => void;
}

const RegistrationAddressForm = React.forwardRef(
  (
    { defaultValues, setRegistrationAddress }: Props,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const {
      selectedRegisteredAgent,
      changePuertoRico,
      puertoRico,
      setIsMailingAddressSame,
      isMailingAddressSame,
    } = useCreateCompanyStepper();
    const handleSubmitAddress = registration_address => {
      setRegistrationAddress(registration_address);
    };
    // @ts-ignore
    const formDisabled = selectedRegisteredAgent !== 'manual';

    useEffect(() => {
      if (formDisabled) {
        changePuertoRico('registration', false);
      }
    }, [formDisabled]);

    return (
      <Form
        ref={ref}
        onSubmit={handleSubmitAddress}
        schema={CREATE_COMPANY_SCHEMA.information.address('Registration')}
        defaultValues={defaultValues}
      >
        <Label text="Registration address" iconName="pin_drop" />
        <Checkbox
          name="isRegistrationInPuertoRico"
          disabled={formDisabled}
          label="My registration address is in Puerto Rico"
          checked={puertoRico.registration}
          onChange={e => changePuertoRico('registration', e.target.checked)}
        />
        <Row breakRowOnMobile>
          <Input
            name="house_number"
            label="House/Building number"
            disabled={formDisabled}
          />
          <Input
            name="street_name"
            label="Street name"
            disabled={formDisabled}
          />
          <Input
            name="street_type"
            label="Street type"
            disabled={formDisabled}
          />
          <Input
            name="unit"
            label="Unit/Apartment number"
            optional
            disabled={formDisabled}
          />
        </Row>
        <Row breakRowOnMobile>
          <Input name="city" label="City" disabled={formDisabled} />
          {!puertoRico.registration && (
            <DropdownInput
              name="state"
              label="State"
              hint="Select your state"
              items={USStates}
              itemRenderer={({ item, onItemClick }) => (
                <USStatesItem onClick={() => onItemClick(item.id)}>
                  <span className="state-id">{item.id}</span>
                  <span className="state-name">{item.name}</span>
                </USStatesItem>
              )}
              disabled={formDisabled}
            />
          )}
          <Input
            name="zip"
            label="Zip code"
            maxLength={5}
            disabled={formDisabled}
          />
        </Row>

        <Checkbox
          defaultChecked={isMailingAddressSame}
          name="isMailingAddressSame"
          label="Make this my mailing address"
          onChange={e => setIsMailingAddressSame(e.target.checked)}
        />

        <Divider />
        <button type="submit" className="hidden formSubmitAddress" />
      </Form>
    );
  }
);

export default RegistrationAddressForm;
