/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSFixMe } from 'shared/types';

interface CompanyState {
  activeCompany: TSFixMe;
  viewingCompany: TSFixMe;
  viewingCompanyApplication: TSFixMe;
}

const initialState = {
  activeCompany: null,
  viewingCompany: null,
} as CompanyState;

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // FIXME: fix these `PayloadAction<TSFixMe>`...
    // COMPANY APPLICATION
    createCompanyApplicationRequest: (_, action: PayloadAction<TSFixMe>) => {},
    createCompanyApplicationSuccess: (
      state,
      action: PayloadAction<TSFixMe>
    ) => {
      state.activeCompany = action.payload;
    },
    updateCompanyApplicationRequest: (_, action: PayloadAction<TSFixMe>) => {},
    updateCompanyApplicationSuccess: (
      state,
      action: PayloadAction<TSFixMe>
    ) => {
      state.activeCompany = action.payload;
    },
    submitCompanyApplicationRequest: (_, action: PayloadAction<TSFixMe>) => {},
    cancelCompanyApplicationRequest: (
      _,
      action: PayloadAction<{ companyApplicationId: string }>
    ) => {},
    // COMPANY PAYMENT (STRIPE)
    checkoutStripePaymentRequest: (_, action: PayloadAction<TSFixMe>) => {},
    // COMPANY INFORMATION
    getCompanyInfoRequest: (_, action: PayloadAction<TSFixMe>) => {},
    getCompanyInfoSuccess: (state, action: PayloadAction<TSFixMe>) => {
      state.viewingCompany = action.payload;
    },
    clearCompanyInfo: state => {
      state.viewingCompany = null;
    },
    clearCompanyApplicationInfo: state => {
      state.viewingCompanyApplication = null;
    },
    // COMPANY SUBSCRIPTION
    updateSubscriptionRequest: (_, action: PayloadAction<TSFixMe>) => {},
    updateSubscriptionSuccess: (state, action: PayloadAction<TSFixMe>) => {
      state.viewingCompany.updateStripeSubscriptionLink = action.payload;
    },
    cancelSubscriptionRequest: (_, action: PayloadAction<TSFixMe>) => {},
    reactivateSubscriptionRequest: (_, action: PayloadAction<TSFixMe>) => {},
    getSubscriptionDataRequest: (_, action: PayloadAction<TSFixMe>) => {},
    // COMPANY DISSOLUTION
    createDissolutionRequest: (_, action: PayloadAction<TSFixMe>) => {},
    updateDissolutionRequest: (_, action: PayloadAction<TSFixMe>) => {},
    submitDissolutionRequest: (_, action: PayloadAction<TSFixMe>) => {},
    getAllDissolutionsRequest: (_, action: PayloadAction<TSFixMe>) => {},
    // REGISTERED AGENTS
    getRegisteredAgentsRequest: (_, action: PayloadAction<TSFixMe>) => {},
    // INVITATIONS
    getInvitationCompanyDataRequest: (_, action: PayloadAction<TSFixMe>) => {},
    getCompanyInvitationsRequest: (_, action: PayloadAction<TSFixMe>) => {},
    sendCompanyInvitationRequest: (_, action: PayloadAction<TSFixMe>) => {},
    resendCompanyInvitationRequest: (_, action: PayloadAction<TSFixMe>) => {},
    cancelCompanyInvitationRequest: (_, action: PayloadAction<TSFixMe>) => {},
    acceptCompanyInvitationRequest: (_, action: PayloadAction<TSFixMe>) => {},
    rejectCompanyInvitationRequest: (_, action: PayloadAction<TSFixMe>) => {},
    // MEMBERS
    getCompanyMembersRequest: (_, action: PayloadAction<TSFixMe>) => {},
    // CAPTABLE
    getCompanyCaptableRequest: (_, action: PayloadAction<TSFixMe>) => {},
    updateCompanyCaptableRequest: (_, action: PayloadAction<TSFixMe>) => {},
  },
});

export const {
  createCompanyApplicationRequest,
  createCompanyApplicationSuccess,
  updateCompanyApplicationRequest,
  updateCompanyApplicationSuccess,
  submitCompanyApplicationRequest,
  cancelCompanyApplicationRequest,
  checkoutStripePaymentRequest,
  getCompanyInfoRequest,
  getCompanyInfoSuccess,
  clearCompanyInfo,
  clearCompanyApplicationInfo,
  updateSubscriptionRequest,
  updateSubscriptionSuccess,
  cancelSubscriptionRequest,
  reactivateSubscriptionRequest,
  getSubscriptionDataRequest,
  createDissolutionRequest,
  updateDissolutionRequest,
  submitDissolutionRequest,
  getAllDissolutionsRequest,
  getRegisteredAgentsRequest,
  getInvitationCompanyDataRequest,
  getCompanyInvitationsRequest,
  sendCompanyInvitationRequest,
  resendCompanyInvitationRequest,
  cancelCompanyInvitationRequest,
  acceptCompanyInvitationRequest,
  rejectCompanyInvitationRequest,
  getCompanyMembersRequest,
  getCompanyCaptableRequest,
  updateCompanyCaptableRequest,
} = companySlice.actions;

export default companySlice.reducer;
