import React, { createContext, useState } from 'react';
import { ContextState } from './types';

export const KYCUpdateStepperContext = createContext<ContextState>({
  currentStep: 1,
  nextStep: () => {},
  previousStep: () => {},
});

export const KYCUpdateStepperProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    currentStep,
    nextStep,
    previousStep,
  };

  return (
    <KYCUpdateStepperContext.Provider value={value}>
      {children}
    </KYCUpdateStepperContext.Provider>
  );
};

export const useKYCUpdateStepperContext = () => {
  const context = React.useContext(KYCUpdateStepperContext);

  return context;
};
