import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  footer {
    width: 100%;
  }

  position: relative;

  .btn-submit {
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 40px 80px;
  font-size: 20px;
  line-height: 1.2em;
  color: var(--neutrals-text);

  .icon {
    margin-right: 12px;
    cursor: pointer;
  }
  margin-bottom: 40px;

  ${mediaQuery.sm} {
    padding: 20px 30px;
    margin-bottom: 20px;
  }
`;

export const ContentContainer = styled.div`
  height: calc(100vh - 90px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  ${mediaQuery.sm} {
    height: calc(100vh - 136px);
  }
`;

export const Content = styled.div`
  flex: 1;
  padding-bottom: 20px;
`;
