import styled, { css } from 'styled-components';

interface Props {
  full: boolean;
  visible: boolean;
  variant:
    | 'primary'
    | 'outlined'
    | 'subtle'
    | 'text'
    | 'header-active'
    | 'header';
}

export const Button = styled.button<Props>`
  position: relative;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  line-height: 1.2em;

  background-color: var(--primary-background-cta);
  color: var(--neutrals-text-contrast);

  &:hover {
    background-color: var(--primary-background-cta-hover);
  }

  &:focus {
    box-shadow: 0 0 0 2px inset var(--primary-background-hover);
  }

  &:disabled {
    background-color: var(--neutrals-border);
    color: var(--neutrals-text-weak-passive);
    border: none;
  }

  ${({ variant }) =>
    variant === 'outlined' &&
    css`
      background-color: var(--neutrals-background);
      color: var(--primary-text);
      border: 2px solid var(--primary-border);

      &:hover {
        background-color: var(--primary-background-cta);
        color: var(--neutrals-text-contrast);
      }

      &:focus {
        background-color: var(--primary-background-cta);
        color: var(--neutrals-text-contrast);
        box-shadow: 0 0 0 2px inset var(--primary-background-hover);
      }
    `}

  ${({ variant }) =>
    variant === 'subtle' &&
    css`
      color: var(--primary-text);
      background-color: var(--primary-background);
      border: none;
      font-size: 1rem;

      &:hover {
        background-color: var(--primary-background-cta);
        color: var(--neutrals-text-contrast);
      }

      &:focus {
        background-color: var(--primary-background-cta);
        color: var(--neutrals-text-contrast);
        box-shadow: 0 0 0 2px inset var(--primary-background-hover);
      }
    `}

  ${({ variant }) =>
    variant === 'text' &&
    css`
      background-color: transparent;
      color: var(--primary-text);
      border: none;
      font-size: 1rem;

      &:hover {
        background-color: var(--primary-background-cta);
        color: var(--neutrals-text-contrast);
      }

      &:focus {
        background-color: var(--primary-background-cta);
        color: var(--neutrals-text-contrast);
        box-shadow: 0 0 0 2px inset var(--primary-background-hover);
      }
    `}

    ${({ variant }) =>
    variant === 'header-active' &&
    css`
      background-color: transparent;
      color: var(--primary-text);
      border-bottom: 2px solid var(--primary-border-active);
      font-size: 25px;
      border-radius: 0;

      &:hover {
        background-color: var(--neutrals-background);
      }

      &:focus {
        box-shadow: none;
      }
    `}

    ${({ variant }) =>
    variant === 'header' &&
    css`
      background-color: var(--neutrals-background);
      color: var(--neutrals-text-weak-active);
      border: none;
      border-radius: 4px;
      font-size: 25px;
      border-radius: 0;

      &:hover {
        background-color: var(--neutrals-background-light);
        color: var(--neutrals-border-active);
      }

      &:focus {
        box-shadow: none;
      }
    `}

  ${({ full }) =>
    full &&
    css`
      width: 100%;
      max-height: 60px;
      height: 100%;
    `}

  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;

export const Loading = styled.span`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

interface ChildrenContainerProps {
  visible: boolean;
}

export const ChildrenContainer = styled.span<ChildrenContainerProps>`
  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;
