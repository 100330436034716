/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Label, TextValue } from 'components/atoms';
import { format } from 'date-fns';

import { Container } from './styles';

interface Props {
  application: any;
}

const Details: React.FC<Props> = ({ application }: Props) => {
  const getAddress = (address: any) => {
    if (!address) return '---';
    const { house_number, street_name, zip, country, city, state } = address;
    return `${house_number} ${street_name}, ${city}, ${state} ${zip} ${country}`;
  };

  const getStatus = () => {
    const { application_status } = application;
    const status = {
      pending_review: 'Pending review',
      pending_payment: 'Pending payment',
      draft: 'In Draft',
      approved: 'Approved',
      rejected: 'Rejected',
    };
    return status[application_status];
  };

  const getOwnersNames = () => {
    const { owners } = application;
    if (!owners) return '';

    return owners.map(owner => owner.name);
  };

  const getFormattedDate = (date: string) => {
    return format(new Date(date), 'MM/dd/yyyy');
  };

  return (
    <Container>
      <Label text="Details" iconName="corporate_fare" />
      <TextValue
        text="Registration Type"
        value="Catawba Digital Economic Zone"
      />
      <TextValue
        text="Company Type"
        value={
          application.public_identifier
            ? 'Decentralized Autonomous Organization LLC'
            : application.type === 'C Corp'
            ? 'C-Corporation'
            : 'Limited Liability Company'
        }
      />
      <TextValue text="Legal Name" value={application.company_full_name} />
      <TextValue
        text="Date Formed"
        value={getFormattedDate(application.date_formed)}
      />
      <TextValue text="Phone Number" value={application.phone} />
      <TextValue
        text="Registered Address"
        value={getAddress(application.registration_address)}
      />
      <TextValue
        text="Mailing Address"
        value={getAddress(application.mailing_address)}
      />
      <TextValue text="Email" value={application.primary_email} />
      <TextValue text="Status" value={getStatus()} />
      <TextValue text="Owners" value={getOwnersNames()} />
    </Container>
  );
};

export default Details;
