/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Checkbox, Form, Accordion } from 'components/atoms/_common';
import { Row } from 'components/atoms/_util';
import { Section } from 'components/molecules';
import { useCreateCompanyStepper } from 'context/CreateCompanyStepper';
import { useAppDispatch, useAppSelector, useIsMobileScreen } from 'hooks';
import { submitCompanyApplicationRequest } from 'store/slices/company';
import { CREATE_COMPANY_SCHEMA } from 'util/schemas';

import { formatDate } from 'util/formatting';
import { Container, Information, Divider, Acceptance } from './styles';

const Review: React.FC = () => {
  const { nextStep, catawbaCitizenship, companyType } =
    useCreateCompanyStepper();
  const dispatch = useAppDispatch();
  const activeCompany = useAppSelector(state => state.company.activeCompany);
  const user = useAppSelector(state => state.user.user);
  const isMobile = useIsMobileScreen();

  const callback = () => {
    nextStep();
  };

  const handleSubmit = () => {
    dispatch(
      submitCompanyApplicationRequest({
        activeCompany: activeCompany.application,
        callback,
      })
    );
  };

  if (!activeCompany.application) {
    return <Container />;
  }

  const subtitles = {
    LLC: 'Limited Liability Company',
    DAO: 'Decentralized Autonomous Organization LLC',
    'C Corp': 'C-Corporation',
  };

  return (
    <Section>
      <Section.Header
        icon="money"
        title={catawbaCitizenship.company_name}
        subtitle={subtitles[companyType.type]}
      />
      <Section.Content noPadding={isMobile}>
        <Container>
          <Information>
            <section>
              <div className="companyDetails box">
                <h3>Company Details</h3>
                <Row>
                  <h4>Company Type:</h4>{' '}
                  <span>{subtitles[companyType.type]}</span>
                </Row>
                <Row>
                  <h4>Date formed</h4>{' '}
                  <span>
                    {formatDate(activeCompany.application.date_formed)}
                  </span>
                </Row>
                <Row>
                  <h4>Fiscal year end</h4>{' '}
                  <span>{activeCompany.application.fiscal_year_end_date}</span>
                </Row>
                <Row>
                  <h4>Phone number</h4>{' '}
                  <span>{activeCompany.application.phone}</span>
                </Row>
                <Row>
                  <h4>Contact Email</h4>{' '}
                  <span>{activeCompany.application.primary_email}</span>
                </Row>

                <div className="ownershipDetails">
                  <h3>Ownership details</h3>
                  <Row>
                    <h4>{user.full_name}</h4> <span>100%</span>
                  </Row>
                </div>
              </div>
            </section>
            <section className="info-accordions">
              <Accordion title="Registration Address">
                <Row>
                  <h4>Street name</h4>{' '}
                  <span>
                    {activeCompany.application.registration_address.street_name}
                  </span>
                </Row>
                <Row>
                  <h4>Street type</h4>{' '}
                  <span>
                    {activeCompany.application.registration_address.street_type}
                  </span>
                </Row>
                <Row>
                  <h4>House/Building number</h4>{' '}
                  <span>
                    {
                      activeCompany.application.registration_address
                        .house_number
                    }
                  </span>
                </Row>
                <Row>
                  <h4>Unit/Apartment number</h4>{' '}
                  <span>
                    {activeCompany.application.registration_address.unit}
                  </span>
                </Row>
                <Row>
                  <h4>City</h4>{' '}
                  <span>
                    {activeCompany.application.registration_address.city}
                  </span>
                </Row>
                <Row>
                  <h4>State</h4>{' '}
                  <span>
                    {activeCompany.application.registration_address.state}
                  </span>
                </Row>
                <Row>
                  <h4>Zip</h4>{' '}
                  <span>
                    {activeCompany.application.registration_address.zip}
                  </span>
                </Row>
              </Accordion>
              <Accordion title="Mailing Address">
                <Row>
                  <h4>Street name</h4>
                  <span>
                    {activeCompany.application.mailing_address.street_name}
                  </span>
                </Row>
                <Row>
                  <h4>Street type</h4>
                  <span>
                    {activeCompany.application.mailing_address.street_type}
                  </span>
                </Row>
                <Row>
                  <h4>House/Building number</h4>
                  <span>
                    {activeCompany.application.mailing_address.house_number}
                  </span>
                </Row>
                <Row>
                  <h4>Unit/Apartment number</h4>
                  <span>{activeCompany.application.mailing_address.unit}</span>
                </Row>
                <Row>
                  <h4>City</h4>{' '}
                  <span>{activeCompany.application.mailing_address.city}</span>
                </Row>
                <Row>
                  <h4>State</h4>
                  <span>{activeCompany.application.mailing_address.state}</span>
                </Row>
                <Row>
                  <h4>Zip</h4>{' '}
                  <span>{activeCompany.application.mailing_address.zip}</span>
                </Row>
              </Accordion>
            </section>
          </Information>
          <Acceptance>
            <Form onSubmit={handleSubmit} schema={CREATE_COMPANY_SCHEMA.review}>
              <Checkbox
                name="agree"
                label="I have reviewed this information and I certify that it is correct, complete, and accurate and consent to sign this application electronically with my registered name."
              />
              <button type="submit" className="submit-createCompany" />
            </Form>
          </Acceptance>
        </Container>
      </Section.Content>
    </Section>
  );
};

export default Review;
