import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10;

  background-color: rgba(0, 0, 0, 0.3);

  @supports (backdrop-filter: blur(2px)) {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 678px;
  padding: 32px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: var(--neutrals-background);

  display: flex;
  flex-direction: column;
  justify-content: center;

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }

  img {
    height: 100px;
  }

  .text {
    color: var(--neutrals-text);
    text-align: center;

    h2 {
      font-size: 25px;
      line-height: 1.2em;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  .actions {
    margin-top: 32px;
    display: flex;

    button {
      flex: 1;
      &:first-of-type {
        margin-right: 8px;
      }
    }
  }
`;
