import React from 'react';
import { Helmet } from 'react-helmet';
import Routes from 'routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import ToastContainer from 'components/atoms/_util/ToastContainer';
import GlobalStyles from 'styles/GlobalStyles';
import ThemeContainer from 'styles/ThemeContainer';
import { store, persistor } from './store';
import i18n from './i18n';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      {/* @ts-ignore https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md */}
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <Router>
            <Helmet>
              <title>{i18n.t<string>('BUSINESS.APPLICATION_NAME')}</title>
            </Helmet>
            <ThemeContainer />
            <GlobalStyles />
            <Routes />
            <ToastContainer autoClose={3000} />
          </Router>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
