import React from 'react';
import CatawbaLogo from 'assets/images/catawba-logo.svg';
import { useNavigate } from 'react-router-dom';

import { Container, MessageContainer } from './styles';

const Message: React.FC = () => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/dashboard');
  };

  return (
    <Container>
      <h2>Confirmation</h2>
      <MessageContainer>
        <img src={CatawbaLogo} alt="Catawba logo" />
        <p>
          You successfully updated your Two-Factor Authentication software.
          <br />
          Now you need to keep this new OTP safe.
        </p>
      </MessageContainer>
      <button className="btn-submit" type="button" onClick={handleRedirect} />
    </Container>
  );
};

export default Message;
