import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const TextContainer = styled.div`
  height: 100%;
  padding-top: 80px;
  display: flex;
  flex-direction: column;

  align-items: center;

  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    width: 50%;
    text-align: center;
  }

  button.submit-kyc {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  ${mediaQuery.sm} {
    padding-top: 36px 20px 0 20px;

    h2 {
      font-size: 20px;
      margin-bottom: 16px;
    }

    p {
      width: 100%;
    }
  }
`;
