import React from 'react';
import cx from 'classnames';

import { Container } from './styles';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  breakRowOnMobile?: boolean;
}

const Row: React.FC<Props> = ({
  children,
  className,
  breakRowOnMobile,
}: Props) => {
  return (
    <Container
      breakRowOnMobile={breakRowOnMobile}
      className={cx({
        row: true,
        [className]: className,
      })}
    >
      {children}
    </Container>
  );
};

Row.defaultProps = {
  breakRowOnMobile: false,
};

export default Row;
