import React from 'react';
import Description from './Description';

import { Container } from './styles';

interface Props {
  currentStep: number;
}

const DESCRIPTIONS = [
  {
    title: 'Trulioo Policy',
    text: "Acknowledge Trulioo's policies to continue.",
  },
  {
    title: 'Workflow',
    text: 'Click the button to start the workflow.',
  },
];

const StepperDescription: React.FC<Props> = ({ currentStep }: Props) => {
  return (
    <Container>
      {DESCRIPTIONS.map((desc, idx) => (
        <Description
          key={desc.title.replace(/\s/g, '')}
          position={idx + 1}
          currentStep={currentStep}
          title={desc.title}
          text={desc.text}
        />
      ))}
    </Container>
  );
};

export default StepperDescription;
