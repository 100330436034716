/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { Button } from 'components/atoms/_common';
import { StepperCircle } from 'components/atoms';
import { useIsMobileScreen } from 'hooks';

import { Container } from './styles';

interface Props {
  previousStepHandler: () => void;
  currentStep: number;
  nextStepHandler: () => void;
  nextButtonText: () => string | string;
  totalSteps: number;
  hideNextButtonOnStep?: number;
  isBackButtonDisabledOnStep?: boolean[];
  isNextDisabled?: boolean;
}

const StepperFooter: React.FC<Props> = ({
  previousStepHandler,
  currentStep,
  nextStepHandler,
  nextButtonText,
  totalSteps,
  hideNextButtonOnStep,
  isBackButtonDisabledOnStep,
  isNextDisabled,
}: Props) => {
  const isMobile = useIsMobileScreen();
  const isBackButtonDisabled = isBackButtonDisabledOnStep
    ? isBackButtonDisabledOnStep[currentStep - 1]
    : false;

  const renderNextButtonText = () => {
    if (isMobile) {
      return <MdArrowForward size={24} />;
    }

    if (typeof nextButtonText === 'function') {
      return nextButtonText();
    }

    return nextButtonText;
  };

  return (
    <Container>
      <Button
        variant="outlined"
        onClick={previousStepHandler}
        disabled={isBackButtonDisabled || currentStep === 1}
      >
        {isMobile ? <MdArrowBack size={24} /> : 'Back'}
      </Button>

      <StepperCircle currentStep={currentStep} totalSteps={totalSteps} />

      <Button
        onClick={nextStepHandler}
        visible={hideNextButtonOnStep !== currentStep}
        disabled={isNextDisabled}
      >
        {renderNextButtonText()}
      </Button>
    </Container>
  );
};

StepperFooter.defaultProps = {
  hideNextButtonOnStep: undefined,
  isBackButtonDisabledOnStep: undefined,
  isNextDisabled: false,
};

export default StepperFooter;
