import React, { useState } from 'react';

import {
  Container,
  DropdownContainer,
  DefaultItemContainer,
  Divider,
} from './styles';

import Input, { InputProps, InputState } from '../Input';
import Modal from '../../_util/Modal';
import { useFormContext } from '../Form';

export type DropdownItem = {
  id: string;
  [key: string]: any;
};

export interface ItemRendererProps {
  item: DropdownItem;
  onItemClick: (id: string) => void;
}

interface Props {
  name: string;
  hint?: string;
  label?: string;
  items: DropdownItem[];
  itemRenderer: (props: ItemRendererProps) => React.ReactElement;
  itemContainer?: React.ReactElement | any;
  onSelect?: (item: DropdownItem) => void;
  disabled?: boolean;
}

const DropdownInput: React.FC<Props & InputProps> = ({
  name,
  label,
  hint,
  items,
  itemRenderer: ItemRenderer,
  itemContainer: ItemContainer,
  onSelect,
  disabled,
  ...rest
}: Props) => {
  const [open, setOpen] = useState(false);
  const [controlledInputState, setControlledInputState] =
    useState<InputState>(null);

  const { setValue } = useFormContext();

  return (
    <Container>
      <Modal open={open} onClose={() => setOpen(false)}>
        <DropdownContainer>
          <h2>{hint}</h2>
          <Divider />
          <ItemContainer>
            {items.map(item => (
              <ItemRenderer
                key={`dropdown-item-child-${item.id}`}
                item={item}
                onItemClick={id => {
                  setValue(name, id, { shouldValidate: true });
                  onSelect(item);
                  setControlledInputState('success');
                  setOpen(false);
                }}
              />
            ))}
          </ItemContainer>
        </DropdownContainer>
      </Modal>
      <Input
        name={name}
        label={label}
        {...rest}
        onFocus={() => setOpen(true)}
        onBlur={() => {}}
        disabled={disabled || open}
        controlledInputState={controlledInputState}
      />
    </Container>
  );
};

DropdownInput.defaultProps = {
  label: '',
  hint: '',
  itemContainer: DefaultItemContainer,
  onSelect: () => {},
  disabled: false,
};

export default DropdownInput;
