import styled from 'styled-components';

const ListItem = styled.div`
  cursor: pointer;

  width: 100%;
  min-width: 400px;
  font-size: 20px;
  padding: 20px;
  font-weight: bold;
  border-radius: 4px;
  margin: 10px;

  display: flex;
  overflow: hidden;
  background: var(--neutrals-background);
  border: 1px solid var(--neutrals-border);

  &:hover {
    background: var(--neutrals-background-hover);
    border: 1px solid var(--primary-border-active);
  }
`;

export default ListItem;
