import CatawbaLogo from 'assets/images/catawba-logo-2.svg';
import { Wrapper, Content, Logo, BlankedContainer } from './styles';

interface Props {
  children: React.ReactNode;
  blanked?: boolean;
}

const PublicLayout: React.FC<Props> = ({ children, blanked }: Props) => {
  if (blanked) return <BlankedContainer>{children}</BlankedContainer>;
  return (
    <Wrapper>
      <Logo>
        <img src={CatawbaLogo} alt="Catawba logo" />
      </Logo>
      <Content>{children}</Content>
    </Wrapper>
  );
};

PublicLayout.defaultProps = {
  blanked: false,
};

export default PublicLayout;
