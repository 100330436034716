import React from 'react';
import { KYCUpdateStepperProvider } from 'context/KYCUpdateStepper';
import Stepper from './Stepper';
import { TruliooNoticeMessage, TruliooWorkflowLink } from './Steps';

import { Container } from './styles';

const KYCUpdate: React.FC = () => {
  return (
    <KYCUpdateStepperProvider>
      <Container>
        <Stepper>
          <TruliooNoticeMessage />
          <TruliooWorkflowLink />
        </Stepper>
      </Container>
    </KYCUpdateStepperProvider>
  );
};

export default KYCUpdate;
