import styled, { css } from 'styled-components';

interface Props {
  variant?: 'expanded' | 'condensed';
}

export const Container = styled.div<Props>`
  display: flex;
  width: 100%;
  max-width: 478px;
  min-width: 200px;

  margin: 10px;
  height: 80px;

  background: var(--neutrals-background-light);
  border-radius: 8px;
  border: 1px solid var(--neutrals-border);

  align-items: center;

  ${props =>
    props.variant === 'condensed' &&
    css`
      width: 100%;
      max-width: 242px;
      height: 64px;

      h2 {
        max-width: 112px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `}

  .company-info-container {
    display: flex;
    flex: 1;
    cursor: pointer;

    .company-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      margin: 18px 0 18px 12px;
      background: var(--neutrals-background);
      border-radius: 50%;
      box-shadow: var(--neutrals-background-hover) 1px 1px 5px 1px;

      .icon-container {
        width: 44px;
        height: 44px;

        .icon {
          font-size: 22px;
        }
      }
    }

    .company-details {
      width: 100%;
      padding: 8px;
      display: flex;
      flex-direction: column;

      ${props =>
        props.variant === 'condensed' &&
        css`
          justify-content: center;
        `}

      h2 {
        font-size: 1rem;
        font-weight: 500;
        line-height: 19.2px;
      }

      p {
        color: var(--neutrals-text-weak-active);
        font-size: 10px;
        line-height: 12px;
        margin-top: 4px;
        margin-bottom: 8px;
      }
    }
  }

  .company-actions {
    display: flex;
    padding-right: 4px;
  }
`;

interface StatusProps {
  type: 'success';
}

export const Status = styled.span<StatusProps>`
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${props => `var(--${props.type}-border)`};
  font-size: 12px;
  line-height: 13px;
  color: ${props => `var(--${props.type}-border)`};
`;
