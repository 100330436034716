import React from 'react';
import SectionHeader, { SectionHeaderProps } from './SectionHeader';
import SectionContent, { SectionContentProps } from './SectionContent';

import { Container } from './styles';

interface SectionProps {
  children: React.ReactNode;
}

const Section: React.FC & {
  Header: React.FC<SectionHeaderProps>;
  Content: React.FC<SectionContentProps>;
} = ({ children }: SectionProps) => {
  return <Container className="section">{children}</Container>;
};

Section.Header = SectionHeader;
Section.Content = SectionContent;

export default Section;
