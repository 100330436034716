/* eslint-disable jsx-a11y/anchor-is-valid */
import { dispatchCustomEvent } from 'util/events';
import { InfoKind, StatusEvents } from './status.d';

const INFORMATION_BY_KIND: InfoKind = {
  companyPaymentUpdate: {
    pageTitle: 'Company Subscription Update',
    success: {
      title: 'Subscription updated!',
      subtitle: 'Your subscription has been updated.',
      hint: 'You have updated your subscription. You will be charged the next billing cycle on your new payment method selected.',
      redirect: {
        path: '/',
        label: 'Go to dashboard',
        icon: 'dashboard',
      },
    },
    failure: {
      title: 'Subscription update failed!',
      subtitle: 'Your subscription has not been updated.',
      hint: 'You have not updated your subscription. Something went wrong. Please try again.',
      redirect: {
        path: '/',
        label: 'Go to dashboard',
        icon: 'dashboard',
      },
    },
  },
  register: {
    pageTitle: 'Registration succeeded',
    success: {
      title: 'Registration succeeded!',
      subtitle: 'Your account has been successfully registered!',
      hint: 'You received an email with a confirmation link. Please click on it to confirm your email address.',
      redirect: {
        path: '/login',
        label: 'Go to Login',
        icon: 'arrow_forward',
      },
    },
  },
  'register/email-confirmation': {
    pageTitle: 'Confirm your email',
    failure: {
      title: 'Before you proceed...',
      subtitle: 'Please confirm your email address.',
      // hint: 'You received an email with a confirmation link. Please click on it to confirm your email address.',
      actionHint: () => (
        <p>
          If you didn&apos;t received a confirmation link via e-mail. Please
          click{' '}
          <a
            href="#"
            onClick={() =>
              dispatchCustomEvent(document, StatusEvents.Action, {
                kind: 'email-resend',
              })
            }
          >
            here
          </a>{' '}
          to resend it.
        </p>
      ),
      redirect: {
        path: '/logout',
        label: 'Go back to Login',
        icon: 'arrow_forward',
      },
    },
  },
  payment: {
    pageTitle: 'Payment',
    success: {
      title: 'Payment received',
      subtitle: 'Your application has been successfully submitted for review!',
      hint: "You'll receive an email when the review of your application is complete.",
      redirect: {
        path: '/',
        label: 'Go to Dashboard',
        icon: 'dashboard',
      },
    },
    failure: {
      title: 'Payment failed',
      subtitle: 'Your application has not been submitted for review.',
      hint: 'Successful payment is required to submit a registration application. Please try to submit payment again or contact support.',
      redirect: {
        path: '/',
        label: 'Go to Dashboard',
        icon: 'dashboard',
      },
    },
  },
  'payment-update': {
    pageTitle: 'Payment Update',
    success: {
      title: 'Payment method updated',
      subtitle: 'Your payment method has been successfully updated!',
      hint: 'You can now go to the application dashboard again',
      redirect: {
        path: '/',
        label: 'Go to Dashboard',
        icon: 'dashboard',
      },
    },
    failure: {
      title: 'Payment method update failed',
      subtitle: 'Your payment method has been updated',
      hint: 'If you want to, you can try changing the payment method again. Else, Please try to contact support.',
      redirect: {
        path: '/',
        label: 'Go to Dashboard',
        icon: 'dashboard',
      },
    },
  },
  'email-invalid': {
    pageTitle: 'Email failed',
    failure: {
      title: 'Email invalid',
      hint: 'Please try to register again with a valid email.',
      redirect: {
        path: '/register',
        label: 'Go to Registration',
        icon: 'mdi-account-plus',
      },
    },
  },
  kyc: {
    pageTitle: 'KYC Process Status',
    success: {
      title: 'Before you continue...',
      subtitle: 'The CDEZ requires a "Know Your Customer" check.',
      hint: 'Your information is private and used to validate your identity only. <br /> Please review our <a href="https://catawbadigital.zone/privacy-policies/">Privacy Policy</a> for more information.',
      redirect: {
        path: '/kyc/update',
        label: 'Continue',
        icon: 'arrow_forward',
      },
    },
    failure: {
      title: "Your KYC application it's under review...",
      subtitle: 'Currently your data is being reviewed by the CDEZ.',
      hint: 'You will be notified as soon as your application is reviewed.',
      redirect: {
        path: '/logout',
        label: 'Go back to login',
        icon: 'arrow_forward',
      },
    },
  },
  vcPaymentUpdate: {
    pageTitle: 'VC Payment Update',
    success: {
      title: 'VC Payment Update',
      subtitle: 'Your payment method has been successfully updated!',
      hint: 'You can now go to the application again',
      redirect: {
        path: '/did-management',
        label: 'Go to DID Management',
        icon: 'dashboard',
      },
    },
    failure: {
      title: 'VC Payment Update',
      subtitle: 'Your payment method has been updated',
      hint: 'If you want to, you can try changing the payment method again. Else, Please try to contact support.',
      redirect: {
        path: '/',
        label: 'Go to Dashboard',
        icon: 'dashboard',
      },
    },
  },
};

export default INFORMATION_BY_KIND;
