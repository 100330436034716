import styled from 'styled-components';

export const Container = styled.div.attrs({
  'data-testid': 'accordion-container',
  className: 'accordion-container',
})`
  border: 1px solid #ccc;
  border-radius: 4px;
`;

interface IsOpen {
  isOpen: boolean;
}

export const Header = styled.div.attrs({
  'data-testid': 'accordion-header',
})<IsOpen>`
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-text);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: ${({ isOpen }) => (isOpen ? '1px solid #ccc' : 'none')};
  cursor: pointer;

  // prevent selection of text
  user-select: none;

  & > svg {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 200ms ease-in;
  }
`;

export const Content = styled.div.attrs({
  'data-testid': 'accordion-content',
  className: 'accordion-content',
})<IsOpen>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  padding: 16px;
`;
