import React from 'react';
import {
  FaEyeSlash as EyeSlashIcon,
  FaEye as EyeOpenedIcon,
} from 'react-icons/fa';
import { Container } from './styles';

interface Props {
  isOpened: boolean;
  onClick: () => void;
}

const EyeIcon: React.FC<Props> = ({ isOpened, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      {isOpened ? <EyeOpenedIcon /> : <EyeSlashIcon />}
    </Container>
  );
};

export default EyeIcon;
