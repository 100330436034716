import styled from 'styled-components';

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;

  // striped table
  tbody {
    tr:nth-child(odd) {
      background-color: #f2f2f2;
    }
  }

  th {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 600;

    &:first-child {
      text-align: left;
      padding: 10px;
    }
  }

  td {
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5;
    text-align: center;

    &:first-child {
      text-align: left;
      padding: 10px;
    }

    &.actions {
      & > * {
        margin-right: 12px;
        font-size: 18px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
