import styled, { css } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

interface Props {
  isOpen: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  position: relative;

  border-radius: 95px;

  &.pill {
    width: ${props => (props.isOpen ? '232.39px' : '44px')} !important;
    height: 44px;
    padding-left: 16px;
    justify-content: space-between;
    overflow: visible;
  }

  .role,
  .icon {
    ${props =>
      props.isOpen
        ? css`
            opacity: 1;
            width: 100%;
          `
        : css`
            opacity: 0;
            width: 0px;
          `}
    transition: opacity 0.4s ease;
  }

  p.role {
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .user {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -1px;

    img {
      z-index: 1;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      margin-left: ${props => (props.isOpen ? '12px' : '0')};

      border: 2px solid var(--primary-icon);
    }
  }

  ${mediaQuery.sm} {
    position: unset;
  }
`;

export const Menu = styled.div<Props>`
  ${({ isOpen }) =>
    isOpen &&
    css`
      left: 265px !important;
    `}

  position: absolute;
  bottom: 0;
  left: 80px;
  width: 200px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--neutrals-background);
  border: 1px solid var(--neutrals-border);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);

  transition: left 0.4s ease-in-out;
  z-index: 3;

  & > button {
    margin-bottom: 8px;
  }

  & > button:last-child {
    margin-bottom: 0;
  }

  ${mediaQuery.sm} {
    left: 0 !important;
    bottom: 60px;
    width: 100%;
  }
`;

export const DetectOutterClick = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
