import { useAppSelector } from 'hooks';
import React from 'react';
import { Navigate } from 'react-router-dom';

import PublicLayout from '../pages/_layouts/public';

interface Props {
  children: React.ReactNode;
  redirectTo?: string;
  blanked?: boolean;
}

const PublicRoute: React.FC<Props> = ({
  children,
  redirectTo,
  blanked,
}: Props) => {
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);

  if (isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }
  return <PublicLayout blanked={blanked}>{children}</PublicLayout>;
};

PublicRoute.defaultProps = {
  redirectTo: '/dashboard',
  blanked: false,
};

export default PublicRoute;
