import { Button } from 'components/atoms/_common';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { getNewsletterRequest } from 'store/slices/user';
import { Container, News } from './styles';

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();

  const newsletter = useAppSelector(state => state.user.newsletter);

  useEffect(() => {
    if (!newsletter || !newsletter.length) {
      dispatch(getNewsletterRequest());
    }
  }, []);

  const redirectTo = (url: string): void => {
    window.open(url, '_blank');
  };

  if (!newsletter || !newsletter.length) {
    return (
      <Container>
        <h1>No news to show</h1>
      </Container>
    );
  }

  return (
    <Container>
      {newsletter.map(news => (
        <News key={news.id}>
          <h2>{news.title || 'Newsletter'}</h2>
          <p>{news.description}</p>

          {news.attachments.map(attachment => {
            if (attachment.type === 'image') {
              return (
                <img
                  key={attachment.title}
                  src={attachment.url}
                  alt={attachment.title}
                />
              );
            }
            return null;
          })}

          <Button variant="subtle" onClick={() => redirectTo(news.url)}>
            Read More
          </Button>
        </News>
      ))}
    </Container>
  );
};

export default Dashboard;
