import { Routes, Route } from 'react-router-dom';

import {
  Login,
  Dashboard,
  Companies,
  Notifications,
  Messages,
  Settings,
  Register,
  ForgotPassword,
  Status,
  Mapping,
  EmailConfirmationStatus,
  RegisterEmailConfirmation,
  CreateCompany,
  Company,
  CompanyApplication,
  TwoFactorAuthentication,
  KYCStatus,
  KYCUpdate,
  Logout,
  CompanyInvitation,
  DIDManagement,
} from 'pages';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import NoAuthRoute from './NoAuthRoute';
import KYCUnverifiedRoute from './KYCUnverifiedRoute';

const CustomRoutes: React.FC = () => {
  return (
    <Routes>
      {/* TEST ROUTES */}
      <Route
        path="/backend-mapping"
        element={
          <NoAuthRoute>
            <Mapping />
          </NoAuthRoute>
        }
      />

      {/* KYC ROUTES */}
      <Route
        path="/kyc/status"
        element={
          <KYCUnverifiedRoute blanked>
            <KYCStatus />
          </KYCUnverifiedRoute>
        }
      />

      <Route
        path="/kyc/update"
        element={
          <KYCUnverifiedRoute blanked>
            <KYCUpdate />
          </KYCUnverifiedRoute>
        }
      />

      {/* NO AUTH ROUTES */}
      <Route
        path="/register/email-confirmation"
        element={
          <NoAuthRoute>
            <EmailConfirmationStatus />
          </NoAuthRoute>
        }
      />
      <Route
        path="/register/success"
        element={
          <NoAuthRoute>
            <Status kind="register" type="success" />
          </NoAuthRoute>
        }
      />
      <Route
        path="/payment"
        element={
          <NoAuthRoute>
            <Status kind="payment" />
          </NoAuthRoute>
        }
      />
      <Route
        path="/company-payment-update"
        element={
          <NoAuthRoute>
            <Status kind="companyPaymentUpdate" />
          </NoAuthRoute>
        }
      />
      <Route
        path="/vc-payment"
        element={
          <NoAuthRoute>
            <Status kind="vcPaymentUpdate" />
          </NoAuthRoute>
        }
      />
      <Route
        path="/logout"
        element={
          <NoAuthRoute>
            <Logout />
          </NoAuthRoute>
        }
      />
      {/* EXTERNAL DEPENDENTS ROUTES */}
      <Route
        path="/user/registration/accept"
        element={
          <NoAuthRoute>
            <RegisterEmailConfirmation />
          </NoAuthRoute>
        }
      />
      {/* PUBLIC ROUTES */}
      <Route
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PublicRoute blanked>
            <Register />
          </PublicRoute>
        }
      />
      <Route
        path="/forgot"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      {/* PRIVATE ROUTES */}
      <Route
        path="/companies/company/:id"
        element={
          <PrivateRoute>
            <Company />
          </PrivateRoute>
        }
      />
      <Route
        path="/companies/companyapplication/:id"
        element={
          <PrivateRoute>
            <CompanyApplication />
          </PrivateRoute>
        }
      />
      <Route
        path="/companies/create"
        element={
          <PrivateRoute>
            <CreateCompany />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/companies"
        element={
          <PrivateRoute>
            <Companies />
          </PrivateRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <PrivateRoute>
            <Notifications />
          </PrivateRoute>
        }
      />
      <Route
        path="/messages"
        element={
          <PrivateRoute>
            <Messages />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/2fa"
        element={
          <PrivateRoute>
            <TwoFactorAuthentication />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/invite"
        element={
          <PrivateRoute>
            <CompanyInvitation />
          </PrivateRoute>
        }
      />
      <Route
        path="/did-management"
        element={
          <PrivateRoute>
            <DIDManagement />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default CustomRoutes;
