/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { REGISTER_SCHEMA } from 'util/schemas';
import { Form, Input } from 'components/atoms/_common';
import { Row } from 'components/atoms/_util';
import { Label } from 'components/atoms';
import { useRegisterStepper } from 'context/RegisterStepper';

import Base from '../_base';

const Subtitle = () => {
  return (
    <>
      <p>Please choose a username and strong password.</p>
      <p>
        We recommend using a Password Manager, such as{' '}
        <a href="https://bitwarden.com/" target="_blank" rel="noreferrer">
          Bitwarden
        </a>
        , to generate and store a strong password for you.
      </p>
    </>
  );
};

const UsernamePassword: React.FC = () => {
  const { setUsernamePassword, usernamePassword, nextStep } =
    useRegisterStepper();

  const handleSubmit = values => {
    setUsernamePassword(values);
    nextStep();
  };

  return (
    <Base
      iconName="key"
      title="Username &#38; Password"
      subtitle={<Subtitle />}
    >
      <Form
        onSubmit={handleSubmit}
        defaultValues={usernamePassword}
        schema={REGISTER_SCHEMA.usernamePassword}
      >
        <Label text="Username" iconName="person" />
        <Row breakRowOnMobile>
          <Input name="username" label="Username" placeholder="Username" />
          <Input name="email" label="Email" placeholder="Email" />
        </Row>

        <Label text="Password" iconName="key" />
        <Row breakRowOnMobile>
          <Input
            name="password"
            type="password"
            label="Password"
            placeholder="Password"
          />
          <Input
            name="confirmPassword"
            type="password"
            label="Confirm password"
            placeholder="Confirm password"
            submitOnEnter
          />
        </Row>

        <button type="submit" className="submit-register" />
      </Form>
    </Base>
  );
};

export default UsernamePassword;
