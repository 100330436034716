import IconCircle from 'components/atoms/_common/IconCircle';
// import { IconCircle } from 'components/atoms/_common';
import React from 'react';

import { Container } from './styles';

interface Props {
  children?: React.ReactNode;
  isOpen: boolean;
  name: string | React.ReactNode;
  icon: string;
  onClick?: () => void;
}

const NavItem: React.FC<Props> = ({
  children,
  isOpen,
  name,
  icon: iconName,
  onClick,
}: Props) => {
  return (
    <Container isOpen={isOpen} className="pill" onClick={onClick}>
      <IconCircle name={iconName} />
      <span className="label">{name}</span>
      {children && children}
    </Container>
  );
};

NavItem.defaultProps = {
  children: null,
  onClick: () => {},
};

export default NavItem;
