import { Link } from 'react-router-dom';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  padding-left: 320px;
  background-color: var(--neutrals-background);

  aside {
    background-color: var(--neutrals-background-light);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 320px;
    padding: 32px;
    z-index: 1;
    justify-content: center;
    align-items: center;

    img {
      width: 234px;
    }

    .divider {
      height: 1px;
      width: 100%;
      background-color: var(--neutrals-border-active);
      margin-top: 40px;
      margin-bottom: 32px;
    }
  }

  section {
    background-color: var(--neutrals-background);
    height: 90%;
    overflow: auto;
  }

  footer {
    position: absolute;
    bottom: 0;
    width: calc(100vw - 320px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    background-color: var(--neutrals-background);

    button {
      width: 280px;
    }
  }

  ${mediaQuery.sm} {
    padding-left: 0;

    form {
      padding-bottom: 20px;
    }

    aside {
      display: none;
    }

    footer {
      width: 100%;
      padding: 12px 24px;

      button {
        width: min-content;
      }
    }

    section {
      height: calc(100vh - 78px);
    }

    .section-content {
      padding: 20px;
    }
  }
`;

export const LinkLogin = styled(Link)`
  text-align: center;
  display: block;
  margin-top: auto;
  color: var(--neutrals-text-weak-active);
  font-size: 1rem;
  line-height: 1.2em;

  span {
    margin-left: 10px;
    font-weight: 700;
    color: var(--select-text);
  }
`;
