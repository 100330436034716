import { Section } from 'components/molecules';
import { Container } from './styles';

const Messages: React.FC = () => {
  return (
    <Section>
      <Section.Header
        title="Messages"
        subtitle="Some messages"
        icon="corporate_fare"
      />
      <Section.Content noPadding>
        <Container>
          <h1>
            Welcome to <strong>Messages</strong>
          </h1>
        </Container>
      </Section.Content>
    </Section>
  );
};

export default Messages;
