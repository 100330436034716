import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--neutrals-background-light);

  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  padding-bottom: 60px;

  h2 {
    margin-bottom: 12px;
    font-size: 25px;
    line-height: 30px;
    font-weight: 500;
  }

  .response {
    max-width: 80vh;
    max-height: 400px;
    overflow: scroll;
    margin: 10px 0;
    padding: 20px;
    background-color: var(--neutrals-background-light);
  }

  .status {
    background-color: var(--neutrals-background);
    padding: 10px;
    border-radius: 12px 12px 0 0;
    .success {
      color: var(--success-text);
    }
    .failure {
      color: var(--danger-text);
    }
  }

  form {
    background-color: var(--neutrals-background);
    padding: 40px;
    border-radius: 12px;

    button[type='submit'] {
      margin-top: 20px;
    }
  }

  .signIn {
    margin-top: 60px;
    form {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      margin-bottom: 20px;
    }

    input {
      font-size: 14px;
      padding: 12px;
      margin: 5px;
    }
  }
`;
