import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks';
import {
  acceptCompanyInvitationRequest,
  getInvitationCompanyDataRequest,
  rejectCompanyInvitationRequest,
} from 'store/slices/company';
import { Button } from 'components/atoms/_common';
import { useNavigate } from 'react-router-dom';
import { notifyError } from 'services/notify';

import { Container } from './styles';

const CompanyInvitation: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>({});
  const auth = new URLSearchParams(window.location.search).get('auth');
  useEffect(() => {
    if (!auth) {
      navigate('/dashboard');
      notifyError('"auth" param was not present in the URL.');
    }

    const callback = (d: any) => {
      setData(d);
    };
    dispatch(getInvitationCompanyDataRequest({ callback, auth }));
  }, []);

  useEffect(() => {
    if (data) setLoading(false);
  }, [data]);

  const handleAccept = () => {
    dispatch(
      acceptCompanyInvitationRequest({
        auth,
        callback: () => navigate('/dashboard'),
      })
    );
  };

  const handleReject = () => {
    dispatch(
      rejectCompanyInvitationRequest({
        auth,
        callback: () => navigate('/dashboard'),
      })
    );
  };

  if (!data && !loading) return <h1>Unexpected error, please try again.</h1>;

  if (loading) return <h1>Loading...</h1>;

  return (
    <Container>
      <div className="center">
        <h1>Company Invitation</h1>
        <h2>
          You are being invited to be part of the company{' '}
          <strong>{data?.company_name}</strong> as a{' '}
          <strong>{data?.role}</strong>.
        </h2>
        <h3>Do you accept this invitation?</h3>
        <div className="buttons">
          <Button onClick={handleAccept} className="accept">
            Accept
          </Button>
          <Button onClick={handleReject} className="reject">
            Reject
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default CompanyInvitation;
