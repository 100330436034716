import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  padding: 20px;
  position: absolute;
  top: 28px;
  right: 0;

  svg {
    color: var(--neutrals-text-weak-active);
  }
`;
