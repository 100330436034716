import React, { createContext, useState } from 'react';
import { ContextState, UsernamePasswordIS } from './types';

export const RegisterStepperContext = createContext<ContextState>({
  currentStep: 1,
  name: null,
  address: null,
  country: '',
  usernamePassword: UsernamePasswordIS,
  twoFactorAuth: {
    code: '',
    secret: '',
  },

  nextStep: () => {},
  previousStep: () => {},
  setName: () => {},
  setAddress: () => {},
  setCountry: () => {},
  setUsernamePassword: () => {},
  setTwoFactorAuth: () => {},
});

export const RegisterStepperProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState(null);
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState(null);
  const [usernamePassword, setUsernamePassword] = useState(UsernamePasswordIS);
  const [twoFactorAuth, setTwoFactorAuth] = useState({ code: '', secret: '' });

  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    name,
    address,
    country,
    usernamePassword,
    twoFactorAuth,
    currentStep,
    nextStep,
    previousStep,
    setName,
    setAddress,
    setCountry,
    setUsernamePassword,
    setTwoFactorAuth,
  };

  return (
    <RegisterStepperContext.Provider value={value}>
      {children}
    </RegisterStepperContext.Provider>
  );
};

export const useRegisterStepper = () => {
  const context = React.useContext(RegisterStepperContext);

  return context;
};
