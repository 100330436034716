import { useEffect, useState } from 'react';
import { Web5 } from '@web5/api';
import { DidKeyMethod } from '@web5/dids';
import { VerifiableCredential } from '@web5/credentials';
import { Loading } from 'components/atoms';
import { Accordion, Button } from 'components/atoms/_common';
import { notifyError, notifySuccess } from 'services/notify';
import API from 'services/API';
import {
  Container,
  DIDDescription,
  AvailableNewDIDBadge,
  DIDContainer,
  Actions,
  VerifiableCredentialsContainer,
  VCCardsContainer,
} from './styles';
import VerifiableCredentialCard from './VerifiableCredentialCard';

const DIDManagement = () => {
  const [userProfile, setUserProfile] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [revealDID, setRevealDID] = useState<boolean>(false);
  const [myDID, setMyDID] = useState<string | null>('');
  const [verifiableCredentials, setVerifiableCredentials] = useState<any[]>([]);

  const checkoutPaymentSession = async () => {
    setIsLoading(true);
    const response = await API.post('/vc/checkout-payment/session', {
      quantity: 1,
    });
    setIsLoading(false);

    const { link } = response.data;
    if (link) {
      window.open(link);
    }
  };

  const createUserVC = async () => {
    try {
      setIsLoading(true);
      const response = await API.get('/user/vc');
      console.log(response, response.data, response.status);

      if (!response.data?.userProfile || !response.data?.CDEZ_DID) {
        console.log('no vc data');
        throw new Error('No VC data');
      }

      const { userProfile: user, CDEZ_DID } = response.data;
      console.log({ userProfile: user, CDEZ_DID });

      const VC = await VerifiableCredential.create({
        issuer: CDEZ_DID,
        subject: myDID,
        type: ['VerifiableCredential', 'UserProfileCredential'],
        data: {
          did: myDID,
          ...user,
        },
      });

      console.log(VC);

      const issuer = await DidKeyMethod.create();
      console.log(issuer);

      const vcJwt = await VC.sign({
        did: issuer,
      });

      console.log(vcJwt);

      const vcResponse = await API.post('/user/vc', {
        vcJwt,
      });

      console.log(vcResponse);

      setIsLoading(false);

      if (vcResponse.data) {
        notifySuccess("You've successfully created your VC!");
        const userVCsResponse = await API.get('/user/vcs');
        setVerifiableCredentials(userVCsResponse.data);
      }
    } catch (error) {
      setIsLoading(false);
      notifyError(error);
    }
  };

  const getUserVCs = async () => {
    try {
      setIsLoading(true);
      const response = await API.get('/user/vcs');
      setIsLoading(false);
      console.log(response);

      if (response.data?.length === 0) {
        createUserVC();
      } else {
        setVerifiableCredentials(response.data);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getUserProfile = async () => {
    setIsLoading(true);
    const response = await API.get('/user/profile');
    setIsLoading(false);

    setUserProfile(response.data.profile);
  };

  const capturePaymentSession = async () => {
    setIsLoading(true);
    try {
      const response = await API.post('/vc/capture-payment/session', {
        waivePayment: false,
      });
      if (response.data === 'succeeded') {
        notifySuccess('Payment successful! Creating your VC...');
        getUserProfile();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notifyError(error);
    }
  };

  useEffect(() => {
    const web5Init = async () => {
      const { did } = await Web5.connect();
      setMyDID(did);
    };

    getUserProfile().then(() => {
      web5Init();
    });
  }, []);

  useEffect(() => {
    if (myDID && userProfile?.vc_payment_status === 'paid') {
      getUserVCs();
    }
  }, [myDID, userProfile]);

  useEffect(() => {
    const isPendingPayment =
      userProfile?.vc_payment_status === 'pending_payment';

    if (isPendingPayment) {
      capturePaymentSession();
    }
  }, [userProfile]);

  if (isLoading) {
    return <Loading />;
  }

  if (!myDID) {
    return (
      <Container>
        <p>Connecting to the network to generate your DID...</p>
      </Container>
    );
  }

  const isUnpaid = userProfile?.vc_payment_status === 'unpaid';
  const isPendingPayment = userProfile?.vc_payment_status === 'pending_payment';

  return (
    <Container>
      <AvailableNewDIDBadge>You have a DID!</AvailableNewDIDBadge>
      <DIDDescription>
        <b>Decentralized Identifiers (DIDs)</b> revolutionize online identity,
        providing a secure, self-sovereign way to manage your digital presence.
        <br />
        Stored on a decentralized network, DIDs ensure unique, tamper-proof
        identities, free from central authority control.
        <br />
        <br />
        {isUnpaid || isPendingPayment ? (
          <>
            <p>
              In order to get your <b>Verifiable Credential</b>, you&apos;ll
              need to use the action below to finish your process and{' '}
              <b>get your VC</b>.<br />
              This credential represents a certified confirmation of your{' '}
              <b>identity and KYC</b>, enhancing trust and security in your
              online interactions.
            </p>
            <Accordion title="About development phase:">
              <p>
                We want to inform you that the Verifiable Credential version
                currently available on our platform is in the beta stage of
                development. As we continue to refine and enhance this feature,
                you may experience occasional changes and updates. <br />
                Your feedback is invaluable to us during this beta phase, and we
                appreciate your understanding as we work towards providing a
                seamless and robust experience. Please feel free to share any
                insights or suggestions you may have, as they will contribute
                significantly to the ongoing improvements. <br /> <br />
                Thank you for being a part of our community and for your
                patience as we strive to deliver a top-notch Verifiable
                Credential experience.
              </p>
            </Accordion>
          </>
        ) : (
          <p>
            See below all your <b>Verifiable Credentials.</b>
          </p>
        )}
      </DIDDescription>

      <Actions>
        <Button onClick={() => setRevealDID(s => !s)}>
          {revealDID ? 'Hide' : 'Reveal'} DID
        </Button>
        {(isUnpaid || isPendingPayment) && (
          <Button onClick={checkoutPaymentSession}>
            Finish the process and get your Verifiable Credential
          </Button>
        )}
      </Actions>

      {revealDID && (
        <DIDContainer>
          <p>Here is your DID:</p>
          <textarea
            name="did"
            id="personal-did"
            cols={20}
            rows={5}
            value={myDID}
            readOnly
          />
        </DIDContainer>
      )}

      {verifiableCredentials.length > 0 && (
        <VerifiableCredentialsContainer>
          <h2>Here are your Verifiable Credentials:</h2>
          <VCCardsContainer>
            {verifiableCredentials.map(vc => (
              <VerifiableCredentialCard data={vc} />
            ))}
          </VCCardsContainer>
        </VerifiableCredentialsContainer>
      )}
    </Container>
  );
};

export default DIDManagement;
