import i18n from '../../i18n';

// Note: these are the error messages that we show to the final user
// prettier-ignore
const ERROR_MESSAGES = {
  'login/unauthorized': i18n.t('ERROR.LOGIN_UNAUTHORIZED'),
  'login/otp-unauthorized': i18n.t('ERROR.LOGIN_OTP_INVALID'),
  'auth/totp-verify-invalid': i18n.t('ERROR.TOTP_VERIFY_INVALID'),
  'register/username_already_exists': i18n.t('ERROR.REGISTER_USERNAME_EXISTS'),
  'register/unable_to_set_user_email': i18n.t('REGISTER_UNABLE_TO_SEND_USER_EMAIL'),
  'register/user_email_unique_error': i18n.t('REGISTER_USER_EMAIL_UNIQUE_ERROR'),
  'register/link_generation_error': i18n.t('REGISTER_LINK_GENERATION_ERROR'),
  'forgotpassword/user_not_found': i18n.t('ERROR.FORGOT_PASSWORD_USER_NOT_FOUND'),
  'resetpassword/unauthorized': i18n.t('ERROR.RESET_PASSWORD_UNAUTHORIZED'),
  'kyc/approval_required': i18n.t('ERROR.KYC_APPROVAL_REQUIRED'),
  'companyapplication/company_already_exists': i18n.t('ERROR.COMPANY_ALREADY_EXISTS'),
  'otp_update/totp_invalid': i18n.t('ERROR.TOTP_INVALID'),
  'otp_update/unauthorized': i18n.t('ERROR.OTP_UPDATE_UNAUTHORIZED'),
  'otp_update/failed': i18n.t('ERROR.OTP_UPDATE_FAILED'),
  'password_update/invalid_otp': i18n.t('ERROR.OTP_VERIFY_INVALID'),
  'password_update/invalid_password': i18n.t('ERROR.UPDATE_PASSWORD_INVALID_PASSWORD'),
  'password_update/repeated_password': i18n.t('ERROR.UPDATE_PASSWORD_REPEATED_PASSWORD'),
  'companydissolve/company_dissolve_request_already_exists': i18n.t('ERROR.COMPANY_DISSOLVE_REQUEST_ALREADY_EXISTS'),
  'kyc/kyc_request_already_exists': i18n.t('ERROR.KYC_REQUEST_ALREADY_EXISTS'),
  'register/email_already_registered': i18n.t('ERROR.EMAIL_ALREADY_REGISTERED'),
  'invitation/already-sent': i18n.t('ERROR.INVITATION_ALREADY_SENT'),
  'invitation/company-not-found': i18n.t('ERROR.INVITATION_COMPANY_NOT_FOUND'),
  'invitation/member-is-not-authorized': i18n.t('ERROR.INVITATION_MEMBER_IS_NOT_AUTHORIZED'),
  'invitation/companies-not-found-for-the-user': i18n.t('ERROR.INVITATION_COMPANIES_NOT_FOUND_FOR_THE_USER'),
};

const DEFAULT_ERROR = i18n.t('ERROR.DEFAULT');

const getErrorMessage = (message: string): string =>
  ERROR_MESSAGES[message] ? ERROR_MESSAGES[message] : DEFAULT_ERROR;

export { getErrorMessage, DEFAULT_ERROR };
