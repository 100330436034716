import styled, { css } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div``;

export const MobileSelectBox = styled.div<{ isChecked: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  min-height: 120px;
  border: 1px solid var(--neutrals-border);
  border-radius: 8px;
  padding: 22px 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${({ isChecked }) =>
    isChecked &&
    css`
      background-color: var(--neutrals-background-light);
      box-shadow: 0px 0px 0px 3px var(--select-border);
    `}

  .checkbox {
    position: absolute;
    top: 16px;
    right: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid var(--neutrals-border-active);
  }

  img {
    height: 60px;
    margin-bottom: 16px;
  }

  p {
    text-align: center;
  }
`;

export const DesktopSelectBox = styled.div<{ isChecked: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;

  border: 1px solid var(--primary-border);

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  height: 160px;
  width: 160px;

  strong {
    font-weight: bold;
  }

  &.select-left {
    margin-right: 40px;
  }

  background-color: ${({ isChecked }) =>
    isChecked
      ? 'var(--neutrals-background-light)'
      : 'var(--neutrals-background)'};

  transition: background-color 0.4s ease-in-out;
  .checkbox {
    position: absolute;
    top: 10px;
    right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid var(--neutrals-border-active);
  }
`;

export const IconCircleContainer = styled.div`
  height: 164px;
  width: 164px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  img {
    &.companyType {
      width: 80px;
    }
  }

  ${mediaQuery.sm} {
    height: 100%;
    width: 100%;

    img {
      width: 100%;

      &.companyType {
        width: 100%;
      }
    }
  }
`;
