import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCreateCompanyStepper } from 'context/CreateCompanyStepper';
import { StepperFooter } from 'components/molecules';
import {
  CatawbaCitizenship,
  CompanyType,
  Information,
  InvoiceDetails,
  Review,
} from '../Steps';

import { Container, Content } from './styles';

const Stepper: React.FC = () => {
  // @ts-ignore
  const { currentStep, previousStep, loadingEditingInfo } =
    useCreateCompanyStepper();

  const nextButtonText = () => {
    const texts = ['Continue', 'Continue', 'Checkout', 'Finish'];
    return texts[currentStep - 1];
  };

  const returnStep = () => {
    const steps = [
      <CompanyType />,
      <CatawbaCitizenship />,
      <Information />,
      <Review />,
      <InvoiceDetails />,
    ];
    return steps[currentStep - 1];
  };

  const handleNextStep = () => {
    document
      .querySelector<HTMLButtonElement>('button.submit-createCompany')
      .click();
  };

  if (loadingEditingInfo) {
    return <h1>Loading</h1>;
  }

  return (
    <Container>
      <Content>{returnStep()}</Content>

      <StepperFooter
        totalSteps={5}
        currentStep={currentStep}
        nextButtonText={nextButtonText}
        previousStepHandler={previousStep}
        nextStepHandler={handleNextStep}
        hideNextButtonOnStep={5}
        isBackButtonDisabledOnStep={[true, false, false, false, true]}
      />
    </Container>
  );
};

export default Stepper;
