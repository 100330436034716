import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  width: 168px;
  margin: 5px 5px 0 5px;
  border: 1px solid var(--neutrals-background-light);
  border-radius: 4px;

  &:hover {
    .image-card {
      background: var(--neutrals-border);
    }
  }
`;

export const ImageCard = styled.div`
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background: var(--neutrals-background-light);
  font-size: 31px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const NameCard = styled.div`
  padding: 8px;
  text-align: center;
  border-top: 1px solid var(--neutrals-background-light);

  overflow: hidden;
  text-overflow: ellipsis;
`;
