import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  padding: 40px 80px;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  ${mediaQuery.sm} {
    padding: 20px 10px;

    & .content {
      padding: 0 10px;
    }
  }
`;

export const SectionMarker = styled.div`
  width: 100%;
  font-size: 25px;
  padding: 20px;
  border-radius: 4px;
  background: var(--neutrals-background-light);
  border: 1px solid var(--neutrals-border);
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background: var(--neutrals-border);
`;

export const EmptyNotificationsMessage = styled.h3`
  text-align: center;
  color: var(--neutrals-text-weak-active);
`;
