import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { Button } from 'components/atoms/_common';

import { checkoutStripePaymentRequest } from 'store/slices/company';

import { Loading } from 'components/atoms';
import { formatDate } from 'util/formatting';
import { Container, InvoiceContainer, Divider, ErrorContainer } from './styles';

const InvoiceDetails = () => {
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);

  const dispatch = useAppDispatch();
  const activeCompany = useAppSelector(state => state.company.activeCompany);

  useEffect(() => {
    dispatch(
      checkoutStripePaymentRequest({
        companyApplicationId: activeCompany.application.id,
        callback: (error, response) => {
          if (error) {
            setErrors(error);
            return;
          }
          setData(response);
        },
      })
    );
  }, []);

  const handleClick = () => {
    if (data && data.link) {
      window.location.replace(data.link);
    }
  };

  if (errors) {
    return (
      <ErrorContainer>
        There was an error with the Stripe integration. Please Contact support
        or try again later.
      </ErrorContainer>
    );
  }

  if (!data) {
    return <Loading />;
  }

  return (
    <Container>
      <InvoiceContainer>
        <div className="invoice">
          <div className="details">
            <h3>Invoice Details</h3>
            <p className="invoiceId">{data?.invoice?.details?.id}</p>
            <p>Issued date: {formatDate(data?.invoice?.details?.issuedDate)}</p>
            <p>Due date: {formatDate(data?.invoice?.details?.dueDate)}</p>
          </div>
          <div className="billed">
            <h3>Billed to:</h3>
            <p>{data?.invoice?.billTo?.name}</p>
            <p>{data?.invoice?.billTo?.city}</p>
            <p>{`${data?.invoice?.billTo?.state}, ${data?.invoice?.billTo?.country}`}</p>
          </div>
        </div>
        <Divider />
        <div className="item">
          <div className="details">
            <h3>Item details</h3>
            <p>{data?.invoice?.item?.companyName}</p>
            <p>{data?.invoice?.item?.type}</p>
            <p>
              {data?.invoice?.item?.isCatawbaCitizen
                ? 'Catawba Entity'
                : 'Non Catawba Entity'}
            </p>
          </div>
        </div>
        <Divider />
        <p className="total">
          Total Amount:{' '}
          <span className="bold">${data?.invoice?.item?.totalAmount}</span>
        </p>
        {data?.invoice?.item?.isCatawbaCitizen && (
          <p className="hint-catawba">
            Once your Catawba Citizenship is confirmed, then your payment will
            be returned to you.
          </p>
        )}
        <Button full onClick={handleClick}>
          Pay ${data?.invoice?.item?.totalAmount}
        </Button>
      </InvoiceContainer>
      <p className="poweredBy">
        Powered by <span>Stripe</span>
      </p>
    </Container>
  );
};

export default InvoiceDetails;
