import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 24px;

  & > * {
    margin-bottom: 1rem;
  }

  position: relative;

  & .loading-contained-container {
    background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    pointer-events: none;
    z-index: 10;
  }

  .accordion-container {
    margin-top: 16px;
  }
`;

export const DIDDescription = styled.div`
  & b {
    font-weight: 600;
  }
`;

export const AvailableNewDIDBadge = styled.div`
  padding: 4px 8px;

  color: var(--success-text);
  font-size: 14px;
  font-weight: 600;
  background-color: var(--success-background);
  border: 1px solid var(--success-border);
  border-radius: 4px;
  width: fit-content;
`;

export const DIDContainer = styled.div`
  p {
    margin-bottom: 0.5rem;
  }
  textarea {
    padding: 8px 16px;
    width: 100%;
    max-width: 800px;
    border: 1px solid var(--select-border-active);
    border-radius: 8px;
    background-color: var(--select-background);
    color: var(--select-text);
  }
`;

export const Actions = styled.div`
  display: flex;

  & > * {
    margin-right: 16px;
  }
`;

export const VerifiableCredentialsContainer = styled.div`
  padding: 24px;

  h2 {
    margin-bottom: 24px;
    font-weight: 600;
  }
`;

export const VerifiableCredentialCardContainer = styled.div`
  padding: 12px;
  border: 1px solid var(--primary-border);
  background-color: var(--primary-background);
  border-radius: 8px;

  header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--primary-border);
    margin-bottom: 16px;

    h3 {
      font-weight: 600;
      font-size: 18px;
    }
  }

  p {
    margin-bottom: 8px;
    font-weight: 600;

    span {
      font-weight: 400;
    }
  }

  section {
    margin: 16px 0;
  }

  .vcJwt {
    display: flex;
    align-items: center;
  }

  input {
    padding: 4px 8px;
    width: 100%;
    border: 1px solid var(--select-border-active);
    border-radius: 4px;
    background-color: var(--select-background);
    color: var(--select-text);

    // '...' at the end of the input
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  svg {
    margin-left: 8px;
    font-size: 20px;
    cursor: pointer;
  }

  h4 {
    padding-top: 16px;
    border-top: 1px solid var(--primary-border);

    margin-bottom: 8px;
    font-weight: 600;
    text-align: center;
  }
`;

export const VCStatusContainer = styled.div<{ status: string }>`
  padding: 4px 8px;

  color: var(--success-text);
  font-size: 14px;
  font-weight: 600;
  background-color: var(--success-background);
  border: 1px solid var(--success-border);
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-right: 8px;
  cursor: default;

  span {
    max-width: 0; // initially no width
    overflow: hidden; // prevents content from spilling out
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    transform: translateX(-20px);
    white-space: nowrap; // ensures the text doesn't wrap
  }

  &:hover span {
    max-width: 200px; // enough width to accommodate content
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    margin-left: 8px;
  }

  .ball {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    ${({ status }) => css`
      background-color: ${status === 'active'
        ? 'var(--success-icon)'
        : 'var(--danger-icon)'};
      border: 1px solid
        ${status === 'active' ? 'var(--success-text)' : 'var(--danger-text)'};
    `}
  }
`;

export const VCCardsContainer = styled.div`
  display: flex;
  gap: 16px;
`;
