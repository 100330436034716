export const dispatchCustomEvent = <T = any>(
  element: HTMLElement | Document,
  eventName: string,
  payload: T,
  options = {}
) => {
  element.dispatchEvent(
    new CustomEvent(eventName, { detail: payload, bubbles: true, ...options })
  );
};
