import React from 'react';
import CatawbaLogoLight from 'assets/images/catawba-logo-min.svg';
import CatawbaLogoDark from 'assets/images/catawba-logo-min-dark.svg';

import { Container } from './styles';

interface Props {
  theme: 'catawba-light' | 'catawba-dark';
}

const ThemeSelector: React.FC<Props> = ({ theme }: Props) => {
  const logoFromTheme = () => {
    const themes = {
      'catawba-light': CatawbaLogoLight,
      'catawba-dark': CatawbaLogoDark,
    };

    return themes[theme];
  };

  const handleChange = () => {
    // TODO: this function should call the backend and store the theme on database.
    // this is commented for now because the dark theme has a lot to fix.
    // document.documentElement.setAttribute('data-theme', theme);
  };

  return (
    <Container backgroundTheme={theme} onClick={handleChange}>
      <img src={logoFromTheme()} alt={`Catawba logo ${theme}`} />
      <p>{theme.slice(8)}</p>
    </Container>
  );
};

export default ThemeSelector;
