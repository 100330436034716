import { Notification } from 'shared/types';
import { takeLatest, all, call, put } from 'redux-saga/effects';

// eslint-disable-next-line
import api, { endpoints } from 'services/API';

import { notifyError, notifySuccess } from 'services/notify';
import { buildURL } from 'services/API/endpoints';
import {
  getNotificationsRequest,
  getNotificationsSuccess,
  archiveNotificationRequest,
  archiveNotificationSuccess,
  unarchiveNotificationRequest,
  unarchiveNotificationSuccess,
  deleteNotificationRequest,
  deleteNotificationSuccess,
  readNotificationRequest,
  readNotificationSuccess,
  unreadNotificationRequest,
  unreadNotificationSuccess,
} from './index';

export function* getNotifications() {
  try {
    const url = buildURL(endpoints.notifications, {
      query: { all: 'true' },
    });

    const { data, success } = yield call(api.get, url, {
      headers: {
        silent: true,
      },
    });

    if (!success) {
      return;
    }

    const notifications: Notification[] = data;

    yield put(
      getNotificationsSuccess({
        notifications,
      })
    );
  } catch (err) {
    notifyError(err.message);
  }
}

export function* archiveNotification({ payload: { notification } }) {
  try {
    const url = buildURL(endpoints.toggle_notification, {
      route: {
        notificationId: notification.id,
      },
    });

    const { success } = yield call(api.post, url, {
      ...notification,
      archived: true,
      unread: false,
    });

    if (!success) {
      return;
    }

    yield put(
      archiveNotificationSuccess({
        notificationId: notification.id,
      })
    );

    notifySuccess('The notification has been successfully archived');
  } catch (err) {
    notifyError(err.message);
  }
}

export function* unarchiveNotification({ payload: { notification } }) {
  try {
    const url = buildURL(endpoints.toggle_notification, {
      route: {
        notificationId: notification.id,
      },
    });

    const { success } = yield call(api.post, url, {
      ...notification,
      archived: false,
      unread: true,
    });

    if (!success) {
      return;
    }

    yield put(
      unarchiveNotificationSuccess({
        notificationId: notification.id,
      })
    );

    notifySuccess('The notification has been successfully unarchived');
  } catch (err) {
    notifyError(err.message);
  }
}

export function* deleteNotification({ payload: { notification } }) {
  try {
    const url = buildURL(endpoints.toggle_notification, {
      route: {
        notificationId: notification.id,
      },
    });

    const { success } = yield call(api.delete, url);

    if (!success) {
      return;
    }

    yield put(
      deleteNotificationSuccess({
        notificationId: notification.id,
      })
    );

    notifySuccess('The notification has been successfully deleted');
  } catch (err) {
    notifyError(err.message);
  }
}

export function* readNotification({ payload: { notification } }) {
  try {
    const url = buildURL(endpoints.toggle_notification, {
      route: {
        notificationId: notification.id,
      },
    });

    const { success } = yield call(api.post, url, {
      ...notification,
      unread: false,
    });

    if (!success) {
      return;
    }

    yield put(
      readNotificationSuccess({
        notificationId: notification.id,
      })
    );

    notifySuccess('The notification has been successfully read');
  } catch (err) {
    notifyError(err.message);
  }
}

export function* unreadNotification({ payload: { notification } }) {
  try {
    const url = buildURL(endpoints.toggle_notification, {
      route: {
        notificationId: notification.id,
      },
    });

    const { success } = yield call(api.post, url, {
      ...notification,
      unread: true,
    });

    if (!success) {
      return;
    }

    yield put(
      unreadNotificationSuccess({
        notificationId: notification.id,
      })
    );

    notifySuccess('The notification has been successfully unread');
  } catch (err) {
    notifyError(err.message);
  }
}

export default all([
  takeLatest(getNotificationsRequest as any, getNotifications),
  takeLatest(archiveNotificationRequest as any, archiveNotification),
  takeLatest(unarchiveNotificationRequest as any, unarchiveNotification),
  takeLatest(deleteNotificationRequest as any, deleteNotification),
  takeLatest(readNotificationRequest as any, readNotification),
  takeLatest(unreadNotificationRequest as any, unreadNotification),
]);
