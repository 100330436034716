import React, { useEffect } from 'react';
import { Button, Form, Input } from 'components/atoms/_common';
import CatawbaLogo from 'assets/images/catawba-logo.svg';

import { formatDate } from 'util/formatting';
import { sub } from 'date-fns';

import { Container, ModalContainer } from './styles';

interface Props {
  onClose: () => void;
  onSubmit: (values: any) => void;
}

const EffectiveDateModal: React.FC<Props> = ({ onClose, onSubmit }: Props) => {
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, []);

  const formattedToday = formatDate(new Date().toDateString(), 'yyyy-MM-dd');
  /* const maxDate = formatDate(new Date().toDateString(), 'yyyy-MM-dd');
  const minDate = formatDate(
    sub(new Date(), {
      days: 30,
    }).toISOString(),
    'yyyy-MM-dd'
  ); */

  return (
    <Container>
      <ModalContainer>
        <img src={CatawbaLogo} alt="Catawba" />
        <div className="text">
          <h2>Are you sure you want to do company dissolution?</h2>
          <p>
            Provide us with effective date and press &quot;Continue&quot; in
            order to start the process of company dissolution.
          </p>
        </div>
        <Form
          onSubmit={onSubmit}
          schema={{}}
          defaultValues={{ date: formattedToday }}
        >
          <Input
            label="Effective date"
            name="date"
            type="date"
            placeholder="Date"
            disabled
            /* max={maxDate}
            min={minDate} */
          />
          <div className="actions">
            <Button variant="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Continue</Button>
          </div>
        </Form>
      </ModalContainer>
    </Container>
  );
};

export default EffectiveDateModal;
