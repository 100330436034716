import styled, { css } from 'styled-components';
import { CSSContainerErrorStyle, CSSInputStyle } from '../Input/styles';

export const Container = styled.div`
  position: relative;
  &.error {
    ${CSSContainerErrorStyle}
  }
`;

interface SelectInputProps {
  isDropdownOpen: boolean;
}

export const SelectInput = styled.div<SelectInputProps>`
  ${CSSInputStyle}

  :after {
    content: '';
    position: absolute;
    top: 47px;
    right: 20px;
    width: 10px;
    height: 10px;
    border: solid var(--neutrals-text-weak-active);
    border-width: 3px 3px 0 0;
    border-radius: 2px;
    transform: rotate(135deg);
    transition: transform 0.2s ease-in-out, top 0.2s ease-in-out;

    ${props =>
      props.isDropdownOpen &&
      css`
        top: 53px;
        transform: rotate(-45deg);
      `}
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  line-height: 1.2em;
  color: var(--neutrals-text-weak-active);
`;

export const DropdownList = styled.ul`
  background-color: var(--neutrals-background-light);
  border: 1px solid var(--neutrals-border);
  margin-top: 8px;
  padding: 8px;
  border-radius: 8px;
`;

export const DropdownListItem = styled.li`
  background-color: var(--neutrals-background);
  border: 1px solid var(--neutrals-border);
  color: var(--neutrals-text-weak-passive);
  margin-bottom: 4px;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--neutrals-background-light);
    color: var(--neutrals-text);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:active {
    border: 1px solid var(--neutrals-border-active);
  }

  &.selected {
    border: 1px solid var(--neutrals-border-active);
    background-color: var(--neutrals-background);
    color: var(--neutrals-text);
    font-weight: 700;
  }
`;

interface ErrorSpanProps {
  visible: boolean;
}

export const ErrorSpan = styled.span<ErrorSpanProps>`
  opacity: ${props => (props.visible ? '1' : '0')};
  max-height: ${props => (props.visible ? '200px' : '0')};

  color: var(--danger-text);
  line-height: 1.2em;
  font-size: 12px;
  margin-top: 3px;
  transition: opacity 0.2s ease-in, max-height 0.2s ease-in-out;
`;
