import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Form } from 'components/atoms/_common';
import { DISSOLUTION_WINDUP_SCHEMA } from 'util/schemas';
import { useAppDispatch, useAppSelector } from 'hooks';
import { submitDissolutionRequest } from 'store/slices/company';
import { formatCapitalizeFirstLetter } from 'util/formatting';

import ConfirmationModal from './ConfirmationModal';
import { CheckboxContainer, Container, Content } from './styles';

interface Props {
  companyId: string;
  onCancel: () => void;
}

const Windup: React.FC<Props> = ({ companyId, onCancel }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const companies = useAppSelector(state => state.user.companies);
  const viewingCompany = useAppSelector(state => state.company.viewingCompany);

  const role = companies.find(
    company => company.id === viewingCompany?.id
  )?.role;

  const handleSubmit = (data: any) => {
    dispatch(
      submitDissolutionRequest({
        companyId,
        callback: () => setShowConfirmation(true),
        membership: formatCapitalizeFirstLetter(role) || 'Admin',
      })
    );
  };

  const handleCloseModal = () => {
    setShowConfirmation(false);
    navigate('/companies');
  };

  return (
    <>
      {showConfirmation && <ConfirmationModal onClose={handleCloseModal} />}
      <Container>
        <Content>
          <h2>Company dissolution windup list</h2>
          <p>
            In order to dissolve a company, you must certify that you have
            completed the following steps:
          </p>
          <Form onSubmit={handleSubmit} schema={DISSOLUTION_WINDUP_SCHEMA}>
            <div className="form-checkboxes">
              <CheckboxContainer>
                <Checkbox
                  label="I have closed all accounts associated with this company"
                  name="firstWindup"
                />
              </CheckboxContainer>
              <CheckboxContainer>
                <Checkbox
                  label="All outstanding invoices and debts have been paid."
                  name="secondWindup"
                />
              </CheckboxContainer>
              <CheckboxContainer>
                <Checkbox
                  label="All employees have been terminated and all compensation has been paid."
                  name="thirdWindup"
                />
              </CheckboxContainer>
            </div>
            <div className="actions">
              <Button variant="subtle" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit">Continue</Button>
            </div>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default Windup;
