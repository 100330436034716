import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div.attrs({ className: 'terms-of-service' })`
  strong,
  b {
    font-weight: 700;
  }

  p {
    margin-bottom: 1rem;
  }

  li {
    list-style-type: disc;
  }

  ul {
    padding-inline-start: 26px;
  }

  ${mediaQuery.sm} {
    p {
      padding: 0;
    }
  }
`;
