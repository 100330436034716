import styled from 'styled-components';

export const Container = styled.div``;
export const DropdownContainer = styled.div`
  h2 {
    font-size: 25px;
    line-height: 30px;
    color: var(--neutrals-text);
    font-weight: 500;
    margin-right: 50px;
    margin-bottom: 4px;
  }
`;
export const DefaultItemContainer = styled.div`
  max-height: 80vh;
  overflow: auto;

  max-width: 596px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 4px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: var(--neutrals-background-light);
  margin: 14px;
`;
