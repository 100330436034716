import { Button, Icon } from 'components/atoms/_common';
import React from 'react';

import { Container } from './styles';

interface Props {
  link: string;
  companyName: string;
}

const StripeCheckout: React.FC<Props> = ({ link, companyName }: Props) => {
  const handleClick = () => {
    if (link) {
      window.location.replace(link);
    }
  };

  return (
    <Container>
      <Icon name="payments" />
      <h2>Checkout to a new payment</h2>
      <h3>
        Your company application named <span>{companyName}</span> is under{' '}
        <br />
        the <span>&quot;pending payment&quot;</span> status.
      </h3>
      <p>
        You need to insert your payment and billing information through Stripe
        integration.
      </p>
      <Button onClick={handleClick}>Go to Checkout</Button>
    </Container>
  );
};

export default StripeCheckout;
