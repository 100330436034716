import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useKYCUpdateStepperContext } from 'context/KYCUpdateStepper';
import { Button } from 'components/atoms/_common';
import API from 'services/API';
import { notifyError } from 'services/notify';

import Base from '../_base';
import { Container } from './styles';

const TruliooWorkflowLink: React.FC = () => {
  const navigate = useNavigate();
  const { nextStep } = useKYCUpdateStepperContext();

  const handleClick = async () => {
    const link = process.env.REACT_APP_TRULIOO_WORKFLOW_LINK;

    if (link) {
      const callback = () => {
        navigate('/logout');
        window.open(link, '_blank');
      };

      API.post('/kyc/user').then(callback);
    } else {
      notifyError('Trulioo workflow link not found');
    }
  };

  return (
    <Base
      title="Trulioo's Workflow"
      subtitle="You need to click the button to start the workflow and Trulioo will guide you through the process."
      iconName="rebase"
      fullHeight
    >
      <Container>
        <h2>
          After you are redirected and complete Trulioo&apos;s Workflow, our
          team will review your application and get back you ASAP. <br />
          You will receive an email notifying you when your application has been
          reviewed.
        </h2>

        <h2>
          Soon you will be able to navigate through the application and <br />
          start revolutionizing the market! 🚀
        </h2>

        <Button className="go-to-workflow" onClick={handleClick}>
          Go to Workflow
        </Button>
      </Container>

      <button className="submit-kyc" type="button" onClick={nextStep} />
    </Base>
  );
};

export default TruliooWorkflowLink;
