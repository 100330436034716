import styled, { css } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  .formContainerCreate {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .selectBoxes {
    display: flex;
    justify-content: center;
    width: 100%;

    .radio:first-of-type {
      margin-right: 80px;
    }
  }

  .companyName {
    max-width: 720px;
    margin: 0 auto;
  }

  .enrollmentNumber {
    margin-top: 20px;
  }

  .messageInline {
    margin-top: 8px;
  }

  .input {
    margin-top: 20px;
  }

  ${mediaQuery.sm} {
    .selectBoxes {
      .radio:first-of-type {
        margin-right: 20px;
      }

      & > * {
        flex: 1;
      }
    }
  }
`;

export const TextInfo = styled.div`
  text-align: center;
  margin: 60px 0;

  h3 {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 120%;
    color: var(--primary-text);

    a {
      color: var(--select-text);
      text-decoration: underline;
    }
  }

  p {
    margin-top: 24px;
  }

  ${mediaQuery.sm} {
    margin: 30px;
  }
`;

interface CatawbaFieldContainerProps {
  visible: boolean;
}

export const CatawbaFieldContainer = styled.div<CatawbaFieldContainerProps>`
  margin-top: 5px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const Divider = styled.div`
  height: 1px;
  background-color: var(--neutrals-border);
  margin: 20px auto;
  max-width: 720px;
`;
