const customMediaQuery = (maxWidth: number) =>
  `@media (max-width: ${maxWidth}px)`;

export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const mediaQuery = {
  custom: customMediaQuery,
  sm: customMediaQuery(breakpoints.sm),
  md: customMediaQuery(breakpoints.md),
  lg: customMediaQuery(breakpoints.lg),
  xl: customMediaQuery(breakpoints.xl),
};

export default mediaQuery;
