import { format, parseISO } from 'date-fns';
import { notifySuccess } from 'services/notify';
import { MdContentCopy } from 'react-icons/md';
import { VerifiableCredentialCardContainer } from './styles';
import VCStatus from './VCStatus';

const VerifiableCredentialCard = ({ data }: { data: any }) => {
  const handleCopy = (jwt: string) => {
    if (jwt) {
      const el = document.createElement('textarea');
      el.value = jwt;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      notifySuccess('Verifiable Credential JWT copied to your clipboard!');
    }
  };

  return (
    <VerifiableCredentialCardContainer>
      <header>
        <VCStatus status={data.status} />
        <h3>{data.vcType}</h3>
      </header>

      <section>
        {data.createdAt && (
          <p>
            Created at: <span>{format(parseISO(data.createdAt), 'Pp')}</span>
          </p>
        )}

        {data.username && (
          <p>
            User: <span>{data.username}</span>
          </p>
        )}
      </section>

      <h4>Verifiable Credential JWT:</h4>
      <div className="vcJwt">
        <input type="text" name="id" value={data.vcJwt} />
        <MdContentCopy onClick={() => handleCopy(data.vcJwt)} />
      </div>
    </VerifiableCredentialCardContainer>
  );
};

export default VerifiableCredentialCard;
