import { Label } from 'components/atoms';
import { Input } from 'components/atoms/_common';
import { Row } from 'components/atoms/_util';
import { SpecializedSharesContainer } from '../styles';

const SpecializedShares = ({ visible }: { visible: boolean }) => {
  if (visible) {
    return (
      <SpecializedSharesContainer>
        <Row breakRowOnMobile>
          <Input
            type="number"
            name="total_preferred"
            label="Total amount of shares"
          />
          <Input name="preferred_name" label="Name of shares" />
          <Input
            name="preferred_description"
            label="Description of the shares"
          />
        </Row>
      </SpecializedSharesContainer>
    );
  }

  return null;
};

export default SpecializedShares;
