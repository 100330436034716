import React from 'react';
import { Form, Select } from 'components/atoms/_common';
import { useCreateCompanyStepper } from 'context/CreateCompanyStepper';

interface Props {
  setRegistrationAddress: (any) => void;
}

const RegisteredAgent: React.FC<Props> = ({
  setRegistrationAddress,
}: Props) => {
  const {
    registeredAgents,
    selectedRegisteredAgent,
    setSelectedRegisteredAgent,
  } = useCreateCompanyStepper();

  return (
    <Form
      onSubmit={() => {}}
      schema={{}}
      defaultValues={{
        registeredAgent:
          selectedRegisteredAgent || registeredAgents[0].username,
      }}
    >
      <Select
        name="registeredAgent"
        label="Registered Agent"
        hideDefaultOption
        options={[
          ...registeredAgents.map(
            agent =>
              ({
                id: agent.username as string,
                label: agent.full_name as string,
              } as any)
          ),
          { id: 'manual', label: 'Manual Entry' },
        ]}
        onSelect={registeredAgent => {
          if (registeredAgent === 'manual') {
            setRegistrationAddress(null);
            setSelectedRegisteredAgent('manual');
            return;
          }

          const address = registeredAgents.find(
            el => el.username === registeredAgent
          )?.address;
          setRegistrationAddress(address);
          setSelectedRegisteredAgent(registeredAgent);
        }}
      />
    </Form>
  );
};

export default RegisteredAgent;
