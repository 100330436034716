import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary-background);
  width: min-content;
  min-width: 60%;
  text-align: center;
  padding: 60px 80px;
  border-radius: 8px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    height: 150px;
    margin-bottom: 40px;
  }

  h1 {
    font-size: 25px;
    line-height: 30px;
    color: var(--neutrals-text);
    font-weight: 500;
    margin-bottom: 10px;
  }

  h2 {
    margin-bottom: 40px;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 600;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
  }

  @media (max-width: 668px) {
    padding: 40px;
    width: 100%;
    height: 100vh;
    justify-content: center;
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    border-radius: 0;
  }
`;
