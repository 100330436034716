import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  width: 168px;
  margin: 5px 5px 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .icon {
    font-size: 100px;
    color: var(--neutrals-background-light);
  }

  &:hover {
    .icon {
      color: var(--neutrals-border);
    }
  }
`;
