import styled from 'styled-components';

interface Props {
  open: boolean;
}

export const Container = styled.div<Props>`
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

export const Content = styled.div`
  position: relative;
  background: white;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.5) 4px 4px 8px;
  border-radius: 12px;
  z-index: 2;
`;

export const Background = styled.div`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border: none;
  font-size: 20px;
  color: var(--neutrals-text-weak-passive);
  z-index: 3;
  &:hover {
    color: var(--neutrals-text-weak-active);
  }
`;
