import React from 'react';
import { CreateCompanyProvider } from 'context/CreateCompanyStepper';

import Stepper from './Stepper';

const CreateCompany: React.FC = () => {
  return (
    <CreateCompanyProvider>
      <Stepper />
    </CreateCompanyProvider>
  );
};

export default CreateCompany;
