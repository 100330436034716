import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 80px;

  > h1.title {
    font-size: 31px;
    line-height: 37.2px;
    text-align: left;
  }

  .tab-content {
    max-width: 500px;

    h2 {
      margin-bottom: 20px;
    }

    .hint {
      display: flex;

      h3 {
        margin-bottom: 10px;
      }

      li {
        margin-bottom: 4px;
      }
    }

    .change2fa {
      margin: 16px 0;
    }

    .temporarySession {
      margin-bottom: 40px;
    }
  }

  ${mediaQuery.sm} {
    padding: 40px 20px;

    .tab-content {
      max-width: 100%;
    }
  }
`;

export const SecurityContainer = styled.div`
  button[type='submit'] {
    margin-top: 28px;
  }

  .password-hint {
    display: flex;
    margin: 8px 0;
  }

  ${mediaQuery.sm} {
    button {
      width: 100%;
    }

    button[type='submit'] {
      margin-top: 8px;
    }

    .hide-on-mobile {
      display: none;
    }

    .password-hint {
      flex-direction: column;

      & > div {
        margin-bottom: 8px;
      }
      h3 {
        margin-bottom: 10px;
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background: var(--neutrals-border);
`;
