import { MdMenu } from 'react-icons/md';
import { Container } from './styles';

interface Props {
  onClickMenu: () => void;
}

const Appbar = ({ onClickMenu }: Props) => {
  return (
    <Container className="appbar">
      <h1>CDEZ</h1>
      <MdMenu size="2rem" onClick={onClickMenu} />
    </Container>
  );
};

export default Appbar;
