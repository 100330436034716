import styled, { css } from 'styled-components';

// These styles is also being used in the Select component.
export const CSSInputStyle = css`
  padding: 20px;
  border: 1px solid;
  border-color: var(--neutrals-border);
  border-radius: 8px;
  color: var(--neutrals-text);

  &::placeholder {
    color: var(--neutrals-text-weak-passive);
  }

  &:hover:not(:disabled) {
    background-color: var(--neutrals-background-light);
  }

  &:focus {
    border-color: var(--neutrals-border-active);
  }

  &:disabled {
    &::placeholder {
      color: var(--neutrals-text-disabled);
    }
  }
`;

// These styles is also being used in the Select component.
export const CSSContainerErrorStyle = css`
  label {
    color: var(--danger-text);
  }
  input,
  .select-input {
    color: var(--danger-text);
    background: var(--danger-background);
    border-color: var(--danger-border);

    &::placeholder {
      color: var(--danger-text);
    }

    &:hover {
      color: var(--danger-icon);
      background: var(--danger-background-light);
    }
  }
  svg {
    color: var(--danger-text);
  }
`;

export const CSSContainerSuccessStyle = css`
  label {
    color: var(--success-text);
  }
  input {
    color: var(--success-text);
    background: var(--success-background-light);
    border-color: var(--success-border-active);

    &::placeholder {
      color: var(--success-text);
    }

    &:hover {
      color: var(--success-icon);
      background: var(--success-background);
    }
  }
  svg {
    color: var(--success-text);
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  input {
    ${CSSInputStyle}
  }

  &.error {
    ${CSSContainerErrorStyle}
  }

  &.success {
    ${CSSContainerSuccessStyle}
  }

  input:disabled {
    color: var(--neutrals-text-disabled);
    background: var(--neutrals-background-light);
    border-color: var(--neutrals-border);

    :hover {
      color: var(--neutrals-text-disabled);
      background: var(--neutrals-background-light);
      border-color: var(--neutrals-border);
    }
  }

  .hint {
    color: var(--neutrals-text-weak-active);
    font-size: 12px;
    margin-top: 3px;
  }
`;

interface ErrorSpanProps {
  visible: boolean;
}

export const ErrorSpan = styled.span<ErrorSpanProps>`
  opacity: ${props => (props.visible ? '1' : '0')};
  max-height: ${props => (props.visible ? '200px' : '0')};

  color: var(--danger-text);
  line-height: 1.2em;
  font-size: 12px;
  margin-top: 3px;
  transition: opacity 0.2s ease-in, max-height 0.2s ease-in-out;
`;

export const Label = styled.label`
  color: var(--neutrals-text-weak-active);
  margin-bottom: 8px;
`;
