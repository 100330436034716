import styled from 'styled-components';

export const Container = styled.div`
  .submit-register {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  #already-login-mobile {
    padding: 16px;
    width: 100%;
    text-align: center;
    display: block;
    background-color: var(--primary-background);
    border-bottom: 1px solid var(--primary-border);
    text-decoration: underline;
    color: var(--primary-text);
  }
`;
