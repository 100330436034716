/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { REGISTER_SCHEMA } from 'util/schemas';
import { Accordion, Button, Checkbox, Form } from 'components/atoms/_common';
import { useRegisterStepper } from 'context/RegisterStepper';

import { useAppDispatch } from 'hooks';
import { registerRequest } from 'store/slices/auth';
import { Container, Divider } from './styles';
import Base from '../_base';
import TermsOfService from './TermsOfService';

const ReviewAgreement: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { name, address, usernamePassword, twoFactorAuth, country } =
    useRegisterStepper();

  const handleSubmit = () => {
    const callback = () => navigate('/register/success');

    const data = {
      name,
      address,
      usernamePassword,
      twoFactorAuth,
      callback,
      country,
    };

    dispatch(registerRequest(data));
  };

  return (
    <Base
      iconName="task"
      title="Review &#38; Agreement"
      subtitle="Please make sure everything is correct and agree to Catawba Digital Economic Zone's Terms of Service."
    >
      <Container>
        <Form onSubmit={handleSubmit} schema={REGISTER_SCHEMA.reviewAgreement}>
          <h2 className="catawba-title">Catawba</h2>
          <p className="conditions">
            By submitting this application using the Website, you consent to and
            agree to be bound by the Catawba Digital Economic Zone Terms of
            Service. If we decide to change these Terms, your use of the Website
            and/or the Online Service following any amendment of these Terms
            will signify your consent to and acceptance of its revised terms.
            Please Review the terms by accessing the link below. Once you have
            finished reviewing the terms please acknowledge using the checkbox
            below.
          </p>
          <Divider />

          {/* // FIXME: solve the issue of losting the state when clicking in the link, iPhone;
            <a href="https://catawbadigital.zone/terms-of-service/" target="_blank">
              Terms of Service
            </a> 
          */}

          <Accordion title="Terms of Service">
            <TermsOfService />
          </Accordion>

          <Checkbox
            name="agreement"
            label="I have read and I agree to Catawba Digital Economic Zone's Terms of Service"
          />
          <button type="submit" className="submit-register" />
        </Form>
      </Container>
    </Base>
  );
};

export default ReviewAgreement;
