import { useAppSelector } from 'hooks';
import React from 'react';
import { Navigate } from 'react-router-dom';

import PrivateLayout from '../pages/_layouts/private';

interface Props {
  children: React.ReactNode;
  blanked?: boolean;
}

const PrivateRoute: React.FC<Props> = ({ children, blanked }: Props) => {
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);
  const user = useAppSelector(state => state.user.user);

  if (!isAuthenticated) {
    const { pathname, search } = window.location;
    const encodedPathname = encodeURIComponent(pathname + search);
    return <Navigate to={`/login?redirectTo=${encodedPathname}`} />;
  }

  if (!user?.email_verification) {
    return <Navigate to="/register/email-confirmation" />;
  }

  if (
    user?.kyc_verification === 'unverified' ||
    user?.kyc_verification === 'pending'
  ) {
    return <Navigate to="/kyc/status" />;
  }

  return <PrivateLayout blanked={blanked}>{children}</PrivateLayout>;
};

PrivateRoute.defaultProps = {
  blanked: false,
};

export default PrivateRoute;
