import React from 'react';
import { useAppSelector } from 'hooks';

const roleWeights = {
  admin: 3,
  director: 2,
  member: 1,
};

const isRoleInScope = (role, scope) => {
  return roleWeights[role] >= roleWeights[scope] || false;
};

interface Props {
  scope: 'admin' | 'director' | 'member';
  children: React.ReactElement;
}

const ScopedSection: React.FC<Props> = ({ scope, children }: Props) => {
  const companies = useAppSelector(state => state.user.companies);
  const viewingCompany = useAppSelector(state => state.company.viewingCompany);

  const role = companies.find(
    company => company.id === viewingCompany?.id
  )?.role;

  if (isRoleInScope(role, scope)) {
    return children;
  }

  return null;
};

export default ScopedSection;
