import React from 'react';
import { useKYCUpdateStepperContext } from 'context/KYCUpdateStepper';

import { TextContainer } from './styles';
import Base from '../_base';

const TruliooNoticeMessage: React.FC = () => {
  const { nextStep } = useKYCUpdateStepperContext();

  return (
    <Base
      title="Identity Check Agreement"
      subtitle="You need to acknowledge Trulioo's policies to continue."
      iconName="assured_workload"
      fullHeight
    >
      <TextContainer>
        <h2>Trulioo&apos;s Policies:</h2>
        <p>
          We use Trulioo to process your identity check. By clicking “Continue”,
          you acknowledge Trulioo&apos;s collection and use of your personal
          information (such as your selfie image and/or image of your identity
          document) in accordance with the Trulioo{' '}
          <a
            href="https://www.trulioo.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{' '}
          and, where you are located in the United States, you also indicate
          that you understand and consent to the Trulioo{' '}
          <a
            href="https://www.trulioo.com/biometric-information-policy"
            target="_blank"
            rel="noreferrer"
          >
            Facial Scan and Biometric Information Policy
          </a>
          .
        </p>

        <button className="submit-kyc" type="button" onClick={nextStep} />
      </TextContainer>
    </Base>
  );
};

export default TruliooNoticeMessage;
