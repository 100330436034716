import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span.hint {
    text-align: center;
    display: block;
    color: var(--neutrals-text);
    line-height: 1.2em;
    margin-bottom: 32px;
  }

  .valuesContainer {
    display: flex;
  }
`;

export const ImageBackground = styled.div`
  min-width: 232px;
  min-height: 232px;
  background-color: var(--select-background-light);
  padding: 16px;
  border-radius: 8px;
  width: min-content;
  margin-bottom: 10px;
`;

export const Divider = styled.div`
  margin: 16px auto;
  height: 1px;
  background-color: var(--neutrals-border);
`;
