import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  margin-bottom: 12px;
  background-color: var(--neutrals-background-light);

  .label {
    margin-bottom: 32px;
  }
`;
