import styled from 'styled-components';

export const Container = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid var(--primary-icon);
  border-radius: 50%;

  color: var(--primary-icon);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .icon {
    font-size: 16px;
  }
`;
