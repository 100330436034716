import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .center {
    text-align: center;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 14px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 14px;
  }

  h3 {
    margin-bottom: 14px;
  }

  strong {
    font-weight: 600;
  }

  .accept {
    background-color: var(--success-background);
    color: var(--success-text);
    border: 1px solid var(--success-border);
    margin-right: 20px;
  }

  .reject {
    background-color: var(--danger-background);
    color: var(--danger-text);
    border: 1px solid var(--danger-border);
  }
`;
