import styled from 'styled-components';

const USStatesItem = styled.div`
  cursor: pointer;

  width: 76px;
  height: 66px;
  border-radius: 4px;
  padding: 8px;
  margin: 4px;

  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: var(--neutrals-background);
  border: 1px solid var(--neutrals-border);

  &:hover {
    background: var(--neutrals-background-hover);
    border: 1px solid var(--primary-border-active);
    & span.state-id {
      color: var(--primary-text);
    }
  }
  span.state-id {
    font-size: 20px;
  }

  span.state-name {
    font-size: 12px;
    color: var(--neutrals-text-weak-active);
  }
`;

export default USStatesItem;
