import styled from 'styled-components';

const properties = {
  bg: { 'catawba-light': '#F3F4F5', 'catawba-dark': '#2C2C2C' },
  border: { 'catawba-light': '#949AA1', 'catawba-dark': '#393939' },
  font: { 'catawba-light': '#323232', 'catawba-dark': '#F2F2F2' },
};

export const Container = styled.div<{
  backgroundTheme: 'catawba-light' | 'catawba-dark';
}>`
  background-color: ${({ backgroundTheme }) => properties.bg[backgroundTheme]};
  border-color: ${({ backgroundTheme }) => properties.border[backgroundTheme]};
  color: ${({ backgroundTheme }) => properties.font[backgroundTheme]};

  width: 150px;
  height: 150px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;

  img {
    width: 50px;
  }

  p {
    text-transform: capitalize;
  }
`;
