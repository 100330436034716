import React from 'react';
import { TwoFactorUpdateStepperProvider } from 'context/TwoFactorUpdateStepper';
import Stepper from './Stepper';

const TwoFactorAuthentication: React.FC = () => {
  return (
    <TwoFactorUpdateStepperProvider>
      <Stepper />
    </TwoFactorUpdateStepperProvider>
  );
};

export default TwoFactorAuthentication;
