import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;

  .icon {
    font-size: 48px;
    color: var(--success-icon);
    margin-bottom: 12px;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: var(--primary-text);
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 24px;
    span {
      font-weight: 600;
      color: var(--primary-text);
    }
  }

  p {
    margin-bottom: 24px;
  }
`;
