import React from 'react';
import { Icon } from '../_common';

import { Container } from './styles';

interface Props {
  text: string;
  icon?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const MenuItem: React.FC<Props> = ({
  icon,
  text,
  onClick,
  disabled,
}: Props) => {
  const handleClick = () => {
    if (onClick) {
      if (disabled) {
        return;
      }
      onClick();
    }
  };

  return (
    <Container className="menu-item" onClick={handleClick} disabled={disabled}>
      {icon && <Icon name={icon} />}
      <span>{text}</span>
    </Container>
  );
};

MenuItem.defaultProps = {
  icon: '',
  onClick: () => {},
  disabled: false,
};

export default MenuItem;
