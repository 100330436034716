import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  align-items: center;

  ${mediaQuery.sm} {
    padding: 30px 20px;
  }
`;

export const IconContainer = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 50%;

  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 3px solid var(--primary-icon);
  margin-right: 20px;

  .icon {
    font-size: 48px;
    color: var(--primary-icon);
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  h2 {
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    color: var(--neutrals-text);
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    color: var(--neutrals-text-weak-active);
  }
`;

export const ActionsContainer = styled.div`
  position: relative;

  & > .icon {
    color: var(--neutrals-icon-active);
    cursor: pointer;
  }
  user-select: none;
`;

export const Actions = styled.div`
  position: absolute;
  right: 10px;

  white-space: nowrap;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px;
  background-color: var(--neutrals-background);
`;

export const Action = styled.div<{ color: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;

  margin-bottom: 4px;
  border-bottom: 1px solid var(--neutrals-border);

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  .icon {
    color: ${({ color }) => color || 'var(--neutrals-icon-active)'};
  }

  p {
    margin-left: 8px;
    color: ${({ color }) => color || 'var(--neutrals-text)'};
    font-size: 16px;
    line-height: 1.2em;
  }
`;
