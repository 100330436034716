import React, { useState } from 'react';

import { Controller } from 'react-hook-form';
import cn from 'classnames';
import { Container, Label } from './styles';
import { useFormContext } from '../Form';
import { ErrorSpan } from '../Input/styles';

export interface InputProps {
  name: string;
  label?: string;
  optional?: boolean;
  disabled?: boolean;
  className?: string;
  [rest: string]: any;
}

const FileInput: React.FC<InputProps> = ({
  name,
  label,
  optional,
  disabled,
  className,
  ...rest
}: InputProps) => {
  const [file, setFile] = useState(null);
  const { control, errors } = useFormContext();

  const hasError = !!errors[name];

  return (
    <Container className={`input ${className}`}>
      {label && <Label htmlFor={name}>{optional ? label : `${label} *`}</Label>}
      <label htmlFor={name} className={cn('fileInput', { error: hasError })}>
        {file ? file.name : 'Select a file...'}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <input
              id={name}
              type="file"
              disabled={disabled}
              onChange={e => {
                setFile(e.target.files[0]);
                field.onChange(e.target.files[0]);
              }}
              {...rest}
            />
          )}
        />
      </label>
      <ErrorSpan visible={hasError}>
        {errors[name] && errors[name].message} *
      </ErrorSpan>
    </Container>
  );
};

FileInput.defaultProps = {
  label: '',
  disabled: false,
  optional: false,
  className: '',
};

export default FileInput;
