import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  padding: 48px 20px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  background: var(--neutrals-background);
  z-index: 2;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 28px;
    margin: 20px 0;
  }

  ${mediaQuery.sm} {
    height: calc(100vh - 3.5rem);
  }

  & > svg {
    position: absolute;
    top: 14px;
    right: 20px;
    cursor: pointer;
  }

  .content {
    border: 1px solid var(--neutrals-border);
    border-radius: 8px;
    flex: 1;
    overflow-y: auto;
    padding: 10px;

    pre,
    code {
      font-family: Menlo, Monaco, 'Courier New', monospace;
    }

    pre {
      padding: 0.5rem;
      line-height: 1.25;
      overflow-x: scroll;
    }

    a,
    a:visited {
      color: #3498db;
    }

    a:hover,
    a:focus,
    a:active {
      color: #2980b9;
    }

    .modest-no-decoration {
      text-decoration: none;
    }

    html {
      font-size: 12px;
    }

    @media screen and (min-width: 32rem) and (max-width: 48rem) {
      html {
        font-size: 15px;
      }
    }

    @media screen and (min-width: 48rem) {
      html {
        font-size: 16px;
      }
    }

    body {
      line-height: 1.85;
    }

    p,
    .modest-p {
      font-size: 1rem;
      margin-bottom: 1.3rem;
    }

    h1,
    .modest-h1,
    h2,
    .modest-h2,
    h3,
    .modest-h3,
    h4,
    .modest-h4 {
      margin: 1.414rem 0 0.5rem;
      font-weight: inherit;
      line-height: 1.42;
    }

    h1,
    .modest-h1 {
      margin-top: 0;
      font-size: 1.6rem;
    }

    h2,
    .modest-h2 {
      font-size: 1.4rem;
    }

    h3,
    .modest-h3 {
      font-size: 1.2rem;
    }

    h4,
    .modest-h4 {
      font-size: 1.2rem;
    }

    h5,
    .modest-h5 {
      font-size: 1rem;
    }

    h6,
    .modest-h6 {
      font-size: 0.88rem;
    }

    small,
    .modest-small {
      font-size: 0.65em;
    }

    /* https://github.com/mrmrs/fluidity */

    img,
    canvas,
    iframe,
    video,
    svg,
    select,
    textarea {
      max-width: 100%;
    }

    @import url(http://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700);

    @import url(http://fonts.googleapis.com/css?family=Arimo:700,700italic);

    body {
      color: #444;
      font-family: 'Open Sans Condensed', sans-serif;
      font-weight: 300;
      margin: 0 auto;
      max-width: 48rem;
      line-height: 1.45;
      padding: 0.25rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: Arimo, Helvetica, sans-serif;
    }

    h1,
    h2,
    h3 {
      border-bottom: 2px solid #fafafa;
      margin-bottom: 1.15rem;
      padding-bottom: 0.5rem;
      text-align: center;
    }

    blockquote {
      border-left: 8px solid #fafafa;
      padding: 1rem;
    }

    pre,
    code {
      background-color: #fafafa;
    }
  }

  p.date {
    font-size: 1.1rem;
    font-weight: 600;

    span {
      font-weight: 400;
      font-size: 1rem;
    }
  }
`;
