import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

interface HeaderContainerProps {
  spaced: boolean;
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--neutrals-border);

  ${({ spaced }) =>
    spaced &&
    css`
      justify-content: space-between;
    `}
`;

export const ContentContainer = styled.div``;
