import React from 'react';

import { Container, Step } from './styles';

interface Props {
  currentStep: number;
  position: number;
  title: string;
  text: string;
}

const Description: React.FC<Props> = ({
  currentStep,
  position,
  title,
  text,
}: Props) => {
  return (
    <Container>
      <Step isActive={position === currentStep} isDone={position < currentStep}>
        <span>{position}</span>
      </Step>
      <div className="titleTextContainer">
        <span className="title">{title}</span>
        <span className="text">{text}</span>
      </div>
    </Container>
  );
};

export default Description;
