import styled from 'styled-components';

export const Container = styled.div`
  width: 80vw;
  padding: 20px;

  .row-form {
    display: flex;

    .member {
      flex: 0.5;
    }

    .percentage,
    button {
      flex: 0.25;
    }

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    button {
      height: 61.2px;
      margin-top: 27px;
      margin-bottom: auto;
    }
  }

  table {
    margin: 20px 0;
  }
`;
