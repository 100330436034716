/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Label, TextValue } from 'components/atoms';
import { format } from 'date-fns';
import { useAppSelector } from 'hooks';

import { Container } from './styles';

const Details: React.FC = ({ children }) => {
  const viewingCompany = useAppSelector(state => state.company.viewingCompany);

  const getAddress = (address: any) => {
    const { house_number, street_name, zip, country, city, state } = address;
    return `${house_number} ${street_name}, ${city}, ${state} ${zip} ${
      country || ''
    }`;
  };

  const getStatus = () => {
    const { is_active, is_verified } = viewingCompany;
    if (is_active) {
      return 'Active';
    }
    if (is_verified) {
      return 'Verified';
    }
    return 'Inactive';
  };

  const getFormattedDate = (date: string) => {
    return format(new Date(date), 'MM/dd/yyyy');
  };

  return (
    <Container>
      <Label text="Details" iconName="corporate_fare" />
      <TextValue
        text="Registration Type"
        value="Catawba Digital Economic Zone"
      />
      <TextValue
        text="Company Type"
        value={
          viewingCompany?.public_identifier
            ? 'Decentralized Autonomous Organization LLC'
            : viewingCompany?.type || 'Limited Liability Company'
        }
      />
      <TextValue text="Legal Name" value={viewingCompany.company_full_name} />
      <TextValue
        text="Date Formed"
        value={getFormattedDate(viewingCompany.date_formed)}
      />
      <TextValue text="Phone Number" value={viewingCompany.phone} />
      <TextValue
        text="Registered Address"
        value={getAddress(viewingCompany.registration_address)}
      />
      <TextValue
        text="Mailing Address"
        value={getAddress(viewingCompany.mailing_address)}
      />
      <TextValue text="Email" value={viewingCompany.primary_email} />
      <TextValue text="Status" value={getStatus()} />

      {children}
    </Container>
  );
};

export default Details;
