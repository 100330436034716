import styled, { css } from 'styled-components';

export const Container = styled.div<{ disabled?: boolean }>`
  &.errored {
    span.labelText {
      color: var(--danger-text);
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

export const Label = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  span.labelText {
    padding-left: 5px;
  }

  input {
    height: 0;
    width: 0;
    opacity: 0;

    &:checked {
      & ~ .checkmark {
        background-color: var(--neutrals-background-light);
        .icon {
          display: block;
        }
      }
    }
  }

  .checkmark {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1px solid var(--neutrals-border-active);
    background-color: var(--neutrals-background);
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      display: none;
      font-size: 20px;
    }
  }

  &:hover input ~ .checkmark {
    background-color: var(--neutrals-background-light);
  }

  &.errored {
    .checkmark,
    input:checked ~ .checkmark {
      border-color: var(--danger-border);
      background-color: var(--danger-background);

      .icon {
        color: var(--danger-text);
      }
    }
  }
`;

interface ErrorSpanProps {
  visible: boolean;
}

export const ErrorSpan = styled.span<ErrorSpanProps>`
  opacity: ${props => (props.visible ? '1' : '0')};
  max-height: ${props => (props.visible ? '200px' : '0')};

  color: var(--danger-text);
  line-height: 1.2em;
  font-size: 12px;
  margin-top: 3px;
  transition: opacity 0.2s ease-in, max-height 0.2s ease-in-out;
`;
