export interface StatusActionEvent {
  kind: string;
}

export interface TypeInfo {
  title: string;
  subtitle?: string;
  hint?: string;
  actionHint?: () => any;
  redirect?: {
    path: string;
    label: string;
    icon: string;
  };
}

export interface InfoKind {
  [key: string]: {
    pageTitle: string;
    success?: TypeInfo;
    failure?: TypeInfo;
  };
}

export enum StatusEvents {
  Action = 'StatusActionEvent',
}
