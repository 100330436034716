import styled from 'styled-components';

interface Props {
  isOpen: boolean;
}

export const Container = styled.div<Props>`
  position: relative;
  width: ${props => (props.isOpen ? '232.39px' : '80px')};
  height: 48px;
  margin: 13.5px 0;
  padding: 8px;
  border-radius: 8px;

  .label {
    font-weight: bold;
  }

  .email-support {
    font-size: 12px;
    font-weight: 400;
    color: var(--secondary-text);
  }
`;
