import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -20px;
  h2.catawba-title {
    font-size: 31px;
    text-transform: uppercase;
    line-height: 1.2em;
    font-family: 'Oswald', sans-serif;
    margin-bottom: 40px;
  }
  p {
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.2em;
    color: var(--neutrals-text);
  }
  .accordion-container {
    margin-bottom: 20px;
  }
  padding-bottom: 20px;
`;

export const Divider = styled.div`
  margin: 20px 0;
  height: 1px;
  background-color: var(--neutrals-border);
`;
