import { useEffect } from 'react';

const useEventListener = (
  element: HTMLElement | Document,
  eventName: string,
  callback: (event: Event | CustomEvent) => any
) => {
  useEffect(() => {
    element.addEventListener(eventName, callback);

    return () => document.removeEventListener(eventName, callback);
  }, []);
};

export default useEventListener;
