import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  input:disabled {
    color: var(--neutrals-text-disabled);
    background: var(--neutrals-background-light);
    border-color: var(--neutrals-border);

    :hover {
      color: var(--neutrals-text-disabled);
      background: var(--neutrals-background-light);
      border-color: var(--neutrals-border);
    }
  }

  .fileInput {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 60px;
    border: 2px dashed var(--neutrals-border);
    background: var(--neutrals-background-light);
    border-radius: 4px;

    &:hover {
      border: 2px dashed var(--neutrals-border-active);
      background: var(--neutrals-background);
    }

    input {
      display: none;
    }

    &.error {
      color: var(--danger-text);
      background: var(--danger-background);
      border-color: var(--danger-border);

      &::placeholder {
        color: var(--danger-text);
      }

      &:hover {
        color: var(--danger-icon);
        background: var(--danger-background-light);
      }
    }
  }
`;

export const Label = styled.label`
  color: var(--neutrals-text-weak-active);
  margin-bottom: 8px;
`;
