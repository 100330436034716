import React from 'react';
import { useRegisterStepper } from 'context/RegisterStepper';

import CatawbaLogo from 'assets/images/catawba-logo-2.svg';

import { StepperFooter } from 'components/molecules';
import { Container, LinkLogin } from './styles';
import StepperDescription from './StepperDescription';

const Stepper: React.FC = ({ children }) => {
  const { currentStep, previousStep, country } = useRegisterStepper();

  const handleNext = () => {
    document.querySelector<HTMLButtonElement>('button.submit-register').click();
  };

  const buttonText = () => {
    const texts = ['Continue', 'Continue', 'Verify', 'Finish'];
    return texts[currentStep - 1];
  };

  return (
    <Container>
      <aside>
        <img src={CatawbaLogo} alt="Catawba logo" />
        <div className="divider" />
        <StepperDescription currentStep={currentStep} />
        <LinkLogin to="/login">
          You have an account? <span>Login</span>
        </LinkLogin>
      </aside>

      <section>
        {React.Children.map(children, (child, idx) => {
          if (idx === currentStep - 1) {
            return child;
          }
          return null;
        })}
      </section>

      <StepperFooter
        currentStep={currentStep}
        nextButtonText={buttonText}
        previousStepHandler={previousStep}
        nextStepHandler={handleNext}
        totalSteps={4}
        isNextDisabled={currentStep === 1 && country === ''}
      />
    </Container>
  );
};

export default Stepper;
