/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Label, TextValue } from 'components/atoms';
import { useAppSelector } from 'hooks';

import { Container } from './styles';

const Shares: React.FC = () => {
  const viewingCompany = useAppSelector(state => state.company.viewingCompany);

  if (viewingCompany.type !== 'C Corp') {
    return null;
  }

  return (
    <Container>
      <Label text="Shares" iconName="cases" />
      <TextValue
        text="Total Common Shares:"
        value={viewingCompany.total_common}
      />
      <TextValue
        text="Total Specialized Shares:"
        value={viewingCompany.total_preferred}
      />
      <TextValue
        text="Specialized Shares Name:"
        value={viewingCompany.preferred_name}
      />
      <TextValue
        text="Specialized Shares Description"
        value={viewingCompany.preferred_description}
      />
    </Container>
  );
};

export default Shares;
