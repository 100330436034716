export const ALLOWED_COUNTRIES = [
  'US',
  'AT',
  'BH',
  'BE',
  'CZ',
  'DK',
  'FR',
  'EG',
  'DE',
  'FI',
  'GE',
  'GH',
  'GR',
  'LU',
  'IS',
  'JO',
  'MT',
  'NG',
  'NL',
  'QA',
  'NO',
  'KW',
  'SA',
  'OM',
  'IT',
  'PL',
  'IE',
  'IL',
  'KE',
  'LB',
  'PT',
  'RO',
  'SK',
  'CH',
  'SE',
  'ES',
  'AE',
  'GB',
  'TR',
  'UA',
  'ZA',
  'MX',
  'CA',
  'SV',
  'AR',
  'CL',
  'CO',
  'CR',
  'EC',
  'BR',
  'PE',
  'VE',
  'UY',
  'GT',
];
