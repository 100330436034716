import styled from 'styled-components';

export const Container = styled.div`
  * {
    cursor: not-allowed !important;
    color: var(--neutrals-text-disabled);
  }
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: show;

  &:hover .message {
    visibility: visible;
  }

  .message {
    color: var(--neutrals-text);
    visibility: hidden;
    font-size: 12px;
    position: absolute;
    bottom: -20px;
  }
`;
