import React from 'react';
import { useKYCUpdateStepperContext } from 'context/KYCUpdateStepper';

import CatawbaLogo from 'assets/images/catawba-logo-2.svg';

import { StepperFooter } from 'components/molecules';
import { Container, LinkLogin } from './styles';
import StepperDescription from './StepperDescription';

const Stepper: React.FC = ({ children }) => {
  const { currentStep, previousStep } = useKYCUpdateStepperContext();

  const handleNext = () => {
    document.querySelector<HTMLButtonElement>('button.submit-kyc').click();
  };

  const buttonText = () => {
    const texts = ['Continue', 'Accept', 'Submit'];
    return texts[currentStep - 1];
  };

  return (
    <Container>
      <aside>
        <img src={CatawbaLogo} alt="Catawba logo" />
        <div className="divider" />
        <StepperDescription currentStep={currentStep} />
        <LinkLogin to="/logout">
          You have an account? <span>Login</span>
        </LinkLogin>
      </aside>

      <section>
        {React.Children.map(children, (child, idx) => {
          if (idx === currentStep - 1) {
            return child;
          }
          return null;
        })}
      </section>

      <StepperFooter
        currentStep={currentStep}
        nextButtonText={buttonText}
        previousStepHandler={previousStep}
        nextStepHandler={handleNext}
        isBackButtonDisabledOnStep={[true, false, false, true]}
        isNextDisabled={currentStep === 2}
        totalSteps={2}
      />
    </Container>
  );
};

export default Stepper;
