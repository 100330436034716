import { Dispatch, SetStateAction } from 'react';

export interface UsernamePassword {
  username: string;
  password: string;
  email: string;
}

export const UsernamePasswordIS: UsernamePassword = {
  username: '',
  password: '',
  email: '',
};

export interface TwoFactorAuth {
  code: string;
  secret: string;
}

export interface Name {
  firstName: string;
  middleName?: string;
  lastName: string;
}

export interface ContextState {
  currentStep: number;
  name: Name;
  address: any;
  country: string;
  usernamePassword: UsernamePassword;
  twoFactorAuth: TwoFactorAuth;

  nextStep: () => void;
  previousStep: () => void;
  setName: Dispatch<SetStateAction<Name>>;
  setAddress: Dispatch<SetStateAction<any>>;
  setCountry: Dispatch<SetStateAction<string>>;
  setUsernamePassword: Dispatch<SetStateAction<UsernamePassword>>;
  setTwoFactorAuth: Dispatch<SetStateAction<TwoFactorAuth>>;
}
