/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestMap } from 'util/mapping/mapping';

export const mappingSlice = createSlice({
  name: 'mapping',
  initialState: null,
  reducers: {
    triggerTestRequest: (
      _,
      action: PayloadAction<{
        handler: RequestMap;
        callback: (error: any, data: any) => void;
      }>
    ) => {},
  },
});

export const { triggerTestRequest } = mappingSlice.actions;

export default mappingSlice.reducer;
