import React from 'react';
import { Icon } from 'components/atoms/_common';

import { Container } from './styles';

interface Props {
  active?: boolean;
  icon?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const ActionItem: React.FC<Props> = ({
  active,
  icon,
  onClick,
  disabled,
}: Props) => {
  const handleClick = () => {
    if (onClick) {
      if (disabled) {
        return;
      }
      onClick();
    }
  };

  return (
    <Container
      className="action-item"
      onClick={handleClick}
      disabled={disabled}
    >
      {icon && <Icon fill={active ? 1 : 0} name={icon} />}
    </Container>
  );
};

ActionItem.defaultProps = {
  active: false,
  icon: '',
  onClick: () => {},
  disabled: false,
};

export default ActionItem;
