/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { Label } from 'components/atoms';
import { Form, Input, DropdownInput, Checkbox } from 'components/atoms/_common';
import { Row } from 'components/atoms/_util';
import { CompanyMailingAddress } from 'context/CreateCompanyStepper/types';
import { CREATE_COMPANY_SCHEMA } from 'util/schemas';

import USStates from 'components/atoms/_common/DropdownInput/renderers/USStatesItem/us-states-data.mock';

import USStatesItem from 'components/atoms/_common/DropdownInput/renderers/USStatesItem/styles';
import { useCreateCompanyStepper } from 'context/CreateCompanyStepper';

interface Props {
  defaultValues: CompanyMailingAddress;
  setMailingAddress: (address: CompanyMailingAddress) => void;
}

const MailingAddressForm = React.forwardRef(
  (
    { defaultValues, setMailingAddress }: Props,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const { puertoRico, changePuertoRico } = useCreateCompanyStepper();
    const handleSubmitAddress = mailing_address => {
      setMailingAddress(mailing_address);
    };

    return (
      <Form
        ref={ref}
        onSubmit={handleSubmitAddress}
        schema={CREATE_COMPANY_SCHEMA.information.address('Mailing')}
        defaultValues={defaultValues}
      >
        <Label text="Mailing address" iconName="pin_drop" />
        <Checkbox
          name="isMailingInPuertoRico"
          label="My mailing address is in Puerto Rico"
          checked={puertoRico.mailing}
          onChange={e => changePuertoRico('mailing', e.target.checked)}
        />
        <Row breakRowOnMobile>
          <Input name="house_number" label="House/Building number" />
          <Input name="street_name" label="Street name" />
          <Input name="street_type" label="Street type" />
          <Input name="unit" label="Unit/Apartment number" optional />
        </Row>
        <Row breakRowOnMobile>
          <Input name="city" label="City" />
          {!puertoRico.mailing && (
            <DropdownInput
              name="state"
              label="State"
              hint="Select your state"
              items={USStates}
              itemRenderer={({ item, onItemClick }) => (
                <USStatesItem onClick={() => onItemClick(item.id)}>
                  <span className="state-id">{item.id}</span>
                  <span className="state-name">{item.name}</span>
                </USStatesItem>
              )}
            />
          )}
          <Input name="zip" label="Zip code" maxLength={5} />
        </Row>
        <button type="submit" className="hidden formSubmitAddress" />
      </Form>
    );
  }
);

export default MailingAddressForm;
