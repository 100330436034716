import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  max-width: 600px;
  margin: 0 auto;

  text-align: center;

  h2 {
    line-height: 1.5;

    &:first-child {
      margin-bottom: 20px;
    }
  }

  .go-to-workflow {
    margin-top: 40px;
  }
`;
