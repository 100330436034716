import PublicLayout from '../pages/_layouts/public';

interface Props {
  children: React.ReactNode;
}

const NoAuthRoute: React.FC<Props> = ({ children }: Props) => {
  return <PublicLayout blanked>{children}</PublicLayout>;
};

export default NoAuthRoute;
