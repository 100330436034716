import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  margin-right: 20px;
  flex: 1;

  &:last-of-type {
    margin-right: 0;
  }

  & > div {
    padding: 20px;
    background-color: var(--select-background-light);
    border-radius: 8px;
  }

  span.label {
    color: var(--neutrals-text-weak-active);
    margin-bottom: 10px;
    display: block;
  }

  span.value {
    color: var(--select-text);
    line-height: 1.2em;
    font-weight: 500;
    word-break: break-word;
  }
`;
