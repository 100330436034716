export const removeItemFromArray = (array: Array<any>, index: number) => {
  const newArray = [...array];
  newArray.splice(index, 1);
  return newArray;
};

export const dissociateArrayIntoArrays = (
  array: Array<any>,
  getDissociateCondition: (item: any) => boolean
) => {
  const array1 = [];
  const array2 = [];

  array.forEach(item => {
    if (getDissociateCondition(item)) {
      array1.push(item);
    } else {
      array2.push(item);
    }
  });

  return [array1, array2];
};
