import { useIsMobileScreen } from 'hooks';

import CatawbaLogoMin from 'assets/images/catawba-logo-min.svg';
import RegistrationUserIcon from 'assets/images/registration-user-icon.svg';
import { FaCheck } from 'react-icons/fa';
import { Icon, Input } from 'components/atoms/_common';
import {
  DesktopSelectBox,
  IconCircleContainer,
  MobileSelectBox,
} from './styles';

interface Props {
  isChecked: boolean;
  type: 'yes' | 'no';
}

const SelectBox = ({ isChecked, type }: Props) => {
  const isMobile = useIsMobileScreen();

  const image = type === 'yes' ? CatawbaLogoMin : RegistrationUserIcon;
  const alt = type === 'yes' ? 'Catawba Logo' : 'Registration User Icon';

  return isMobile ? (
    <MobileSelectBox isChecked={isChecked}>
      <div className="checkbox">{isChecked ? <FaCheck /> : ''}</div>
      <img className="isCatawba" src={image} alt={alt} />
      {type === 'yes' ? (
        <p>
          Yes, I&apos;m a<br />
          Catawba Citizen
        </p>
      ) : (
        <p>
          I&apos;m not a<br />
          Catawba Citizen
        </p>
      )}
    </MobileSelectBox>
  ) : (
    <>
      <DesktopSelectBox isChecked={isChecked}>
        <span className="checkbox">
          {isChecked ? <Icon name="check" /> : ''}
        </span>
        <IconCircleContainer>
          <img className="isCatawba" src={image} alt={alt} />
        </IconCircleContainer>
        {type === 'yes' ? (
          <p>I am a Catawba Citizen</p>
        ) : (
          <p>
            I am <strong>not</strong> a Catawba Citizen
          </p>
        )}
      </DesktopSelectBox>
      {type === 'yes' && isChecked && (
        <Input name="tribal_id" placeholder="Enrollment Number" />
      )}
    </>
  );
};

export default SelectBox;
