/* eslint-disable import/no-cycle */
import { takeLatest, all, call, put } from 'redux-saga/effects';
import api, { endpoints } from 'services/API';
import { buildURL } from 'services/API/endpoints';
import { notifyError, notifySuccess } from 'services/notify';
import { cancelCompanyApplicationSuccess } from '../user/index';
import {
  // COMPANY APPLICATION
  createCompanyApplicationRequest,
  createCompanyApplicationSuccess,
  updateCompanyApplicationRequest,
  updateCompanyApplicationSuccess,
  checkoutStripePaymentRequest,
  submitCompanyApplicationRequest,
  cancelCompanyApplicationRequest,
  // COMPANY INFORMATION
  getCompanyInfoRequest,
  getCompanyInfoSuccess,
  // COMPANY SUBSCRIPTION
  updateSubscriptionRequest,
  updateSubscriptionSuccess,
  cancelSubscriptionRequest,
  reactivateSubscriptionRequest,
  getSubscriptionDataRequest,
  // COMPANY DISSOLUTION
  createDissolutionRequest,
  updateDissolutionRequest,
  submitDissolutionRequest,
  getAllDissolutionsRequest,
  getRegisteredAgentsRequest,
  getInvitationCompanyDataRequest,
  sendCompanyInvitationRequest,
  getCompanyInvitationsRequest,
  cancelCompanyInvitationRequest,
  resendCompanyInvitationRequest,
  acceptCompanyInvitationRequest,
  rejectCompanyInvitationRequest,
  getCompanyMembersRequest,
  getCompanyCaptableRequest,
  updateCompanyCaptableRequest,
} from './index';

export function* createCompanyApplication({
  payload: { company_name, callback, tribal_id, type },
}) {
  try {
    const company_full_name =
      type === 'C Corp' ? `${company_name} Inc.` : `${company_name} LLC`;

    const { data, success } = yield call(
      api.post,
      endpoints.create_company_application,
      {
        company_name,
        tribal_id,
        type: type === 'C Corp' ? 'C Corp' : 'Limited Liability Company',
        company_full_name,
        jurisdiction: 'domestic',
      }
    );

    if (!success) {
      console.log(data);
      return;
    }

    yield put(createCompanyApplicationSuccess(data));

    callback();
  } catch (err) {
    console.log(err);
  }
}

export function* updateCompanyApplication({
  payload: {
    isMailingAddressSame,
    callback,
    companyId,
    information,
    updateOnly,
    companyType,
  },
}) {
  try {
    let backendData = {};

    const getDayAndMonth = date => {
      const [year, month, day] = date.split('-');
      return `${month}/${day}`;
    };

    if (updateOnly) {
      backendData = updateOnly;
    } else {
      backendData = {
        public_identifier:
          companyType.type === 'DAO'
            ? companyType.data.public_identifier
            : null,
        date_formed: new Date(
          information.company_details.date_formed
        ).toISOString(),
        fiscal_year_end_date: getDayAndMonth(
          information.company_details.fiscal_year_end_date
        ),
        primary_email: information.company_details.primary_email?.toLowerCase(),
        phone: information.company_details.phone,
        registration_address: information.registration_address,
        mailing_address: isMailingAddressSame
          ? information.registration_address
          : information.mailing_address,
        registeredAgentUsername:
          information.registeredAgentUsername === 'manual'
            ? null
            : information.registeredAgentUsername,
        ...(information.company_details.total_common
          ? {
              total_common: Number(information.company_details.total_common),
            }
          : {}),
        ...(information.company_details.total_preferred
          ? {
              total_preferred: Number(
                information.company_details.total_preferred
              ),
            }
          : {}),
        ...(information.company_details.preferred_name
          ? { preferred_name: information.company_details.preferred_name }
          : {}),
        ...(information.company_details.preferred_description
          ? {
              preferred_description:
                information.company_details.preferred_description,
            }
          : {}),
      };
    }

    const url = buildURL('update_company_application', {
      route: { applicationId: companyId },
    });

    const { data, success } = yield call(api.post, url, backendData);

    if (!success) {
      return;
    }

    yield put(updateCompanyApplicationSuccess(data));

    callback(data);
  } catch (err) {
    console.log(err);
  }
}

export function* submitCompanyApplication({ payload }) {
  try {
    const { activeCompany, callback } = payload;

    const url = buildURL('submit_company_application', {
      route: { applicationId: activeCompany.id },
    });

    const { data, success } = yield call(api.post, url, activeCompany);

    if (!success) {
      return;
    }

    notifySuccess('Application submitted successfully!');

    callback();
  } catch (error) {
    console.log(error);
  }
}

export function* cancelCompanyApplication({
  payload: { companyApplicationId },
}) {
  try {
    const url = buildURL(endpoints.cancel_company_application, {
      route: { applicationId: companyApplicationId },
    });

    const { data, success } = yield call(api.post, url);

    if (!success) {
      throw Error('Error cancelling application');
    }

    yield put(cancelCompanyApplicationSuccess({ companyApplicationId }));

    notifySuccess('Company Application cancelled successfully!');
  } catch (err) {
    console.log(err);
  }
}

export function* checkoutStripePayment({ payload }) {
  try {
    const { companyApplicationId, callback } = payload;

    const url = buildURL('checkout_stripe_payment', {
      route: { companyApplicationId },
    });

    const { data, success } = yield call(api.post, url, {
      quantity: 1,
      price: process.env.REACT_APP_PRICE_KEY,
      success_url: process.env.REACT_APP_STRIPE_URL_SUCCESS,
      cancel_url: process.env.REACT_APP_STRIPE_URL_FAILURE,
    });

    if (!success) {
      callback(data);
      return;
    }

    callback(null, data);
  } catch (error) {
    console.log(error);
  }
}

export function* getCompanyInfo({ payload }) {
  try {
    const { companyId } = payload;

    const url = buildURL('get_company_info', {
      route: { companyId },
    });

    const { data, success } = yield call(api.get, url);

    if (!success) {
      console.log(data);
      return;
    }

    yield put(getCompanyInfoSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

export function* updateSubscription({ payload }) {
  try {
    const { companyId } = payload;

    const url = buildURL(endpoints.update_subscription_payment_method, {
      route: { companyId },
    });

    const { success, data } = yield call(api.put, url, {
      success_url: process.env.REACT_APP_STRIPE_UPDATE_SUBSCRIPTION_URL_SUCCESS,
      cancel_url: process.env.REACT_APP_STRIPE_UPDATE_SUBSCRIPTION_URL_FAILURE,
    });

    if (!success) {
      return;
    }

    yield put(updateSubscriptionSuccess(data));
  } catch (error) {
    notifyError(error.message);
  }
}

export function* cancelSubscription({ payload }) {
  try {
    const { companyId, callback } = payload;

    const url = buildURL(endpoints.cancel_subscription, {
      route: { companyId },
    });

    const { success } = yield call(api.delete, url);

    if (!success) {
      return;
    }

    callback();

    notifySuccess('Subscription cancelled successfully!');
  } catch (error) {
    notifyError(error.message);
  }
}

export function* reactivateSubscription({ payload }) {
  try {
    const { companyId, callback } = payload;

    const url = buildURL(endpoints.reactivate_subscription, {
      route: { companyId },
    });

    const { success } = yield call(api.post, url);

    if (!success) {
      return;
    }

    callback();

    notifySuccess('Subscription reactivated successfully!');
  } catch (error) {
    notifyError(error.message);
  }
}

export function* getSubscriptionData({ payload }) {
  try {
    const { companyId, callback } = payload;

    const url = buildURL(endpoints.get_subscription, {
      route: { companyId },
    });

    const { data, success } = yield call(api.get, url);

    if (!success) {
      return;
    }

    callback(data);
  } catch (error) {
    notifyError(error.message);
  }
}

export function* createDissolution({ payload }) {
  try {
    const { companyId, callback } = payload;

    const url = buildURL(endpoints.create_dissolution, {
      route: { companyId },
    });

    const { data, success } = yield call(api.post, url);

    if (!success) {
      return;
    }

    callback();
  } catch (error) {
    notifyError(error.message);
  }
}

export function* updateDissolution({ payload }) {
  try {
    const { companyId, callback } = payload;

    const url = buildURL(endpoints.update_dissolution, {
      route: { companyId },
    });

    const { data, success } = yield call(api.post, url, {
      newStatus: 'windup',
    });

    if (!success) {
      return;
    }

    callback();
  } catch (error) {
    notifyError(error.message);
  }
}

export function* submitDissolution({ payload }) {
  try {
    const { companyId, callback, membership } = payload;

    const url = buildURL(endpoints.submit_dissolution, {
      route: { companyId },
    });

    const { data, success } = yield call(api.post, url, { membership });

    if (!success) {
      return;
    }

    callback();
  } catch (error) {
    notifyError(error.message);
  }
}

export function* getAllDissolutions({ payload }) {
  try {
    const { companyId, callback } = payload;

    const url = buildURL(endpoints.create_dissolution, {
      route: { companyId },
    });

    const { data, success } = yield call(api.get, url);

    if (!success) {
      return;
    }

    callback(data);
  } catch (error) {
    notifyError(error.message);
  }
}

export function* getRegisteredAgents({ payload }) {
  try {
    const { callback } = payload;

    const url = endpoints.get_registered_agents;

    const { data, success } = yield call(api.get, url);

    if (!success) {
      return;
    }

    callback(data);
  } catch (error) {
    notifyError(error.message);
  }
}

export function* getInvitationCompanyData({ payload }) {
  try {
    const { auth, callback } = payload;
    const url = endpoints.get_company_invite;
    // @ts-ignore
    const { data, success } = yield call(api.get, url, { params: { auth } });
    if (!success) {
      return;
    }
    callback(data);
  } catch (error) {
    notifyError(error.message);
  }
}

export function* getCompanyInvitations({ payload }) {
  try {
    const { companyId, callback } = payload;
    const url = buildURL(endpoints.get_company_invites, {
      route: { companyId },
    });
    const { data, success } = yield call(api.get, url);
    if (!success) {
      return;
    }
    callback(data);
  } catch (error) {
    notifyError(error.message);
  }
}

export function* sendCompanyInvitation({ payload }) {
  try {
    const { companyId, email, role, callback } = payload;
    const url = buildURL(endpoints.create_company_invite, {
      route: { companyId },
    });
    const { data, success } = yield call(api.post, url, { email, role });
    if (!success) {
      return;
    }
    callback();
    notifySuccess('Invitation sent successfully!');
  } catch (error) {
    notifyError(error.message);
  }
}

export function* resendCompanyInvitation({ payload }) {
  try {
    const { companyId, inviteId } = payload;
    const url = buildURL(endpoints.resend_company_invite, {
      route: { companyId, inviteId },
    });
    const { data, success } = yield call(api.post, url);
    if (!success) {
      return;
    }
    notifySuccess('Invitation resent successfully!');
  } catch (error) {
    notifyError(error.message);
  }
}

export function* cancelCompanyInvitation({ payload }) {
  try {
    const { companyId, inviteId, callback } = payload;
    const url = buildURL(endpoints.cancel_company_invite, {
      route: { companyId, inviteId },
    });
    const { data, success } = yield call(api.post, url);
    if (!success) {
      return;
    }
    callback();
    notifySuccess('Invitation cancelled successfully!');
  } catch (error) {
    notifyError(error.message);
  }
}

export function* acceptCompanyInvitation({ payload }) {
  try {
    const { auth, callback } = payload;
    const url = endpoints.accept_company_invite;
    const { data, success } = yield call(
      api.post,
      url,
      {},
      { params: { auth } }
    );
    if (!success) {
      return;
    }
    callback();
    notifySuccess('Invitation accepted successfully!');
  } catch (error) {
    notifyError(error.message);
  }
}

export function* rejectCompanyInvitation({ payload }) {
  try {
    const { auth, callback } = payload;
    const url = endpoints.reject_company_invite;
    const { data, success } = yield call(
      api.post,
      url,
      {},
      { params: { auth } }
    );
    if (!success) {
      return;
    }
    callback();
    notifySuccess('Invitation rejected successfully!');
  } catch (error) {
    notifyError(error.message);
  }
}

export function* getCompanyMembers({ payload }) {
  try {
    const { companyId, callback } = payload;
    const url = buildURL(endpoints.get_company_members, {
      route: { companyId },
    });
    const { data, success } = yield call(api.get, url);
    if (!success) {
      return;
    }
    callback(data);
  } catch (error) {
    notifyError(error.message);
  }
}

export function* getCompanyCaptable({ payload }) {
  try {
    const { companyId, callback } = payload;
    const url = buildURL(endpoints.get_company_captable, {
      route: { companyId },
    });
    const { data, success } = yield call(api.get, url);
    if (!success) {
      return;
    }
    callback(data);
  } catch (error) {
    if (payload.callback) payload.callback([]);
    notifyError(error.message);
  }
}

export function* updateCompanyCaptable({ payload }) {
  try {
    const { companyId, data, callback } = payload;

    const TOTAL_COMMON_SHARES = 10000;

    const owners = data.map(owner => {
      const { name, role, username } = owner.member;

      return {
        name,
        role,
        username,
        shares: {
          common: {
            amount: owner.percentage * (TOTAL_COMMON_SHARES / 100),
          },
        },
      };
    });

    const values = {
      shares: {
        total_common: TOTAL_COMMON_SHARES,
        total_preferred: 0,
      },
      owners,
    };

    const url = buildURL(endpoints.update_company_captable, {
      route: { companyId },
    });
    const { data: response, success } = yield call(api.post, url, values);
    if (!success) {
      return;
    }
    callback(response);
    notifySuccess('Captable updated successfully!');
  } catch (error) {
    notifyError(error.message);
  }
}

export default all([
  takeLatest(createCompanyApplicationRequest, createCompanyApplication),
  takeLatest(updateCompanyApplicationRequest, updateCompanyApplication),
  takeLatest(cancelCompanyApplicationRequest, cancelCompanyApplication),
  takeLatest(submitCompanyApplicationRequest, submitCompanyApplication),
  takeLatest(checkoutStripePaymentRequest, checkoutStripePayment),
  takeLatest(getCompanyInfoRequest, getCompanyInfo),
  takeLatest(updateSubscriptionRequest, updateSubscription),
  takeLatest(cancelSubscriptionRequest, cancelSubscription),
  takeLatest(reactivateSubscriptionRequest, reactivateSubscription),
  takeLatest(getSubscriptionDataRequest, getSubscriptionData),
  takeLatest(createDissolutionRequest, createDissolution),
  takeLatest(updateDissolutionRequest, updateDissolution),
  takeLatest(submitDissolutionRequest, submitDissolution),
  takeLatest(getAllDissolutionsRequest, getAllDissolutions),
  takeLatest(getRegisteredAgentsRequest, getRegisteredAgents),
  takeLatest(getInvitationCompanyDataRequest, getInvitationCompanyData),
  takeLatest(getCompanyInvitationsRequest, getCompanyInvitations),
  takeLatest(sendCompanyInvitationRequest, sendCompanyInvitation),
  takeLatest(resendCompanyInvitationRequest, resendCompanyInvitation),
  takeLatest(cancelCompanyInvitationRequest, cancelCompanyInvitation),
  takeLatest(acceptCompanyInvitationRequest, acceptCompanyInvitation),
  takeLatest(rejectCompanyInvitationRequest, rejectCompanyInvitation),
  takeLatest(getCompanyMembersRequest, getCompanyMembers),
  takeLatest(getCompanyCaptableRequest, getCompanyCaptable),
  takeLatest(updateCompanyCaptableRequest, updateCompanyCaptable),
]);
