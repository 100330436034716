/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector, useRouteParams } from 'hooks';
import {
  getDocumentPreviewRequest,
  getDocumentsRequest,
  uploadDocumentRequest,
} from 'store/slices/document';
import { Button, Icon, FileInput, Form } from 'components/atoms/_common';
import { Modal, LoadingSection } from 'components/atoms/_util';

import { DOCUMENT_UPLOAD_SCHEMA } from 'util/schemas';
import { notifyError } from 'services/notify';
import NamedSection from '../_common/NamedSection';
import Folder from './Folder';
import FileRenderer from './File';

import {
  Container,
  Breadcumb,
  Divider,
  FilesContainer,
  PreviewModalContainer,
  // UploadModalContainer,
} from './styles';

interface Props {
  companyId: string;
}

const DEFAULT_DOCUMENT_BUCKET = '/company-private/';

const Documents: React.FC<Props> = ({ companyId }: Props) => {
  const dispatch = useAppDispatch();
  const { documents, activeDocumentPreview, loading } = useAppSelector(
    state => state.document
  );
  const viewingCompany = useAppSelector(state => state.company.viewingCompany);
  const [previewURL, setPreviewURL] = useState(null);

  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  useRouteParams(
    documentPath => {
      if (!documentPath) return;
      dispatch(
        getDocumentPreviewRequest({
          companyId: viewingCompany.id,
          documentPath,
        })
      );
      setPreviewModalOpen(true);
    },
    ['documentPath']
  );

  const [documentNavigation, setDocumentNavigation] = useState({
    title: 'Document files',
    path: '/',
    data: documents || [],
    history: [],
  });

  useEffect(() => {
    dispatch(getDocumentsRequest({ companyId }));
  }, []);

  useEffect(() => {
    /* TODO: recursively navigate again to the current navigation after the
    main documents are updated. By now we are going to reset the documents only */
    setDocumentNavigation({
      title: 'Document files',
      path: '/',
      data: documents || [],
      history: [],
    });
  }, [documents]);

  useEffect(() => {
    if (!activeDocumentPreview) return;

    async function fetchImageURL() {
      try {
        const { path, url } = activeDocumentPreview;
        const response = await fetch(url);
        const data = await response.blob();

        const filename = path.split('/').pop();

        // TODO: review approaches for different file formats if it's the case. If needed we can probably extract the file type from the filename and create a hashmap of file types and their respective file extensions
        const file = new File([data], filename, {
          type: 'application/pdf',
        });
        setPreviewURL(URL.createObjectURL(file));
      } catch (err) {
        notifyError(
          'There was an error preparing the document preview. Please try again later'
        );
        setPreviewModalOpen(false);
      }
    }
    fetchImageURL();
  }, [activeDocumentPreview]);

  const actions = {
    onFolderClick: folder => {
      setDocumentNavigation(previousNavigation => ({
        title: folder.name,
        path: `${previousNavigation.path + folder.name}/`,
        data: [
          {
            name: `..${previousNavigation.path}`,
            type: 'back',
          },
          ...(folder.children || []),
        ],
        history: [...previousNavigation.history, previousNavigation],
      }));
    },
    onFileClick: file => {
      actions.enablePreview(file);
    },
    onBackClick: () => {
      setDocumentNavigation(previousNavigation =>
        previousNavigation.history.pop()
      );
    },
    enableUpload: () => {
      setUploadModalOpen(true);
    },
    cancelUpload: () => {
      setUploadModalOpen(false);
    },
    enablePreview: file => {
      dispatch(
        getDocumentPreviewRequest({
          companyId: viewingCompany.id,
          documentPath: file.path.replace(viewingCompany.id, ''),
        })
      );
      setPreviewModalOpen(true);
    },
    cancelPreview: () => {
      setPreviewModalOpen(false);
    },
    upload: ({ file }) => {
      if (!file) return;

      const path =
        documentNavigation.path !== '/'
          ? documentNavigation.path
          : DEFAULT_DOCUMENT_BUCKET;

      dispatch(
        uploadDocumentRequest({
          file,
          filename: path + file.name,
          companyId: viewingCompany.id,
          callback: error => {
            if (!error) {
              setUploadModalOpen(false);
            }
          },
        })
      );
    },
  };

  return (
    <Container>
      <Modal open={previewModalOpen} onClose={actions.cancelPreview}>
        <LoadingSection isLoading={!activeDocumentPreview}>
          <PreviewModalContainer>
            {activeDocumentPreview && previewURL && (
              <iframe
                title="document-preview"
                src={previewURL}
                frameBorder="0"
                width="100%"
                height="650px"
              />
            )}
          </PreviewModalContainer>
        </LoadingSection>
      </Modal>
      {/* <Modal open={uploadModalOpen} onClose={actions.cancelUpload}>
        <LoadingSection isLoading={loading}>
          <UploadModalContainer>
            <Form onSubmit={actions.upload} schema={DOCUMENT_UPLOAD_SCHEMA}>
              <Breadcumb>
                Uploading to:{' '}
                {documentNavigation.path !== '/'
                  ? documentNavigation.path
                  : DEFAULT_DOCUMENT_BUCKET}
              </Breadcumb>
              <FileInput name="file" />
              <Button type="submit">Upload</Button>
            </Form>
          </UploadModalContainer>
        </LoadingSection>
      </Modal> */}
      <NamedSection name="Company Documents" iconName="description">
        <Breadcumb>
          {viewingCompany.company_name}
          {documentNavigation.path
            .split('/')
            .filter(path => !!path)
            .map(path => (
              <span key={`breadcumb-${path}`}>
                <Icon name="arrow_forward_ios" />
                {path}
              </span>
            ))}
        </Breadcumb>
        <Divider />
        <FilesContainer>
          {documentNavigation.data.map(document => {
            if (document.type === 'back') {
              return (
                <Folder
                  key={document.name}
                  document={document}
                  onClick={() => actions.onBackClick()}
                />
              );
            }

            if (document.type === 'folder') {
              return (
                <Folder
                  key={document.name}
                  document={document}
                  onClick={() => actions.onFolderClick(document)}
                />
              );
            }

            return (
              <FileRenderer
                key={document.name}
                document={document}
                onClick={() => actions.onFileClick(document)}
              />
            );
          })}
        </FilesContainer>
      </NamedSection>
    </Container>
  );
};

export default Documents;
