/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { MessageInline } from 'components/atoms';
import { Section } from 'components/molecules';
import { Form, Input, Radio } from 'components/atoms/_common';
import { useCreateCompanyStepper } from 'context/CreateCompanyStepper';
import { useAppDispatch, useAppSelector, useIsMobileScreen } from 'hooks';
import {
  createCompanyApplicationRequest,
  updateCompanyApplicationRequest,
} from 'store/slices/company';
import { CREATE_COMPANY_SCHEMA } from 'util/schemas';
import { CompanyApplication } from 'shared/types';

import { Container, TextInfo, Divider } from './styles';

import SelectBox from './SelectBox';

const CATAWBA_FORM_LINK =
  'https://share.hsforms.com/1gVc2ijOcS1CLrI5sHGuuSQcwz43';

const CatawbaCitizenship: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const activeCompany = useAppSelector(state => state.company.activeCompany);
  const { nextStep, setCatawbaCitizenship, catawbaCitizenship, companyType } =
    useCreateCompanyStepper();
  const [isCatawba, setIsCatawba] = React.useState(
    catawbaCitizenship.isCatawbaCitizen || null
  );
  const isMobile = useIsMobileScreen();

  const companyAlreadySubmitted = !!catawbaCitizenship.company_name;

  const handleSubmit = values => {
    const editSearchParam = searchParams.get('edit');
    const callback = (data: CompanyApplication) => {
      setCatawbaCitizenship(values);
      nextStep();

      // checks if the user is creating a company or updating an existing one
      const isEditing = !!editSearchParam;
      if (!isEditing) {
        setSearchParams({ edit: data.id });
      }
    };

    if (companyAlreadySubmitted) {
      dispatch(
        updateCompanyApplicationRequest({
          companyId: activeCompany?.application?.id || editSearchParam,
          updateOnly: {
            tribal_id: values.tribal_id,
          },
          callback,
        })
      );
      return;
    }

    dispatch(
      createCompanyApplicationRequest({
        company_name: values.company_name,
        tribal_id: values.tribal_id,
        callback,
        type: companyType.type,
      })
    );
  };

  const handleCatawbaChange = e => {
    setIsCatawba(e.target.value === 'yes');
  };

  return (
    <Section>
      <Section.Header
        title="Create CDEZ Company"
        subtitle="Create a company registered as a part of Catawba Digital Economic Zone"
        icon="corporate_fare"
      />
      <Section.Content>
        <Container>
          <div className="formContainerCreate">
            <TextInfo>
              <h3>
                Are you a{' '}
                <a href={CATAWBA_FORM_LINK} target="_blank" rel="noreferrer">
                  Catawba Citizen
                </a>
                ?
              </h3>
              <p>
                100%-owned Catawba entities will have free-of charge
                eCorporation registration at the CDEZ
              </p>
            </TextInfo>

            <Form
              onSubmit={handleSubmit}
              schema={
                catawbaCitizenship.isCatawbaCitizen || isCatawba
                  ? CREATE_COMPANY_SCHEMA.catawbaCitizenship
                  : CREATE_COMPANY_SCHEMA.nonCatawbaCitizenship
              }
              defaultValues={catawbaCitizenship}
            >
              <div className="selectBoxes">
                <Radio
                  id="catawba-radio-no"
                  name="isCatawbaCitizen"
                  checked={!isCatawba}
                  value="no"
                  renderer={({ checked }) => (
                    <SelectBox type="no" isChecked={checked} />
                  )}
                  onChange={handleCatawbaChange}
                />
                <Radio
                  id="catawba-radio-yes"
                  name="isCatawbaCitizen"
                  checked={isCatawba}
                  value="yes"
                  renderer={({ checked }) => (
                    <SelectBox type="yes" isChecked={checked} />
                  )}
                  onChange={handleCatawbaChange}
                />
              </div>
              {isCatawba && isMobile && (
                <Input name="tribal_id" placeholder="Enrollment Number" />
              )}
              <Divider />
              <Input
                disabled={companyAlreadySubmitted}
                className="companyName"
                name="company_name"
                label="Company Name"
                submitOnEnter
              />
              <MessageInline type="warning">
                Note: Once you select a name and proceed, you will not be able
                to change the name without creating a new application.
              </MessageInline>

              <button type="submit" className="submit-createCompany" />
            </Form>
          </div>
        </Container>
      </Section.Content>
    </Section>
  );
};

export default CatawbaCitizenship;
