import { useEffect } from 'react';
import { getItemsFromParams } from 'util/params';

const useRouteParams = (
  callback: (...values: string[]) => void | any,
  params: string[]
) => {
  useEffect(() => {
    callback(...getItemsFromParams(...params));
  }, []);
};

/*
Usage:
useRouteParams(
  (auth, invitation) => {
    if (invitation && auth) {
      setAuthInfo({ invitation, auth });
    }
  },
  ['auth', 'invitation']
);
*/

export default useRouteParams;
