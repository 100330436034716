import React from 'react';
import { Form, Input } from 'components/atoms/_common';
import { UPDATE_OTP_PASSWORD_SCHEMA } from 'util/schemas';
import { useAppDispatch } from 'hooks';
import { update2FARequest } from 'store/slices/user';
import { useTwoFactorUpdateStepperContext } from 'context/TwoFactorUpdateStepper';

import { Container } from './styles';

const OldOTP: React.FC = () => {
  const { nextStep, secret } = useTwoFactorUpdateStepperContext();

  const dispatch = useAppDispatch();
  const handleSubmit = ({ password, oldOtp }) => {
    dispatch(
      update2FARequest({ callback: () => nextStep(), password, oldOtp, secret })
    );
  };

  return (
    <Container>
      <h2>Enter your password and old OTP</h2>
      <p>
        Insert your password and your old OTP code. Your code will be validated
        when you submit.
      </p>
      <Form schema={UPDATE_OTP_PASSWORD_SCHEMA} onSubmit={handleSubmit}>
        <Input label="Your current password" name="password" type="password" />
        <Input label="Insert your old OTP Code" name="oldOtp" submitOnEnter />
        <button type="submit" className="btn-submit" />
      </Form>
    </Container>
  );
};

export default OldOTP;
