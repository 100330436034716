import { dissociateArrayIntoArrays } from 'util/arrays';
import { Notification } from 'shared/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotificationState {
  notifications: Notification[];
  archivedNotifications: Notification[];
  unarchivedNotifications: Notification[];
  readNotifications: Notification[];
  unreadNotifications: Notification[];
}

export const initialState = {
  notifications: [],
  archivedNotifications: [],
  unarchivedNotifications: [],
  readNotifications: [],
  unreadNotifications: [],
} as NotificationState;

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    updateNotificationDistribution: (
      state,
      action: PayloadAction<{ notifications: Notification[] }>
    ) => {
      const [archived, unarchived] = dissociateArrayIntoArrays(
        action.payload.notifications,
        notification => notification.archived
      );

      const [unread, read] = dissociateArrayIntoArrays(
        unarchived,
        notification => notification.unread
      );

      state.archivedNotifications = archived;
      state.unarchivedNotifications = unarchived;
      state.readNotifications = read;
      state.unreadNotifications = unread;
    },
    getNotificationsRequest: () => {},
    getNotificationsSuccess: (
      state,
      action: PayloadAction<{ notifications: Notification[] }>
    ) => {
      state.notifications = action.payload.notifications;

      notificationSlice.caseReducers.updateNotificationDistribution(
        state,
        action
      );
    },
    archiveNotificationRequest: (
      _,
      action: PayloadAction<{ notification: Notification }>
    ) => {},
    archiveNotificationSuccess: (
      state,
      action: PayloadAction<{ notificationId: string }>
    ) => {
      const selectedNotification = state.notifications.find(
        notification => notification.id === action.payload.notificationId
      );

      selectedNotification.archived = true;
      selectedNotification.unread = false;
      notificationSlice.caseReducers.updateNotificationDistribution(state, {
        payload: { notifications: state.notifications },
        type: 'string',
      });
    },
    unarchiveNotificationRequest: (
      _,
      action: PayloadAction<{ notification: Notification }>
    ) => {},
    unarchiveNotificationSuccess: (
      state,
      action: PayloadAction<{ notificationId: string }>
    ) => {
      const selectedNotification = state.notifications.find(
        notification => notification.id === action.payload.notificationId
      );

      selectedNotification.archived = false;
      selectedNotification.unread = true;
      notificationSlice.caseReducers.updateNotificationDistribution(state, {
        payload: { notifications: state.notifications },
        type: 'string',
      });
    },
    deleteNotificationRequest: (
      _,
      action: PayloadAction<{ notification: Notification }>
    ) => {},
    deleteNotificationSuccess: (
      state,
      action: PayloadAction<{ notificationId: string }>
    ) => {
      const selectedNotificationIndex = state.notifications.findIndex(
        notification => notification.id === action.payload.notificationId
      );

      state.notifications.splice(selectedNotificationIndex, 1);

      notificationSlice.caseReducers.updateNotificationDistribution(state, {
        payload: { notifications: state.notifications },
        type: 'string',
      });
    },
    readNotificationRequest: (
      _,
      action: PayloadAction<{ notification: Notification }>
    ) => {},
    readNotificationSuccess: (
      state,
      action: PayloadAction<{ notificationId: string }>
    ) => {
      const selectedNotification = state.notifications.find(
        notification => notification.id === action.payload.notificationId
      );

      selectedNotification.unread = false;

      notificationSlice.caseReducers.updateNotificationDistribution(state, {
        payload: { notifications: state.notifications },
        type: 'string',
      });
    },
    unreadNotificationRequest: (
      _,
      action: PayloadAction<{ notification: Notification }>
    ) => {},
    unreadNotificationSuccess: (
      state,
      action: PayloadAction<{ notificationId: string }>
    ) => {
      const selectedNotification = state.notifications.find(
        notification => notification.id === action.payload.notificationId
      );

      selectedNotification.unread = true;

      notificationSlice.caseReducers.updateNotificationDistribution(state, {
        payload: { notifications: state.notifications },
        type: 'string',
      });
    },
  },
});

export const {
  getNotificationsRequest,
  getNotificationsSuccess,
  archiveNotificationRequest,
  archiveNotificationSuccess,
  unarchiveNotificationRequest,
  unarchiveNotificationSuccess,
  deleteNotificationRequest,
  deleteNotificationSuccess,
  readNotificationRequest,
  readNotificationSuccess,
  unreadNotificationRequest,
  unreadNotificationSuccess,
} = notificationSlice.actions;

export default notificationSlice.reducer;
