import styled, { css } from 'styled-components';

interface Props {
  isOpen: boolean;
}

export const Container = styled.div<Props & { isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px;

  width: ${props => (props.isOpen ? '265px' : '80px')};

  background: var(--neutrals-background-light);
  transition: width 0.4s ease-in-out;

  > section {
    padding: 16px;
  }

  .company-card {
    margin: 10px 0;
  }

  .pill {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    background: var(--neutrals-background);
    border: 1px solid var(--primary-border);
    /* background: transparent;
    border: 1px solid var(--neutrals-border); */
    transition: width 0.4s ease-in-out;
    width: ${props => (props.isOpen ? '232.39px' : '48px')};
    height: 48px;

    &:hover {
      background: var(--primary-background);
      border-color: var(--primary-border-active);
    }

    .label {
      opacity: ${props => (props.isOpen ? '1' : '0')};
      width: ${props => (props.isOpen ? '100%' : '0px')};
      margin-left: ${props => (props.isOpen ? '10px' : '0px')};
      font-size: 1.2rem;

      transition: opacity 0.2s ease-in, width 0.4s ease-in,
        margin-left 0.4s ease-in;

      &.small {
        font-size: 1rem;
      }
    }
  }

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}
`;

export const Logo = styled.div<Props>`
  position: relative;
  width: 100%;
  height: 140px;
  flex-shrink: 0;
  overflow: hidden;
  max-width: ${props => (props.isOpen ? '260px' : '68px')};
  padding: ${props => (props.isOpen ? '12px' : '0')};
  transition: max-width 0.4s ease-in-out, padding 0.4s ease-in-out;

  img {
    position: absolute;
    top: 28px;
    left: ${props => (props.isOpen ? '16px' : '11px')};

    width: ${props => (props.isOpen ? '230px' : '190px')};
    transition: width 0.4s ease-in-out, padding 0.4s ease-in-out,
      left 0.4s ease-in-out;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--neutrals-border-active);
`;

export const NotificationMarker = styled.div<Props>`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  top: 10px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: var(--neutrals-text-contrast);
  background: var(--select-icon);
  transition: all 0.4s ease-in-out;

  ${props =>
    !props.isOpen &&
    css`
      top: 6px;
      right: 5px;
      width: 15px;
      height: 15px;
      font-size: 10px;
    `}
`;
