import styled from 'styled-components';

export const Container = styled.div``;

export const Breadcumb = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 56px;
  font-size: 20px;

  .icon {
    font-size: 14px;
    margin: 0 8px;
    margin-top: 4px;
  }
  > span {
    display: flex;
    align-items: center;
  }
`;

export const Divider = styled.div`
  margin: 12px 0;
  width: 100%;
  height: 1px;
  background: var(--neutrals-border);
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PreviewModalContainer = styled.div`
  padding-top: 27px;
  display: flex;
  min-width: 600px;
  min-height: 400px;

  iframe {
    border-radius: 8px;
  }
`;

export const UploadModalContainer = styled.div`
  padding-top: 27px;
  display: flex;
  min-width: 400px;

  .formContainer {
    width: 100%;

    form {
      display: flex;
      flex-direction: column;
      flex-direction: flex-end;

      .fileInput {
        height: 100px;
      }

      button[type='submit'] {
        margin-top: 10px;
      }
    }
  }
`;
