/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Button, Checkbox } from 'components/atoms/_common';
import { isFormValid } from 'components/atoms/_common/Form';
import { DisabledFeature } from 'components/atoms/_util';
import { Section } from 'components/molecules';

import { useCreateCompanyStepper } from 'context/CreateCompanyStepper';
import { useAppDispatch, useAppSelector, useIsMobileScreen } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { updateCompanyApplicationRequest } from 'store/slices/company';

import CompanyDetailsForm from './CompanyDetailsForm';
import MailingAddressForm from './MailingAddressForm';
import RegisteredAgent from './RegisteredAgent';
import RegistrationAddressForm from './RegistrationAddressForm';

import { Container, Divider } from './styles';

const Information = () => {
  const detailsFormSubmitRef = useRef<HTMLButtonElement>();
  const registrationAddressFormSubmitRef = useRef<HTMLButtonElement>();
  const mailingAddressFormSubmitRef = useRef<HTMLButtonElement>();

  const dispatch = useAppDispatch();
  const activeCompany = useAppSelector(state => state.company.activeCompany);
  const {
    catawbaCitizenship,
    information,
    nextStep,
    setInformation,
    selectedRegisteredAgent,
    companyType,
  } = useCreateCompanyStepper();

  const { isMailingAddressSame } = useCreateCompanyStepper();
  const [companyDetails, setCompanyDetails] = useState(
    information.company_details
  );
  const [registrationAddress, setRegistrationAddress] = useState(
    information.registration_address
  );
  const [mailingAddress, setMailingAddress] = useState(
    information.mailing_address
  );

  /* // TODO: implement this soon
  const [isOwnerDetailsVisible, setIsOwnerDetailsVisible] = useState(false);

  const toggleOwnerDetails = () => {
    setIsOwnerDetailsVisible(!isOwnerDetailsVisible);
  };
  */

  useEffect(() => {
    if (
      isFormValid(detailsFormSubmitRef) &&
      isFormValid(registrationAddressFormSubmitRef) &&
      (isMailingAddressSame ? true : isFormValid(mailingAddressFormSubmitRef))
    ) {
      // TODO: find a way to disable the Form from the Checkbox
      const newRegistrationAddress = { ...registrationAddress };

      // @ts-ignore
      delete newRegistrationAddress.isMailingAddressSame;
      // @ts-ignore
      if (newRegistrationAddress.isRegistrationInPuertoRico) {
        newRegistrationAddress.state = 'PR';
      }

      // @ts-ignore
      delete newRegistrationAddress.isRegistrationInPuertoRico;

      // @ts-ignore
      if (mailingAddress && mailingAddress?.isMailingInPuertoRico) {
        mailingAddress.state = 'PR';
      }

      if (
        mailingAddress &&
        // @ts-ignore
        mailingAddress?.isMailingInPuertoRico !== undefined
      ) {
        // @ts-ignore
        delete mailingAddress.isMailingInPuertoRico;
      }

      const newInformation = {
        ...information,
        company_details: companyDetails,
        registration_address: newRegistrationAddress,
        mailing_address: mailingAddress,
        registeredAgentUsername: selectedRegisteredAgent,
      };

      setInformation(() => newInformation);
      dispatch(
        updateCompanyApplicationRequest({
          information: newInformation,
          companyId: activeCompany.application.id,
          isMailingAddressSame,
          companyType,
          callback: () => {
            nextStep();
          },
        })
      );
    }
  }, [companyDetails, registrationAddress, mailingAddress]);

  const subtitles = {
    LLC: 'Limited Liability Company',
    DAO: 'Decentralized Autonomous Organization LLC',
    'C Corp': 'C-Corporation',
  };

  return (
    <Section>
      <Section.Header
        icon="folder"
        title={catawbaCitizenship.company_name}
        subtitle={subtitles[companyType.type]}
      />
      <Section.Content>
        <Container>
          <CompanyDetailsForm
            ref={detailsFormSubmitRef}
            defaultValues={companyDetails}
            setCompanyDetails={setCompanyDetails}
          />

          {/*
          <DisabledFeature>
            <Button
              disabled
              className="addOwner"
              variant="outlined"
              // onClick={toggleOwnerDetails}
            >
              Add ownership details
            </Button>
          </DisabledFeature> */}
          <Divider />
          <RegisteredAgent setRegistrationAddress={setRegistrationAddress} />
          <RegistrationAddressForm
            key={JSON.stringify(selectedRegisteredAgent)}
            ref={registrationAddressFormSubmitRef}
            defaultValues={registrationAddress}
            setRegistrationAddress={setRegistrationAddress}
          />
          {!isMailingAddressSame && (
            <MailingAddressForm
              ref={mailingAddressFormSubmitRef}
              defaultValues={mailingAddress}
              setMailingAddress={setMailingAddress}
            />
          )}
          <button
            className="submit-createCompany"
            onClick={() => {
              detailsFormSubmitRef.current.click();
              registrationAddressFormSubmitRef.current.click();
              mailingAddressFormSubmitRef.current?.click();
            }}
          />
        </Container>
      </Section.Content>
    </Section>
  );
};

export default Information;
