import React from 'react';

import { Container } from './styles';

export interface SectionContentProps {
  noPadding?: boolean;
  children: React.ReactNode;
}

const SectionContent: React.FC<SectionContentProps> = ({
  children,
  noPadding,
}: SectionContentProps) => {
  return (
    <Container className="section-content" noPadding={noPadding}>
      {children}
    </Container>
  );
};

SectionContent.defaultProps = {
  noPadding: false,
};

export default SectionContent;
