import cn from 'classnames';
import React from 'react';

import { Container } from './styles';

interface Props {
  currentStep: number;
  totalSteps: number;
}

const StepperCircle: React.FC<Props> = ({ currentStep, totalSteps }: Props) => {
  const steps = Array.from({ length: totalSteps - 1 }, (_, i) => i + 1);

  return (
    <Container step={currentStep}>
      {steps.map(step => (
        <div
          className={cn('circle', { previous: step < currentStep })}
          key={step}
        />
      ))}
      <div className="circle" />
    </Container>
  );
};

export default StepperCircle;
