import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  .companyFormHeader {
    margin-bottom: 60px;
  }
  .addOwner {
    width: 280px;
  }

  form:first-of-type {
    margin-bottom: 20px;
  }

  ${mediaQuery.sm} {
    padding-bottom: 20px;
  }
`;

export const Divider = styled.div`
  margin: 20px 0;
  height: 1px;
  background-color: var(--neutrals-border);
`;

export const SpecializedSharesContainer = styled.div`
  width: 100%;
`;
