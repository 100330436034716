import { Document } from 'shared/types';
import { takeLatest, all, call, put } from 'redux-saga/effects';

// eslint-disable-next-line
import api, { endpoints, anonymousInstance } from 'services/API';

import { notifyError, notifySuccess } from 'services/notify';
import { buildURL } from 'services/API/endpoints';
import {
  getDocumentsRequest,
  getDocumentsSuccess,
  getDocumentPreviewRequest,
  getDocumentPreviewSuccess,
  uploadDocumentRequest,
  uploadDocumentSuccess,
  uploadDocumentFailure,
  uploadFileRequest,
} from './index';

export function* getDocuments({ payload: { companyId } }) {
  try {
    const url = buildURL(endpoints.get_company_document_directory, {
      route: {
        companyId,
      },
    });

    const { data, success } = yield call(api.get, url);

    if (!success) {
      return;
    }

    const documents: Document[] = data;

    yield put(
      getDocumentsSuccess({
        documents,
      })
    );
  } catch (err) {
    notifyError(err.message);
  }
}

export function* getDocumentPreview({ payload: { companyId, documentPath } }) {
  try {
    const url = buildURL(endpoints.get_company_document_retrieval_link, {
      route: {
        companyId,
      },
      query: {
        documentPath,
      },
    });

    const { data, success } = yield call(api.get, url);

    if (!success) {
      return;
    }

    yield put(
      getDocumentPreviewSuccess({
        documentPreview: {
          url: data,
          path: documentPath,
        },
      })
    );
  } catch (err) {
    notifyError(err.message);
  }
}

export function* uploadFile({
  payload: { file, formData, postURL, onComplete },
}) {
  try {
    const dataWithForm = new FormData();
    const keys = Object.keys(formData);
    keys.forEach(key => {
      dataWithForm.append(key, formData[key]);
    });

    dataWithForm.append('file', file);

    const data = yield call(anonymousInstance.post, postURL, dataWithForm);

    if (data.status !== 204 && data.status !== 200 && data.status !== 201) {
      yield onComplete(new Error('Failed to upload file'));
      return;
    }

    yield onComplete(null, data);
  } catch (err) {
    yield onComplete(err.message);
  }
}

export function* uploadDocumentStatus({
  payload: { error, file, filename, companyId, documentId },
}) {
  try {
    const notifyUrl = buildURL(
      endpoints.update_company_document_upload_status,
      {
        route: {
          companyId,
        },
      }
    );

    // @ts-ignore TODO: we need to figure out a way to type our interceptors' changes on the API responses later on.
    const { success: statusUpdated } = yield api.post(notifyUrl, {
      document_id: documentId,
      document_status: error ? 'FAILED' : 'UPLOADED',
    });

    if (!statusUpdated) {
      throw new Error('Failed to update document upload status');
    }

    if (error) {
      yield put(uploadDocumentFailure());
      return notifyError('Failed to upload document');
    }

    yield put(
      uploadDocumentSuccess({
        document: {
          type: 'file',
          name: file.name,
          path: `${companyId}${filename}`,
          size: file.size,
          // lastModified: file.lastModified,
        },
      })
    );
    return notifySuccess('Document successfully uploaded!');
  } catch (err) {
    notifyError(err.message);
    return null;
  }
}

export function* uploadDocument({
  payload: { file, filename, companyId, callback },
}) {
  try {
    const url = buildURL(endpoints.create_company_document, {
      route: {
        companyId,
      },
    });

    const { data: postPolicy, success: policySuccess } = yield call(
      api.post,
      url,
      {
        path: filename,
      }
    );

    if (!policySuccess) {
      throw new Error('Error while initiating Upload. Please try again.');
    }

    const {
      document_id: documentId,
      post_info: { postURL, formData },
    } = postPolicy;

    yield put(
      uploadFileRequest({
        file,
        postURL,
        formData,
        *onComplete(error) {
          yield uploadDocumentStatus({
            payload: { error, file, filename, companyId, documentId },
          });
          if (callback) {
            callback(error);
          }
        },
      })
    );
  } catch (err) {
    notifyError(err.message);
  }
}

export default all([
  takeLatest(getDocumentsRequest as any, getDocuments),
  takeLatest(getDocumentPreviewRequest as any, getDocumentPreview),
  takeLatest(uploadDocumentRequest as any, uploadDocument),
  takeLatest(uploadFileRequest as any, uploadFile),
]);
