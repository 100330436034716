import { Button, Form, Input } from 'components/atoms/_common';
import { TabSection } from 'components/molecules';
import { Tabs } from 'components/molecules/TabSection';
import { useAppDispatch } from 'hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updatePasswordRequest } from 'store/slices/user';
import { CHANGE_PASSWORD_SCHEMA } from 'util/schemas';
import Appearance from './Appearance';

import { Container, Divider, SecurityContainer } from './styles';

const TABS: Tabs = {
  security: {
    key: 'security',
    label: 'Security',
    icon: 'lock',
  },
  appearance: {
    key: 'appearance',
    label: 'Appearance',
    icon: 'palette',
  },
};

const Settings: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(TABS.security.key);

  const redirect2FA = () => {
    navigate('/settings/2fa');
  };

  const handlePasswordUpdateSubmit = ({ oldPassword, newPassword, otp }) => {
    dispatch(
      updatePasswordRequest({ password: oldPassword, newPassword, otp })
    );
  };

  return (
    <Container>
      <h1 className="title">Settings</h1>
      <TabSection tabs={TABS} activeTab={activeTab} setActiveTab={setActiveTab}>
        <TabSection.Content tab={TABS.security.key}>
          <SecurityContainer>
            <h2>Change password</h2>
            <Form
              schema={CHANGE_PASSWORD_SCHEMA}
              onSubmit={handlePasswordUpdateSubmit}
            >
              <Input type="password" name="oldPassword" label="Old password:" />
              <Input type="password" name="newPassword" label="New password:" />
              <Divider className="hide-on-mobile" />
              <div className="password-hint">
                <div>
                  <h3>The new password should follow these rules</h3>

                  <ul className="weak">
                    <li>Must contains at least 8 characters</li>
                    <li>At least one lowercase letter</li>
                    <li>At least one uppercase letter</li>
                    <li>At least one number</li>
                  </ul>
                </div>
                <Input type="text" name="otp" maxLength={6} label="OTP code:" />
              </div>

              <Button type="submit">Submit Password Update</Button>
            </Form>

            <Divider />
            <h2>Change the Two-Factor Authentication</h2>

            <Button className="change2fa" onClick={redirect2FA}>
              Change Two-Factor Auth
            </Button>

            <Divider />

            <div className="temporarySession">
              <h2>Temporary Session</h2>
              <p className="weak">
                As a security measure, you can optionally set your authenticated
                session as temporary and active only while on the current
                browser tab. This means if you refresh the page, navigate away,
                or close the current tab, you will be logged out.
              </p>
            </div>
          </SecurityContainer>
        </TabSection.Content>

        <TabSection.Content tab={TABS.appearance.key}>
          <Appearance />
        </TabSection.Content>
      </TabSection>
    </Container>
  );
};

export default Settings;
