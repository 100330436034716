import { NotificationCard, TabSection } from 'components/molecules';
import { Tabs } from 'components/molecules/TabSection';
import { useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { dissociateArrayIntoArrays } from 'util/arrays';
import NotificationModal from './NotificationModal';
import {
  Container,
  SectionMarker,
  Divider,
  EmptyNotificationsMessage,
} from './styles';

const TABS: Tabs = {
  notifications: {
    key: 'notifications',
    label: 'Notifications',
    icon: 'bell',
  },
  archivedNotifications: {
    key: 'archivedNotifications',
    label: 'Archived Notifications',
    icon: 'archive',
  },
};

const Notifications: React.FC = () => {
  const [viewingNotification, setViewingNotification] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(TABS.notifications.key);
  const { archivedNotifications, unarchivedNotifications } = useAppSelector(
    state => state.notification
  );

  const [todayNotifications, setTodayNotifications] = useState([]);
  const [earlierNotifications, setEarlierNotifications] = useState([]);

  useEffect(() => {
    if (!unarchivedNotifications) return;

    const [today, earlier] = dissociateArrayIntoArrays(
      unarchivedNotifications,
      notification => {
        return (
          new Date(notification.createdAt).getDate() === new Date().getDate()
        );
      }
    );

    setTodayNotifications(today);
    setEarlierNotifications(earlier);
  }, [unarchivedNotifications]);

  return (
    <>
      {viewingNotification && (
        <NotificationModal
          notification={viewingNotification}
          closeModal={() => setViewingNotification(null)}
        />
      )}

      <Container>
        <TabSection
          tabs={TABS}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          spaced
        >
          <TabSection.Content tab={TABS.notifications.key}>
            {todayNotifications.length > 0 && (
              <section className="content">
                <SectionMarker>TODAY</SectionMarker>
                {todayNotifications.map(notification => (
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                    viewNotification={setViewingNotification}
                    actions={{
                      read: true,
                      unread: true,
                      archive: true,
                      delete: true,
                    }}
                  />
                ))}
                <Divider />
              </section>
            )}
            {earlierNotifications.length > 0 ? (
              <section className="content">
                <SectionMarker>EARLIER</SectionMarker>
                {earlierNotifications.map(notification => (
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                    viewNotification={setViewingNotification}
                    actions={{
                      read: true,
                      unread: true,
                      archive: true,
                      delete: true,
                    }}
                  />
                ))}
              </section>
            ) : (
              <EmptyNotificationsMessage>
                No notifications to show
              </EmptyNotificationsMessage>
            )}
          </TabSection.Content>

          <TabSection.Content tab={TABS.archivedNotifications.key}>
            {archivedNotifications && archivedNotifications.length ? (
              archivedNotifications.map(notification => (
                <NotificationCard
                  key={notification.id}
                  notification={notification}
                  viewNotification={setViewingNotification}
                  actions={{
                    read: true,
                    unread: true,
                    unarchive: true,
                    delete: true,
                  }}
                />
              ))
            ) : (
              <EmptyNotificationsMessage>
                No archived notifications
              </EmptyNotificationsMessage>
            )}
          </TabSection.Content>
        </TabSection>
      </Container>
    </>
  );
};

export default Notifications;
