import { triggerTestRequest } from 'store/slices/mapping';
/* eslint-disable */

import { takeLatest, all, call, put } from 'redux-saga/effects';

import api from 'services/API';
import { buildURL } from 'services/API/endpoints';
import { notifySuccess } from 'services/notify';

export function* requestHandler({ payload: { handler, callback } }) {
  console.info('requestHandler', handler);

  if (!handler) return;

  const { method, endpoint, payload, options } = handler;

  yield makeRequest({
    payload: { method, options, endpoint, payload, callback },
  });
}

export function* makeRequest({
  payload: { method, options, endpoint, payload, callback },
}) {
  try {
    const url = buildURL(endpoint, {
      route: options && options.route,
      query: options && options.query,
    });
    const { data } = yield call(api[method], url, payload);

    callback(data);

    notifySuccess('Enpoint called!');
  } catch (error) {
    callback(error);
  }
}

export default all([takeLatest(triggerTestRequest as any, requestHandler)]);
