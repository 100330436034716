import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.footer`
  position: absolute;
  bottom: 0;
  width: calc(100vw - 320px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  background-color: var(--neutrals-background);

  button {
    width: 280px;
  }

  ${mediaQuery.sm} {
    padding: 12px 24px;
    position: fixed;
    button {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
    }
  }
`;
