import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loading = styled.span`
  position: absolute;
  svg {
    color: var(--neutrals-border);
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

interface ChildrenContainerProps {
  visible: boolean;
}

export const ChildrenContainer = styled.span<ChildrenContainerProps>`
  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;
