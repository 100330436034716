import { Icon } from 'components/atoms/_common';
import React from 'react';
import { Document } from 'shared/types';

import { Container } from './styles';

interface Props {
  document: Document;
  onClick: () => void;
}

const Folder: React.FC<Props> = ({ document, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <Icon name="folder" fill={1} />
      {document.name}
    </Container>
  );
};

export default Folder;
