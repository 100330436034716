import React from 'react';

import { useFormContext } from '../Form';

import { Container, ErrorSpan, RendererContainer } from './styles';

interface RendererProps {
  checked: boolean;
}

interface Props {
  id: string;
  name: string;
  value: any;
  checked: boolean;
  disabled?: boolean;
  renderer?: React.FC<RendererProps>;
  onChange?: (e: any) => void;
}

const Radio: React.FC<Props> = ({
  id,
  name,
  disabled,
  value,
  checked,
  renderer: Renderer,
  onChange,
}: Props) => {
  const { register, errors } = useFormContext();

  const hasErrors = !!errors[name];

  return (
    <Container className={hasErrors ? 'errored radio' : 'radio'}>
      <label htmlFor={id}>
        {Renderer && (
          <RendererContainer>
            {/* TODO: implement way of checking if the radio is chosen */}
            <Renderer checked={checked} />
          </RendererContainer>
        )}
        <input
          type="radio"
          id={id}
          value={value}
          name={name}
          disabled={disabled}
          {...register(name)}
          onChange={e => {
            register(name).onChange(e);
            if (onChange) {
              onChange(e);
            }
          }}
        />
        <ErrorSpan visible={hasErrors}>{errors[name]?.message}</ErrorSpan>
      </label>
    </Container>
  );
};

Radio.defaultProps = {
  disabled: false,
  onChange: undefined,
  renderer: null,
};

export default Radio;
