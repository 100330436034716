import React, { useEffect } from 'react';
import { LabelIconButton } from 'components/atoms';
import { useAppDispatch } from 'hooks';

import { getSettingsRequest } from 'store/slices/user';
import USAFlagIcon from 'assets/images/usa-flag-icon.svg';
import ThemeSelector from './ThemeSelector';

import { CatawbaBlue, Container, VioletPunk } from './styles';

const Appearance: React.FC = () => {
  /* TODO: get the user's settings from the store and display them, in user slice.
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSettingsRequest());
  }, []); */

  return (
    <Container>
      <div className="section">
        <h1>Themes</h1>
        <p className="hint">
          Change the appearance of Catawba across all of your workspaces.
        </p>
        <div className="actions-theme">
          <ThemeSelector theme="catawba-light" />
          <ThemeSelector theme="catawba-dark" />
        </div>
      </div>
      <div className="section">
        <h1>Primary Color</h1>
        <p className="hint">
          Customize the look of your workspace. Feeling adventurous? Change the
          primary color.
        </p>
        <div className="actions">
          <LabelIconButton
            icon={<CatawbaBlue />}
            label="Catawba Blue"
            selected
          />
          <LabelIconButton icon={<VioletPunk />} label="Violet Punk" disabled />
        </div>
      </div>
      <div className="section">
        <h1>Language</h1>
        <p className="hint">Choose the language of your workspace.</p>
        <div className="actions">
          <LabelIconButton
            isIconImage
            icon={USAFlagIcon}
            label="English"
            selected
          />
        </div>
      </div>
    </Container>
  );
};

export default Appearance;
