import styled, { css } from 'styled-components';

interface Props {
  isOpen: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  position: relative;

  margin-top: 10px;
  padding: 8px;
  border-radius: 95px;

  &.pill {
    ${props =>
      !props.isOpen &&
      css`
        width: 44px !important;
      `}
    height: 44px;
  }
`;
