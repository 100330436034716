/* eslint-disable no-nested-ternary */
import { Label, CountrySelect } from 'components/atoms';
import { DropdownInput, Input } from 'components/atoms/_common';
import { Row } from 'components/atoms/_util';
import { useRegisterStepper } from 'context/RegisterStepper';

import USStates from 'components/atoms/_common/DropdownInput/renderers/USStatesItem/us-states-data.mock';
import USStatesItem from 'components/atoms/_common/DropdownInput/renderers/USStatesItem/styles';

const Address = () => {
  const { country, setCountry, setAddress } = useRegisterStepper();

  const onSelect = (countryCode: string) => {
    setAddress(null);
    setCountry(countryCode);
  };

  return (
    <>
      <Label text="Address" iconName="pin_drop" />

      <CountrySelect onSelect={onSelect} selected={country} label="Country *" />

      {!country ? (
        <p>Please, select your country first...</p>
      ) : country && country !== 'US' ? (
        <>
          <Row breakRowOnMobile>
            <Input
              name="addressLine1"
              label="Address Line 1"
              placeholder="Street address, P.O. box, company name, c/o"
            />
          </Row>
          <Row breakRowOnMobile>
            <Input
              name="addressLine2"
              label="Address Line 2"
              placeholder="Apartment, suite, unit, building, floor, etc."
            />
          </Row>
          <Row breakRowOnMobile>
            <Input name="city" label="City" placeholder="City" />
            <Input
              name="state"
              label="State/Province/County"
              placeholder="City"
            />
            <Input
              name="zipCode"
              label="Zip/Postal code"
              placeholder="Zip/Postal code"
            />
          </Row>
        </>
      ) : (
        <>
          <Row breakRowOnMobile>
            <Input
              name="houseNumber"
              label="House number"
              placeholder="House number"
            />
            <Input
              name="streetName"
              label="Street name"
              placeholder="Street name"
            />
            <Input
              name="streetType"
              label="Street type"
              placeholder="Street type"
            />
            <Input
              optional
              name="unitApNumber"
              label="Unit or apartment number"
              placeholder="Unit or apartment number"
            />
          </Row>

          <Row breakRowOnMobile>
            <Input name="city" label="City" placeholder="City" />
            <DropdownInput
              name="state"
              label="State"
              hint="Select your state"
              items={USStates}
              itemRenderer={({ item, onItemClick }) => (
                <USStatesItem onClick={() => onItemClick(item.id)}>
                  <span className="state-id">{item.id}</span>
                  <span className="state-name">{item.name}</span>
                </USStatesItem>
              )}
            />
            <Input
              name="zipCode"
              label="Postal code"
              placeholder="Postal code"
              maxLength={5}
            />
          </Row>
        </>
      )}

      {}
    </>
  );
};

export default Address;
