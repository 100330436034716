/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IconCircle } from 'components/atoms/_common';
import { format } from 'date-fns';

import { Notification } from 'shared/types';
import { useAppDispatch } from 'hooks';
import {
  readNotificationRequest,
  unreadNotificationRequest,
  archiveNotificationRequest,
  unarchiveNotificationRequest,
  deleteNotificationRequest,
} from 'store/slices/notification';
import ActionItem from './ActionItem';

import { Container, CardImage } from './styles';

export interface NotificationCardActions {
  read?: boolean;
  unread?: boolean;
  archive?: boolean;
  unarchive?: boolean;
  delete?: boolean;
}

export type NotificationCardHandler = (notification: Notification) => void;

interface Props {
  notification: Notification;
  viewNotification: (notification: Notification) => void;
  actions?: NotificationCardActions;
}

const NotificationCard: React.FC<Props> = ({
  notification,
  actions,
  viewNotification,
}: Props) => {
  const dispatch = useAppDispatch();
  const handlers: Record<string, NotificationCardHandler> = {
    read: selectedNotification => {
      if (!selectedNotification.unread) {
        if (actions.unread) {
          dispatch(
            unreadNotificationRequest({ notification: selectedNotification })
          );
        }
        return;
      }
      dispatch(readNotificationRequest({ notification: selectedNotification }));
    },
    archive: selectedNotification => {
      dispatch(
        archiveNotificationRequest({ notification: selectedNotification })
      );
    },
    unarchive: selectedNotification => {
      dispatch(
        unarchiveNotificationRequest({ notification: selectedNotification })
      );
    },
    delete: selectedNotification => {
      dispatch(
        deleteNotificationRequest({ notification: selectedNotification })
      );
    },
  };

  const openNotificationInModal = () => {
    viewNotification(notification);
  };

  return (
    <Container className="notification-card">
      <div className="card-info-container" onClick={() => {}}>
        <CardImage
          unread={notification.unread}
          onClick={() => actions.read && handlers.read(notification)}
        >
          <IconCircle
            name={notification.unread ? 'drafts' : 'check'}
            fill={0}
          />
        </CardImage>

        <div className="card-details" onClick={openNotificationInModal}>
          <h2>
            <strong>Title:</strong> {notification.title}
          </h2>
          <h3>Click here to see more...</h3>
          <p>
            {format(new Date(notification.createdAt), 'MM/dd/yyyy HH:mm:ss')}
          </p>
        </div>
      </div>

      {actions && (
        <div className="card-actions">
          {actions.archive && (
            <ActionItem
              icon="archive"
              onClick={() => handlers.archive(notification)}
            />
          )}
          {actions.unarchive && (
            <ActionItem
              icon="inbox"
              onClick={() => handlers.unarchive(notification)}
            />
          )}
          {actions.delete && (
            <ActionItem
              icon="delete"
              onClick={() => handlers.delete(notification)}
            />
          )}
        </div>
      )}
    </Container>
  );
};

NotificationCard.defaultProps = {
  actions: null,
};

export default NotificationCard;
