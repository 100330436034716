import React from 'react';
import { Icon } from '../_common';
import { Container } from './styles';

interface Props {
  children: React.ReactNode;
  type?: 'select' | 'success' | 'warning' | 'danger';
  bold?: boolean;
  fullWidth?: boolean;
}

const MessageInline: React.FC<Props> = ({
  children,
  type,
  bold,
  fullWidth,
}: Props) => {
  return (
    <Container
      className="messageInline"
      type={type}
      bold={bold}
      fullWidth={fullWidth}
    >
      <Icon name={type} />
      <p>{children}</p>
    </Container>
  );
};

MessageInline.defaultProps = {
  type: 'select',
  bold: false,
  fullWidth: false,
};

export default MessageInline;
