import { useAppSelector } from 'hooks';
import React from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Container, Loading, ChildrenContainer } from './styles';

interface Props {
  isLoading?: boolean;
  loadingSize?: number;
  children: React.ReactNode;
}

const LoadingSection: React.FC<Props> = ({
  isLoading: loadingProp,
  loadingSize,
  children,
}) => {
  const loadingState = useAppSelector(state => state.loading.isLoading);

  const isLoading = loadingProp || loadingState;

  return (
    <Container>
      {isLoading && (
        <Loading>
          <AiOutlineLoading3Quarters size={loadingSize} />
        </Loading>
      )}
      {/* TODO: make children not clickable when loading. pointer-events: none might work */}
      <ChildrenContainer visible={!isLoading}>{children}</ChildrenContainer>
    </Container>
  );
};

LoadingSection.defaultProps = {
  isLoading: false,
  loadingSize: 100,
};

export default LoadingSection;
