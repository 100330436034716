import { useAppSelector } from 'hooks';
import React from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Button as ButtonStyled, Loading, ChildrenContainer } from './styles';

interface Props {
  children: React.ReactNode;
  variant?:
    | 'primary'
    | 'outlined'
    | 'subtle'
    | 'text'
    | 'header-active'
    | 'header';
  type?: 'button' | 'submit';
  disabled?: boolean;
  showLoading?: boolean;
  full?: boolean;
  visible?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  [rest: string]: any;
}

const Button: React.FC<Props> = ({
  children,
  type,
  onClick,
  disabled,
  full,
  visible,
  variant,
  showLoading,
  ...rest
}) => {
  const loadingState = useAppSelector(state => state.loading.isLoading);

  const isLoading = showLoading && loadingState;

  return (
    <ButtonStyled
      visible={visible}
      type={type}
      onClick={onClick}
      disabled={isLoading || disabled}
      full={full}
      variant={variant}
      {...rest}
    >
      {isLoading && (
        <Loading>
          <AiOutlineLoading3Quarters size={16} />
        </Loading>
      )}
      <ChildrenContainer visible={!isLoading}>{children}</ChildrenContainer>
    </ButtonStyled>
  );
};

Button.defaultProps = {
  variant: 'primary',
  type: 'button',
  disabled: false,
  onClick: () => {},
  full: false,
  visible: true,
  showLoading: true,
};

export default Button;
