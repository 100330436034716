import { Section } from 'components/molecules';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  checkoutStripePaymentRequest,
  clearCompanyApplicationInfo,
} from 'store/slices/company';
import Details from './Details';
import StripeCheckout from './StripeCheckout';
import { ErrorContainer } from './styles';

const CompanyApplication: React.FC = () => {
  const dispatch = useAppDispatch();
  const [application, setApplication] = useState(null);
  const [stripeLink, setStripeLink] = useState('');
  const [stripeErrors, setStripeErrors] = useState(null);
  const companyApplications = useAppSelector(
    state => state.user.companyApplications
  );
  const { id } = useParams();

  useEffect(() => {
    const foundedApplication = companyApplications.find(app => app.id === id);
    setApplication(foundedApplication);

    if (foundedApplication.application_status === 'pending_payment') {
      dispatch(
        checkoutStripePaymentRequest({
          companyApplicationId: foundedApplication.id,
          callback: (error, response) => {
            if (error) {
              setStripeErrors(error);
              return;
            }
            setStripeLink(response.link);
          },
        })
      );
    }

    return () => {
      dispatch(clearCompanyApplicationInfo());
    };
  }, []);

  if (!application) {
    return null;
  }

  if (stripeErrors) {
    return (
      <ErrorContainer>
        There was an error with the Stripe integration. Please Contact support
        or try again later.
      </ErrorContainer>
    );
  }

  if (application.application_status === 'pending_payment') {
    return (
      <StripeCheckout
        link={stripeLink}
        companyName={application.company_name}
      />
    );
  }

  return (
    <Section>
      <Section.Header
        icon="store"
        title={application.company_full_name}
        subtitle={application.type}
      />
      <Section.Content noPadding>
        <Details application={application} />
      </Section.Content>
    </Section>
  );
};

export default CompanyApplication;
