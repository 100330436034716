import styled from 'styled-components';

interface Props {
  step: number;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;

  .circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--neutrals-background-light);
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(${({ step }) => step}) {
      background-color: var(--primary-background-cta);
    }

    &.previous {
      background-color: var(--success-icon);
    }
  }
`;
