import React from 'react';

const ThemeContainer: React.FC = () => {
  React.useEffect(() => {
    // TODO: unmock dark theme
    // const storedTheme =
    //   localStorage.getItem('theme') ||
    //   (window.matchMedia('(prefers-color-scheme: dark)').matches
    //     ? 'catawba-dark'
    //     : 'catawba-light');

    const storedTheme = 'catawba-light';

    document.documentElement.setAttribute('data-theme', storedTheme);
  }, []);

  return <div />;
};

export default ThemeContainer;
