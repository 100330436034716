import styled from 'styled-components';

interface ContainerProps {
  variant: string;
  children: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = styled.div<ContainerProps>`
  //bg2
  padding: 40px;
  border-radius: 12px;
`;
