import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${mediaQuery.sm} {
    padding: 30px;
    text-align: center;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .poweredBy {
    margin-top: 8px;
    color: var(--neutrals-text-weak-passive);

    span {
      font-weight: 700;
    }
  }
`;

export const InvoiceContainer = styled.div`
  width: 60%;
  padding: 40px;
  border-radius: 8px;
  background-color: var(--select-background-light);
  color: var(--primary-text);

  h3 {
    font-size: 20px;
    line-height: 1.2em;
    font-weight: 600;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 12px;
  }

  .invoice {
    display: flex;
    justify-content: space-between;
  }

  .item {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  .value {
    min-width: 200px;
    text-align: right;
  }

  .bold {
    font-weight: 600;
  }

  .total {
    text-align: right;
  }

  button {
    margin-top: 20px;
  }

  .hint-catawba {
    font-size: 14px;
    text-align: right;
    color: var(--primary-text);
  }

  .invoiceId {
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ${mediaQuery.sm} {
    width: 100%;
    padding: 24px;

    .invoice {
      flex-direction: column;

      .billed {
        margin-top: 20px;
      }
    }
  }
`;

export const Divider = styled.div`
  margin: 40px 0 16px 0;
  height: 1px;
  width: 100%;
  background-color: var(--primary-border);

  ${mediaQuery.sm} {
    margin: 20px 0;
  }
`;
