import React from 'react';
import { Icon } from '../_common';

import { Container } from './styles';

interface Props {
  icon: string | React.ReactNode | any;
  isIconImage?: boolean;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
}

const LabelIconButton: React.FC<Props> = ({
  icon,
  label,
  onClick,
  disabled,
  selected,
  isIconImage,
}: Props) => {
  const renderIcon = () => {
    if (typeof icon === 'string' && !isIconImage) {
      return <Icon name={icon} />;
    }

    if (typeof icon === 'string' && isIconImage) {
      return <img src={icon} alt="Icon" />;
    }

    return icon;
  };

  return (
    <Container
      disabled={disabled}
      selected={selected}
      onClick={() => {
        if (onClick && !disabled) onClick();
      }}
    >
      <div className="label-icon">{renderIcon()}</div>
      <span className="label-text">{label}</span>
    </Container>
  );
};

LabelIconButton.defaultProps = {
  onClick: null,
  disabled: false,
  selected: false,
  isIconImage: false,
};

export default LabelIconButton;
