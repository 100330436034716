import {
  User,
  CompanyApplication,
  Company,
  Newsletter,
  Notification,
} from 'shared/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSFixMe } from '../../../shared/types/TSFixMe.d';

interface UserState {
  user: User;
  shouldRemember: boolean;
  newsletter: Newsletter;
  // TODO: Move companyApplications to their own slice
  companyApplications: CompanyApplication[];
  // TODO: remove duplicated entry for pinnedCompanies
  pinnedCompanies: Company[];
  // TODO: Move companies to their own slice
  companies: TSFixMe[];
  // TODO: Move the settings to their own slice
  settings: {
    language: string;
    primaryColor: string;
    pinnedCompanies: string[];
    theme: string;
  };
}

export const initialState = {
  user: null,
  shouldRemember: false,
  newsletter: [],
  companyApplications: [],
  pinnedCompanies: [],
  companies: [],
  settings: null,
} as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getNewsletterRequest: () => {},
    getNewsletterSuccess: (
      state,
      action: PayloadAction<{ newsletter: Newsletter }>
    ) => {
      state.newsletter = action.payload.newsletter;
    },
    setUser: (
      state,
      action: PayloadAction<{
        user: User;
        shouldRemember?: boolean;
        notifications?: Notification[];
      }>
    ) => {
      state.user = action.payload.user;
      state.shouldRemember = action.payload.shouldRemember || false;
    },
    confirmEmailRequest: (
      _,
      action: PayloadAction<{ auth: string; callback: () => void }>
    ) => {},
    resendConfirmationEmailRequest: (
      _,
      action: PayloadAction<{ email: string; callback?: () => void }>
    ) => {},
    getEmailConfirmationInfoRequest: (
      _,
      action: PayloadAction<{
        auth: string;
        callback: (emailInfo: { email: string; username: string }) => void;
      }>
    ) => {},
    getCompanyApplicationsRequest: () => {},
    getCompanyApplicationsSuccess: (
      state,
      action: PayloadAction<{
        companyApplications: CompanyApplication[];
      }>
    ) => {
      state.companyApplications = action.payload.companyApplications;
    },
    // FIXME: this should be on the company application slice
    cancelCompanyApplicationSuccess: (
      state,
      action: PayloadAction<{ companyApplicationId: string }>
    ) => {
      const index = state.companyApplications.findIndex(application => {
        return application.id === action.payload.companyApplicationId;
      });

      if (index !== -1) {
        state.companyApplications.splice(index, 1);
      }
    },
    getCompaniesRequest: () => {},
    getCompaniesSuccess: (
      state,
      action: PayloadAction<{ companies: any[] }>
    ) => {
      state.companies = action.payload.companies;
    },
    pinCompany: (state, action: PayloadAction<{ company: Company }>) => {
      if (!state.pinnedCompanies) {
        state.pinnedCompanies = [];
      }

      const selectedCompanyIndex = state.pinnedCompanies.findIndex(
        _company => _company.id === action.payload.company.id
      );

      const isAlreadyPinned = selectedCompanyIndex !== -1;

      if (isAlreadyPinned) {
        state.pinnedCompanies.splice(selectedCompanyIndex, 1);
        return;
      }

      state.pinnedCompanies.push(action.payload.company);
    },
    update2FARequest: (
      _,
      action: PayloadAction<{
        password: string;
        oldOtp: string;
        secret: string;
        callback: () => void;
      }>
    ) => {},
    updatePasswordRequest: (
      _,
      action: PayloadAction<{
        password: string;
        newPassword: string;
        otp: string;
      }>
    ) => {},
    getSettingsRequest: () => {},
    setSettingsRequest: (_, action: PayloadAction<any>) => {},
    settingsSuccess: (_, action: PayloadAction<any>) => {
      const settings = action.payload;
      // TODO: use the settings to save to store and manipulate the pinnedCompanies
    },
    createUserKYCRequest: (_, action: PayloadAction<any>) => {},
    submitUserKYCRequest: (_, action: PayloadAction<any>) => {},
    createUserKYCInternationalRequest: (_, action: PayloadAction<any>) => {},
    submitUserKYCInternationalRequest: (_, action: PayloadAction<any>) => {},
  },
});

export const {
  getNewsletterRequest,
  getNewsletterSuccess,
  setUser,
  confirmEmailRequest,
  resendConfirmationEmailRequest,
  getEmailConfirmationInfoRequest,
  getCompanyApplicationsRequest,
  getCompanyApplicationsSuccess,
  cancelCompanyApplicationSuccess,
  pinCompany,
  getCompaniesRequest,
  getCompaniesSuccess,
  update2FARequest,
  updatePasswordRequest,
  getSettingsRequest,
  setSettingsRequest,
  settingsSuccess,
  createUserKYCRequest,
  submitUserKYCRequest,
  createUserKYCInternationalRequest,
  submitUserKYCInternationalRequest,
} = userSlice.actions;

export default userSlice.reducer;
