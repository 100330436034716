import React from 'react';
import { Section } from 'components/molecules';
import { useIsMobileScreen } from 'hooks';
import { Link } from 'react-router-dom';
import { Container } from './styles';

interface Props {
  children: React.ReactNode;
  title: string;
  subtitle: any;
  iconName: string;
}

const Base: React.FC<Props> = ({
  children,
  title,
  subtitle,
  iconName,
}: Props) => {
  const isMobile = useIsMobileScreen();

  return (
    <Section>
      {isMobile && (
        <Link to="/login" id="already-login-mobile">
          Already have an account? Log in
        </Link>
      )}
      <Section.Header icon={iconName} title={title} subtitle={subtitle} />
      <Section.Content>
        <Container>{children}</Container>
      </Section.Content>
    </Section>
  );
};

export default Base;
