/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  isAuthenticated: boolean;
  sessionId: string;
  require2fa: boolean;
}

const initialState = {
  isAuthenticated: false,
  sessionId: null,
  require2fa: false,
} as AuthState;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Sign in
    signInRequest: (
      _,
      action: PayloadAction<{
        username: string;
        password: string;
        otp: string;
        remember: boolean;
        callback?: () => void;
      }>
    ) => {},
    require2fa: (state, action: PayloadAction<{ require2fa: boolean }>) => {
      state.require2fa = action.payload.require2fa;
    },
    signInSuccess: (state, action: PayloadAction<{ sessionId: string }>) => {
      state.isAuthenticated = true;
      state.sessionId = action.payload.sessionId;
      state.require2fa = false;
    },
    totpVerifyRequest: (
      _,
      action: PayloadAction<{
        secret: string;
        code: string;
        callback: () => void;
      }>
    ) => {},
    registerRequest: (_, action: PayloadAction<any>) => {},
    registerSuccess: (state, action: PayloadAction<{ sessionId: string }>) => {
      state.isAuthenticated = true;
      state.sessionId = action.payload.sessionId;
    },
    signOut: state => {
      state.isAuthenticated = false;
      state.sessionId = null;
    },
    // forgot password
    forgotPasswordRequest: (
      _,
      action: PayloadAction<{
        username?: string;
        email?: string;
      }>
    ) => {},
    resetPasswordRequest: (
      _,
      action: PayloadAction<{
        password: string;
        invitation: string;
        auth: string;
      }>
    ) => {},
    logoutRequest: () => {},
    logoutSuccess: state => {
      state.isAuthenticated = false;
      state.sessionId = null;
    },
  },
});

export const {
  require2fa,
  signInRequest,
  signInSuccess,
  registerRequest,
  registerSuccess,
  totpVerifyRequest,
  signOut,
  forgotPasswordRequest,
  resetPasswordRequest,
  logoutRequest,
  logoutSuccess,
} = authSlice.actions;

export default authSlice.reducer;
