export default {
  translation: {
    BUSINESS: {
      APPLICATION_NAME: 'Catawba Digital Economic Zone',
    },
    LOGIN: {
      LOGIN: 'Log in',
    },
    SIGN_UP: {
      SIGN_UP: 'Sign up',
      CREATE_AN_ACCOUNT: 'Create an account',
    },
    DASHBOARD: {
      DASHBOARD: 'Dashboard',
    },
    ERROR: {
      DEFAULT: 'An unexpected error happened. Verify your internet connection.',
      LOGIN_UNAUTHORIZED:
        'Unauthorized access. Verify your username and/or password.',
      LOGIN_OTP_INVALID: 'Invalid OTP verification code. Please try again.',
      REGISTER_USERNAME_EXISTS:
        'Username already exists, please try another one.',
      REGISTER_UNABLE_TO_SEND_USER_EMAIL:
        'There was an error trying to resend the email confirmation. Please try again later.',
      REGISTER_USER_EMAIL_UNIQUE_ERROR:
        'Unable to check for existing user with email. Please try again later.',
      REGISTER_LINK_GENERATION_ERROR:
        'We were unable to generate an account confirmation link for this user. Please try again later',
      TOTP_VERIFY_INVALID: 'Invalid TOTP verification code. Please try again.',
      FORGOT_PASSWORD_USER_NOT_FOUND:
        'We were not able to find an user for the given Username/E-mail. Please verify your information and try to recover your password again.',
      RESET_PASSWORD_UNAUTHORIZED:
        'Expired invitation. Request your password recovery again.',
      COMPANY_ALREADY_EXISTS:
        'Company already exists. This company name is already in use.',
      KYC_APPROVAL_REQUIRED:
        'Your account is not yet KYC approved. Please submit your KYC request and check again after approval.',
      TOTP_INVALID: 'Invalid TOTP verification code. Please try again.',
      UPDATE_PASSWORD_INVALID_OTP:
        'Invalid OTP verification code. Please try again.',
      UPDATE_PASSWORD_INVALID_PASSWORD:
        'Your password is invalid. Make sure you have entered the correct password.',
      UPDATE_PASSWORD_REPEATED_PASSWORD:
        'Your new password cannot be equal your old password. Please try again.',
      OTP_UPDATE_UNAUTHORIZED:
        'Password is invalid. Make sure you have entered the correct password.',
      OTP_UPDATE_FAILED: 'Failed to update OTP. Please try again.',
      COMPANY_DISSOLVE_REQUEST_ALREADY_EXISTS:
        'Company dissolve request already exists. You can only have one dissolve request at a time.',
      KYC_REQUEST_ALREADY_EXISTS:
        'KYC request already exists. Your KYC is being reviewed yet, please wait.',
      EMAIL_ALREADY_REGISTERED:
        'Email already registered. Please try again with another email.',
      INVITATION_ALREADY_SENT:
        'Invitation already sent. Please try again by sending it to another email.',
      INVITATION_COMPANY_NOT_FOUND: 'Company not found in your companies list.',
      INVITATION_MEMBER_IS_NOT_AUTHORIZED:
        'You are not authorized to check this company. You need to be Director or Admin.',
      INVITATION_COMPANIES_NOT_FOUND_FOR_THE_USER:
        "Companies not found. You don't have any company yet.",
    },
    SCHEMA: {
      REQUIRED: '{{field}} is a required field',
      LENGTH: '{{field}} must be {{length}} characters long',
      MIN_LENGTH: '{{field}} must have at least {{min}} characters',
      MAX_LENGTH: '{{field}} must have less than {{max}} characters',
      NUMBER: '{{field}} must have only numeric characters',
      PASSWORD_REQUIREMENTS:
        '{{field}} must have at least one uppercase, one lowercase, one number and one special character',
      EMAIL: 'This field should be a valid email',
      PASSWORD_MISMATCH: 'Passwords do not match',
      AGREEMENT_REQUIRED: 'You must agree to the terms and conditions',
      MIN_VALUE: '{{field}} must be greater than {{min}}',
      MAX_VALUE: '{{field}} must be less than {{max}}',
      GOVERNMENT_ID:
        'This field must be a valid SSN. Following this pattern: ###-##-####.',
      LETTERS_SPACE:
        '{{field}} must have only letters, space and hiphen characters',
      USERNAME_REGEX:
        'This field must have only letters, numbers, underscore and must be between 3 and 32 characters long',
      // PATTERN: 'This field must match the pattern {{pattern}}',
    },
  },
};
