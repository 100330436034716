import { VCStatusContainer } from './styles';

const VCStatus = ({ status }: { status: string }) => {
  const text = status
    ? status.charAt(0).toUpperCase() + status.slice(1)
    : 'Not Defined';

  return (
    <VCStatusContainer status={status}>
      <div className="ball" />
      <span>{text}</span>
    </VCStatusContainer>
  );
};

export default VCStatus;
