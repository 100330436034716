import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  cancelSubscriptionRequest,
  getSubscriptionDataRequest,
  reactivateSubscriptionRequest,
} from 'store/slices/company';
import { TextValue } from 'components/atoms';
import { Button } from 'components/atoms/_common';
import {
  formatCapitalizeFirstLetter,
  formatCurrency,
  formatDate,
} from 'util/formatting';

import { Container } from './styles';

interface Props {
  companyId: string;
}

const Subscription: React.FC<Props> = ({ companyId }: Props) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const dispatch = useAppDispatch();
  const viewingCompany = useAppSelector(state => state.company.viewingCompany);

  const getSubscriptionData = () => {
    const callback = data => {
      setSubscriptionData(data);
    };
    dispatch(getSubscriptionDataRequest({ companyId, callback }));
  };

  useEffect(() => {
    getSubscriptionData();
  }, []);

  const handleUpdatePaymentSubscription = () => {
    if (viewingCompany.updateStripeSubscriptionLink) {
      window.location.replace(viewingCompany.updateStripeSubscriptionLink);
    }
  };

  const handleCancelSubscription = () => {
    dispatch(
      cancelSubscriptionRequest({ companyId, callback: getSubscriptionData })
    );
  };

  const handleReactivateSubscription = () => {
    dispatch(
      reactivateSubscriptionRequest({
        companyId,
        callback: getSubscriptionData,
      })
    );
  };

  const shouldDisableCancelButton =
    subscriptionData?.active?.status !== 'active' ||
    subscriptionData.active?.status === 'canceled';

  const shouldDisableReactivateButton =
    subscriptionData?.active?.status !== 'canceled' ||
    subscriptionData.active?.status === 'active';

  return (
    <Container>
      <header>
        <h2>Company Registration Subscription</h2>
        <div className="buttons">
          {viewingCompany.updateStripeSubscriptionLink && (
            <Button variant="text" onClick={handleUpdatePaymentSubscription}>
              Update payment method
            </Button>
          )}
          <Button disabled>View registration history</Button>
        </div>
      </header>

      <section>
        {subscriptionData && (
          <>
            <TextValue
              text="Status"
              value={formatCapitalizeFirstLetter(
                subscriptionData.active?.status
              )}
            />
            <TextValue
              text="Last Registration"
              value={formatDate(subscriptionData.active?.lastRenewalDate)}
            />
            <TextValue
              text="Registration Renewal Date"
              value={formatDate(subscriptionData.active?.renewalDate)}
            />
            <TextValue
              text="Registration Renewal Price"
              value={formatCurrency(
                subscriptionData.active?.renewalPrice?.amount,
                subscriptionData.active?.renewalPrice?.currency
              )}
            />
          </>
        )}
      </section>

      <footer>
        <Button
          disabled={shouldDisableCancelButton}
          variant="text"
          onClick={handleCancelSubscription}
        >
          Cancel
        </Button>
        <Button
          disabled={shouldDisableReactivateButton}
          onClick={handleReactivateSubscription}
        >
          Reactivate
        </Button>
      </footer>
    </Container>
  );
};

export default Subscription;
