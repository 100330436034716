import styled from 'styled-components';

interface Props {
  noPadding: boolean;
}

export const Container = styled.div<Props>`
  width: 100%;
  height: 100%;
  flex: 1;

  padding: ${({ noPadding }) => (noPadding ? '0' : '0 32px 32px 32px')};
`;
