/* eslint-disable import/no-cycle */
import { all } from 'redux-saga/effects';

/*
FIXME: the following error makes no sense. There's no cycle in the imports.
- slices/auth/sagas => imports API
- API => imports slices/loading

but there's nothing in slices/loading using API (which would be a cycle).
I think the linter is considering that API => slices and slices => API (which is not true)
*/
import auth from './slices/auth/sagas';
import user from './slices/user/sagas';
import company from './slices/company/sagas';
import mapping from './slices/mapping/sagas';
import notification from './slices/notification/sagas';
import document from './slices/document/sagas';

export default function* rootSaga() {
  return yield all([auth, user, company, mapping, notification, document]);
}
