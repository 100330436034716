import { Container } from './styles';

interface Props {
  label: string;
  value: string;
}

const ActionValues: React.FC<Props> = ({ label, value }: Props) => {
  return (
    <Container>
      <span className="label">{label}</span>
      <div>
        <span className="value">{value}</span>
      </div>
    </Container>
  );
};

export default ActionValues;
