import React from 'react';
import { RegisterStepperProvider } from 'context/RegisterStepper';
import Stepper from './Stepper';
import {
  NameAddress,
  ReviewAgreement,
  TwoFactorAuth,
  UsernamePassword,
} from './Steps';

import { Container } from './styles';

const Register: React.FC = () => {
  return (
    <RegisterStepperProvider>
      <Container>
        <Stepper>
          <NameAddress />
          <UsernamePassword />
          <TwoFactorAuth />
          <ReviewAgreement />
        </Stepper>
      </Container>
    </RegisterStepperProvider>
  );
};

export default Register;
