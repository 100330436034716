import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { AnyAction, combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
// eslint-disable-next-line import/no-cycle
import rootSaga from './rootSaga';
import {
  auth,
  user,
  config,
  loading,
  company,
  mapping,
  notification,
  document,
} from './slices';
import { logoutRequest } from './slices/auth';
import { initialState as userInitialState } from './slices/user';

const reducers = combineReducers({
  auth,
  user,
  config,
  loading,
  company,
  mapping,
  notification,
  document,
});

const rootReducer = (state: ReturnType<typeof reducers>, action: AnyAction) => {
  if (action.type === logoutRequest.type) {
    storage.removeItem('persist:root');
    state = {
      user: {
        ...userInitialState,
        user: { username: state.user.user.username },
        shouldRemember: state.user.shouldRemember,
      },
    } as ReturnType<typeof reducers>;
  }

  return reducers(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk, sagaMiddleware],
});

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
