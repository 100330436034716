/* eslint-disable jsx-a11y/label-has-associated-control */
import ReactFlagsSelect from 'react-flags-select';
import { ALLOWED_COUNTRIES } from 'util/constants';
import { CountryContainer } from './styles';

interface Props {
  onSelect: (countryCode: string) => void;
  selected: string;
  label?: string;
}

const CountrySelect = ({ onSelect, selected, label }: Props) => {
  return (
    <CountryContainer>
      {label && <label htmlFor="rfs-btn">Country *</label>}
      <ReactFlagsSelect
        onSelect={onSelect}
        selected={selected}
        searchable
        countries={ALLOWED_COUNTRIES.sort()}
      />
    </CountryContainer>
  );
};

CountrySelect.defaultProps = {
  label: null,
};

export default CountrySelect;
