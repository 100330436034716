import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  width: 95%;

  height: 100%;

  padding: 40px;
  border-radius: 8px;
  background: var(--primary-background);

  overflow: auto;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    width: 0px;
    background: transparent;
  }

  ${mediaQuery.sm} {
    width: 100%;
    padding: 20px;
  }
`;

export const News = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background: var(--neutrals-background);
  border: 1px solid var(--primary-border);
  margin-bottom: 20px;

  h2 {
    color: var(--primary-text);
    font-size: 25px;
  }

  p {
    margin: 20px 0;
    font-family: 'Lato', sans-serif;
    line-height: 19.2px;
    color: var(--neutrals-text-weak-active);
  }

  img {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 20px;
  }
`;
