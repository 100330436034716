import React from 'react';
import Description from './Description';

import { Container } from './styles';

interface Props {
  currentStep: number;
}

const DESCRIPTIONS = [
  {
    title: 'Name & Address',
    text: 'Please enter your full legal name and mailing address.',
  },
  {
    title: 'Username & Password',
    text: 'Please choose a username and strong password.',
  },
  {
    title: 'Two-Factor Authentication',
    text: 'Please scan QR code with an authentictor app and enable 2FA for your account.',
  },
  {
    title: 'Review & Agreement',
    text: "Please make sure everything is correct and agree to Catawba Digital Economic Zone's Terms of Service.",
  },
];

const StepperDescription: React.FC<Props> = ({ currentStep }: Props) => {
  return (
    <Container>
      {DESCRIPTIONS.map((desc, idx) => (
        <Description
          key={desc.title.replace(/\s/g, '')}
          position={idx + 1}
          currentStep={currentStep}
          title={desc.title}
          text={desc.text}
        />
      ))}
    </Container>
  );
};

export default StepperDescription;
