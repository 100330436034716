import { CompanyApplication } from 'shared/types/CompanyApplication.d';
import { takeLatest, all, call, put } from 'redux-saga/effects';

// eslint-disable-next-line
import api, { endpoints } from 'services/API';
import { buildURL } from 'services/API/endpoints';
import { notifyError, notifySuccess } from 'services/notify';
import {
  confirmEmailRequest,
  resendConfirmationEmailRequest,
  getCompanyApplicationsRequest,
  getEmailConfirmationInfoRequest,
  getCompanyApplicationsSuccess,
  getCompaniesRequest,
  getCompaniesSuccess,
  getNewsletterRequest,
  getNewsletterSuccess,
  update2FARequest,
  updatePasswordRequest,
  getSettingsRequest,
  settingsSuccess,
  setSettingsRequest,
  createUserKYCRequest,
  submitUserKYCRequest,
  createUserKYCInternationalRequest,
  submitUserKYCInternationalRequest,
} from './index';

export function* getEmailConfirmationInfo({ payload: { auth, callback } }) {
  try {
    const url = buildURL(endpoints.get_email_confirmation_info, {
      query: { auth },
    });

    const { success, data } = yield call(api.get, url);

    if (success || data) {
      yield callback(data);
    }
  } catch (err) {
    notifyError(err.message);
  }
}

export function* confirmEmail({ payload: { auth, callback } }) {
  try {
    const url = buildURL('accept_confirm_email', { query: { auth } });

    const { success } = yield call(api.post, url);

    if (!success) {
      return;
    }

    notifySuccess(
      'Your email has been confirmed. You can now sign in with your account.'
    );
    callback();
  } catch (err) {
    notifyError(err.message);
  }
}

export function* resendConfirmationEmail({ payload: { callback } }) {
  try {
    const { success } = yield call(api.post, endpoints.register_resend);

    if (!success) {
      return;
    }

    notifySuccess(
      'A new email will be send soon. Please check out your SPAM as well.'
    );

    if (callback) {
      callback();
    }
  } catch (err) {
    notifyError(err.message);
  }
}

export function* getCompanyApplications() {
  try {
    const url = endpoints.get_company_applications;

    const { data, success } = yield call(api.get, url);

    if (!success) {
      return;
    }

    const companies: CompanyApplication[] = data;

    yield put(
      getCompanyApplicationsSuccess({
        companyApplications: companies,
      })
    );
  } catch (err) {
    notifyError(err.message);
  }
}

export function* getCompanies() {
  try {
    const url = endpoints.get_companies;

    const { data, success } = yield call(api.get, url);

    if (!success) {
      return;
    }

    const companies: CompanyApplication[] = data;

    yield put(getCompaniesSuccess({ companies }));
  } catch (err) {
    notifyError(err.message);
  }
}

export function* getNewsletter() {
  try {
    const url = endpoints.get_newsletters;

    const { data, success } = yield call(api.get, url);

    if (!success) {
      return;
    }

    yield put(getNewsletterSuccess({ newsletter: data.data }));
  } catch (err) {
    notifyError(err.message);
  }
}

export function* update2FA({
  payload: { password, oldOtp, secret, callback },
}) {
  try {
    const url = endpoints.update_otp;

    const { success } = yield call(api.post, url, {
      password,
      old_otp: oldOtp,
      newOtpSecret: secret,
    });

    if (!success) {
      return;
    }

    notifySuccess('Your 2FA has been updated.');

    callback();
  } catch (err) {
    notifyError(err.message);
  }
}

export function* updatePassword({ payload: { password, newPassword, otp } }) {
  try {
    const url = endpoints.password_update;

    const { success } = yield call(api.put, url, {
      password,
      newPassword,
      otp,
    });

    if (!success) {
      return;
    }

    notifySuccess('Your password has been updated.');
  } catch (err) {
    notifyError(err.message);
  }
}

export function* getSettings() {
  try {
    const url = endpoints.get_settings;

    const { data, success } = yield call(api.get, url);

    if (!success) {
      return;
    }

    yield put(settingsSuccess(data));
  } catch (err) {
    notifyError(err.message);
  }
}

export function* setSettings({
  payload: { pinnedCompanies, theme, primaryColor, language },
}) {
  try {
    const url = endpoints.get_settings;

    const { data, success } = yield call(api.post, url, {
      pinnedCompanies,
      theme,
      primaryColor,
      language,
    });

    if (!success) {
      return;
    }

    yield put(settingsSuccess(data));

    notifySuccess('Your settings have been updated.');
  } catch (err) {
    notifyError(err.message);
  }
}

export function* createUserKYCInternational({
  payload: { onSuccess, onKYCAlreadySubmitted, values },
}) {
  try {
    const url = endpoints.create_kyc_request_international;

    const { data, success } = yield call(api.post, url, {
      newKycRequest: values,
    });

    if (!success) {
      if (data.status === 'kyc/kyc_request_already_exists') {
        onKYCAlreadySubmitted();
      }
      return;
    }

    onSuccess(data.user);
  } catch (err) {
    notifyError(err.message);
  }
}

export function* submitUserKYCInternational({
  payload: { personalInformation, embedid_experience, callback },
}: SubmitUserKYCType) {
  try {
    const url = endpoints.submit_kyc_request_international;

    const { data, success } = yield call(api.post, url, {
      /*  
      first_name: personalInformation.firstName,
      middle_name: personalInformation.middleName,
      last_name: personalInformation.lastName,
      address: {
        house_number: personalInformation.houseNumber,
        street_name: personalInformation.streetName,
        street_type: personalInformation.streetType,
        unit: personalInformation.unitApNumber,
        city: personalInformation.city,
        state: personalInformation.state,
        zip: personalInformation.zipCode,
        country: 'US',
      },
      government_id: personalInformation.governmentId,
      dob: personalInformation.dateOfBirth, 
      */
      newKycRequest: {
        ...personalInformation,
      },
      embedid_experience,
    });

    if (!success) {
      return;
    }

    notifySuccess('Your KYC request has been submitted successfully!');

    callback();
  } catch (err) {
    notifyError(err.message);
  }
}

export function* createUserKYC({
  payload: { onSuccess, onKYCAlreadySubmitted },
}) {
  try {
    const url = endpoints.create_kyc_request;

    const { data, success } = yield call(api.post, url);

    if (!success) {
      if (data.status === 'kyc/kyc_request_already_exists') {
        onKYCAlreadySubmitted();
      }
      return;
    }

    onSuccess(data.user);
  } catch (err) {
    notifyError(err.message);
  }
}

interface SubmitUserKYCType {
  payload: {
    personalInformation: any;
    callback: any;
    embedid_experience: string;
  };
}

export function* submitUserKYC({
  payload: { personalInformation, embedid_experience, callback },
}: SubmitUserKYCType) {
  try {
    const url = endpoints.submit_kyc_request;

    const { data, success } = yield call(api.post, url, {
      first_name: personalInformation.firstName,
      middle_name: personalInformation.middleName,
      last_name: personalInformation.lastName,
      address: {
        house_number: personalInformation.houseNumber,
        street_name: personalInformation.streetName,
        street_type: personalInformation.streetType,
        unit: personalInformation.unitApNumber,
        city: personalInformation.city,
        state: personalInformation.state,
        zip: personalInformation.zipCode,
        country: 'US',
      },
      government_id: personalInformation.governmentId,
      dob: personalInformation.dateOfBirth,
      embedid_experience,
    });

    if (!success) {
      return;
    }

    notifySuccess('Your KYC request has been submitted successfully!');

    callback();
  } catch (err) {
    notifyError(err.message);
  }
}

export default all([
  takeLatest(confirmEmailRequest as any, confirmEmail),
  takeLatest(resendConfirmationEmailRequest as any, resendConfirmationEmail),
  takeLatest(getEmailConfirmationInfoRequest as any, getEmailConfirmationInfo),
  takeLatest(getCompanyApplicationsRequest as any, getCompanyApplications),
  takeLatest(getCompaniesRequest as any, getCompanies),
  takeLatest(getNewsletterRequest as any, getNewsletter),
  takeLatest(update2FARequest as any, update2FA),
  takeLatest(updatePasswordRequest as any, updatePassword),
  takeLatest(getSettingsRequest as any, getSettings),
  takeLatest(setSettingsRequest as any, setSettings),
  takeLatest(createUserKYCRequest as any, createUserKYC),
  takeLatest(submitUserKYCRequest as any, submitUserKYC),
  takeLatest(
    createUserKYCInternationalRequest as any,
    createUserKYCInternational
  ),
  takeLatest(
    submitUserKYCInternationalRequest as any,
    submitUserKYCInternational
  ),
]);
