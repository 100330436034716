import { useState } from 'react';
import { useIsMobileScreen, useNotifications } from 'hooks';

import Sidebar from './Sidebar';
import Appbar from './Appbar';

import { Container, Content, Overlay } from './styles';

interface Props {
  children: React.ReactNode;
  blanked?: boolean;
}

const PrivateLayout = ({ children, blanked }: Props) => {
  useNotifications();
  const isMobileScreen = useIsMobileScreen();
  const [mobileSidebarActive, setMobileSidebarActive] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  if (blanked) return <main>{children}</main>;

  return (
    <Container>
      <Appbar onClickMenu={() => setMobileSidebarActive(s => !s)} />
      <Sidebar
        isExpanded={sidebarOpen}
        setExpanded={setSidebarOpen}
        isVisible={(mobileSidebarActive && isMobileScreen) || !isMobileScreen}
      />
      <Overlay
        isSidebarActive={mobileSidebarActive}
        onClick={() => setMobileSidebarActive(false)}
      />
      <Content>{children}</Content>
    </Container>
  );
};

PrivateLayout.defaultProps = {
  blanked: false,
};

export default PrivateLayout;
