import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const BlankedContainer = styled.main``;

export const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  & > aside,
  & > section {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a.internal {
    text-decoration: none;
    color: var(--select-text);

    &.bold {
      font-weight: 700;
    }
  }

  section.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: auto;
    margin-bottom: 40px;

    & .iconCircle {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px;
      border-radius: 50%;
      border: 2px solid var(--primary-background-cta);
      margin-bottom: 40px;

      .icon {
        color: var(--primary-background-cta);
        font-size: 48px;
      }
    }

    & > h2 {
      font: 400 39px/1.2em 'Oswald', sans-serif;
      margin-bottom: 12px;
    }
  }

  section.linkBottom {
    display: block;
    margin-top: auto;
    text-align: center;

    a {
      margin-left: 10px;
    }
  }

  ${mediaQuery.sm} {
    aside {
      display: none;
    }
  }
`;

export const Logo = styled.aside`
  background-color: var(--neutrals-background-light);

  img {
    width: 476px;
  }
`;

export const Content = styled.section`
  background-color: var(--neutrals-background);
`;
