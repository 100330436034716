import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--primary-background);
  border: 1px solid var(--neutrals-border);
  padding: 20px;
  border-radius: 4px;

  & > h2 {
    font-size: 20px;
    line-height: 1.2em;
    color: var(--neutrals-text);
  }

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttons {
      display: flex;
      align-items: center;

      button:first-of-type {
        margin-right: 20px;
      }
    }
  }

  & > section {
    margin: 40px 0;
  }

  & > footer {
    display: flex;
    justify-content: end;

    button:first-of-type {
      margin-right: 20px;
    }
  }
`;
