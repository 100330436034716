/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { get, useController } from 'react-hook-form';
import cn from 'classnames';
import { useFormContext } from '../Form';

import {
  Container,
  SelectInput,
  Label,
  DropdownList,
  DropdownListItem,
  ErrorSpan,
} from './styles';

interface Props {
  name: string;
  options: { id: string; label: string }[];
  label: string;
  multiple?: boolean;
  optional?: boolean;
  onSelect?: (value: string | string[]) => void;
  hideDefaultOption?: boolean;
  className?: string;
  required?: boolean;
}

const Select: React.FC<Props> = ({
  name,
  options,
  label,
  multiple,
  optional,
  onSelect,
  hideDefaultOption,
  className,
  required,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { control, errors, setValue } = useFormContext();

  const {
    field: { onChange, value, onBlur },
  } = useController({
    control,
    name,
    defaultValue: multiple ? [] : '',
    rules: { required },
  });

  const handleClickDefault = () => {
    onChange('');
    setShowDropdown(s => !s);
    onBlur();
  };

  const handleClickOption = (optionId: string) => {
    if (multiple) {
      if (value.includes(optionId)) {
        onChange(value.filter(v => v !== optionId));
        onSelect(value.filter(v => v !== optionId));
      } else {
        onChange([...value, optionId]);
        onSelect([...value, optionId]);
      }
      onBlur();
      return;
    }

    onChange(optionId);
    setValue(name, optionId);
    onSelect(optionId);
    setShowDropdown(s => !s);
    onBlur();
  };

  const hasErrors = !!get(errors, name);

  const valueToDisplay = () => {
    if (Array.isArray(value)) {
      return value.map(v => options.find(o => o.id === v)?.label).join(', ');
    }

    return options.find(o => o.id === value)?.label || 'Select';
  };

  return (
    <Container
      className={cn(`input ${className}`, {
        error: hasErrors,
        success: !hasErrors,
      })}
    >
      <Label>{optional ? label : `${label} *`}</Label>
      <SelectInput
        isDropdownOpen={showDropdown}
        className="select-input"
        onClick={() => setShowDropdown(s => !s)}
      >
        {valueToDisplay()}
      </SelectInput>

      {showDropdown && (
        <DropdownList>
          {!hideDefaultOption && (
            <DropdownListItem onClick={handleClickDefault}>
              Select
            </DropdownListItem>
          )}
          {options.map(option => (
            <DropdownListItem
              className={value?.includes(option.id) ? 'selected' : ''}
              onClick={() => handleClickOption(option.id)}
              key={option.id}
            >
              {option.label}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}

      <ErrorSpan visible={hasErrors}>
        {get(errors, name) && get(errors, name).message}
      </ErrorSpan>
    </Container>
  );
};

Select.defaultProps = {
  multiple: false,
  optional: false,
  onSelect: () => {},
  hideDefaultOption: false,
  className: '',
  required: false,
};

export default Select;
