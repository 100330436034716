import { useAppDispatch, useAppSelector } from 'hooks';
import Status, { StatusActionEvent, StatusEvents } from 'pages/public/Status';

import useEventListener from 'hooks/useEventListener';

import { resendConfirmationEmailRequest } from 'store/slices/user';

const EmailConfirmationStatus: React.FC = () => {
  const { email } = useAppSelector(state => state.user.user);

  const dispatch = useAppDispatch();

  useEventListener(
    document,
    StatusEvents.Action,
    (e: CustomEvent<StatusActionEvent>) => {
      if (e.detail.kind === 'email-resend') {
        dispatch(resendConfirmationEmailRequest({ email }));
      }
    }
  );

  return <Status kind="register/email-confirmation" type="failure" />;
};

export default EmailConfirmationStatus;
