import React, { useEffect } from 'react';

import CatawbaLogo from 'assets/images/catawba-logo.svg';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useIsMobileScreen } from 'hooks';
import { CloseSidebarOnClick } from 'components/atoms/_util';
import { CompanyCard } from 'components/molecules';
import {
  getCompanyApplicationsRequest,
  getCompaniesRequest,
} from 'store/slices/user';
import { Container, Logo, NotificationMarker } from './styles';
import NavItem from './NavItem';
import UserPill from './UserPill';
import CollapsePill from './CollapsePill';

interface Props {
  isExpanded: boolean;
  setExpanded?: (expanded: boolean) => void;
  isVisible?: boolean;
}

const EMAIL_ADDRESS_SUPPORT = 'contact@zoneauthority.io';

const Sidebar: React.FC<Props> = ({
  isExpanded,
  setExpanded,
  isVisible,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobileScreen();

  const pinnedCompanies = useAppSelector(state => state.user.pinnedCompanies);
  const { unreadNotifications } = useAppSelector(state => state.notification);

  useEffect(() => {
    dispatch(getCompanyApplicationsRequest());
    dispatch(getCompaniesRequest());
  }, []);

  const navigateToSupport = async () => {
    window.location.href = `mailto:${EMAIL_ADDRESS_SUPPORT}`;
  };

  return (
    <Container
      isVisible={isVisible}
      isOpen={isExpanded}
      className="sidebar-container"
    >
      <Logo isOpen={isExpanded}>
        <img src={CatawbaLogo} alt="Catawba logo" />
      </Logo>
      <section>
        <CloseSidebarOnClick>
          <NavItem
            isOpen={isExpanded}
            name="Dashboard"
            icon="dashboard"
            onClick={() => navigate('/dashboard')}
          />
        </CloseSidebarOnClick>

        <CloseSidebarOnClick>
          <NavItem
            isOpen={isExpanded}
            name="Companies"
            icon="corporate_fare"
            onClick={() => navigate('/companies')}
          />
        </CloseSidebarOnClick>

        {pinnedCompanies &&
          pinnedCompanies.map(company => (
            <CompanyCard key={company.id} company={company} />
          ))}

        <CloseSidebarOnClick>
          <NavItem
            isOpen={isExpanded}
            name="Notifications"
            icon="notifications"
            onClick={() => navigate('/notifications')}
          >
            {unreadNotifications && unreadNotifications.length > 0 && (
              <NotificationMarker isOpen={isExpanded}>
                {unreadNotifications.length}
              </NotificationMarker>
            )}
          </NavItem>
        </CloseSidebarOnClick>

        <CloseSidebarOnClick>
          <NavItem
            isOpen={isExpanded}
            name="Settings"
            icon="settings"
            onClick={() => navigate('/settings')}
          />
        </CloseSidebarOnClick>

        <CloseSidebarOnClick>
          <NavItem
            isOpen={isExpanded}
            name="DID Management"
            icon="badge"
            onClick={() => navigate('/did-management')}
          />
        </CloseSidebarOnClick>

        <NavItem
          isOpen={isExpanded}
          name={
            <>
              <div>Support</div>
              <div className="email-support">{EMAIL_ADDRESS_SUPPORT}</div>
            </>
          }
          icon="support_agent"
          onClick={() => navigateToSupport()}
        />
      </section>

      <section>
        <UserPill isOpen={isExpanded} />
        {!isMobile && (
          <CollapsePill
            isOpen={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
          />
        )}
      </section>
    </Container>
  );
};

Sidebar.defaultProps = {
  isVisible: true,
  setExpanded: () => {},
};

export default Sidebar;
