import { Container } from './styles';

// FIXME: This is a temporary solution to get the terms of service;
// we should just display the link to the Catawba website:
// https://catawbadigital.zone/terms-of-service/

/**
 * Temporary solution to render and view the Terms of Service.
 *
 * Content is hardcoded.
 *
 * Content from: https://catawbadigital.zone/terms-of-service/
 */
const TermsOfService = () => {
  return (
    <Container>
      <p style={{ textAlign: 'center' }}>
        <b>GREEN EARTH ZONE&nbsp;</b>
        <b>TERMS OF SERVICE&nbsp;</b>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Welcome to the Green Earth Zone!&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          These are the terms of service (“Terms”) between you and Green Earth
          Zone (“GEZ” “we,” “us,” and “our”). You acknowledge that you have
          read, comprehend, and agree to all of the terms and conditions stated
          in this agreement by using the site and/or registering for a GEZ
          Account or service through our site. Before using this website,
          including any online features, contents, services, or programs offered
          on, by, or through this website, such as any text, materials,
          documents, images, graphics, logos, design, audio, video, and other
          information, offerings, or services provided from or on, uploaded to,
          and/or downloaded from the Website, please carefully read the
          following Terms (collectively, the “Website”) by or on behalf of the
          Green Earth Zone, a special economic zone created under United states
          law and under CATAWBA law (a Catawba Indian sovereign jurisdiction
          created under Tribal law of the Catawba Nation), Green Earth Zone
          Inc., a Catawba corporation, Nest Financial Group Inc., a Utah
          corporation, eTribe Inc., a Wyoming corporation, Seaphia SAS, a
          Colombian company, and Catawba Corporations, a Catawba corporation
          (collectively “GEZ Group” or “Counterparty”). As used in these Terms,
          references to “affiliate” include owners, licensees, assigns,
          subsidiaries, affiliated companies, officers, directors, suppliers,
          partners, sponsors, advertisers, and includes (without limitation) all
          parties involved in creating, producing, licensing, and/or delivering
          all or any part of this Website. “Us” or “we” refer to each relevant
          Counterparty.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          In order to enter into the terms, conditions, obligations,
          affirmations, representations, and warranties set forth in these Terms
          and to abide by and comply with these Terms, you hereby represent and
          warrant that you are at least 18 years old, an emancipated minor, or
          the possess legal parental or guardian consent.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We reserve the right to make modifications to the Website, these
          Terms, and/or the services covered by these Terms at any time without
          prior notice. You will be deemed to have acknowledged and accepted the
          updated Terms if you continue to use this website after such
          modifications.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          If you do not intend to be bound by these terms and conditions, please
          leave this website. By using this website, you agree to be bound by
          these terms.
        </span>
      </p>
      <p>
        <i>
          <span style={{ fontWeight: 400 }}>
            Your Consent and Agreement to This Agreement.{' '}
          </span>
        </i>
        <span style={{ fontWeight: 400 }}>
          By accessing and using the Website, you consent to and agree to be
          bound by these GEZ Terms of Service (“Agreement”). If we decide to
          change these Terms, your use of the Website and/or the Online Service
          following any amendment of these Terms will signify your consent to
          and acceptance of its revised terms, and such amendment shall be
          deemed a part of the Agreement which supersedes any conflicting
          previous provision.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          <strong>1. CONVENIENCE AND INFORMATION ONLY;</strong> ACCEPTANCE OF
          TERMS. By merely providing access to the Website, no Counterparty
          warrants or represents that: (a) the Website is accurate, complete, up
          to-date or current; (b) any of them have any obligation to update the
          Website; (c) the Website is free from technical inaccuracies or
          typographical errors; (d) the Website does not infringe on the
          intellectual property rights of any third party; (e) that the Website
          is free from changes caused by a third party; (f) your access to the
          Website will be free from interruptions, errors, computer viruses or
          other harmful components; and (g) any information obtained in response
          to questions asked through, or postings made on, the Website is
          accurate or complete. Your use of the Website and the services offered
          therein are subject to Applicable Law. You understand and acknowledge
          that we cannot promise or guarantee specific results from using the
          Website. You understand and agree that temporary interruptions of the
          Website may occur as normal events that are out of our control. You
          also understand and agree that we have no control over the third-party
          networks or service(s) that we may use to provide you with the
          Website. You agree that all offerings available on the Website are
          provided “AS IS” and that we assume no responsibility for the
          timeliness, deletion, mis-delivery, or failure to store any user
          communications or personalization settings.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <b>
          <span style={{ fontWeight: 400 }}>
            <strong>2. </strong>
          </span>
          SITE USE AND CONTENT.
        </b>{' '}
        You may view, copy, or print pages from the Website solely for personal,
        non-commercial purposes or other purposes authorized herein. You may not
        otherwise use, modify, copy, print, display, reproduce, distribute, or
        publish any information from the Website without the express, prior,
        written consent of Counterparty. You are solely responsible for
        providing, at your own expense, all equipment necessary to use the
        services, including a computer and modem; and your own Internet access
        (including payment of service fees associated with such access).
      </p>
      <p>&nbsp;</p>
      <p>
        <b>
          3. USER ACCOUNT, PASSWORD, SECURITY AND ELECTRONIC ACCOUNT SERVICES
        </b>
        <strong>.</strong>
      </p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong>a. DEFINITIONS.</strong> The following definitions govern the
          terms of this Paragraph 3 and as used elsewhere in these Terms:
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Payment Cutoff Time means 5:00 p.m. eastern Standard
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Time on any Business Day and is the time by which you must
                    transmit Payment Instructions for such Payment Instructions
                    to be considered effective for that particular Business Day.
                    Business Day means every day except Saturdays and
                    Sundays.&nbsp;
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Linked Accounts means accounts associated with the GEZ
                    Personal Registry, and the GEZ Entity Registry which may be
                    interactive or concurrently accessible by you through the
                    same username and password.
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Payment Account means your personal or business checking,
                    savings, or other financial account, such as a credit card,
                    from which payments may be made by you.
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Payee means the relevant Counterparty to which you direct a
                    payment. This will be determined by the particular component
                    of the Online Services you access and posted terms specific
                    to that component.
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Payment Instructions means the information provided by you
                    for a payment to be made to Payee (i.e., banking account
                    number, ABA number, payment date, payment amount, and any
                    additional information).
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Payment Due Date means the Business Day upon which your
                    payment will be received by Payee and your Payment Account
                    will be debited. Note: Your Payment Due Date may be no later
                    than the “Due Date” set by Counterparty for your most
                    current bill.
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Pending Payment means a pending payment authorized by you
                    through the Online Service that has not been debited to your
                    Payment Account.
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    KYC – AML consists of a “Know your Customer / Anti-money
                    laundering” procedure.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong>b.</strong>{' '}
        </span>
        <strong>ONLINE ACCOUNT ACCESS.</strong> For certain types of features
        available through the Website, including the Online Account Access
        features (the “Online Service”’), we will either have a public access
        service, whereby any member of the public will have access to the Online
        Service (“Public Access Service”) or we will require the use of
        encryption technologies provided for your protection and/or your use of
        a user identification name (“UserlD”) and password after setting up a
        user account (“User Account”) for your Personal Registry, which wiII be
        private and will require a mandatory two-factor authentication (“Private
        Access Service”). For self-enrollment into the Private Access Service,
        you must provide Account-specific information to authenticate yourself.
        In these Terms, as to the Private Access Service, “you” and “your” refer
        to each person, or, if applicable, the entity who is an owner, signer,
        or has access to a User Account and each person that uses the Online
        Service with your permission (“Authorized User”). For the Entity
        Registry, you must submit the information required by Applicable Law and
        agree to comply with the applicable regulations to the entity type
        pursuant to the Applicable Law. As to the Private Access Service, you
        may never use another person’s User Account and/or UserlD with
        registering yourself as an Authorized User on that User Account. When
        your Online Service is linked to one or more Accounts, Counterparty may
        act on the written, or electronic instructions of any authorized signer
        regarding your service for those Accounts. It is your responsibility to
        notify Counterparty if an Authorized User should no longer be given
        access to an Account through the Online Service.
      </p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          {' '}
          <strong>c.</strong> <strong>USER ACCOUNT AND PASSWORD.</strong> You
          are ultimately responsible for protecting your password and User
          Account information from disclosure to third parties, and you are not
          permitted to circumvent the use of required encryption technologies.
          You are required to take reasonable precautions to safeguard your
          password. You agree to never leave your computer unattended while
          using the Online Service and to always exit the Online Service by
          clicking on “Log Out.” You also agree to immediately notify
          Counterparty of any unauthorized use of your UserlD, password and/or
          User Account, or any other breach of security by email are solely
          responsible for any activity that occurs with respect to your User
          Account and UserlD. We do not and cannot guarantee or warrant that
          information transmitted through the Internet is secure, or that such
          transmissions are free from delay, error, interception, or
          interruption.
        </span>
      </p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          {' '}
          <strong>d. RELIANCE BY COUNTERPARTY.</strong> You will be bound by and
          authorize Counterparty to rely on your UserlD and password to identify
          you when you use the Online Service, and as signature authorization
          for any payment made using the Online Service. You acknowledge and
          agree that you are responsible for all payments you make using the
          Online Service and for paying any and all late charges or penalties.{' '}
        </span>
        <span style={{ fontWeight: 400 }}>
          You will also be bound by and authorize Counterparty to rely upon your
          acceptance of any posted terms specifically relating to the component
          of the Online Service accessed (such as the non-refundability of
          payments, except as may be expressly waived by Counterparty) to the
          extent such posted terms are consistent with these Terms.
        </span>
        <span style={{ fontWeight: 400 }}>
          {' '}
          You also acknowledge and agree that if you permit another person or
          persons to use the Online Service or give them your UserlD and/or
          password, you are responsible for any payment that person makes to
          your Account, even if the person exceeds your authorization. You agree
          that Counterparty may comply with the Payment Instructions entered by
          any person using your UserlD and Password in accordance with these
          Terms.
        </span>
      </p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong>e.</strong>{' '}
        </span>
        <strong>SECURITY.</strong> Counterparty may, but is not obligated, to
        use several different security methods to protect your Account and User
        Account information, including:
      </p>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    You can only access the Online Service with certain browsers
                    that have high security standards.
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    The Online Service will require that you carry out a
                    two-factor authentication to access the Online Account.
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    The Online Service will automatically log off if prolonged
                    periods of inactivity occur.
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    In order to set up access with your Online Account you will
                    need to be verified through a KYC – AML process.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p style={{ paddingLeft: '40px' }}>
        <strong>f. ELECTRONIC COMMUNICATION.</strong>
      </p>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Email and Online Message Center. When you enroll in the
                    Private Access Service component of the Online Service, you
                    must designate a primary email address that will be used for
                    receiving electronic communication; To the extent that
                    Counterparty maintains an online message center now or in
                    the future, such exchange of communications shall be
                    considered an electronic communication and may be utilized
                    by Counterparty for providing you notices pursuant to these
                    Terms, as required by Applicable Law (as permitted) or
                    generally regarding your Account(s) with Counterparty.
                    Counterparty will NEVER send you an email requesting
                    confidential information such as Account numbers, usernames,
                    or passwords and you should NEVER respond to any email
                    requesting such information. If you receive such an email
                    purportedly from Counterparty, do not respond to the email
                    and notify Counterparty by email to{' '}
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    techsupport@catawbadigital.zone.
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Usage of Electronic Communication. By your enrollment in the
                    Private Access Service component of the Online Service, you
                    agree to receive these Terms and any disclosures or notices
                    required by Applicable Law and all other communications
                    electronically to the email address you designate in your
                    account profile. You also agree that Counterparty may
                    respond to any communication you send to Counterparty with
                    an electronic communication, regardless of whether your
                    original communication with Counterparty was an electronic
                    communication. Any electronic communication Counterparty
                    sends to you will be considered received within 7 calendar
                    days of the date such communication is sent by computer
                    servers utilized by Counterparty to the email address you
                    designate in your account profile or posted to
                    Counterparty’s online message center (if applicable). Any
                    electronic communication you send to Counterparty will not
                    be effective until Counterparty has had a reasonable
                    opportunity to act on the electronic communication. You
                    should not rely on electronic communication if you need to
                    communicate with Counterparty immediately and, in no event,
                    should your sole method of communication with Counterparty
                    regarding any emergency be by electronic communication.
                    Counterparty may require you to provide written confirmation
                    of any verbal or electronic notice of alleged error by
                    Counterparty.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          {' '}
          <strong>g.</strong> <strong>PERMISSIBLE ACTIVITIES.</strong> You may
          perform the following activities with the Online Service as available,
          subject to posted terms specific to the service, whether you are using
          the Public Access Service or Private Access Service component of the
          Online Service,, as determined by the GEZ.
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Access Services. You may access the available services
                    related to the GEZ Entity Registry, and Personal Registry,
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Account Inquiry. You may retrieve Account information (such
                    as a balance due).
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    You may receive alert messages for certain circumstances.
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Review Invoices. You may view electronic version of one or
                    more Account Invoices.&nbsp;
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    Payments. You may make payments to your Account from your
                    Payment Account. Payments may only be made from credit
                    cards, checking, or savings accounts and must be made in
                    accordance with the terms of these Terms and any other
                    applicable account agreements.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong>h.</strong>{' '}
        </span>
        <strong>PAYMENTS.</strong> You authorize Counterparty to debit your
        Payment Account and remit funds on your behalf to the Payee. When
        Counterparty receives a Payment Instruction, you have authorized
        Counterparty to debit your Payment Account and remit funds on your
        behalf so that the funds arrive as close as reasonably possible to the
        Payment Due Date as designated by you. You also authorize Counterparty
        to credit your Payment Account for payments returned to you by
        Counterparty.
      </p>
      <p>&nbsp;</p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong>i.</strong>{' '}
          <strong>DISCLOSURE OF ACCOUNT INFORMATION.</strong> Counterparty may
          disclose information to third parties about you, including email
          addresses, or your Account, in the situations described below and as
          otherwise set forth in Counterparty’s Privacy Policy. We will disclose
          such information:
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    where disclosure is necessary for completing payments, or to
                    resolve a problem related to a payment;
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    to verify the condition and existence of your Account for a
                    third party, such as a credit bureau or merchant;
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    to persons authorized by law in the course of their official
                    duties, including but not limited to persons authorized by
                    the Applicable Law;
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    to comply with a government agency, final arbitral award, or
                    court order, such as a lawfuI subpoena;
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    to Counterparty employees, auditors, service providers,
                    attorneys, or collection agents in the course of their
                    duties; or
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    if you give Counterparty written permission
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>&nbsp;</span>
      </p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong>j.</strong>{' '}
        </span>
        <strong>INSUFFICIENT FUNDS TO COMPLETE PAYMENT.</strong> You must have
        sufficient available funds or credit in your Payment Account on the
        Payment Due Date. If your Payment Account has insufficient funds, the
        Payment will not be completed. Should a Payment fail because of
        insufficient funds in your Payment Account, an amount equal to
        Counterparty’s Returned Payment Fee then in effect will be applied to
        your Account. If, for any reason, your Payment Account company declines
        or otherwise refuses to pay the amount owed for the offerings or
        services you have purchased, you agree that we may, at our option,
        suspend or terminate your access to the GEZ Entity Registry and
        associated entity services and use of the Website and may require you to
        pay any overdue amounts incurred (including any third-party chargeback
        fees or penalties) by other means acceptable to us. In the event legal
        action is necessary to collect on balances due, you agree to reimburse
        us for all expenses incurred to recover sums due, including attorney
        fees and other legal expenses.
      </p>
      <p>&nbsp;</p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong>k.</strong>{' '}
        </span>
        <strong>
          COUNTERPARTY’S LIABILITY FOR FAILURE TO COMPLETE PAYMENTS.
        </strong>{' '}
        If Counterparty debits your Payment Account incorrectly, Counterparty
        will be responsible for returning the improperly debited funds to your
        Payment Account. However, Counterparty will not be liable in the
        following situations:
      </p>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span style={{ fontWeight: 400 }}>
                    You did not properly follow the provisions of these Terms,
                    the online instructions for the Online Service, or other
                    instructions for making a payment;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Through no fault of Counterparty’s, you have insufficient
                    funds in your Payment Account to make a payment;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Your computer, the software, phone lines, Counterparty’s
                    computer system or the Online Service were not working
                    properly or were temporarily unavailable, and this problem
                    was or reasonably should have been apparent to you when you
                    attempted the payment or you were advised by the Online
                    Service of the malfunction before you executed the payment;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Circumstances beyond our control prevented the payment,
                    despite reasonable precautions that we have taken, including
                    telecommunications outages, fires, floods, or other natural
                    disasters;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Counterparty has reasonable basis to believe that
                    unauthorized use of your UserlD, Password, or User Account
                    or Account has occurred or may be occurring;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Counterparty or you have terminated your Online Service or
                    closed your Account to which the UserlD was linked;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    You have supplied your login information to another party;
                    or
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    For any other reason specified in these Terms or any other
                    agreement Counterparty has with you.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong>l.</strong> <strong>ACCURATE INFORMATION.</strong> In creating
          and using your User Account on the Website, you agree to: (i) provide
          true, accurate, current, and complete information about yourself on
          any registration form required on the Website (such information being
          the “Registration Data”); and (ii) maintain and promptly update the
          Registration Data to keep it true, accurate, current, and complete. If
          you provide any information that is untrue, inaccurate, not current or
          incomplete, or Counterparty has reasonable grounds to suspect that
          such information is untrue, inaccurate, not current or incomplete,
          Counterparty has the right to suspend or terminate your User Account
          and refuse any and all current or future use of your User Account.
        </span>
      </p>
      <p style={{ paddingLeft: '40px' }}>
        <span style={{ fontWeight: 400 }}>
          {' '}
          <strong>m.</strong> <strong>TERMINATION OF ACCOUNT.</strong> Subject
          to the terms of this Agreement, Counterparty reserves the right to
          terminate your use of the Online Service for any reason including
          inactivity and at any time without notice to you. You have the right
          to terminate your use of the Online Service by writing to Counterparty
          at{' '}
        </span>
        <span style={{ fontWeight: 400 }}>techsupport@catawbadigital.zone</span>
        <span style={{ fontWeight: 400 }}>
          . Any termination of your use of the Online Service, whether initiated
          by you or by Counterparty, will not affect any of your or
          Counterparty’s rights and obligations under these Terms that have
          arisen before the effective date of such termination. Your termination
          of Online Service will not effectuate a termination of any applicable
          Agreement.{' '}
        </span>
      </p>
      <p style={{ paddingLeft: '40px' }}>
        <strong>n.</strong> <strong>ERRORS OR QUESTIONS ABOUT PAYMENTS.</strong>{' '}
        In case of errors or questions about Payments, you should notify
        Counterparty at once by email at techsupport@catawbadigital.zone or
        through the online message center (if applicable).
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          4. WEBSITE CONDUCT, POSTING POLICIES &amp; THIRD-PARTY WEBSITES
        </strong>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <strong>a. THIRD-PARTY WEBSITES AND INFORMATION.</strong> This Website
        may redirect or link to other websites on the Internet, or may otherwise
        include references to information, products or services made available
        by unaffiliated third parties. While we make every effort to work with
        trusted, reputable providers, from time to time such sites may contain
        information, material, or policies that some may find inappropriate or
        personally objectionable. You understand that we are not responsible for
        the accuracy, completeness, decency, or legality of content hosted by
        third party websites, nor are we responsible for errors or omissions in
        any references made on those websites. The inclusion of such a link or
        reference is provided merely as a convenience and does not imply
        endorsement of, or association with the Website or party by us, or any
        warranty of any kind, either express or implied.
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <strong>b. PROMOTIONS.</strong> From time to time, this Website may
        include advertisements offered by third parties. You may enter into
        correspondence with or participate in promotions of the advertisers
        showing their products on this site. Any such correspondence or
        promotions, including the delivery of and the payment for goods and
        services by those third parties, and any other terms, conditions,
        warranties, or representations associated therewith, are solely between
        you and the advertiser. We assume no liability, obligation, or
        responsibility for any part of any such correspondence or promotion.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5. INTELLECTUAL PROPERTY</strong>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          {' '}
          <strong>a. CONTENT.</strong> For purposes of these Terms, “content” is
          defined as any information, communications, software, published works,
          photos, video, graphics, music, sounds, or other material that can be
          viewed, used, or accessed by users on our Website and is owned or
          licensed by one or all of Counterparty.
        </span>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          {' '}
          <strong>b. OWNERSHIP OF CONTENT.</strong> By accepting these Terms,
          you agree that all content presented to you on this Website is
          protected by any and aII intellectual property and/or other
          proprietary rights available within the GEZ and protected by treaties
          governing the Catawba Indian Nation and is the sole original or
          licensed property of one or all of Counterparty. Without limitation,
          the following are registered trademarks, trademarks, or service marks
          of GEZ Group All custom graphics, icons, logos, and service names are
          registered trademarks, trademarks, or service marks of one or all of
          Counterparty by ownership or license. Nothing in these Terms grants
          you any right to use any trademark, service mark, logo, and/or the
          name of any Counterparty. Certain of the ideas, software and processes
          incorporated into the offerings and services available on this Website
          are protected by patent applications pending in the United States and
          elsewhere, and we may prepare and file additional patent applications
          in selected foreign jurisdictions.
        </span>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          {' '}
          <strong>c.</strong> <strong>LIMITATIONS ON USE OF CONTENT.</strong>{' '}
          Except for a single copy made for personal use, you may not copy,
          reproduce, modify, republish, upload, post, transmit, or distribute
          any content from this Website in any form or by any means whatsoever
          without prior written permission from us. Any unauthorized use of
          Website content violates our intellectual property interests and could
          result in criminal or civil penalties.
        </span>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          {' '}
          <strong>d. NO WARRANTY FOR THIRD-PARTY INFRINGEMENT.</strong> Neither
          we or our Affiliates warrant or represent that your use of materials
          displayed on, or obtained through, this Website will not infringe the
          rights of third parties.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>6. CONTENT YOU CREATE OR PROVIDE.</strong>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <strong>a. CONFIDENTIAL INFORMATION.</strong> As stated above, all
        communications sent by you to us will be treated as non-confidential and
        non-proprietary (subject to our Privacy Policy). Please do not submit
        confidential or proprietary information to us (including patentable
        ideas, new content suggestions or business proposals) unless we have
        mutually agreed in writing otherwise. Ideas that we receive unsolicited
        will be treated as property owned by GEZ and will not be returned to
        you.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong> 7. EMAIL SERVICES &amp; DATA STORED</strong>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <strong>a. EMAIL SERVICE.</strong> We may make email services available
        to users of our Website, either directly or through a third-party
        provider. We will not inspect or disclose the contents of private email
        messages except with the consent of the sender or the recipient, or in
        the narrowly-defined situations provided under Applicable Law or by
        court or governmental order. Further information is available in our
        Privacy Policy.
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <strong>b. </strong>STORAGE PROVIDED BY US. All personal data or Content
        that is transmitted through or created on the Website is handled and
        stored by or at the direction of the GEZ, under Applicable Law as
        governmental authorities. If you store personal data of any kind with
        us, you understand and agree to abide by the relevant posted terms. You
        agree that we have no responsibility or liability for the deletion or
        failure to store any content maintained or transmitted on or through
        this Website. You acknowledge that we reserve the right to suspend
        and/or limit access to the GEZ Entity Registry accounts which have not
        made any required payment, that remain inactive for longer than TWO (2)
        YEARS, or in cases where you have violated one or more Terms.
      </p>
      <p style={{ paddingLeft: '20px' }}>&nbsp;</p>
      <p>
        <strong>8. DISCLAIMER</strong>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          THIS WEBSITE AND ALL CONTENT, SERVICES AND OFFERINGS ON THIS WEBSITE
          ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTY
          OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
          THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
          PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT WITHOUT LIMITING THE
          FOREGOING, WE MAKE NO WARRANTY THAT (A) THE CONTENT OR OFFERINGS WILL
          MEET YOUR REQUIREMENTS, (B) THE CONTENT, OFFERINGS OR WEBSITE WILL BE
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (C) THE RESULTS THAT MAY
          BE OBTAINED FROM THE USE OF THE CONTENT OR OFFERINGS OFFERED WILL BE
          EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE QUALITY OF ANY CONTENT OR
          OFFERINGS PURCHASED OR OBTAINED BY YOU FROM THE WEBSITE FROM US OR OUR
          AFFILIATES WILL MEET YOUR EXPECTATIONS OR BE FREE FROM MISTAKES,
          ERRORS OR DEFECTS.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          THIS WEBSITE COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES
          OR TYPOGRAPHICAL ERRORS. WE MAY MAKE CHANGES TO THE CONTENT AND
          OFFERINGS AT THIS WEBSITE, INCLUDING THE PRICES AND DESCRIPTIONS OF
          ANY PRODUCTS OR OFFERINGS LISTED HEREIN, AT ANY TIME WITHOUT NOTICE.
          THE CONTENT OR PRODUCTS AVAILABLE AT THIS WEBSITE MAY BE OUT OF DATE,
          AND WE MAKE NO COMMITMENT TO UPDATE SUCH CONTENT OR PRODUCTS.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          COUNTERPARTY DOES NOT GUARANTEE THAT YOU WILL BE ABLE TO ACCESS OR USE
          THE WEBSITE AND/OR THE ONLINE SERVICE AT TIMES OR LOCATIONS OF YOUR
          CHOOSING, OR THAT COUNTERPARTY SHALL HAVE ADEQUATE CAPACITY FOR THE
          WEBSITE AND/OR THE ONLINE SERVICE AS A WHOLE OR IN ANY SPECIFIC
          GEOGRAPHIC AREA.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          THE USE OF THE OFFERINGS OR THE DOWNLOADING OR OTHER ACQUISITION OF
          ANY SERVICES, PRODUCTS OR CONTENT THROUGH THIS WEBSITE IS DONE AT YOUR
          OWN DISCRETION AND RISK AND WITH YOUR AGREEMENT THAT YOU WILL BE
          SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
          DATA THAT RESULTS FROM SUCH ACTIVITIES.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          BY USING THE WEBSITE, YOU MAY HAVE THE OPPORTUNITIES TO ENGAGE IN
          COMMERCIAL TRANSACTIONS WITH OTHER USERS AND VENDORS. YOU ACKNOWLEDGE
          THAT ALL TRANSACTIONS RELATING TO ANY PRODUCTS, SERVICES OR OFFERINGS
          PROVIDED BY ANY THIRD PARTY, INCLUDING, BUT NOT LIMITED TO THE
          PURCHASE TERMS, PAYMENT TERMS, WARRANTIES, GUARANTEES RELATING TO SUCH
          TRANSACTIONS, ARE AGREED TO SOLELY BETWEEN THE SELLER OF SUCH
          MERCHANDISE, SERVICES AND YOU.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          GEZ MAKES NO WARRANTY REGARDING ANY TRANSACTIONS EXECUTED THROUGH A
          THIRD PARTY, OR IN CONNECTION WITH THIS WEBSITE, AND YOU UNDERSTAND
          AND AGREE THAT SUCH TRANSACTIONS ARE CONDUCTED ENTIRELY AT YOUR OWN
          RISK. ANY WARRANTY THAT IS PROVIDED IN CONNECTION WITH ANY OFFERINGS
          OR CONTENT AVAILABLE ON OR THROUGH THIS WEBSITE FROM A THIRD PARTY IS
          PROVIDED SOLELY BY SUCH THIRD PARTY, AND NOT BY US OR ANY OTHER OF OUR
          AFFILIATES.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          WE RESERVE THE SOLE RIGHT TO EITHER MODIFY OR DISCONTINUE THE WEBSITE,
          INCLUDING ANY OFFERINGS, SERVICES OR FEATURES THEREIN, AT ANY TIME
          WITH OR WITHOUT NOTICE TO YOU. WE SHALL NOT BE LIABLE TO YOU OR ANY
          THIRD PARTY SHOULD WE EXERCISE SUCH RIGHT. MODIFICATIONS MAY INCLUDE,
          BUT ARE NOT LIMITED TO, CHANGES IN THE PRICING STRUCTURE, THE ADDITION
          OF FREE OR FEE-BASED SERVICES, OR CHANGES TO LIMITATIONS ON ALLOWABLE
          CONTENT, FILE SIZES OR FILE TYPES. ANY NEW FEATURES THAT AUGMENT OR
          ENHANCE THE THEN-CURRENT OFFERINGS ON THIS WEBSITE SHALL ALSO BE
          SUBJECT TO THESE TERMS OF SERVICE. SOME JURISDICTIONS MAY NOT ALLOW
          THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS
          MAY NOT APPLY TO YOU. PLEASE CONSULT THE LAWS IN YOUR JURISDICTION.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>&nbsp;</span>
      </p>
      <p>
        <strong>9. LIMITATION OF LIABILITY &amp; INDEMNIFICATION</strong>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          YOUR EXCLUSIVE REMEDY AND OUR ENTIRE LIABILITY, IF ANY, FOR ANY CLAIMS
          ARISING OUT OF THESE TERMS AND YOUR USE OF THIS WEBSITE SHALL BE
          LIMITED TO THE AMOUNT YOU PAID US FOR OFFERINGS PURCHASED ON THE
          WEBSITE DURING THE TWELVE (12) MONTH PERIOD BEFORE THE ACT GIVING RISE
          TO THE LIABILITY, EXCEPT AS MAY OTHERWISE BE PROVIDED BY YOUR
          AGREEMENT.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          IN NO EVENT SHALL ANY OF COUNTERPARTY BE LIABLE TO YOU OR ANY THIRD
          PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR CONSEQUENTIAL
          DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT
          LIMITATION, THOSE RESULTING FROM LOSS OF USE, DATA OR PROFIT LOSS,
          WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES, AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN
          CONNECTION WITH THE USE OF THIS WEBSITE OR OF ANY WEBSITE REFERENCED
          OR LINKED TO FROM THIS WEBSITE; INCLUDING, BUT NOT LIMITED TO, (A)
          ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (B) PERSONAL INJURY OR
          PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS
          TO AND USE OF THE WEBSITE, THE ONLINE SERVICE AND/OR ANY SERVICE
          PROVIDED IN CONNECTION WITH THE WEBSITE, (C) ANY UNAUTHORIZED ACCESS
          TO OR USE OF OUR COMPUTER SERVERS AND/OR ANY AND ALL PERSONAL
          INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (D) ANY
          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE, THE
          ONLINE SERVICE AND/OR ANY SERVICE PROVIDED IN CONNECTION WITH THE
          WEBSITE, AND/OR (E) ANY VIRUSES, WORMS, TIME BOMBS, DROP DEAD DEVICES,
          TROJAN HORSES OR OTHER HARMFUL COMPONENTS THAT MAY BE TRANSMITTED TO
          OR THROUGH THE WEBSITE, THE ONLINE SERVICE AND/OR ANY SERVICE PROVIDED
          IN CONNECTION WITH THE WEBSITE BY ANY THIRD PARTY OR FOR ANY LOSS OR
          DAMAGE OF ANY KIND. (F) ANY LIABILITY FOR ANY OF USER´S TAXATION
          OBLIGATIONS UNDER ANY STATE OR FEDERAL STATUTE. BECAUSE SOME STATES OR
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
          FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR
          JURISDICTIONS COUNTERPARTY’S LIABILITY WILL BE LIMITED TO THE GREATEST
          EXTENT PERMITTED BY LAW.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          FURTHER, WE SHALL NOT BE LIABLE IN ANY WAY FOR THIRD PARTY PROMISES
          REGARDING OUR OFFERINGS OR CONTENT OR FOR ASSISTANCE IN CONDUCTING
          COMMERCIAL TRANSACTIONS WITH THE THIRD PARTY THROUGH THIS WEBSITE,
          INCLUDING WITHOUT LIMITATION THE PROCESSING OF ORDERS.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          FURTHERMORE, YOU ACKNOWLEDGE THAT RELATED ENTITIES AND PERSONS ARE OR
          COULD BE IN POSITIONS OF CONTROL AND AUTHORITY OVER ANY OR ALL OF
          COUNTERPARTY, AND EACH HAS OR MAY DERIVE A PECUNIARY GAIN DIRECTLY OR
          INDIRECTLY FROM DECISIONS MADE BY A RELATED ENTITY IN CONNECTION WITH
          THE WEBSITE. IN RECOGNITION OF THE PRACTICAL NECESSITY OF SUCH JOINT
          CONTROL AND AUTHORITY DUE TO THE ORGANIZATIONAL AND DEVELOPMENTAL
          PHASE IN WHICH THE GEZ IS OPERATING, AND ITS “STARTUP GOVERNMENT”
          NATURE, YOU WAIVE AND DISCLAIM ANY CLAIM THAT A BREACH OF CONTRACT,
          BREACH OF FIDUCIARY DUTY, OR FIDUCIARY FRAUD WOULD RESULT OR SHOULD BE
          PRESUMED BASED ON ANY ACTUAL OR APPARENT CONFLICT OF INTEREST THAT HAS
          ARISEN OR MAY ARISE IN ANY WAY, WHETHER DIRECTLY OR INDIRECTLY, FROM
          THE CONTINUATION OF JOINT CONTROL AND AUTHORITY OVER ANY OR ALL OF
          COUNTERPARTY BY RELATED ENTITIES AND PERSONS.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          YOU AGREE TO DEFEND, INDEMNIFY AND HOLD COUNTERPARTY INCLUDING
          SUBSIDIARIES, OWNERS, DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS
          HARMLESS FROM AND AGAINST ANY AND ALL CLAIMS, DEMANDS, SUITS,
          PROCEEDINGS, LIABILITIES, JUDGMENTS, LOSSES, DAMAGES, EXPENSES AND
          COSTS (INCLUDING WITHOUT LIMITATION REASONABLE ATTORNEYS’ FEES)
          ASSESSED OR INCURRED BY COUNTERPARTY, DIRECTLY OR INDIRECTLY, WITH
          RESPECT TO OR ARISING OUT OF: (I) YOUR FAILURE TO COMPLY WITH THESE
          TERMS; (II) YOUR BREACH OF YOUR OBLIGATIONS UNDER THESE TERMS; (III)
          YOUR USE OF THE RIGHTS GRANTED HEREUNDER, INCLUDING WITHOUT LIMITATION
          ANY CLAIMS MADE BY ANY THIRD PARTIES; AND/OR (IV) YOUR VIOLATION OF
          ANY THIRD PARTY RIGHT, INCLUDING WITHOUT LIMITATION ANY COPYRIGHT,
          PROPERTY, OR PRIVACY RIGHT. WE RESERVE THE RIGHT, AT OUR OWN EXPENSE,
          TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE
          SUBJECT TO INDEMNIFICATION BY YOU, IN WHICH EVENT YOU WILL COOPERATE
          WITH US IN ASSERTING ANY AVAILABLE DEFENSES.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          COUNTERPARTY DOES NOT INTEND TO PROVIDE ANY INVESTMENT OR TAX ADVICE
          OR INFORMATION RELATING TO ITSELF OR ANY COUNTERPARTY IDENTIFIED ON
          THE WEBSITE, NOR DOES IT CONSIDER USERS CONTENT, POSTS OR COMMENTS TO
          BE INVESTMENT OR TAX ADVICE. NEVERTHELESS, THE WEBSITE MAY, FROM TIME
          TO TIME, CONTAIN INFORMATION ON BUSINESS AND DIGITAL ASSET REGULATION,
          OR FINANCIAL CONDITION OF CERTAIN OTHER INDIVIDUALS AND COMPANIES,
          ENTITIES INCLUDING USERS FOR WHICH USERS MUST FIND APPROPRIATE LEGAL
          COUNSEL. COUNTERPARTY CAUTIONS THAT THERE ARE VARIOUS IMPORTANT
          FACTORS THAT COULD CAUSE ACTUAL RESULTS TO DIFFER MATERIALLY FROM
          THOSE INDICATED IN THE INFORMATION YOU MAY ENCOUNTER ON THE WEBSITE.
          ACCORDINGLY, THERE CAN BE NO ASSURANCE THAT SUCH INDICATED RESULTS
          WILL BE REALIZED. THESE FACTORS INCLUDE, AMONG OTHER THINGS,
          LEGISLATIVE AND REGULATORY INITIATIVES REGARDING REGULATION OF
          COMPANIES AND DIGITAL ASSETS; POLITICAL AND ECONOMIC CONDITIONS AND
          DEVELOPMENTS IN CATAWBA LAND AND LAND UNDER TRUST IN OTHER STATES IN
          WHICH THE ENTITIES AND COMPANIES DISCUSSED UNDER THIS AGREEMENT AND ON
          THE WEBSITE OPERATE; TAX REGULATIONS AND FINANCIAL MARKET CONDITIONS
          AND THE RESULTS OF FINANCING EFFORTS; AND CHANGES IN TAXATION, REAL
          ESTATE AND COMMODITY PRICES AND INTEREST RATES.
        </span>
      </p>
      <p>
        <strong>10. TERMINATION OF USE</strong>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong> a. GROUNDS FOR TERMINATION.</strong> You agree that we may,
          at our sole discretion, terminate or suspend your access to all or
          part of the Website with or without notice and for any reason,
          including, without limitation, breach of these Terms, non-compliance
          with the Applicable Law and in the event that an entity is dissolved
          and/or terminated pursuant to the Applicable Law. Any suspected
          fraudulent, abusive, or illegal activity may be grounds for barring
          your access to this Website, and reporting you to the proper
          authorities, if necessary. However, the foregoing is subject to the
          terms of your Agreement.
        </span>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          {' '}
          <strong>b. NO RIGHT TO OFFERINGS UPON TERMINATION.</strong> Upon
          termination and regardless of the reason(s) motivating such
          termination, your right to use the services and offerings available on
          this Website will immediately cease. We shall not be liable to you or
          any third party for any claims for damages arising out of any
          termination or suspension or any other actions taken by us in
          connection therewith. Liability for any unpaid fees and any
          intellectual property rights license granted by you to us shall
          survive any termination.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>11. MISCELLANEOUS PROVISIONS</strong>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <strong>a. EXTERNAL TO GEZ/INTERNATIONAL USE.</strong> Although this
        Website may be accessible worldwide, we make no representation that
        materials on this Website are appropriate or available for use in
        locations outside the United States Those who choose to access this
        Website from other locations do so on their own initiative and at their
        own risk. If you choose to access this Website from outside the United
        States, you are responsible for compliance with local laws in your
        jurisdiction, including but not limited to, the taxation of products and
        services purchased over the Internet. Any offer for any product,
        service, and/or information made in connection with this Website is void
        where prohibited. Access to the Website from jurisdictions where such
        access is illegal is prohibited. You further agree to comply with all
        applicable laws regarding the transmission of technical data exported
        from the GEZ and the jurisdiction in which you reside (if different from
        the United States).
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong>b. APPLICABLE LAW.</strong> This Website (excluding any Third
          Party websites) is controlled by the GEZ, and the law promulgated by
          and applicable in the GEZ and the Catawba Nation shall be controlling,
          such as the Civil Zone Ordinance, the CATAWBA commercial code
          Sections, without regard to the conflicts of laws principles thereof,
          legal or political instability precludes direct application of GEZ
          Law, then you shall be contractually bound to be governed by the rules
          published at https://zoneauthority.io to the furthest extent permitted
          by CATAWBA Tribal law (all of the foregoing shall be deemed the
          “Applicable Law”). You agree and hereby submit to the exclusive
          personal jurisdiction and venue of the Catawba Nation Tribal Court as
          provided by the Catawba Nation Arbitration Ordinance, as amended from
          time to time. The Website and the Online Service shall be deemed a
          passive website and service that does not give rise to personal
          jurisdiction over any Counterparty outside of the GEZ. Any controversy
          or claim arising out of or relating to this contract, or the breach
          thereof, shall be settled by arbitration administered by the American
          Arbitration Association in accordance with its Commercial Arbitration
          Rules, as may be applicable, and judgment on the award rendered by the
          arbitrator(s) may be entered in the Catawba Nation Tribal Court.
        </span>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong> c. NO RESALE RIGHT.</strong> You agree not to sell, resell,
          reproduce, duplicate, distribute, copy, or use for any commercial
          purposes any portion of this Website, or use of or access to this
          Website or services, products or offerings provided through this
          Website, beyond the limited rights granted to you under these Terms.
        </span>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          {' '}
          <strong>d.</strong> <strong>FORCE MAJEURE.</strong> In addition to any
          excuse provided by applicable law, we shall be excused from liability
          for non-delivery or delay in delivery of products, services and
          offerings available through our Website arising from any event beyond
          our reasonable control, whether or not foreseeable by either party,
          including but not limited to: labor disturbance, war, fire, accident,
          adverse weather, inability to secure transportation, governmental act
          or regulation, and other causes or events beyond our reasonable
          control, whether or not similar to those which are enumerated above.
        </span>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong> e. SAVINGS CLAUSE.</strong> If any part of these Terms is
          held invalid or unenforceable, that portion shall be construed in a
          manner consistent with applicable law to reflect, as nearly as
          possible, the original intentions of the parties, and the remaining
          portions shall remain in full force and effect.
        </span>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong> f. NO WAIVER</strong>. Any failure by us to enforce or
          exercise any provision of these Terms or related rights shall not
          constitute a waiver of that right or provision.
        </span>
      </p>
      <p style={{ paddingLeft: '20px' }}>
        <span style={{ fontWeight: 400 }}>
          <strong> g. ENTIRE AGREEMENT.</strong> Your Account linked to the
          Online Service will also be governed by the agreements, disclosures,
          and other documents provided to you in connection with the opening of
          your Account, as they may be amended periodically, as well as any
          posted terms specifically relating to the component of the Online
          Service accessed. If any inconsistency exists between such other
          documentation and these Terms, then these Terms shall control to the
          extent of inconsistency. Otherwise, these terms and conditions
          constitute the entire agreement and understanding between the parties
          concerning the subject matter hereof and supersedes all prior
          agreements and understandings of the parties with respect thereto, as
          well as the posted terms governing the specific component of the
          Online Services that you may purchase, if any. These Terms may NOT be
          altered, supplemented, or amended by the use of any other document(s)
          except as expressly authorized herein. To the extent that anything in
          or associated with this Website is in conflict or inconsistent with
          these Terms, these Terms shall take precedence.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          <strong> 12. PRIVACY.</strong> Personal data that you provide
          regarding yourself will be handled in accordance with Counterparty’s
          Privacy Policy as may be linked to this Website (“Privacy Policy”).
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          <strong>13. AVAILABILITY.</strong> Information that Counterparty
          publishes in the Website may contain references or cross-references to
          products, programs or services of Counterparty that are not
          necessarily announced or available in your area. Such references do
          not mean that Counterparty will announce any of those products,
          programs, or services in your area at any time in the future. You
          should contact Counterparty for information regarding the products,
          programs and services that may be available to you, if any.
        </span>
      </p>
      <p>
        <strong>14. ON-TRANSFERABILITY OF USER ACCOUNT.&nbsp;</strong>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          User Accounts and UserlDs are non-transferable, and all users are
          obligated to take preventative measures to prohibit unauthorized users
          from accessing the Website with his or her UserlD and password. You
          may not assign these Terms, in whole or in part, or delegate any of
          your responsibilities hereunder to any third party. Any such attempted
          assignment or delegation will not be recognized by Counterparty unless
          acknowledged by Counterparty in writing. Counterparty has no
          obligation to provide you with written acknowledgment. Counterparty
          may, at any time and in its sole discretion, assign these Terms, in
          whole or in part, or delegate any of our rights and responsibilities
          under these Terms to any third party or entity.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          <strong>15. CUSTOMER COMMENTS</strong>. We welcome the submission of
          comments, information, or feedback through the Website. By submitting
          information through the Website, you agree that the information
          submitted shall be subject to the Counterparty Privacy Policy.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          <strong> 16. ENGLISH VERSION CONTROLLING.</strong> These Terms may be{' '}
        </span>
        <span style={{ fontWeight: 400 }}>
          translated into Spanish and other languages; however, the English
          version of these Terms shall be controlling.
        </span>
      </p>{' '}
    </Container>
  );
};

export default TermsOfService;
