import { Button, DropdownInput, Form, Input } from 'components/atoms/_common';
import ListItem from 'components/atoms/_common/DropdownInput/renderers/ListItem/styles';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { signInRequest } from 'store/slices/auth';
import { triggerTestRequest } from 'store/slices/mapping';
import HANDLERS from 'util/mapping';
import { LOGIN_SCHEMA } from 'util/schemas';

import { Container } from './styles';

interface Props {}

const ITEMS = Object.keys(HANDLERS).map(key => ({ id: key }));
const Mapping: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);

  const [response, setResponse] = useState(null);
  const [selectedHandler, setSelectedHandler] = useState(null);
  const [customPayload, setCustomPayload] = useState('');

  const handleSignIn = ({ username, password, otp }) => {
    dispatch(signInRequest({ username, password, remember: true, otp }));
  };

  const handleMakeRequest = ({ handler }) => {
    const callback = data => {
      setResponse({ data });
    };

    const dispatchHandler = {
      ...HANDLERS[handler],
      payload: customPayload
        ? JSON.parse(customPayload)
        : HANDLERS[handler].payload,
    };

    dispatch(triggerTestRequest({ handler: dispatchHandler, callback }));
  };

  useEffect(() => {
    if (selectedHandler && selectedHandler.payload) {
      const { payload } = selectedHandler;
      setCustomPayload(JSON.stringify(payload, null, 4));
    }
  }, [selectedHandler]);

  return (
    <Container>
      <div className="signIn">
        <span className="status">
          Status:{' '}
          <span className={isAuthenticated ? 'success' : 'failure'}>
            {isAuthenticated ? 'SIGNED IN' : 'SIGNED OUT'}
          </span>
        </span>
        <Form schema={LOGIN_SCHEMA} onSubmit={handleSignIn}>
          <Input name="username" label="Username" placeholder="Your username" />
          <Input
            name="password"
            label="Password"
            type="password"
            placeholder="Your password"
            submitOnEnter
          />
          <Input name="otp" label="OTP" optional />

          <Button type="submit" full>
            Submit
          </Button>
        </Form>
      </div>
      <Form
        schema={{}}
        onSubmit={handleMakeRequest}
        defaultValues={(selectedHandler && selectedHandler.payload) || null}
      >
        <DropdownInput
          name="handler"
          hint="Select a function"
          placeholder="Select a function"
          items={ITEMS}
          itemRenderer={({ item, onItemClick }) => (
            <ListItem onClick={() => onItemClick(item.id)}>
              <span className="state-id">{item.id}</span>
            </ListItem>
          )}
          onSelect={item => setSelectedHandler(HANDLERS[item.id])}
          optional
        />

        {/* {selectedHanlder && (
          <div>
            {Object.keys(selectedHanlder.payload).map(key => (
              <div key={key}>
                <Input name={key} label={key} />
              </div>
            ))}
          </div>
        )} */}

        <textarea
          name="inputData"
          rows={12}
          cols={60}
          value={customPayload}
          onChange={e => setCustomPayload(e.target.value)}
        />

        {response && (
          <div className="response">
            <pre>{JSON.stringify(response, null, 2)}</pre>
          </div>
        )}

        <Button type="submit" full>
          Make Request
        </Button>
      </Form>
    </Container>
  );
};

Mapping.defaultProps = {};

export default Mapping;
