/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';

import { ScopedSection, Table } from 'components/atoms';
import { useAppDispatch } from 'hooks';
import { Member } from 'shared/types/Member';
import {
  getCompanyCaptableRequest,
  getCompanyMembersRequest,
} from 'store/slices/company';
import { Button } from 'components/atoms/_common';
import { GetCaptable } from 'shared/types';
import NamedSection from '../_common/NamedSection';
import { Container } from './styles';
import CreateModal from './CreateModal';

const Captables = ({ companyId }: { companyId: string }) => {
  const dispatch = useAppDispatch();
  const [createCaptableModalOpen, setCreateCaptableModalOpen] = useState(false);
  const [captable, setCaptable] = useState<GetCaptable>(null);
  const [members, setMembers] = useState<Member[]>([]);

  useEffect(() => {
    dispatch(
      getCompanyCaptableRequest({
        companyId,
        callback: setCaptable,
      })
    );
    dispatch(
      getCompanyMembersRequest({
        companyId,
        callback: setMembers,
      })
    );
  }, []);

  return (
    <ScopedSection scope="director">
      <>
        {createCaptableModalOpen && (
          <CreateModal
            members={members}
            onClose={() => setCreateCaptableModalOpen(false)}
            fetchCaptable={() =>
              dispatch(
                getCompanyCaptableRequest({
                  companyId,
                  callback: setCaptable,
                })
              )
            }
            companyId={companyId}
          />
        )}
        <NamedSection name="Captables" iconName="format_list_numbered">
          <Container>
            {captable?.captable?.length > 0 ? (
              <Table>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Percentage of share (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {captable?.captable?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.username}</td>
                      <td>{item.name}</td>
                      <td>{item.role}</td>
                      <td>{item.common_amount / 100}%</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="not-found">
                <p>No captables found yet.</p>
              </div>
            )}
            <Button
              className="create-captable-btn"
              onClick={() => setCreateCaptableModalOpen(true)}
            >
              Create New Captable
            </Button>
          </Container>
        </NamedSection>
      </>
    </ScopedSection>
  );
};

export default Captables;
