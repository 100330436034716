import {
  require2fa,
  resetPasswordRequest,
  signInRequest,
  signInSuccess,
  forgotPasswordRequest,
  registerRequest,
  totpVerifyRequest,
  logoutSuccess,
  logoutRequest,
} from 'store/slices/auth';
/* eslint-disable */

import {
  Name,
  TwoFactorAuth,
  UsernamePassword,
} from 'context/RegisterStepper/types';

import { takeLatest, all, call, put } from 'redux-saga/effects';

import api, { endpoints } from 'services/API';
import { buildURL } from 'services/API/endpoints';
import { notifyError, notifySuccess } from 'services/notify';
import { setUser } from '../user';

export function setToken({ payload }) {
  if (!payload) return;

  const { sessionId } = payload.auth;

  if (!sessionId) return;

  api.defaults.headers['Gez-Csrf-Token'] = sessionId;
}

export function* reset2fa() {
  yield put(require2fa({ require2fa: false }));
}

export function* signIn({
  payload: { username, password, otp, remember, callback },
}) {
  try {
    const { data, success, headers } = yield call(api.post, endpoints.login, {
      username,
      password,
      otp,
    });

    if (!success) {
      return;
    }

    if (data.require2fa && !otp) {
      yield put(require2fa({ require2fa: data.require2fa }));
      return;
    }

    const { user, notifications } = data;
    const sessionId = headers['gez-csrf-token'];

    api.defaults.headers['Gez-Csrf-Token'] = sessionId;

    yield put(signInSuccess({ sessionId }));
    yield put(setUser({ user, shouldRemember: remember }));

    if (callback) callback();

    notifySuccess('Hello! Welcome aboard!');
  } catch (err) {
    // yield put(signInFailure());
  }
}

export function* totpVerify({ payload: { secret, code, callback } }) {
  try {
    const { success } = yield call(api.post, endpoints.totp_verify, {
      secret,
      token: code,
    });

    if (success) {
      yield call(callback);
      notifySuccess('TOTP verification successful!');
    }
  } catch (err) {
    notifyError(err.message);
  }
}

export function* forgotPassword({ payload: { username, email } }) {
  try {
    const { success } = yield call(api.post, endpoints.password_forgot, {
      ...(username ? { username } : {}),
      ...(email ? { email } : {}),
    });

    if (!success) {
      return;
    }

    notifySuccess(
      'Password recovery is activated! Password reset link has been sent to your email.'
    );
  } catch (err) {
    notifyError(err.message);
  }
}

interface RegisterPayload {
  usernamePassword: UsernamePassword;
  name: Name;
  address: any;
  country: string;
  twoFactorAuth: TwoFactorAuth;
  callback: () => void;
}

export function* register({ payload }: { payload: RegisterPayload }) {
  try {
    const USAddress = {
      house_number: payload.address.houseNumber,
      street_name: payload.address.streetName,
      street_type: payload.address.streetType,
      unit: payload.address.unitApNumber,
      city: payload.address.city,
      state: payload.address.state,
      zip: payload.address.zipCode,
      country: payload.country,
    };

    const nonUSAddress = {
      address_line_1: payload.address.addressLine1,
      address_line_2: payload.address.addressLine2,
      city: payload.address.city,
      state: payload.address.state,
      zip: payload.address.zipCode,
      country: payload.country,
    };

    const { data, success, headers } = yield call(
      api.post,
      endpoints.register,
      {
        user: {
          username: payload.usernamePassword.username,
          password: payload.usernamePassword.password,
          email: payload.usernamePassword.email,
          name: {
            first_name: payload.name.firstName.trim(),
            middle_name: payload.name.middleName.trim(),
            last_name: payload.name.lastName.trim(),
          },
          address: payload.country === 'US' ? USAddress : nonUSAddress,
          country: payload.country,
          otp_secret: payload.twoFactorAuth.secret,
        },
      }
    );

    if (!success) {
      throw new Error('Unauthorized');
    }

    payload.callback();

    notifySuccess('Hello! Welcome aboard!');
  } catch (err) {
    notifyError(err.message);
  }
}

export function* resetPassword({ payload: { password, invitation, auth } }) {
  try {
    const url = buildURL('password_reset', {
      route: { invitationId: invitation },
      query: { auth },
    });

    const { success } = yield call(api.post, url, { password });

    if (!success) {
      return;
    }

    notifySuccess(
      'Password recovery successfull! You can now sign in with your new password.'
    );
  } catch (err) {
    notifyError(err.message);
  }
}

export function* logout() {
  try {
    const { success } = yield call(api.post, endpoints.logout);

    if (!success) {
      return;
    }

    yield put(logoutSuccess());

    notifySuccess('You have been logged out!');
  } catch (error) {
    notifyError(error.message);
  }
}

export default all([
  takeLatest('persist/REHYDRATE' as any, setToken),
  takeLatest('persist/REHYDRATE' as any, reset2fa),
  takeLatest(signInRequest as any, signIn),
  takeLatest(registerRequest as any, register),
  takeLatest(totpVerifyRequest as any, totpVerify),
  takeLatest(forgotPasswordRequest as any, forgotPassword),
  takeLatest(resetPasswordRequest as any, resetPassword),
  takeLatest(logoutRequest as any, logout),
]);
