import styled, { css } from 'styled-components';

interface Props {
  type: 'success' | 'failure';
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-background);

  width: 100vw;
  height: 100vh;

  text-align: center;
  padding: 20px;

  img {
    height: 180px;
    margin-bottom: 40px;
  }

  h2 {
    margin-bottom: 12px;
    font-size: 25px;
    line-height: 30px;
    font-weight: 500;
  }

  h4 {
    margin-bottom: 8px;
  }

  p {
    font-weight: 400;
    color: var(--neutrals-text-weak-active);
  }

  button {
    display: flex;
    align-items: center;
    margin-top: 40px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 6px;
    }
  }

  ${props =>
    props.type === 'success' &&
    css`
      background-color: var(--success-background-light);
    `}

  ${props =>
    props.type === 'failure' &&
    css`
      background-color: var(--danger-background-light);
    `}
`;
