interface Props {
  children: React.ReactNode;
}

const CloseSidebarOnClick = ({ children }: Props) => {
  const handleClick = () => {
    window.dispatchEvent(new CustomEvent('closeSidebar'));
  };

  return (
    <div onClick={handleClick} role="presentation">
      {children}
    </div>
  );
};

export default CloseSidebarOnClick;
