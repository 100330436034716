/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Section } from 'components/molecules';
import { Form, Input, Radio } from 'components/atoms/_common';
import { useCreateCompanyStepper } from 'context/CreateCompanyStepper';
import { useAppDispatch, useIsMobileScreen } from 'hooks';
import { COMPANY_TYPE_SCHEMA } from 'util/schemas';

import {
  Container,
  Types,
  TypeCard,
  TypeDescription,
  TypeContainer,
  TypeDataFields,
  Divider,
} from './styles';

import SelectBox from './SelectBox';

const CompanyType: React.FC = () => {
  const { nextStep, setCompanyType, companyType } = useCreateCompanyStepper();
  const [companyTypeState, setCompanyTypeState] = useState(companyType);

  const isMobile = useIsMobileScreen();

  const handleSubmit = values => {
    setCompanyType({
      type: values.company_type,
      data: {
        public_identifier: values.data?.public_identifier,
      },
    });
    nextStep();
  };

  const handleChangeType = e => {
    setCompanyTypeState(s => ({ ...s, type: e.target.value }));
  };

  return (
    <Section>
      <Section.Header
        title="Company Type"
        subtitle="Choose a type for your company"
        icon="corporate_fare"
      />
      <Section.Content>
        <Container>
          <Form
            onSubmit={handleSubmit}
            schema={COMPANY_TYPE_SCHEMA}
            defaultValues={{
              company_type: companyTypeState.type,
              data: {
                public_identifier: companyTypeState.data?.public_identifier,
              },
            }}
          >
            <Types>
              <TypeContainer>
                <TypeCard>
                  <Radio
                    id="llc-type"
                    name="company_type"
                    checked={companyTypeState.type === 'LLC'}
                    value="LLC"
                    renderer={({ checked }) => (
                      <SelectBox type="LLC" isChecked={checked} />
                    )}
                    onChange={handleChangeType}
                  />
                  <TypeDescription>
                    <strong>LLC</strong>: Limited liability companies (LLC) are
                    a flexible business structure where owners of the LLC are
                    called &ldquo;members&ldquo;. LLCs have all the protection
                    of a corporation plus the ability to pass through any
                    business profits and losses to the individual members.
                    Catawba Digital Economic Zone LLCs are governed under Zone
                    Civil Code Title VII, Ch. 7.
                  </TypeDescription>
                </TypeCard>
              </TypeContainer>

              <Divider />

              <TypeContainer>
                <TypeCard>
                  <Radio
                    id="ccorp-type"
                    name="company_type"
                    checked={companyTypeState.type === 'C Corp'}
                    value="C Corp"
                    renderer={({ checked }) => (
                      <SelectBox type="C Corp" isChecked={checked} />
                    )}
                    onChange={handleChangeType}
                  />
                  <TypeDescription>
                    <strong>C-Corp</strong>: A C-Corporation (C-Corp) is a type
                    of business entity that is legally distinct from its owners.
                    Shareholders of a C-Corp have limited liability protection,
                    meaning they are not personally responsible for the debts or
                    legal actions against the corporation. C-Corps are unique in
                    that they are subject to corporate income tax; profits are
                    taxed at the corporate level and then again at the
                    shareholder level when distributed as dividends.
                  </TypeDescription>
                </TypeCard>
              </TypeContainer>

              <Divider />

              <TypeContainer>
                <TypeCard>
                  <Radio
                    id="dao-type"
                    name="company_type"
                    checked={companyTypeState.type === 'DAO'}
                    value="DAO"
                    renderer={({ checked }) => (
                      <SelectBox type="DAO" isChecked={checked} />
                    )}
                    onChange={handleChangeType}
                  />
                  <TypeDescription>
                    <strong>DAO LLC</strong>: &ldquo;Limited Liability
                    Decentralized Autonomous Organization&ldquo;, or &ldquo;DAO
                    LLC&ldquo;, means a Decentralized Autonomous Organization
                    organized under Zone Regulation as a Limited Liability
                    Company and subject to Zone Civil Code Title VII, Ch. 7.
                  </TypeDescription>
                </TypeCard>
                <TypeDataFields>
                  {companyTypeState.type === 'DAO' && (
                    <>
                      <Input
                        name="data.public_identifier"
                        placeholder="Public Identifier"
                      />
                      <p className="hint">
                        An address that is available publicly and that
                        identifies a smart contract. This can include a URL, a
                        uniform resource locator, contract address, published
                        whitepaper, or other similar item.
                      </p>
                    </>
                  )}
                </TypeDataFields>
              </TypeContainer>
            </Types>
            <button type="submit" className="submit-createCompany" />
          </Form>
        </Container>
      </Section.Content>
    </Section>
  );
};

export default CompanyType;
