import React, { useEffect, useState } from 'react';
import { Section } from 'components/molecules';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useParams } from 'react-router-dom';
import {
  clearCompanyInfo,
  createDissolutionRequest,
  getAllDissolutionsRequest,
  getCompanyInfoRequest,
  updateDissolutionRequest,
  updateSubscriptionRequest,
} from 'store/slices/company';
import { pinCompany } from 'store/slices/user';

import Details from './Details';
import Subscription from './Subscription';
import { EffectiveDateModal, Windup } from './Dissolve';
import Documents from './Documents';

import { Container } from './styles';
import Invitations from './Invitations';
import Captables from './Captables';
import Shares from './Shares';

const Company: React.FC = () => {
  const [showDissolveModal, setShowDissolveModal] = useState(false);
  const [showWindup, setShowWindup] = useState(false);

  const dispatch = useAppDispatch();
  const viewingCompany = useAppSelector(state => state.company.viewingCompany);
  const { id: companyId } = useParams();
  const pinnedCompanies = useAppSelector(state => state.user.pinnedCompanies);

  const isPinned =
    pinnedCompanies &&
    pinnedCompanies.length > 0 &&
    !!pinnedCompanies?.find(_pin => _pin.id === companyId);

  useEffect(() => {
    dispatch(getCompanyInfoRequest({ companyId }));

    return () => {
      dispatch(clearCompanyInfo());
    };
  }, []);

  useEffect(() => {
    if (viewingCompany?.id) {
      dispatch(updateSubscriptionRequest({ companyId }));
    }
  }, [viewingCompany?.id]);

  const actions = {
    pin: () => {
      dispatch(pinCompany({ company: viewingCompany }));
    },
    showDissolve: () => {
      const callback = data => {
        if (
          data &&
          data.active?.length > 0 &&
          data.active[0].active &&
          data.active[0].status === 'windup'
        ) {
          setShowWindup(true);
        } else {
          setShowDissolveModal(true);
        }
      };
      dispatch(getAllDissolutionsRequest({ companyId, callback }));
    },
  };

  const handleSubmit = (data: any) => {
    const cbUpdate = () => {
      setShowDissolveModal(false);
      setShowWindup(true);
    };

    const cbCreate = () => {
      dispatch(updateDissolutionRequest({ companyId, callback: cbUpdate }));
    };

    dispatch(createDissolutionRequest({ companyId, callback: cbCreate }));
  };

  const handleWindupCancel = () => {
    setShowWindup(false);
  };

  if (!viewingCompany) {
    return null;
  }

  return (
    <Container>
      {showDissolveModal && (
        <EffectiveDateModal
          onClose={() => setShowDissolveModal(false)}
          onSubmit={handleSubmit}
        />
      )}
      <Section>
        <Section.Header
          icon="store"
          title={viewingCompany.company_full_name}
          subtitle={viewingCompany.type}
          actions={[
            {
              icon: isPinned ? 'playlist_remove' : 'push_pin',
              text: isPinned ? 'Unpin to sidebar' : 'Pin to sidebar',
              onClick: actions.pin,
            },
            {
              icon: 'delete',
              text: 'Dissolve company',
              onClick: actions.showDissolve,
              color: 'var(--danger-text)',
              shouldCloseOnClick: true,
              showOnlyToRole: 'admin',
            },
          ]}
        />
        <Section.Content noPadding>
          {showWindup ? (
            <Windup companyId={companyId} onCancel={handleWindupCancel} />
          ) : (
            <>
              <Details>
                <Subscription companyId={companyId} />
              </Details>
              <Invitations companyId={companyId} />
              <Shares />
              <Captables companyId={companyId} />
              <Documents companyId={companyId} />
              <div style={{ height: '400px' }} />
            </>
          )}
        </Section.Content>
      </Section>
    </Container>
  );
};

export default Company;
