/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import API, { endpoints } from 'services/API';
import { REGISTER_SCHEMA } from 'util/schemas';
import { Form, Input } from 'components/atoms/_common';
import { useRegisterStepper } from 'context/RegisterStepper';
import { useAppDispatch } from 'hooks';
import QRCode from 'qrcode';
import { totpVerifyRequest } from 'store/slices/auth';
import { ActionValues } from 'components/atoms';

import { LoadingSection } from 'components/atoms/_util';
import Base from '../_base';
import { Container, Divider, ImageBackground } from './styles';

const Subtitle = () => {
  return (
    <>
      <p>
        Please scan QR code with an authenticator app and enable 2FA for your
        account.
      </p>
      <p>
        We recommend using apps like{' '}
        <a
          href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
          target="_blank"
          rel="noreferrer"
        >
          Microsoft Authenticator{' '}
        </a>
        or{' '}
        <a href="https://authy.com/" target="_blank" rel="noreferrer">
          Authy
        </a>
        .
      </p>
    </>
  );
};

const TwoFactorAuth: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    setTwoFactorAuth,
    twoFactorAuth,
    nextStep,
    usernamePassword: { username },
  } = useRegisterStepper();

  useEffect(() => {
    if (twoFactorAuth.code.length === 6) {
      return;
    }

    API.get(endpoints.totp).then(res => {
      const { secret } = res.data;
      setTwoFactorAuth({ ...twoFactorAuth, secret });
    });
  }, []);

  useEffect(() => {
    if (twoFactorAuth.secret && username) {
      const otpstring = `otpauth://totp/${username}:CDEZ?secret=${twoFactorAuth.secret}&issuer=CDEZ`;

      QRCode.toDataURL(otpstring, { type: 'image/jpeg' }, (err, url) => {
        const img = document.getElementById('qr-code-img');
        if (img) {
          img.setAttribute('src', url);
        }
      });
    }
  }, [twoFactorAuth, username]);

  const handleSubmit = values => {
    const cb = () => {
      setTwoFactorAuth({ ...twoFactorAuth, code: values.code });
      nextStep();
    };

    dispatch(
      totpVerifyRequest({
        secret: twoFactorAuth.secret,
        code: values.code,
        callback: cb,
      })
    );
  };

  return (
    <Base
      iconName="document_scanner"
      title="Two-Factor Authentication"
      subtitle={<Subtitle />}
    >
      <Container>
        <LoadingSection isLoading={!twoFactorAuth.secret}>
          <ImageBackground>
            {/* FIXME: Adding a retry button if something goes wrong seems like a good idea */}
            {twoFactorAuth.secret && <img alt="QR code" id="qr-code-img" />}
          </ImageBackground>
        </LoadingSection>
        <Form
          onSubmit={handleSubmit}
          defaultValues={twoFactorAuth}
          schema={REGISTER_SCHEMA.twoFactorAuth}
        >
          <Input
            name="code"
            placeholder="Code"
            disabled={!twoFactorAuth.secret}
            submitOnEnter
          />
          <Divider />
          <span className="hint">
            If scanning does not work or you are unable, enter this information
            manually into your Authenticator App:
          </span>
          <div className="valuesContainer">
            <ActionValues label="Account name:" value={`${username}@CDEZ`} />
            <ActionValues
              label="Secret:"
              value={twoFactorAuth.secret || '...'}
            />
          </div>
          <button type="submit" className="submit-register" />
        </Form>
      </Container>
    </Base>
  );
};

export default TwoFactorAuth;
