import React, { useEffect } from 'react';
import { ActionValues, MessageInline } from 'components/atoms';
import { Form, Input } from 'components/atoms/_common';
import { OTP_CODE_SCHEMA } from 'util/schemas';
import { useAppDispatch, useAppSelector } from 'hooks';
import API, { endpoints } from 'services/API';
import { useTwoFactorUpdateStepperContext } from 'context/TwoFactorUpdateStepper';

import { totpVerifyRequest } from 'store/slices/auth';

import { Container } from './styles';

const QRCode: React.FC = () => {
  const { nextStep, secret, setSecret } = useTwoFactorUpdateStepperContext();
  const dispatch = useAppDispatch();
  const username = useAppSelector(state => state.user.user.username);

  useEffect(() => {
    API.get(endpoints.totp).then(res => {
      setSecret(res.data.secret);
    });
  }, []);

  const handleSubmit = ({ code }) => {
    const callback = () => {
      nextStep();
    };

    dispatch(
      totpVerifyRequest({
        secret,
        code,
        callback,
      })
    );
  };

  const qrCodeURL = `https://chart.googleapis.com/chart?chs=200x200&chld=M|0&cht=qr&chl=otpauth://totp/${username}:CDEZ?secret=${secret}&issuer=CDEZ`;

  return (
    <Container>
      <h2>Scan the QR Code</h2>
      <MessageInline type="warning">
        You must keep a backup of your OTP Code as it is not recoverable.
      </MessageInline>
      <div className="imageBackground">
        {secret && <img src={qrCodeURL} alt="QR Code" />}
      </div>
      <p>
        After scanning, enter the code that appears in your Authenticator App:
      </p>
      <Form schema={OTP_CODE_SCHEMA} onSubmit={handleSubmit}>
        <Input name="code" placeholder="Enter OTP Code" submitOnEnter />
        <button type="submit" className="btn-submit" />
      </Form>
      <div className="divider" />
      <p className="hint-not-scan">
        If scanning does not work or you are unable, enter this information
        manually into your Authenticator App:
      </p>
      <div className="valuesContainer">
        <ActionValues label="Account name:" value={`${username}@CDEZ`} />
        <ActionValues label="Secret:" value={secret || '...'} />
      </div>
    </Container>
  );
};

export default QRCode;
