import React from 'react';

interface Props {
  condition: boolean;
  children: React.ReactElement;
  wrapper?: (children: React.ReactElement) => React.ReactElement;
}

const ConditionalWrapper = ({ condition, wrapper, children }: Props) =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
