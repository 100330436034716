import { createContext, useContext, useState } from 'react';

const TwoFactorUpdateStepperContext = createContext({
  currentStep: 1,
  secret: '',
  nextStep: () => {},
  previousStep: () => {},
  setSecret: action => {},
});

export const useTwoFactorUpdateStepperContext = () => {
  const context = useContext(TwoFactorUpdateStepperContext);

  return context;
};

export const TwoFactorUpdateStepperProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [secret, setSecret] = useState('');

  const nextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    currentStep,
    secret,
    nextStep,
    previousStep,
    setSecret,
  };

  return (
    <TwoFactorUpdateStepperContext.Provider value={value}>
      {children}
    </TwoFactorUpdateStepperContext.Provider>
  );
};
