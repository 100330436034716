/* eslint-disable react/no-danger */
import { format } from 'date-fns';
import { useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import CatawbaLogo from 'assets/images/catawba-logo.svg';
import { Notification } from 'shared/types';
import { Container } from './styles';

const NotificationModal = ({
  notification,
  closeModal,
}: {
  notification: Notification;
  closeModal: () => void;
}) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, []);

  return (
    <Container>
      <MdClose size={28} onClick={closeModal} />
      <p className="date">
        <span>Created At:</span>{' '}
        {format(new Date(notification.createdAt), 'MM/dd/yyyy HH:mm:ss')}
      </p>
      <h1>{notification.title}</h1>
      <div
        className="content"
        // TODO: add a valid check for inserting CatawbaLogo only for Catawba notifications
        dangerouslySetInnerHTML={{
          __html:
            notification.title ===
            'Your company application request has been approved!'
              ? `<div style="text-align: center; width: 100%;"><img  src="${CatawbaLogo}" width="200px" /></div><br/>${notification.text}`
              : notification.text,
        }}
      />
    </Container>
  );
};

export default NotificationModal;
