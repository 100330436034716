import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 540px;
  padding: 40px;
  height: 100%;

  button {
    margin-top: 20px;
  }
`;

export const LinkBack = styled(Link)`
  text-decoration: none;
  color: var(--neutrals-text-weak-active);
  display: block;
  margin-top: 20px;
  text-align: center;
`;
