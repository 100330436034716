import { ScopedSection, Table } from 'components/atoms';
import { Button, Form, Input, Select } from 'components/atoms/_common';
import { useAppDispatch } from 'hooks';
import { useEffect, useState } from 'react';
import { notifySuccess } from 'services/notify';
import {
  cancelCompanyInvitationRequest,
  getCompanyInvitationsRequest,
  resendCompanyInvitationRequest,
  sendCompanyInvitationRequest,
} from 'store/slices/company';
import { MdReplay, MdOutlineCancel, MdContentCopy } from 'react-icons/md';
import { INVITATION_SCHEMA } from 'util/schemas';
import { Container } from './styles';
import NamedSection from '../_common/NamedSection';

const Invitations = ({ companyId }: { companyId: string }) => {
  const [invitations, setInvitations] = useState<any[]>();
  const dispatch = useAppDispatch();

  const getInvitations = () =>
    dispatch(
      getCompanyInvitationsRequest({
        companyId,
        callback: data => setInvitations(data),
      })
    );

  useEffect(() => {
    getInvitations();
  }, []);

  const handleSubmit = (data: any) => {
    dispatch(
      sendCompanyInvitationRequest({
        email: data.email,
        role: data.role,
        companyId,
        callback: getInvitations,
      })
    );
  };

  const handleResend = (id: string) => {
    dispatch(resendCompanyInvitationRequest({ inviteId: id, companyId }));
  };

  const handleCancel = (id: string) => {
    dispatch(
      cancelCompanyInvitationRequest({
        inviteId: id,
        companyId,
        callback: getInvitations,
      })
    );
  };

  const handleCopy = (link: string) => {
    if (link) {
      const el = document.createElement('textarea');
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      notifySuccess('Invitation link copied to your clipboard');
    }
  };

  return (
    <ScopedSection scope="director">
      <NamedSection name="Invitations" iconName="co_present">
        <Container>
          <div>
            <h3>Send an invitation:</h3>
            <Form onSubmit={handleSubmit} schema={INVITATION_SCHEMA}>
              <div className="row-form">
                <Input className="email" name="email" label="Email" />
                <Select
                  className="role"
                  name="role"
                  label="Role"
                  hideDefaultOption
                  options={[
                    { id: 'director', label: 'Director' },
                    { id: 'member', label: 'Member' },
                  ]}
                />
                <Button type="submit">Send</Button>
              </div>
            </Form>
            <div className="divider" />
            <div className="sent-container">
              <h3>Invitations sent:</h3>
              <Table>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Username</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {invitations?.map(invitation => (
                    <tr key={invitation.id}>
                      <td>{invitation.email || '-'}</td>
                      <td>{invitation.role || '-'}</td>
                      <td>{invitation.status || '-'}</td>
                      <td>{invitation.username || '-'}</td>
                      <td className="actions">
                        <MdReplay onClick={() => handleResend(invitation.id)} />
                        <MdOutlineCancel
                          onClick={() => handleCancel(invitation.id)}
                        />
                        <MdContentCopy
                          onClick={() => handleCopy(invitation.invitation_link)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Container>
      </NamedSection>
    </ScopedSection>
  );
};

export default Invitations;
