import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  ${mediaQuery.sm} {
    flex-direction: column;
    .sidebar-container {
      position: fixed;
      height: 100vh;
      z-index: 7;
    }
    height: calc(100vh - 3.5rem);
  }
`;

export const Content = styled.main`
  flex: 1;
`;

export const Overlay = styled.div<{ isSidebarActive: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 6;
  ${({ isSidebarActive }) => !isSidebarActive && 'display: none;'}
`;
