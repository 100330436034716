import React from 'react';
import { Icon } from 'components/atoms/_common';
import { StepperFooter } from 'components/molecules';
import { useTwoFactorUpdateStepperContext } from 'context/TwoFactorUpdateStepper';
import { useNavigate } from 'react-router-dom';

import { Container, Header, Content, ContentContainer } from './styles';

import Message from '../Steps/Message';
import OldOTP from '../Steps/OldOTP';
import QRCode from '../Steps/QRCode';

const Stepper: React.FC = () => {
  const navigate = useNavigate();
  const { previousStep, currentStep } = useTwoFactorUpdateStepperContext();

  const nextStepHandler = () => {
    (document.querySelector('.btn-submit') as HTMLButtonElement).click();
  };

  const returnStep = () => {
    const steps = [<QRCode />, <OldOTP />, <Message />];

    return steps[currentStep - 1];
  };

  const buttonText = () => {
    const texts = ['Continue', 'Submit', 'Finish'];
    return texts[currentStep - 1];
  };

  const redirectSettings = () => {
    navigate('/settings');
  };

  return (
    <Container>
      <ContentContainer>
        <Header>
          <Icon name="arrow_back" onClick={redirectSettings} />
          Two-Factor Authentication
        </Header>
        <Content>{returnStep()}</Content>
      </ContentContainer>

      <StepperFooter
        currentStep={currentStep}
        nextButtonText={buttonText}
        nextStepHandler={nextStepHandler}
        previousStepHandler={previousStep}
        totalSteps={3}
        isBackButtonDisabledOnStep={[true, false, true]}
      />
    </Container>
  );
};

export default Stepper;
