import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  footer {
    width: 100%;
  }

  button.submit-createCompany {
    visibility: hidden;
    display: none;
    opacity: 0;
  }
`;

export const Content = styled.div`
  height: calc(100vh - 90px);
  ${mediaQuery.sm} {
    height: calc(100vh - 136px);
  }
  overflow-y: scroll;
`;
