import styled from 'styled-components';

export const Container = styled.div``;

export const CreationContainer = styled.div`
  padding: 32px;
  background-color: var(--neutrals-background);
  border-bottom: 1px solid var(--neutrals-border);

  &.active {
    background-color: var(--neutrals-background-light);
  }

  > h2 {
    font-size: 20px;
    line-height: 1.2em;
    font-weight: 600;
    margin-bottom: 12px;
  }

  > p {
    color: var(--neutrals-text-weak-active);
  }

  .actions {
    padding: 0 12px;
    display: flex;
    margin-top: 32px;

    & > :first-child {
      margin-right: 12px;
    }
  }
`;

export const ListingContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 668px) {
    justify-content: center;
    align-items: center;
  }
`;
