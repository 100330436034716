import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: var(--primary-background);
  padding: 8px 16px;
  border-radius: 8px;
  width: fit-content;
  border: 1px solid var(--primary-border);

  & > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--neutrals-background);
    margin-right: 10px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    color: var(--primary-icon);
    border: 1px solid var(--primary-icon);

    & > span {
      font-size: 22px;
    }
  }

  & > .text {
    color: var(--primary-text);
    font-size: 20px;
    line-height: 24px;
  }
`;
