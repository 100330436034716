import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withTheme } from 'styled-components';

interface Props {
  children: React.ReactNode;
  theme: any;
}

const MaterialThemeProvider: React.FC<Props> = ({ children, theme }: Props) => {
  const MUITheme = createTheme({
    typography: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: 18,
    },
    palette: {
      primary: {
        main: theme.colors.primary,
      },
      text: {
        primary: theme.colors.primary,
        // secondary: getOvershadow(0.2, { theme }, 'primary'),
        // disabled: getOvershadow(0.2, { theme }, 'primary'),
      },
    },
  });

  return <ThemeProvider theme={MUITheme}>{children}</ThemeProvider>;
};

export default withTheme(MaterialThemeProvider);
