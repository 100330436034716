import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  margin-bottom: 12px;
  background-color: var(--neutrals-background);

  .label {
    margin-bottom: 32px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  > button {
    height: 42px;
  }
`;
