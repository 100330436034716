/* eslint-disable */

import { useAppDispatch, useAppSelector } from 'hooks';
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifyError } from 'services/notify';
import { CompanyApplication } from 'shared/types';
import { getRegisteredAgentsRequest } from 'store/slices/company';
import { getCompanyApplicationsRequest } from 'store/slices/user';
import { Context } from './types';

export const CreateCompanyStepperContext = createContext<Context | undefined>(
  undefined
);

/*
* Mocked information, if needed
const information = {
  company_details: {
    date_formed: '03/07/1993',
    fiscal_year_end_date: '03/07',
    phone: '192892 28298298',
    primary_email: 'company@email.com',
  },
  registration_address: {
    street_name: '123 Main St',
    street_type: 'road',
    house_number: '221',
    unit: '22A',
    city: 'New York',
    state: 'NY',
    zip: '12345',
  },
  mailing_address: {
    street_name: '123 Main St',
    street_type: 'road',
    house_number: '221',
    unit: '22A',
    city: 'New York',
    state: 'NY',
    zip: '12345',
  },
};
*/
const DEFAULT_INFORMATION_DATA = {
  ownershipDetails: [], // [{ name: "", sharedAmount: "" }]
  company_details: null,
  registration_address: null,
  mailing_address: null,
};

interface ProviderProps {
  children: React.ReactNode;
}

export const CreateCompanyProvider = ({ children }: ProviderProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const companyApplications = useAppSelector(
    state => state.user.companyApplications
  );
  const [loadingEditingInfo, setLoadingEditingInfo] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [catawbaCitizenship, setCatawbaCitizenship] = useState({
    isCatawbaCitizen: null,
    company_name: '',
    tribal_id: '',
  });
  const [information, setInformation] = useState(DEFAULT_INFORMATION_DATA);
  const [puertoRico, setPuertoRico] = useState({
    registration: false,
    mailing: false,
  });
  const [isMailingAddressSame, setIsMailingAddressSame] = useState(true);
  const [registeredAgents, setRegisteredAgents] = useState([]);
  const [selectedRegisteredAgent, setSelectedRegisteredAgent] = useState(null);
  const [companyType, setCompanyType] = useState<{
    type: 'LLC' | 'DAO' | 'C Corp' | '';
    data: any;
  }>({
    type: '',
    data: null,
  });

  useEffect(() => {
    const editSearchParam = new URLSearchParams(location.search).get('edit');
    const isEditing = !!editSearchParam;

    const editingApplication: CompanyApplication = companyApplications.find(
      app => app.id === editSearchParam
    );

    const callback = data => {
      setRegisteredAgents(data);
      if (isEditing) {
        if (!editingApplication) return;
        setSelectedRegisteredAgent(
          editingApplication.registeredAgentUsername || 'manual'
        );
        setInformation(info => ({
          ...info,
          registeredAgentUsername: editingApplication.registeredAgentUsername,
        }));
      } else {
        setSelectedRegisteredAgent(data[0].username);
        setInformation(info => ({
          ...info,
          registration_address: data[0].address,
        }));
      }
    };
    dispatch(getRegisteredAgentsRequest({ callback }));

    const callbackGetCompanyApplications = () => {
      if (editingApplication) {
        setCompanyType({
          type:
            editingApplication.type === 'Limited Liability Company'
              ? 'LLC'
              : editingApplication.public_identifier
              ? 'DAO'
              : 'C Corp',
          data: {
            public_identifier: editingApplication.public_identifier,
          },
        });
        setCatawbaCitizenship({
          isCatawbaCitizen: !!editingApplication.tribal_id,
          company_name: editingApplication.company_name,
          tribal_id: editingApplication.tribal_id,
        });
        setIsMailingAddressSame(
          JSON.stringify(editingApplication.mailing_address) ===
            JSON.stringify(editingApplication.registration_address)
        );
        setPuertoRico({
          registration: editingApplication.registration_address?.state === 'PR',
          mailing: editingApplication.mailing_address?.state === 'PR',
        });
        setInformation(info => ({
          ...info,
          company_details: {
            date_formed: editingApplication.date_formed?.split('T')[0],
            fiscal_year_end_date: editingApplication.fiscal_year_end_date
              ? `${new Date().getFullYear()}-${editingApplication.fiscal_year_end_date.replace(
                  '/',
                  '-'
                )}`
              : '',
            phone: editingApplication.phone,
            primary_email: editingApplication.primary_email,
            total_shares: editingApplication.total_common,
            total_preferred: editingApplication.total_preferred,
            preferred_name: editingApplication.preferred_name,
            preferred_description: editingApplication.preferred_description,
          },
          registration_address: editingApplication.registration_address,
          mailing_address: editingApplication.mailing_address,
        }));
      } else {
        navigate('/companies/create');
        notifyError('Application not found');
      }
      setLoadingEditingInfo(false);
    };

    if (isEditing) {
      callbackGetCompanyApplications();
      setLoadingEditingInfo(false);
    }
    setLoadingEditingInfo(false);
  }, []);

  const nextStep = () => {
    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const changePuertoRico = (
    type: 'registration' | 'mailing',
    value: boolean
  ) => {
    setPuertoRico(p => ({ ...p, [type]: value }));
  };

  const value = {
    currentStep,
    nextStep,
    previousStep,
    catawbaCitizenship,
    setCatawbaCitizenship,
    information,
    setInformation,
    setRegisteredAgents,
    registeredAgents,
    selectedRegisteredAgent,
    setSelectedRegisteredAgent,
    puertoRico,
    changePuertoRico,
    isMailingAddressSame,
    setIsMailingAddressSame,
    loadingEditingInfo,
    companyType,
    setCompanyType,
  };

  return (
    <CreateCompanyStepperContext.Provider value={value}>
      {children}
    </CreateCompanyStepperContext.Provider>
  );
};

export const useCreateCompanyStepper = () => {
  const context = React.useContext(CreateCompanyStepperContext);

  return context;
};
