import styled from 'styled-components';

interface ContainerProps {
  fill?: number;
  weight?: number;
  grade?: number;
  opticalSize?: number;
}

export const Container = styled.span<ContainerProps>`
  // prettier-ignore
  font-variation-settings: 
    'FILL' ${props => props.fill || 0},
    'wght' ${props => props.weight || 400}, 
    'GRAD' ${props => props.grade || 0},
    'opsz' ${props => props.opticalSize || 48};
`;
