import { useAppSelector } from 'hooks';
import React from 'react';
import { Navigate } from 'react-router-dom';

import PublicLayout from '../pages/_layouts/public';

interface Props {
  children: React.ReactNode;
  blanked?: boolean;
}

const KYCUnverifiedRoute: React.FC<Props> = ({ children, blanked }: Props) => {
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const { kyc_verification } = useAppSelector(state => state.user.user);

  if (kyc_verification === 'verified') {
    return <Navigate to="/login" />;
  }

  return <PublicLayout blanked={blanked}>{children}</PublicLayout>;
};

KYCUnverifiedRoute.defaultProps = {
  blanked: false,
};

export default KYCUnverifiedRoute;
