import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Section, CompanyCard } from 'components/molecules';
import { MenuItem } from 'components/atoms';

import { useAppDispatch, useAppSelector } from 'hooks';
import { CompanyCardActions } from 'components/molecules/CompanyCard';

import { getCompanyApplicationsRequest, pinCompany } from 'store/slices/user';
import { cancelCompanyApplicationRequest } from 'store/slices/company';
import { CreationContainer, ListingContainer } from './styles';

const Companies: React.FC = () => {
  const dispatch = useAppDispatch();
  const { companyApplications, companies } = useAppSelector(
    state => state.user
  );
  const navigate = useNavigate();

  const actions: CompanyCardActions = {
    pin: company => {
      dispatch(pinCompany({ company }));
    },
    delete: company => {
      dispatch(
        cancelCompanyApplicationRequest({ companyApplicationId: company.id })
      );
    },
  };

  useEffect(() => {
    dispatch(getCompanyApplicationsRequest());
  }, []);

  return (
    <Section>
      <Section.Header
        title="Companies"
        subtitle="Create, add and delete companies on your profile. "
        icon="corporate_fare"
      />
      <Section.Content noPadding>
        <CreationContainer className="active">
          <h2>Company creation</h2>
          <p>
            Create new company or add existing one to Catawba Digital Economic
            Zone
          </p>
          <div className="actions">
            <MenuItem
              text="Create new company"
              icon="library_add"
              onClick={() => navigate('/companies/create')}
            />
            <MenuItem
              text="Add existing company"
              icon="import_contacts"
              disabled
            />
          </div>
        </CreationContainer>
        {companies && (
          <CreationContainer>
            <h2>Created Companies ({companies.length})</h2>

            <ListingContainer>
              {companies.map(company => (
                <CompanyCard
                  key={company.id}
                  company={company}
                  variant="expanded"
                  actions={{
                    pin: actions.pin,
                  }}
                />
              ))}
            </ListingContainer>
          </CreationContainer>
        )}
        {companyApplications && (
          <CreationContainer>
            <h2>Company Applications ({companyApplications.length})</h2>

            <ListingContainer>
              {companyApplications.map(company => (
                <CompanyCard
                  isApplication
                  key={company.id}
                  company={company}
                  variant="expanded"
                  actions={{
                    delete: actions.delete,
                  }}
                />
              ))}
            </ListingContainer>
          </CreationContainer>
        )}
      </Section.Content>
    </Section>
  );
};

export default Companies;
