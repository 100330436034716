import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  & > h2 {
    font-size: 31px;
    line-height: 1.2em;
    font-weight: 300;
    margin-bottom: 24px;
  }

  ${mediaQuery.sm} {
    align-items: center;
    justify-content: unset;
  }
`;

export const MessageContainer = styled.div`
  padding: 40px;
  border-radius: 4px;
  background-color: var(--success-background-light);
  text-align: center;
  min-width: 60%;

  img {
    height: 170px;
    margin-bottom: 20px;
  }

  ${mediaQuery.sm} {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;
