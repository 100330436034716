/* eslint-disable react/jsx-no-target-blank */
import { useAppDispatch, useAppSelector } from 'hooks';
import { Link, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';

import Status from '../Status';

const KYCStatus: React.FC = () => {
  const { kyc_verification } = useAppSelector(state => state.user.user);

  const trulioo_workflow_link = process.env.REACT_APP_TRULIOO_WORKFLOW_LINK;

  return (
    <Status
      kind="kyc"
      type={kyc_verification === 'unverified' ? 'success' : 'failure'}
    >
      <p style={{ marginTop: '40px' }}>
        Have you not completed Trulioo&apos;s Workflow?{' '}
        <a href={trulioo_workflow_link} target="_blank">
          Click here to try again.
        </a>
      </p>
    </Status>
  );
};

export default KYCStatus;
