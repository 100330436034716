import { IconCircle } from 'components/atoms/_common';
import React from 'react';

import { Container } from './styles';

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

const CollapsePill: React.FC<Props> = ({ isOpen, onClick }: Props) => {
  return (
    <Container isOpen={isOpen} className="pill" onClick={onClick}>
      <IconCircle name={isOpen ? 'arrow_back_ios' : 'arrow_forward_ios'} />
      <span className="label small">Collapse</span>
    </Container>
  );
};

export default CollapsePill;
