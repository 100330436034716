import { MdClose } from 'react-icons/md';
import { Container, Content, Background, CloseButton } from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<Props> = ({ open, onClose, children }: Props) => {
  return (
    <Container open={open}>
      <Content>
        <CloseButton type="button" onClick={onClose}>
          <MdClose />
        </CloseButton>
        {children}
      </Content>
      <Background onClick={onClose} />
    </Container>
  );
};

Modal.defaultProps = {};

export default Modal;
