import styled from 'styled-components';

export const Container = styled.div`
  &.errored {
    span.labelText {
      color: var(--danger-text);
    }
  }

  input[type='radio'] {
    display: none;
  }
`;

export const Label = styled.label``;

interface ErrorSpanProps {
  visible: boolean;
}

export const ErrorSpan = styled.span<ErrorSpanProps>`
  opacity: ${props => (props.visible ? '1' : '0')};
  max-height: ${props => (props.visible ? '200px' : '0')};

  color: var(--danger-text);
  line-height: 1.2em;
  font-size: 12px;
  margin-top: 3px;
  transition: opacity 0.2s ease-in, max-height 0.2s ease-in-out;
`;

export const RendererContainer = styled.div`
  cursor: pointer;
`;
