import styled from 'styled-components';

export const CountryContainer = styled.div`
  margin-top: 20px;

  label {
    color: var(--neutrals-text-weak-active);
  }

  & > div {
    margin-top: 8px;
  }

  #rfs-btn {
    height: 61px;
    border: 1px solid;
    border-color: var(--neutrals-border);
    border-radius: 8px;
    color: var(--neutrals-text);
  }
`;
