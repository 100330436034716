import React from 'react';

import { Container } from './styles';

interface Props {
  text: string;
  value: string | string[] | number;
}

const TextValue: React.FC<Props> = ({ text, value }: Props) => {
  return (
    <Container>
      <p className="text">{text}</p>
      {Array.isArray(value) ? (
        <div className="values">
          {value.map(v => (
            <p>{v}</p>
          ))}
        </div>
      ) : (
        <p className="value">{value}</p>
      )}
    </Container>
  );
};

export default TextValue;
