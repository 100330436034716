import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useRouteParams } from 'hooks';
import {
  confirmEmailRequest,
  getEmailConfirmationInfoRequest,
} from 'store/slices/user';
import { Button } from 'components/atoms/_common';

import CatawbaLogo from 'assets/images/catawba-logo-2.svg';
import { Container } from './styles';

const RegisterEmailConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);

  const [authToken, setAuthToken] = useState('');
  const [userEmailInfo, setUserEmailInfo] = useState({
    email: '',
    username: '',
  });

  const callbackUserEmailInfo = emailInfo => {
    setUserEmailInfo(emailInfo);
  };
  // prettier-ignore
  useRouteParams(auth => {
    if(!auth) {
      navigate('/logout');
      return;
    }
    setAuthToken(auth);
    dispatch(getEmailConfirmationInfoRequest({ auth, callback: callbackUserEmailInfo }));
  }, ['auth']);

  const handleAccept = () => {
    const redirectLogin = () =>
      isAuthenticated ? navigate('/logout') : navigate('/login');
    dispatch(confirmEmailRequest({ auth: authToken, callback: redirectLogin }));
  };

  return (
    <Container>
      <img src={CatawbaLogo} alt="Catawba logo" />
      <h1>
        Welcome, <strong>{userEmailInfo.username}</strong>!
      </h1>
      <p>
        You&apos;ve successfully fill all the information to register in the
        application!
      </p>
      <p>Now, we just need to confirm your account with the following email:</p>
      <h2>{userEmailInfo.email}</h2>
      <Button variant="outlined" onClick={handleAccept}>
        Confirm my account
      </Button>
    </Container>
  );
};

export default RegisterEmailConfirmation;
