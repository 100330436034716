import { useAppDispatch } from 'hooks';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { logoutRequest } from 'store/slices/auth';

const Logout: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(logoutRequest());
  }, []);

  return <Navigate to="/login" />;
};

export default Logout;
