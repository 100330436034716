import { endpoints } from 'services/API';
import { RequestMap } from './mapping';

const requests: { [key: string]: RequestMap } = {
  sampleRequest: {
    endpoint: endpoints.login, // or just 'login'
    method: 'post',
    options: {
      route: {
        companyId: '123',
      },
      query: {
        name: 'Elroy',
      },
    },
    payload: {
      username: 'testUser',
      password: 'E@syP5SS',
      otp: '12345',
    },
  },
  get_company: {
    endpoint: endpoints.get_company, // or just 'login'
    method: 'get',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
    },
  },
  get_inbox: {
    endpoint: endpoints.get_inbox,
    method: 'get',
    options: {},
  },
  company_broadcast_message: {
    endpoint: endpoints.company_broadcast_message,
    method: 'post',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
    },
    payload: {
      title: 'A notification title',
      message: 'Simple notification message',
      htmlText: "<p style='color:red'>Simple Notification Message</p>",
    },
  },
  update_company_document_upload_status: {
    endpoint: endpoints.update_company_document_upload_status,
    method: 'post',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
    },
    payload: {
      document_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      document_status: 'FAILED',
    },
  },
  get_company_document_retrieval_link: {
    endpoint: endpoints.get_company_document_retrieval_link,
    method: 'get',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
      query: {
        documentPath: '/formation-documents/articles-of-incorporation.pdf',
      },
    },
  },
  get_company_document_directory: {
    endpoint: endpoints.get_company_document_directory,
    method: 'get',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
      query: {
        documentPath: '/formation-documents',
      },
    },
  },
  create_company_document: {
    endpoint: endpoints.create_company_document,
    method: 'post',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
    },
    payload: {
      path: '/company-public/myfile.pdf',
    },
  },
  cancel_company_invite: {
    endpoint: endpoints.cancel_company_invite,
    method: 'post',
    options: {
      route: {
        inviteId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
    },
  },
  create_company_invite: {
    endpoint: endpoints.create_company_invite,
    method: 'post',
    options: {
      route: {
        inviteId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
    },
  },
  cancel_company_application: {
    endpoint: endpoints.cancel_company_application,
    method: 'post',
    options: {
      route: {
        applicationId: '2c48398f-91a0-40d8-a3dd-95b60b7bffff',
      },
    },
  },
  submit_company_application: {
    endpoint: endpoints.submit_company_application,
    method: 'post',
    options: {
      route: {
        applicationId: '2c48398f-91a0-40d8-a3dd-95b60b7bffff',
      },
    },
  },
  update_company_application: {
    endpoint: endpoints.update_company_application,
    method: 'post',
    options: {
      route: {
        applicationId: '2c48398f-91a0-40d8-a3dd-95b60b7bffff',
      },
    },
  },
  create_company_application: {
    endpoint: endpoints.create_company_application,
    method: 'post',
    options: {},
  },
  get_company_applications: {
    endpoint: endpoints.get_company_applications,
    method: 'get',
    options: {},
  },
  get_company_member_permissions: {
    endpoint: endpoints.get_company_member_permissions,
    method: 'post',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
        username: 'companyappsuser',
      },
    },
  },
  get_company_members: {
    endpoint: endpoints.get_company_members,
    method: 'post',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
    },
  },
  get_company_captable: {
    endpoint: endpoints.get_company_captable,
    method: 'post',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
    },
  },
  check_company_name: {
    endpoint: endpoints.check_company_name,
    method: 'get',
    options: {
      query: {
        company_name: 'eTribe',
      },
    },
  },
  resend_company_invites: {
    endpoint: endpoints.resend_company_invite,
    method: 'post',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
        companyInviteId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
    },
  },
  cancel_company_invites: {
    endpoint: endpoints.cancel_company_invite,
    method: 'post',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
        companyInviteId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
    },
  },
  get_company_invites: {
    endpoint: endpoints.get_company_invites,
    method: 'post',
    options: {
      route: {
        companyId: 'd663b4a2-cf93-45f8-a03d-a692c1ae3a79',
      },
    },
  },
  reject_company_invite: {
    endpoint: endpoints.reject_company_invite,
    method: 'post',
    options: {
      query: {
        auth: '',
      },
    },
  },
  accept_company_invite: {
    endpoint: endpoints.accept_company_invite,
    method: 'post',
    options: {
      query: {
        auth: '',
      },
    },
  },
  get_company_invite: {
    endpoint: endpoints.get_company_invite,
    method: 'get',
    options: {
      query: {
        auth: '',
      },
    },
  },
  user_register_checkusername: {
    endpoint: endpoints.user_register_checkusername,
    method: 'get',
    options: {
      query: {
        auth: 'companyadminuser',
      },
    },
  },
  get_newsletters: {
    endpoint: endpoints.get_newsletters,
    method: 'get',
  },
};

export default requests;
