import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConfigState {
  theme: string;
  route: string;
}

const initialState = {
  theme: 'catawbaLight',
  route: '',
} as ConfigState;

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
  },
});

export const { setTheme } = configSlice.actions;
export default configSlice.reducer;
