import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { LoadingContainedContainer } from './styles';

const Loading: React.FC = () => {
  return (
    <LoadingContainedContainer>
      <AiOutlineLoading3Quarters size={50} />
    </LoadingContainedContainer>
  );
};

export default Loading;
