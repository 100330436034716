import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > h2,
  & > p {
    text-align: center;
  }

  & > h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .actions {
    display: flex;

    button {
      flex: 1;
      &:first-of-type {
        margin-right: 8px;
      }
    }
  }

  .form-checkboxes {
    margin: 32px 0;
  }
`;

export const CheckboxContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 12px;
  background-color: var(--neutrals-background-light);
  border-radius: 4px;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;
