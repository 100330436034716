import React, { useEffect } from 'react';
import { Button } from 'components/atoms/_common';
import CatawbaLogo from 'assets/images/catawba-logo.svg';

import { Container, ModalContainer } from './styles';

interface Props {
  onClose: () => void;
}

const ConfirmationModal: React.FC<Props> = ({ onClose }: Props) => {
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, []);
  return (
    <Container>
      <ModalContainer>
        <img src={CatawbaLogo} alt="Catawba" />
        <div className="text">
          <h2>Company dissolution in review</h2>
          <p>
            Your company disolution application has been accepted and it is in
            the process of review. You will be informed as soon as possible
            about the outcome.
          </p>
        </div>
        <Button full onClick={onClose}>
          Back to Companies
        </Button>
      </ModalContainer>
    </Container>
  );
};

export default ConfirmationModal;
