import { Document, DocumentPreview } from 'shared/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSFixMe } from '../../../shared/types/TSFixMe.d';

interface DocumentState {
  loading: boolean;
  activeDocumentPreview: DocumentPreview;
  documents: Document[];
}

export const initialState = {
  loading: false,
  activeDocumentPreview: null,
  documents: [],
} as DocumentState;

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    getDocumentsRequest: (
      _,
      action: PayloadAction<{ companyId: string }>
    ) => {},
    getDocumentsSuccess: (
      state,
      action: PayloadAction<{ documents: Document[] }>
    ) => {
      state.documents = action.payload.documents;
    },
    getDocumentPreviewRequest: (
      _,
      action: PayloadAction<{ companyId: string; documentPath: string }>
    ) => {},
    getDocumentPreviewSuccess: (
      state,
      action: PayloadAction<{ documentPreview: DocumentPreview }>
    ) => {
      state.activeDocumentPreview = action.payload.documentPreview;
    },
    uploadDocumentRequest: (
      state,
      action: PayloadAction<{
        file: TSFixMe;
        filename: string;
        companyId: string;
        callback?: (error: any) => void;
      }>
    ) => {
      state.loading = true;
    },
    uploadDocumentSuccess: (
      state,
      action: PayloadAction<{ document: Document }>
    ) => {
      state.loading = false;

      const { document } = action.payload;

      const [, ...pathArray] = document.path.split('/'); // remove first element (companyId)
      pathArray.pop(); // remove the filename

      if (!pathArray[0]) return;

      const findParent = (array: Document[], searchPathIndex: number) => {
        if (!pathArray[searchPathIndex]) {
          // directory found
          return array.push(document);
        }

        const parentIndex = array.findIndex(
          doc => doc.name === pathArray[searchPathIndex]
        );

        if (parentIndex === -1) {
          // directory not found
          return null;
        }

        if (!array[parentIndex].children) {
          // directory found
          array[parentIndex].children = [document];
          return null;
        }

        // iterating directory children
        return findParent(array[parentIndex].children, searchPathIndex + 1);
      };

      findParent(state.documents, 0);

      state.documents = [...state.documents];
    },
    uploadDocumentFailure: state => {
      state.loading = false;
    },
    uploadFileRequest: (
      _,
      action: PayloadAction<{
        file: TSFixMe;
        postURL: string;
        formData: TSFixMe;
        onComplete: (error: TSFixMe) => void;
      }>
    ) => {},
  },
});

export const {
  getDocumentsRequest,
  getDocumentsSuccess,
  getDocumentPreviewRequest,
  getDocumentPreviewSuccess,
  uploadDocumentRequest,
  uploadDocumentSuccess,
  uploadDocumentFailure,
  uploadFileRequest,
} = documentSlice.actions;

export default documentSlice.reducer;
