import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  padding-bottom: 40px;
  max-width: 1000px;
  margin: 0 auto;

  ${mediaQuery.sm} {
    max-width: unset;
    margin: 0;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: auto;
  background: var(--neutrals-border);
  margin: 20px;
`;

export const Acceptance = styled.div`
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  .checkmark {
    margin-right: 12px;
  }
  input,
  .checkmark {
    flex-shrink: 0;
  }

  ${mediaQuery.sm} {
    font-size: 16px;
    text-align: center;
    padding: 16px;
  }
`;

export const Information = styled.div`
  max-width: 1200px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: var(--neutrals-background-light);

  > section {
    display: flex;
    flex-direction: row;
  }

  .box {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px;
    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    .row {
      justify-content: space-between;
      margin: 10px 0;
      h4 {
        font-weight: bold;
      }
    }
  }

  .companyDetails {
    width: 100%;
    background: var(--primary-background);
    border: 1px solid var(--primary-border);

    .ownershipDetails {
      color: var(--primary-text-contrast);
      background: var(--primary-background-cta);
      padding: 20px;
      margin: 10px -20px -20px -20px;
    }
  }

  .info-accordions {
    display: flex;
    flex-direction: column;

    .accordion-container:not(:last-child) {
      margin: 8px 0;
    }

    .accordion-content {
      .row {
        justify-content: space-between;
        margin: 14px 0;
        h4 {
          font-weight: bold;
        }
      }
    }
  }

  ${mediaQuery.sm} {
    margin-top: 0;
    padding: 20px;
  }
`;
