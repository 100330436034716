import { format } from 'date-fns';

export const formatDate = (ISOString: string, formatString?: string) => {
  try {
    const date = new Date(ISOString);

    return format(date, formatString || 'dd MMM yyyy');
  } catch (err) {
    console.log(err);
    return ISOString;
  }
};

export const formatCurrency = (
  amount: string | number,
  currency: string,
  spaceBetween?: boolean
) => {
  const symbols = {
    usd: '$',
    eur: '€',
    gbp: '£',
    cad: '$',
    brl: 'R$',
    aud: '$',
  };

  try {
    return `${symbols[currency]}${spaceBetween ? ' ' : ''}${amount}`;
  } catch (err) {
    console.log(err);
    return amount;
  }
};

export const formatCapitalizeFirstLetter = (str: string) => {
  try {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } catch (err) {
    console.log(err);
    return str;
  }
};
