import { useIsMobileScreen } from 'hooks';

import LLCIcon from 'assets/images/LLC.svg';
import DAOIcon from 'assets/images/DAO.svg';
import CCORPIcon from 'assets/images/C-CORP.svg';
import { FaCheck } from 'react-icons/fa';
import { Icon } from 'components/atoms/_common';
import {
  DesktopSelectBox,
  IconCircleContainer,
  MobileSelectBox,
} from './styles';

interface Props {
  isChecked: boolean;
  type: 'LLC' | 'DAO' | 'C Corp';
}

const variables = {
  image: {
    LLC: LLCIcon,
    DAO: DAOIcon,
    'C Corp': CCORPIcon,
  },
  alt: {
    LLC: 'LLC',
    DAO: 'DAO',
    'C Corp': 'C Corp',
  },
  text: {
    LLC: 'LLC Company',
    DAO: 'DAO Company',
    'C Corp': 'C-Corp Company',
  },
};

const SelectBox = ({ isChecked, type }: Props) => {
  const isMobile = useIsMobileScreen();

  return isMobile ? (
    <MobileSelectBox isChecked={isChecked}>
      <div className="checkbox">{isChecked ? <FaCheck /> : ''}</div>
      <img
        className="companyType"
        src={variables.image[type]}
        alt={variables.alt[type]}
      />
      {variables.text[type]}
    </MobileSelectBox>
  ) : (
    <DesktopSelectBox isChecked={isChecked}>
      <span className="checkbox">{isChecked ? <Icon name="check" /> : ''}</span>
      <IconCircleContainer>
        <img
          className="companyType"
          src={variables.image[type]}
          alt={variables.alt[type]}
        />
      </IconCircleContainer>
      {variables.text[type]}
    </DesktopSelectBox>
  );
};

export default SelectBox;
