/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IconCircle } from 'components/atoms/_common';
import { useAppSelector } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { Company } from 'shared/types';

import ActionItem from './ActionItem';

import { Container, Status } from './styles';

export interface CompanyCardActions {
  pin?: (company: Company) => void;
  delete?: (company: Company) => void;
}

interface Props {
  company: Company;
  actions?: CompanyCardActions;
  variant?: 'expanded' | 'condensed';
  isApplication?: boolean;
}

const COMPANY_STATUSES = {
  pending_review: {
    label: 'Pending review',
    color: 'secondary',
  },
  pending_payment: {
    label: 'Pending payment',
    color: 'secondary',
  },
  draft: {
    label: 'In Draft',
    color: 'primary',
  },
  approved: {
    label: 'Approved',
    color: 'success',
  },
  created: {
    label: 'Created',
    color: 'success',
  },
  rejected: {
    label: 'Rejected',
    color: 'danger',
  },
};

const CompanyCard: React.FC<Props> = ({
  variant,
  company,
  actions,
  isApplication,
}: Props) => {
  const navigate = useNavigate();
  const pinnedCompanies = useAppSelector(state => state.user.pinnedCompanies);

  const isPinned =
    pinnedCompanies &&
    pinnedCompanies.length > 0 &&
    !!pinnedCompanies?.find(_pin => _pin.id === company.id);
  const companyStatus = !isApplication
    ? COMPANY_STATUSES.created
    : COMPANY_STATUSES[company.application_status];

  if (!companyStatus) {
    return null;
  }

  const handleRedirect = () => {
    if (isApplication) {
      if (company.application_status === 'draft') {
        return navigate(`/companies/create?edit=${company.id}`);
      }
      return navigate(`/companies/companyapplication/${company.id}`);
    }
    return navigate(`/companies/company/${company.id}`);
  };

  return (
    <Container variant={variant} className="company-card">
      <div className="company-info-container" onClick={handleRedirect}>
        <div className="company-image">
          <IconCircle name="corporate_fare" />
        </div>

        <div className="company-details">
          <h2>{company.company_full_name}</h2>
          <p>{company.type}</p>

          {variant === 'expanded' && (
            <Status type={companyStatus.color}>
              STATUS: {companyStatus.label}
            </Status>
          )}
        </div>
      </div>

      {actions && (
        <div className="company-actions">
          {actions.pin && (
            <ActionItem
              active={isPinned}
              icon="push_pin"
              onClick={() => actions.pin(company)}
            />
          )}
          {actions.delete && (
            <ActionItem icon="delete" onClick={() => actions.delete(company)} />
          )}
        </div>
      )}
    </Container>
  );
};

CompanyCard.defaultProps = {
  variant: 'condensed',
  actions: null,
  isApplication: false,
};

export default CompanyCard;
