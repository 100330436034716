import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;

  & > h2 {
    font-size: 31px;
    line-height: 1.2em;
    font-weight: 300;
    margin-bottom: 24px;
  }

  & > p {
    font-size: 16px;
    line-height: 1.2em;
    color: var(--neutrals-text-weak-active);
    margin-bottom: 48px;
  }

  .input:first-of-type {
    width: 600px;
    margin-bottom: 40px;
  }

  ${mediaQuery.sm} {
    padding: 0 20px;

    .formContainer {
      width: 100%;
    }

    .input:first-of-type {
      width: 100%;
      margin-bottom: 24px;
    }
  }
`;
