import React from 'react';
import { Document } from 'shared/types';

import { Container, ImageCard, NameCard } from './styles';

interface Props {
  document: Document;
  onClick: () => void;
}

const File: React.FC<Props> = ({ document, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <ImageCard className="image-card">
        {document.name.split('.').pop()}
      </ImageCard>
      <NameCard>{document.name}</NameCard>
    </Container>
  );
};

export default File;
