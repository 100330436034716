import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 540px;
  padding: 40px;
  height: 100%;

  div.input {
    margin-top: 12px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

export const ImagePlaceholder = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--neutrals-background-light);
  border: 2px solid var(--primary-background-cta);
  margin-bottom: 20px;
`;

interface FieldsDisplayerProps {
  visible: boolean;
}

export const FieldsDisplayer = styled.div<FieldsDisplayerProps>`
  opacity: ${props => (props.visible ? '1' : '0')};
  height: ${props => (props.visible ? 'auto' : '0px')};
`;
