/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Label } from 'components/atoms';

import { Container, Header } from './styles';

interface Props {
  name: string;
  iconName: string;
  children: React.ReactNode;
  actions?: () => React.ReactElement;
}

const NamedSection: React.FC<Props> = ({
  name,
  iconName,
  children,
  actions: Actions,
}: Props) => {
  return (
    <Container>
      <Header>
        <Label text={name} iconName={iconName} />
        {Actions ? <Actions /> : null}
      </Header>
      {children}
    </Container>
  );
};

NamedSection.defaultProps = {
  actions: null,
};

export default NamedSection;
