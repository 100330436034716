import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
  border-bottom: 1px solid var(--neutrals-border);
  margin-bottom: 20px;
  color: var(--neutrals-text);
  line-height: 1.2em;

  .values {
    text-align: right;
  }
`;
