import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .titleTextContainer {
    display: flex;
    flex-direction: column;

    .title {
      color: var(--neutrals-text);
      line-height: 1.2em;
      font-weight: 500;
      margin-bottom: 4px;
    }

    .text {
      color: var(--neutrals-text-weak-active);
      font-size: 13px;
      line-height: 1.3em;
    }
  }
`;

interface StepProps {
  isActive: boolean;
  isDone: boolean;
}

export const Step = styled.div<StepProps>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--primary-background-cta);

  ${props =>
    props.isActive &&
    css`
      background-color: var(--primary-background-cta);
      color: var(--neutrals-text-contrast);
    `}

  ${props =>
    props.isDone &&
    css`
      position: relative;
      background-color: var(--success-background);
      border: 1px solid var(--success-border);

      span {
        visibility: hidden;
      }

      &:after {
        content: '';
        top: 10px;
        position: absolute;
        width: 6px;
        height: 12px;
        border: 1px solid var(--success-icon);
        border-width: 0 4px 4px 0;
        transform: rotate(45deg);
      }
    `}

  span {
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
    line-height: 24px;
  }
`;
