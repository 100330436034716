import React, { useEffect, useState } from 'react';
import { Controller, useController, get } from 'react-hook-form';
import cn from 'classnames';
import MaskedInput from 'react-input-mask';

import { validateSingle } from 'util/validate';
import { Container, Label, ErrorSpan } from './styles';
import EyeIcon from './EyeIcon';
import { useFormContext } from '../Form';

export type InputState = 'neutral' | 'error' | 'success';

export interface InputProps {
  name: string;
  label?: string;
  disabled?: boolean;
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  optional?: boolean;
  submitOnEnter?: boolean;
  className?: string;
  controlledInputState?: InputState;
  mask?: string;
  hint?: string;
  required?: boolean;
  [rest: string]: any;
}

const Input: React.FC<InputProps> = ({
  name,
  label,
  disabled,
  type,
  onChange,
  optional,
  submitOnEnter,
  className,
  controlledInputState,
  mask,
  hint,
  required,
  ...rest
}: InputProps) => {
  const { register, watch, errors, schema, control } = useFormContext();

  const [typeTracking, setTypeTracking] = useState(type);
  const [inputState, setInputState] = useState<InputState>(
    controlledInputState || 'neutral'
  );
  const [prevError, setPrevError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const togglePasswordVisibility = () => {
    setTypeTracking(typeTracking === 'password' ? 'text' : 'password');
  };

  const triggerStateValidation = (value: string) => {
    if (!value || value.length < 2) {
      return setInputState('neutral');
    }

    const [isValid, message] = required
      ? [value.length > 0, 'Required field']
      : validateSingle(schema[name], value);

    if (message) {
      setErrorMessage(message);
    }

    return setInputState(isValid ? 'success' : 'error');
  };

  useEffect(() => {
    const watchField = watch(name);
    triggerStateValidation(watchField);
  }, []);

  useEffect(() => {
    if (get(errors, name) && prevError !== get(errors, name)) {
      setPrevError(get(errors, name));
      setInputState('error');
      setErrorMessage(get(errors, name).message);
    }
  }, [errors]);

  useEffect(() => {
    if (controlledInputState) {
      setInputState(controlledInputState);
    }
  }, [controlledInputState]);

  if (mask) {
    return (
      <Container
        className={cn(`input ${className}`, {
          error: inputState === 'error',
          success: inputState === 'success',
        })}
      >
        {label && (
          <Label htmlFor={name}>{optional ? label : `${label} *`}</Label>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <MaskedInput
              mask={mask}
              maskChar=""
              value={field.value}
              onChange={field.onChange}
            >
              {(inputProps: any) => (
                <input
                  id={name}
                  // TODO: add disabled prop to use the disabled prop from the input
                  type={typeTracking}
                  {...inputProps}
                  onKeyUp={e => {
                    triggerStateValidation(
                      (e.target as HTMLInputElement).value
                    );
                  }}
                  onKeyPress={e => {
                    if (submitOnEnter && e.key === 'Enter') {
                      e.preventDefault();

                      (e.target as HTMLInputElement).blur();

                      document
                        .querySelector<HTMLButtonElement>(`#${name}`)
                        .click();
                      document
                        .querySelector<HTMLButtonElement>(`#${name}`)
                        .focus();
                      document
                        .querySelector<HTMLButtonElement>(
                          'button[type="submit"]'
                        )
                        .click();
                    }
                  }}
                  {...rest}
                />
              )}
            </MaskedInput>
          )}
        />
        {type === 'password' && (
          <EyeIcon
            isOpened={typeTracking === 'password'}
            onClick={togglePasswordVisibility}
          />
        )}

        <ErrorSpan visible={inputState === 'error'}>
          {errorMessage || (get(errors, name) && get(errors, name).message)}
        </ErrorSpan>
      </Container>
    );
  }

  return (
    <Container
      className={cn(`input ${className}`, {
        error: inputState === 'error',
        success: inputState === 'success',
      })}
    >
      {label && <Label htmlFor={name}>{optional ? label : `${label} *`}</Label>}
      <input
        id={name}
        disabled={disabled}
        type={typeTracking}
        required={required}
        {...register(name, { required: true })}
        onChange={e => {
          register(name).onChange(e);
          if (onChange) onChange(e);
        }}
        onKeyUp={e => {
          triggerStateValidation((e.target as HTMLInputElement).value);
        }}
        onKeyPress={e => {
          if (submitOnEnter && e.key === 'Enter') {
            e.preventDefault();

            (e.target as HTMLInputElement).blur();

            document.querySelector<HTMLButtonElement>(`#${name}`).click();
            document.querySelector<HTMLButtonElement>(`#${name}`).focus();
            document
              .querySelector<HTMLButtonElement>('button[type="submit"]')
              .click();
          }
        }}
        {...rest}
      />
      {type === 'password' && (
        <EyeIcon
          isOpened={typeTracking === 'password'}
          onClick={togglePasswordVisibility}
        />
      )}

      {hint && <span className="hint">{hint}</span>}

      <ErrorSpan visible={inputState === 'error'}>
        {errorMessage || (get(errors, name) && get(errors, name).message)}
      </ErrorSpan>
    </Container>
  );
};

Input.defaultProps = {
  label: '',
  disabled: false,
  type: 'text',
  onChange: () => {},
  optional: false,
  submitOnEnter: false,
  className: '',
  controlledInputState: null,
  mask: '',
  hint: '',
  required: false,
};

export default Input;
