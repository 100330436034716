import styled, { css } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

interface ContainerProps {
  variant?: 'expanded' | 'condensed';
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;

  margin: 10px 0;
  height: 80px;

  background: var(--neutrals-background-light);
  border-radius: 8px;
  border: 1px solid var(--neutrals-border);

  align-items: center;

  .card-info-container {
    display: flex;
    flex: 1;

    .card-details {
      width: 100%;
      padding: 8px 8px 8px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        font-size: 1rem;

        strong {
          font-weight: 600;
        }
      }

      h3 {
        font-size: 14px;
        margin: 10px 0;
        cursor: pointer;
        text-decoration: underline;
      }

      p {
        color: var(--neutrals-text-weak-active);
        font-size: 10px;
        line-height: 12px;
        margin-top: 4px;
        margin-bottom: 8px;
      }
    }
  }

  height: unset;

  .card-actions {
    display: flex;
    padding-right: 4px;
  }

  ${mediaQuery.sm} {
    .card-details {
      cursor: pointer;
    }
  }
`;

interface CardImageProps {
  unread?: boolean;
}

export const CardImage = styled.div<CardImageProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin: 16px;
  background: var(--neutrals-background);
  border-radius: 50%;
  box-shadow: var(--neutrals-background-hover) 1px 1px 5px 1px;

  .icon-container {
    width: 44px;
    height: 44px;

    .icon {
      font-size: 22px;
    }
  }

  ${({ unread }) =>
    unread &&
    css`
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: var(--select-icon);
      }
    `}
`;
