import styled, { css } from 'styled-components';

interface Props {
  disabled: boolean;
  selected: boolean;
}

export const Container = styled.div<Props>`
  padding: 12px;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: var(--neutrals-background-light);
  border: 1px solid var(--neutrals-border-active);
  overflow: hidden;
  cursor: pointer;

  .label-icon {
    margin-right: 16px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px var(--neutrals-border-active);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .label-text {
    font-size: 16px;
    line-height: 1.2em;
    color: var(--neutrals-text);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}

  ${({ selected }) =>
    !selected &&
    css`
      background-color: var(--neutrals-background);
      border: 1px solid var(--neutrals-border);
    `}
`;
