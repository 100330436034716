const endpoints = {
  totp: '/auth/otp/totp-generate',
  totp_verify: '/auth/otp/totp-verify',
  login: '/auth/login',
  auth_refresh: '/auth/refresh',
  register: '/user/register',
  register_resend: '/user/register/resend',
  user_email_redirect: '/user/email-redirect',
  get_email_confirmation_info: '/user/registration',
  accept_confirm_email: '/user/registration/accept',
  user_register_checkusername: '/user/checkusername',
  user_profile: '/user/profile',
  password_forgot: '/user/password/forgot',
  password_reset: '/user/password/reset/:invitationId',
  password_update: '/user/password',
  get_company: '/company/:companyId',
  get_company_invite: '/companyinvite',
  accept_company_invite: '/companyinvite/accept',
  reject_company_invite: '/companyinvite/reject',
  get_company_invites: '/company/:companyId/invite',
  cancel_company_invite: '/company/:companyId/invite/:inviteId/cancel',
  resend_company_invite: '/company/:companyId/invite/:inviteId/resend',
  check_company_name: '/company/checkcompanyname',
  get_company_captable: '/company/:companyId/captables',
  update_company_captable: '/company/:companyId/captables',
  get_company_members: '/company/:companyId/members',
  get_company_member_permissions:
    '/company/:companyId/members/:username/permissions',
  get_company_applications: '/company/userapplications',
  create_company_application: '/company/application',
  update_company_application: '/company/application/:applicationId/update',
  submit_company_application: '/company/application/:applicationId/submit',
  cancel_company_application: '/company/application/:applicationId/cancel',
  create_company_invite: '/company/:companyId/invite',

  // DOCUMENT VAULT
  create_company_document: '/company/:companyId/document-vault',
  get_company_document_directory: '/company/:companyId/document-vault',
  get_company_document_retrieval_link:
    '/company/:companyId/document-vault/presigned-get',
  update_company_document_upload_status:
    '/company/:companyId/document-vault/upload',

  company_broadcast_message: '/company/:companyId/message/broadcast',

  // KYC
  get_kyc_images: '/kyc/user/images',
  create_kyc_request: '/kyc/user',
  submit_kyc_request: '/kyc/user/submit',
  get_check_kyc_status: '/kyc/user',
  update_user_kyc: '/kyc/user/update',
  get_kyc_personal_info: '/kyc/personal-info',
  update_kyc_info: '/kyc/personal-info/update',
  get_kyc_fields: '/kyc/recommended-fields?country=:country',
  create_kyc_request_international: '/kyc/international',
  submit_kyc_request_international: '/kyc/international/submit',

  create_converstion: '/conversation',
  create_conversation_message: '/conversation/:conversationId/message',
  archive_conversation: '/conversation/:conversationId/archive',
  get_conversation: '/conversation/:conversationId',
  get_inbox: '/conversation/inbox',
  toggle_notification: '/notification/:notificationId',
  notifications: '/notifications',
  checkout_stripe_payment:
    '/company/application/:companyApplicationId/checkout/session',
  update_subscription_payment_method: '/stripe/subscription/:companyId',
  cancel_subscription: '/stripe/subscription/:companyId',
  get_subscription: '/stripe/subscription/:companyId',
  get_subscription_invoices: '/stripe/subscription/:companyId/invoices',
  reactivate_subscription: '/stripe/subscription/:companyId/reactivation',
  get_newsletters: '/newsletter',
  search: '/search',
  get_company_info: '/company/:companyId',
  get_companies: '/company/usercompanies',
  logout: '/auth/logout',
  update_otp: '/user/otp/update',
  get_settings: '/user/uisettings',
  create_dissolution: '/company/:companyId/dissolve',
  update_dissolution: '/company/:companyId/dissolve-update',
  submit_dissolution: '/company/:companyId/dissolve-submit',
  cancel_dissolution: '/company/:companyId/dissolve-cancel',
  get_registered_agents: '/registered-agents/profiles',
};

export type Endpoint = keyof typeof endpoints;

export interface Options {
  route?: Record<string, string>;
  query?: Record<string, string>;
}

export const buildURL = (
  url: Endpoint | string,
  { route: routeParams, query: queryParams = null }: Options
) => {
  const endpoint = endpoints[url] || url;

  const mountedUrl =
    (routeParams &&
      Object.keys(routeParams).reduce((finalUrl, param) => {
        const paramValue = routeParams[param];
        return finalUrl.replace(`:${param}`, paramValue);
      }, endpoint)) ||
    endpoint;

  const urlQueryParams =
    (queryParams &&
      `?${Object.entries(queryParams)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&')}`) ||
    '';

  return `${mountedUrl}${urlQueryParams}`;
};

export default endpoints;
