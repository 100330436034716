import useAppDispatch from 'hooks/useAppDispatch';
import { useEffect } from 'react';
import { getNotificationsRequest } from 'store/slices/notification';

const POLLING_INTERVAL = 300_000; // 5 minutes
// const POLLING_INTERVAL = 10000; // for testing purposes

const useNotifications = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNotificationsRequest());
    const timer = setInterval(() => {
      dispatch(getNotificationsRequest());
    }, POLLING_INTERVAL);
    return () => clearInterval(timer);
  }, []);
};

export default useNotifications;
