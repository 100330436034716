/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container } from './styles';

type FormHandler = (data: any) => void;
type FormStates = 'neutral' | 'success' | 'failure';
interface Props {
  children: React.ReactNode;
  schema: { [key: string]: any };
  defaultValues?: { [key: string]: any };
  onSubmit: FormHandler;
  onInvalid?: FormHandler;
}

export const FormContext = React.createContext(null);

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a Form');
  }

  return context;
};

export const isFormValid = (ref: React.MutableRefObject<HTMLButtonElement>) => {
  const formState = ref.current?.textContent;

  return formState === 'success';
};

const Form = React.forwardRef(
  (
    { children, defaultValues, schema, onSubmit, onInvalid }: Props,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      watch,
      control,
      trigger,
      setValue,
      getValues,
    } = useForm({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      defaultValues,
      ...(schema && { resolver: yupResolver(Yup.object(schema).required()) }),
      context: undefined,
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: true,
    });

    const [formState, setFormState] = React.useState<FormStates>('neutral');

    const onSubmitInterceptor: FormHandler = data => {
      setFormState('success');
      onSubmit(data);
    };

    const onInvalidInterceptor: FormHandler = data => {
      setFormState('failure');
      onInvalid(data);
    };

    return (
      <Container className="formContainer">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitInterceptor, onInvalidInterceptor)}
        >
          <FormContext.Provider
            value={{
              register,
              errors,
              watch,
              control,
              trigger,
              schema,
              setValue,
              getValues,
            }}
          >
            {children}
          </FormContext.Provider>

          <button type="submit" ref={ref} className="hidden">
            {formState}
          </button>
        </form>
      </Container>
    );
  }
);

Form.defaultProps = {
  defaultValues: null,
  onInvalid: () => {},
};

export default Form;
