import React from 'react';
import { Icon } from '../_common';

import { Container } from './styles';

interface Props {
  text: string;
  iconName?: string;
}

const Label: React.FC<Props> = ({ text, iconName }: Props) => {
  return (
    <Container className="label">
      <div className="icon">
        <Icon name={iconName} />
      </div>
      <span className="text">{text}</span>
    </Container>
  );
};

Label.defaultProps = {
  iconName: null,
};

export default Label;
