import React from 'react';
import Icon from '../Icon';
import { Container } from './styles';

interface Props {
  name: string;
  fill?: number;
  weight?: number;
  grade?: number;
  opticalSize?: number;
}

const IconCircle: React.FC<Props> = ({ ...rest }: Props) => {
  return (
    <Container className="icon-container">
      <Icon {...rest} />
    </Container>
  );
};

IconCircle.defaultProps = {
  fill: 1,
  weight: 300,
  grade: 200,
  opticalSize: 40,
};

export default IconCircle;
