import React, { useState } from 'react';
import { Icon } from 'components/atoms/_common';
import { ScopedSection } from 'components/atoms';

import {
  Action,
  Actions,
  ActionsContainer,
  Container,
  IconContainer,
  TextContainer,
} from './styles';
import ConditionalWrapper from './ConditionalWrapper';

export interface SectionHeaderProps {
  title: string;
  subtitle: string;
  icon: string;
  actions?: {
    icon: string;
    onClick: () => void;
    text: string;
    color?: string;
    shouldCloseOnClick?: boolean;
    showOnlyToRole?: 'admin' | 'director' | 'member';
  }[];
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subtitle,
  icon,
  actions,
}: SectionHeaderProps) => {
  const [isActionOpen, setIsActionOpen] = useState(false);

  return (
    <Container className="section-header">
      <IconContainer>
        <Icon name={icon} fill={0} weight={300} grade={200} opticalSize={40} />
      </IconContainer>
      <TextContainer>
        <div>
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
        </div>
        {actions && actions.length > 0 && (
          <ActionsContainer>
            <Icon
              name="more_vert"
              weight={700}
              opticalSize={25}
              onClick={() => setIsActionOpen(s => !s)}
            />
            {isActionOpen && (
              <Actions>
                {actions?.map(
                  ({
                    icon: actionIcon,
                    text,
                    onClick,
                    color,
                    shouldCloseOnClick,
                    showOnlyToRole,
                  }) => {
                    return (
                      <ConditionalWrapper
                        key={text}
                        condition={!!showOnlyToRole}
                        wrapper={children => (
                          <ScopedSection scope={showOnlyToRole}>
                            {children}
                          </ScopedSection>
                        )}
                      >
                        <Action
                          key={text}
                          color={color}
                          onClick={() => {
                            onClick();

                            if (shouldCloseOnClick) {
                              setIsActionOpen(false);
                            }
                          }}
                        >
                          <Icon
                            name={actionIcon}
                            fill={1}
                            weight={300}
                            grade={200}
                          />
                          <p>{text}</p>
                        </Action>
                      </ConditionalWrapper>
                    );
                  }
                )}
              </Actions>
            )}
          </ActionsContainer>
        )}
      </TextContainer>
    </Container>
  );
};

SectionHeader.defaultProps = {
  actions: [],
};

export default SectionHeader;
